import React, { useEffect, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import {
    CustomContentData, DynamicContentType, AppData, ThumbSize, AnalyticsEvent, PromptOptions, FloorplanFilter, CustomEventType, ScreenSize, ScreenOrientation, MarkerType, QueryType, PageType, ProjectFilter, MediaType, PromptType, ErrorMessage, UserRole, PageData, LLMGenerateType
} from 'app/types'
import {
    Button, DropdownMenu, Icon, IconButton, Media, SlideShow, Map, CollapsibleRow, Tile
} from 'components'
import { Link } from 'react-router-dom'
import { getLinkSelector, getMediaLinkSelector, useAdminPermissions, useAppDispatch, useAppSelector, useDownloadMedia, usePage } from 'app/hooks'
import ReactMarkdown from 'react-markdown'
import { ContentForm, ContentView, NotFound } from 'views/AppPage'
import * as fnc from 'helpers/fnc'
import {
    analyticsEvent, navigateAsync, resetQuery, resolvePending, setQuery, showPrompt, submitBooking,
} from 'actions/appActions'
import { downloadMedia, setEditContent, upsertContent } from 'actions/adminActions'
import { LocationLink } from 'views/HomePage/LocationLink'
import { logger } from 'helpers/logger'
import { ProjectTile } from 'views/HomePage/ProjectTile'
import { RequestInfo } from 'views/AppPage/RequestInfo'
import { ProjectMap } from 'views/HomePage/ProjectMap'
import { organizationMapStyles } from 'app/transformers'
import { pageDescription, pageIcons } from 'app/constants'
import gfm from 'remark-gfm'
import { getAppUrl } from 'helpers/config'
import { exportMediaFile } from 'actions/adminActions'
import { ContentGallery } from 'views/AppPage/ContentGallery'
import { HubspotForm } from 'views/AppPage/HubspotForm'
import { packageFormData } from 'helpers/requestHandler'
import { setQueryHelper } from 'helpers/query'
import { createContentMediaFromUrl, getLegacyMediaId } from 'helpers/media'
import { projectSubtitle } from 'helpers/project'
import { LLMContentHelper } from './LLMContentHelper'

interface EditButtonProps {
    id: string,
    staticLink: string,
    content: CustomContentData,
    onEdit: () => void,
    onOrder: () => void,
    onDelete: () => void,
    pageData: PageData,
    data: CustomContentData,
}

export function EditButtons(props: EditButtonProps) {
    const { id, staticLink, onEdit, onOrder, onDelete, page, app, organization, data } = props
    const isStatic = id?.length != 36
    const editContent = useAppSelector((state) => state.admin.editContent)
    const editing = editContent != null && editContent?.id == id
    const [llmHelper, setLLMHelper] = useState(null)
    const [elem, setElem] = useState(null)
    const dispatch = useAppDispatch()
    const downloadMedia = useDownloadMedia(app, organization)

    const [element, setElement] = useState(null)
    const icon = isStatic ? 'fas fa-plus' : 'fas fa-pen'
    const style = {
        width: element?.clientWidth,
        height: '100%',
        // top: -40,
        // left: 0,
        // left: element ? element.getBoundingClientRect().width - 60 : 10,
        // right: 10,
    }
    if (element) {
        const styleB = getComputedStyle(element);
        const widthWithPadding = element.clientWidth;
        const paddingLeft = parseFloat(styleB.paddingLeft);
        const paddingRight = parseFloat(styleB.paddingRight);
        style.width = widthWithPadding - paddingLeft - paddingRight;
    }

    useEffect(() => {
        // Get element with attribute data-id={id}
        const elem = document.querySelector(`.dynamic-content[data-id="${id}"]`)
        if (!elem) {
            return
        }
        setElement(elem)
    }, [])

    function handleEdit() {
        handleContentAction('edit', { id, staticLink })
    }

    function handleOrder(direction) {
        handleContentAction('order', { id, direction })
    }

    function handleDelete() {
        handleContentAction('delete', { id })
    }
    function handleCancel() {
        handleContentAction('cancel', { id })
    }

    function handleCopy() {
        handleContentAction('copy', { id })

    }

    function handleContentAction(action, data) {
        window.dispatchEvent(new CustomEvent(CustomEventType.ContentAction, { detail: { action, data, page } }))
    }

    function handleLLM(x, e) {
        let type = null
        let title = ''
        let value = x in data ? data[x] : null
        let defaultValue = ''
        switch (x) {
            default:
                break
            case 'title':
                type = LLMGenerateType.SingleField
                title = 'Generate Title'
                break
            case 'subtitle':
                type = LLMGenerateType.SingleField
                title = 'Generate Subtitle'
                break
            case 'body':
                type = LLMGenerateType.SingleField
                title = 'Generate Body'
                break
            case 'social':
                type = LLMGenerateType.SocialMediaPost
                title = 'Generate Social Media Post'
                defaultValue = 'Summarize this content for social media'
                break
            case 'image':
                type = LLMGenerateType.RetrieveImage
                title = 'Replace Image'
                break
        }
        const rect = e.target.getBoundingClientRect()
        const newHelper = {
            id: x,
            type,
            value: x in data ? data[x] : null,
            // options: {
            // defaultValue: type == 
            // },
            target: e.target,
            title,
            top: rect.top + 10,
            left: rect.left + rect.width / 2 - 400 / 2,
            options: { data, defaultValue }
        }
        setLLMHelper(newHelper)
    }

    async function handleLLMValue(value) {
        try {
            switch (llmHelper.id) {
                case 'image': {
                    const newData = { ...data, media: [...data.media] }
                    if (typeof value == 'string') {
                        const media = await downloadMedia(value)
                        if (newData.media.length == 0) {
                            newData.media.push(media)
                        } else {
                            newData.media[0] = { ...newData.media[0], mediaId: media.mediaId }
                        }
                    } else {
                        if (newData.media.length == 0) {
                            newData.media.push({ mediaId: value.id })
                        } else {
                            newData.media[0] = { ...newData.media[0], mediaId: value.id }
                        }
                    }
                    await dispatch(upsertContent({ app, organization, data: newData }))
                    break
                }
                default: {
                    const newData = { ...data, [llmHelper.id]: value }
                    await dispatch(upsertContent({ app, organization, data: newData }))
                    break
                }
            }
        } catch (e) {
            logger.error(e)
            return e
        }
        return null
        // return '!'
    }
    function handleLLMClose() {
        setLLMHelper(null)
    }

    /*useEffect(() => {
        const int = setInterval(applyStyle, element ? 5000 : 1000)
        applyStyle()
 
        return () => {
            clearInterval(int)
        }
    }, [element])*/
    const aiItems = [
        { text: 'Generate title', value: 'title' },
        { text: 'Generate subtitle', value: 'subtitle' },
        { text: 'Generate body', value: 'body' },
        { text: 'Replace media', value: 'image' },
        { text: 'Generate social media post', value: 'social' }
    ]

    return <div className={`edit-inline-wrapper ${editing ? ' editing' : ''}`} style={style}>
        <div className="row edit-inline animate__animated animate__zoomIn" ref={setElem}>
            {!editing && isStatic && <IconButton tertiary onClick={handleEdit} icon={icon} />}
            {!editing && !isStatic && <React.Fragment>
                <IconButton tertiary onClick={handleEdit} icon={icon} />
                <DropdownMenu buttonClass="button-tertiary" items={aiItems} tertiary icon="fas fa-hand-sparkles" onChange={handleLLM} />
                <IconButton tertiary onClick={() => handleOrder(-1)} icon="fas fa-arrow-up" />
                <IconButton tertiary onClick={() => handleOrder(1)} icon="fas fa-arrow-down" />
                <IconButton tertiary onClick={handleCopy} icon="fas fa-clone" />
                <IconButton tertiary onClick={handleDelete} icon="fas fa-trash" />
            </React.Fragment>}
            {editing && <React.Fragment>
                <IconButton tertiary onClick={handleCancel} icon="fas fa-times" />
            </React.Fragment>}
            {llmHelper && <LLMContentHelper {...llmHelper} app={app} organization={organization} onSubmit={handleLLMValue} onClose={handleLLMClose} />}
        </div>
    </div >
}