import { DynamicContentType, MediaType, PageType, AnalyticsEvent } from 'app/types'
import { referralSources, Provinces } from 'app/constants'

export const GatesOfMeafordContentData = {
    'info': [
        {
            type: DynamicContentType.Header,
            media: [{ galleryLink: 'gallery'} ],
            alt: true,
        },
        {
            type: DynamicContentType.ProjectInfo,
            title: 'Now Selling!',
            // title: 'Bungalow Launch Event on Victoria Day Weekend!',
            // subtitle: 'May 18 + 19 from 12pm-3pm',
            location: {
                name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                address: {
                    numberAndStreet: '11352 ON-26',
                    city: 'Collingwood',
                    provinceState: 'ON',
                    postalZipCode: 'L9Y 5C6',
                },
            },
            body: "Experience our homes through VR and check out our detached, semi-detached and bungaloft homes starting from $649,000.\n\nOur sales office is located at 11352 ON-26, Collingwood, ON.\n\nContact us today, we're happy to help!",
            media: [{ galleryLink: 'highlight', index: 0 }],
            // body: "Register for our FREE event this Victoria Day long weekend! Enjoy a complimentary BBQ lunch and get VIP access to exclusive incentives when you purchase a new bungalow or bungaloft model.",
            // links: [{ label: 'Register Now', link: 'bungalow-launch-event' }],
            links: [{ label: 'Request Information', link: '#request' }],
        },
        {
            type: DynamicContentType.Blurb,
            title: "Welcome to Nortterra at the Gates of Meaford",
            header: "From a hilltop vantage point with stunning views to the harbour, you can enjoy this four season destination for a lifetime",
            body: "Perched on a hilltop overlooking Meaford’s downtown, the masterplanned community at the Gates of Meaford is only a walk away from a historic main street and bustling harbour. With only a 7-minute drive to Thornbury or a 15-minute drive to the Blue Mountains, there are endless opportunities for recreation, entertainment, and leisure. Whether it’s for the first time buyer, move-up buyer, or the move-over buyer, homeowners at the Gates of Meaford will benefit from the vibrant lifestyle of Southern Georgian Bay."
        },
        {
            type: DynamicContentType.Media,
            media: { galleryLink: 'video', index: 0 },
        },
        {
            type: DynamicContentType.Blurb,
            title: "Semi-detached Models",
            body: "All of our semi-detached models were designed with family in mind. Whether its for the first time buyer, move-up buyer, or the move-over buyer, homeowners at the Gates of Meaford will benefit from the vibrant lifestyle of Southern Georgian Bay.",
            media: { galleryLink: 'info', index: 1, position: 1 },
            // links: [{ label: 'View Floor Plans', pageType: PageType.Sitemap, focus: true }, { label: 'View Elevations', link: 'gallery#elevations', focus: true }],
        },
        {
            type: DynamicContentType.Blurb,
            body: "With our virtual tours, you'll get a look at standard and upgraded features and finishes that our semi-detached homes have to offer, without ever having to leave your living room.",
            media: [
                {
                    type: MediaType.Tour,
                    subtitle: 'Semi-detached standard suite',
                    link: 'semi-detached-standard',
                },
                {
                    type: MediaType.Tour,
                    subtitle: 'Semi-detached upgraded suite',
                    link: 'semi-detached-upgraded',
                }
            ]
        },
        {
            type: DynamicContentType.Blurb,
            body: "Our large lots provide ample space for activities such as playing sports, camping, and enjoying outdoor activities in the privacy of your own backyard.\n\nYou will have the option for us to build you a beautiful and functional deck, transforming your backyard into an outdoor oasis.",
            media: {
                type: MediaType.Spin,
                link: 'semi-detached',
                // subtitle: 'Test 123',
                // galleryLink: 'info-media',
                // index: 2,
                position: 0,
            }
        },
        {
            type: DynamicContentType.Blurb,
            title: "Detached Models",
            body: "All of our detached models were designed with family in mind. With 2 stories, a 2-car garage, and extra basement storage, there are no constraints on your family's future plans. Whether its for the first time buyer, move-up buyer, or the move-over buyer, homeowners at the Gates of Meaford will benefit from the vibrant lifestyle of Southern Georgian Bay.",
            media: { galleryLink: 'info', index: 0, position: 1 },
            // links: [{ label: 'View Floor Plans', pageType: PageType.Sitemap, focus: true }, { label: 'View Elevations', link: 'gallery#elevations', focus: true }],
        },
        {
            type: DynamicContentType.Blurb,
            body: 'We offer a wide range of home upgrade options to enhance your living space and improve the overall functionality of your home. Our outdoor deck upgrade option is perfect for entertaining guests, relaxing, and spending time with friends and family.',
            media: {
                type: MediaType.Spin,
                link: 'detached',
                // galleryLink: 'info-media',
                // index: 3,
                position: 0,
            },
        },
        /*{
            type: CustomContentType.Blurb,
            title: "Site Plan",
            body: "Explore the Gates of Meaford site plan and see available lots.",
            media: { galleryLink: 'info', index: 1 },
            links: [{ label: 'Explore Site Plan', link: 'floorplans' }],
            focus: true,
        },*/
        {
            type: DynamicContentType.Blurb,
            title: "Your Home, Your Choice.",
            body: "At Nortterra, we pride ourselves on selecting quality materials, especially when it comes to interior finishes. Our approach to interior design takes a light and modern feel accented with natural tones and texture. In our standard home, buyers will have a variety of finish options. In the event that our standard doesn't meet yours, we have curated a selection of over 30 + upgrades for substitution. We want your home to be your choice.",//\n\nSchedule an appointment to come and explore the options we have ready for you.",
            media: { galleryLink: 'info', index: 2, position: 1 },
            // links: [{ label: 'View Upgrade Options', link: 'upgrades' }],
        },
        {
            type: DynamicContentType.Blurb,
            title: "Discover Meaford",
            header: "A gateway to Southern Georgian Bay",
            body: "Situated on the southern shore of Georgian Bay, the Municipality of Meaford is a fascinating, four-season destination that has it all — great live theatre and concerts, fresh local food, unique shopping, nature at your door step, a beautiful harbour and so much more.",
            media: { galleryLink: 'info', index: 3, position: 0 },
            // links: [{ label: 'Explore the Neighbourhood', link: 'thingstodo' }],
        },
        {
            type: DynamicContentType.Accordian,
            title: "Destinations & Amenities",
            body: "There is no shortage of things to do and places to discover. In addition to the countless year-round, outdoor activities available nearby, enjoy a variety of countless options for the family in the heritage town of Collingwood, quaint village of The Blue Mountains, the sandy shores of Wasaga Beach, and the rustic charm of Clearview.",
            alt: true,
            elements: [
                {
                    title: "To the East",
                    body: "Thornbury (10 min), The Blue Mountains (15 min), Collingwood (25 min), Wasaga (35 min)",
                },
                {
                    title: "To the West",
                    body: "Owen Sound (30 min) & Sauble Beach (60min)",
                },
                {
                    title: "School",
                    body: "_Georgian Bay Community School_. A Brand new Regional school, taking in students from kingergarten to garde twelve",
                },
                {
                    title: "College",
                    body: "_Georgian College, South Georgian Bay Campus_. Take a single course, or work towards a diploma, certificate or degree at Georgian College's Campus located just a short drive away in Collingwood.",
                },
                {
                    title: "Groceries",
                    body: "_Independent Grocer, Foodland, Rexall, Petsmart, LCBO, The Beer Store, Dollarama_. In addition to the convenient big box retailer, Meaford has plenty of boutique shops in the downtown core and any needs beyond what's provided is within convenient distance to Thornbury or Collingvvood.",
                },
                {
                    title: "Skiing and Snowboarding",
                    body: "_Blue Mountain Village_ and various other private ski clubs located in the Town of Blue Mountains (15 min)",
                },
                {
                    title: "Golf",
                    body: "_Lora Bay & Meaford Golf Club_",
                },
                {
                    title: "Dog Friendly",
                    body: "_Beautiful Joe Leash-Free Dog Park, Memorial Park Dog Beach._ Meaford is blessed with a love of dogs, and thars seen through our various pet friendly businesses and numerous events and activities. Meaiord has dedicated specific swimming areas for dogs at Memorial Park, pet friendly trails, and an off-leash dog park.",
                },
            ]
        },
        {
            type: DynamicContentType.Map,
            alt: true,
        },
        {
            type: DynamicContentType.Items,
            title: "Continue Exploring...",
            elements: [
                {
                    title: 'Event on May 20th, 2023',
                    media: { galleryLink: 'info-explore', index: 2 },
                    body: "You're invited to enjoy snacks and drinks on May 20th, 2023 from 12 - 4 pm, as we unveil our latest floor plans at the Gates of Meaford!",
                    links: [{ label: "Go", link: 'event', focus: true }],
                },
                {
                    title: 'Virtual Tours',
                    media: { galleryLink: 'info-explore', index: 0 },
                    body: "Our virtual tours allow you to take a detailed walkthrough of our semi-detached homes from the comfort of your home.",
                    links: [{ label: "Go", pageType: PageType.Modelhome, focus: true }],
                },
                {
                    title: 'Floor Plans',
                    media: { galleryLink: 'info-explore', index: 1 },
                    body: "Our open-concept design is perfect for both entertaining and everyday living. View, compare and favourite our floor plans.",
                    links: [{ label: "Go", pageType: PageType.Sitemap, focus: true }],
                },
                /*{
                    title: 'Sales Office',
                    media: { galleryLink: 'info-explore', index: 2 },
                    body: "Information about our sales office location and hours of operation.",
                    links: [{ label: "Go", link: 'contact', focus: true }],
                },*/
            ],
            full: true,
        }
    ],
    'contact': [
        {
            type: DynamicContentType.Header,
            media: [{
                galleryLink: 'info-explore', index: 2
            }]
        },
        {
            type: DynamicContentType.Event,
            title: 'Sales Office',
            elements: [
                {
                    title: 'LOCATION',
                    location: {
                        name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    },
                },
                {
                    title: 'HOURS OF OPERATION',
                    body: "| | |\n| -- | -- |\n| Monday | 11 a.m. - 6 p.m. |\n| Tuesday | 11 a.m. - 6 p.m. |\n| Wednesday | By appointment only |\n| Thursday | By appointment only |\n| Friday | 11 a.m. - 6 p.m. |\n| Saturday | 11 a.m. - 6 p.m. |\n| Sunday | 11 a.m. - 6 p.m. |",
                }
            ],
        },
        {
            type: DynamicContentType.Blurb,
            dividerBefore: true,
            title: "Request Information",
            body: "Want to learn more about The Gates of Meaford?\n\nContact us today and we will be happy to answer any questions you may have.",
            links: [{ label: 'Request Info', link: '#request' }]//{ label: 'Visit The PropertyLinq', organization: true }],
        },

        /*{
            type: CustomContentType.Form,
            dividerBefore: true,
            title: "Request Information",
            body: "Want to learn more about owning a new home at The Gates of Meaford?\n\nContact us today and we will be happy to answer any questions you may have.",
            subtitle: "Fields marked with a * are required",
            footer: 'I am interested in the following home specifications',
            requestType: 'Event Registration',
            fields: [
                {
                    id: 'firstname',
                    label: 'FIRST NAME',
                    required: true,
                },
                {
                    id: 'lastname',
                    label: 'LAST NAME',
                    required: true,
                },
                {
                    id: 'email',
                    label: 'EMAIL',
                    required: true,
                },
                {
                    id: 'phone',
                    label: 'PHONE',
                    required: true,
                    inputProps: { phone: true },
                },
                {
                    id: 'address',
                    label: 'ADDRESS',
                    required: false,
                },
                {
                    id: 'city',
                    label: 'CITY',
                    required: true,
                },
                {
                    id: 'province',
                    label: 'PROVINCE',
                    dropdown: Provinces,
                    select: 'Select Province',
                    required: false,
                },
                {
                    id: 'postalcode',
                    label: 'POSTAL CODE',
                    required: false,
                },
                {
                    id: 'hometype',
                    label: 'HOME TYPE INTERESTED IN',
                    select: 'Select Home Type',
                    dropdown: ['Detatched', 'Semi-Detached'],
                    required: false,
                    extra: 'Home Type',
                },
                {
                    id: 'sqft',
                    label: 'SQUARE FOOTAGE INTERESTED IN',
                    dropdown: ['1000+', '1500+', '2000+', '2500+'],
                    select: 'Select Square Footage',
                    required: false,
                    extra: 'Sqft',
                },
                {
                    id: 'bedrooms',
                    label: 'NUMBER OF BEDROOMS INTERESTED IN',
                    dropdown: ['1+', '2+', '3+', '4+'],
                    select: 'Select Bedrooms',
                    required: false,
                    extra: 'Bedrooms',
                },
                {
                    id: 'bathrooms',
                    label: 'NUMBER OF BATHROOMS INTERESTED IN',
                    dropdown: ['1+', '2+', '3+', '4+'],
                    select: 'Select Bathrooms',
                    required: false,
                    extra: 'Bathrooms',
                },
            ]
        },*/
    ],

    'thankyou': [
        {
            type: DynamicContentType.Event,
            title: 'Thank you!',
            // body: `Thank you for registering! We'll keep you posted about project updates and upcoming events at The Property LinQ.\n\nWe welcome you to visit our sales office hours in Collingwood. We hope to see you soon!`
            body: `Thank you for registering! We'll keep you posted about project updates and upcoming events at The Property LinQ.`
        },
        {
            type: DynamicContentType.Items,
            title: "Continue Exploring...",
            elements: [
                {
                    title: 'Sales Office',
                    media: { galleryLink: 'info-explore', index: 2 },
                    body: "Information about our sales office location and hours of operation.",
                    links: [{ label: "Go", link: 'contact', focus: true }],
                },
                {
                    title: 'Virtual Tours',
                    media: { galleryLink: 'info-explore', index: 0 },
                    body: "Our virtual tours allow you to take a detailed walkthrough of our semi-detached homes from the comfort of your home.",
                    links: [{ label: "Go", pageType: PageType.Modelhome, focus: true }],
                },
                {
                    title: 'Floor Plans',
                    media: { galleryLink: 'info-explore', index: 1 },
                    body: "Our open-concept design is perfect for both entertaining and everyday living. View, compare and favourite our floor plans.",
                    links: [{ label: "Go", pageType: PageType.Sitemap, focus: true }],
                },
            ],
            full: true,
        }
    ],
    'register': [
        {
            type: DynamicContentType.Header,
            media: [{
                galleryLink: 'register', index: 0
            }]
        },
        {
            type: DynamicContentType.Event,
            title: 'Register to learn more about the Gates of Meaford.',
        },
        {
            type: DynamicContentType.Form,
            body: "Register below and we will contact you with more information about the Gates of Meaford by Nortterra.",
            subtitle: "Fields marked with a * are required",
            requestType: AnalyticsEvent.ProjectRegistration,
            redirect: '#thankyou',
            fields: [
                {
                    id: 'firstname',
                    label: 'FIRST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'lastname',
                    label: 'LAST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'email',
                    label: 'EMAIL',
                    required: true,
                    size: 2,
                },
                {
                    id: 'phone',
                    label: 'PHONE',
                    required: true,
                    inputProps: { phone: true },
                    size: 2,
                },
                {
                    id: 'address',
                    label: 'ADDRESS',
                    required: false,
                    size: 2,
                },
                {
                    id: 'city',
                    label: 'CITY',
                    required: true,
                    size: 2,
                },
                {
                    id: 'province',
                    label: 'PROVINCE',
                    dropdown: Provinces,
                    select: 'Select Province',
                    required: false,
                    size: 1,
                },
                {
                    id: 'postalcode',
                    label: 'POSTAL CODE',
                    required: false,
                    size: 1,
                    breakAfter: true,
                },
                {
                    id: 'hometype',
                    label: 'HOME TYPE INTERESTED IN',
                    select: 'Select Home Type',
                    dropdown: ['Detatched', 'Semi-Detached'],
                    required: false,
                    extra: 'Home Type',
                    size: 1,
                },
                {
                    id: 'sqft',
                    label: 'SQUARE FOOTAGE INTERESTED IN',
                    dropdown: ['1000+', '1500+', '2000+', '2500+'],
                    select: 'Select Square Footage',
                    required: false,
                    extra: 'Sqft',
                    size: 1,
                },
                {
                    id: 'bedrooms',
                    label: 'NUMBER OF BEDROOMS INTERESTED IN',
                    dropdown: ['1+', '2+', '3+', '4+'],
                    select: 'Select Bedrooms',
                    required: false,
                    extra: 'Bedrooms',
                    size: 1,
                },
                {
                    id: 'bathrooms',
                    label: 'NUMBER OF BATHROOMS INTERESTED IN',
                    dropdown: ['1+', '2+', '3+', '4+'],
                    select: 'Select Bathrooms',
                    required: false,
                    extra: 'Bathrooms',
                    size: 1,
                },
                {
                    id: 'howdidyouhear',
                    label: 'HOW DID YOU HEAR ABOUT US?',
                    dropdown: referralSources,
                    required: false,
                    other: true,
                    select: 'Select one option',
                    extra: 'How did you hear about us',
                    size: 1,
                },
                {
                    id: 'areyouarealtor',
                    label: 'ARE YOU A REALTOR?',
                    choice: ['Yes', 'No'],
                    required: false,
                    initial: 'No',
                    select: 'Select one option',
                    extra: 'Are you a realtor',
                    size: 1,
                },
                {
                    id: 'areyouworkingwithaarealtor',
                    label: 'ARE YOU WORKING WITH A REALTOR?',
                    choice: ['Yes', 'No'],
                    required: false,
                    initial: 'No',
                    select: 'Select one option',
                    extra: 'Are you working with a realtor',
                    size: 1,
                },
                {
                    id: 'consent',
                    label: "By clicking this box or by completing this Registration Form for The Gates of Meaford, I expressly provide my consent to receive electronic messages from Nortterra or The Property LinQ and its affiliated companies retroactively, today and in the future for any projects by Nortterra or The Property LinQ. I understand that I may withdraw my consent by unsubscribing anytime.",
                    required: true,
                    size: 4,
                }
            ],
        },
    ],
    'abouttplold': [
        {
            type: DynamicContentType.Header,
            media: [{
                galleryLink: 'abouttpl',
            }],
        },
        {
            type: DynamicContentType.Event,
            title: 'Grand Opening',
            subtitle: 'Come enjoy the grand opening of The Property LinQ!\n\nStop by anytime between 11am - 6pm on Thursday December 15th, 2022 for this open door event. We hope to see you there!',
            elements: [
                {
                    title: 'LOCATION',
                    location: {
                        name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    },
                },
                {
                    title: 'DATE',
                    subtitle: 'Thursday December 15th, 2022\n\n11 am - 6 pm',
                }
            ],
            dividerAfter: true,
            media: ['organization'],
        },
        {
            type: DynamicContentType.Blurb,
            supertitle: "Come and Discover",
            title: "Georgian Bay's Innovative\n\nHome Experience Centre",
            body: "The Property LinQ is here to connect you with your dream home. Your one-stop shop for finding new build homes in Southern Georgian Bay, our showcase experience centre and knowledgeable sales staff use cutting-edge technology to place you inside your future home from one convenient location.\n\nWe’re thrilled to announce our grand opening on December 15th, and wanted to invite the Southern Georgian Bay community, and those who are looking to join it, to come and explore the latest new build homes from our first development partner - [**Nortterra**](https://www.nortterra.com/)\n\nThis is the start of something beautiful in Southern Georgian Bay, and we look forward to meeting you in person at our home showcase experience centre soon. Keep an eye on your inbox for invitations to explore more communities from some of Ontario’s premier home builders.",
            titleMedia: { galleryLink: 'grandopening', index: 0, position: 0 },
        },
        {
            type: DynamicContentType.Blurb,
            title: 'Experienced Partners',
            body: "We work with leaders in the Ontario real estate to bring you a state-of-the-art sales experience.",
            media: { galleryLink: 'grandopening', index: 1, position: 1 },
            alt: true,
            inline: true,
        },
        {
            type: DynamicContentType.Blurb,
            title: 'Innovative Technology',
            body: "We are transforming the homebuying process with ground-breaking virtual tours using VR technology.",
            media: { galleryLink: 'grandopening', index: 2, position: 0 },
            alt: true,
            inline: true,
        },
        {
            type: DynamicContentType.Blurb,
            title: 'Expert Sales Staff',
            body: "Our sales team has deep roots in Georgian Bay and can give you an insider's perspective on the area",
            media: { galleryLink: 'grandopening', index: 3, position: 1 },
            alt: true,
            inline: true,
        },
    ],
    'abouttpl': [
        {
            type: DynamicContentType.Splash,
            title: "The Property LinQ Promise",
            body: "The Property LinQ makes it simple, seamless, and fun to find your next home in Southern Georgian Bay. From our convenient online house hunting tools to our innovative showcase experience centre, we've created a buyer-friendly process that will connect you with your dream home on your terms.",
            media: { galleryLink: 'tpl-whoweare-splash' },
            links: [{ link: '', label: 'Visit The Property LinQ', root: true }]
        },
        {
            type: DynamicContentType.Steps,
            title: 'How it works',
            elements: [
                {
                    title: 'Start your\n\nsearch online',
                    body: 'Begin by exploring a variety of new build homes online from our trusted development partners.',
                    media: { galleryLink: 'tpl-whoweare-steps', index: 0 },
                },
                {
                    title: 'Take a\n\nFuturistic Virtual Tour',
                    body: 'Use VR technology to virtually walk through your next home with custom layouts, finishes, and furnishings.',
                    media: { galleryLink: 'tpl-whoweare-steps', index: 1 },
                },
                {
                    title: 'Connect with\n\nYour Developer',
                    body: 'Our helpful sales staff will connect you with your preferred developer to help make your dream home a reality.',
                    media: { galleryLink: 'tpl-whoweare-steps', index: 2 },
                }
            ]
        },
        {
            type: DynamicContentType.Items,
            title: 'Meet the partners',
            body: "We work with a team of industry-leading partners who care about matching you with the perfect home. With decades of experience in real estate and cutting-edge technology you won't find anywhere else, we've created a homebuying process that puts you at the centre of it all.",
            elements: [
                {
                    title: 'EDEV',
                    body: "For more than 25 years, EDEV Inc. has been a trusted real estate development manager and investment partner in Toronto. With an extensive partner network that includes pension fund asset managers, private equity, and REITs, they have developed a wealth of experience across a variety of asset classes, including residential, commercial, and self-storage, with an increased focus on purpose-built and transit-oriented multi-family communities.",
                    logo: { galleryLink: 'tpl-whoweare-partners', index: 0 },
                    media: { galleryLink: 'tpl-whoweare-partners', index: 1 },
                    expandable: true,
                },
                {
                    title: 'Bosley Real Estate',
                    body: "Bosley Real Estate is a fourth generation, Canadian, family-owned business operating in Ontario since 1928. With such great longevity comes knowledge, brand recognition, and an unparalleled level of consumer trust. Bosley’s experience covers all aspects of real estate spanning land assembly, urban, and rural development, appraisal, retail and commercial, residential sales, international relocation, and so much more. The highly trained team at Bosley Real Estate has our customers’ best interests in mind with a clear focus on a smooth, effective, and enjoyable sales experience.",
                    logo: { galleryLink: 'tpl-whoweare-partners', index: 2 },
                    media: { galleryLink: 'tpl-whoweare-partners', index: 3 },
                    expandable: true,
                },
                {
                    title: 'INVENT DEV',
                    body: "INVENT DEV has been helping best-in-class builders sell homes using powerful visualization marketing and sales tools for over 10 years. INVENT DEV has won many awards, including the NAHB Global Innovation Award for the impact of its virtual model homes in helping builders sell projects without constructing a physical model home. INVENT DEV has been assisting builders pre-sell homes at higher prices on an international scale, using highly engaging marketing and sales tools.",
                    logo: { galleryLink: 'tpl-whoweare-partners', index: 4 },
                    media: { galleryLink: 'tpl-whoweare-partners', index: 5 },
                    expandable: true,
                }
            ],
            alt: true,
        },
        {
            type: DynamicContentType.Blurb,
            title: 'Our business is built on relationships',
            body: "We’ve partnered with some of Canada’s most trusted and experienced developers who are bringing beautiful new build homes to Southern Georgian Bay. These are builders who care about quality, integrity, and helping buyers find homes that transform their lives.",
            links: [
                {
                    label: 'Benefits of Being A Partner',
                    link: 'partnerships',
                }
            ],
            media: { galleryLink: 'tpl-whoweare-clients', index: 0, size: 8, position: 0 },
        },
        {
            type: DynamicContentType.RequestInfo
        }
    ],
    'eventold': [
        {
            type: DynamicContentType.Header,
            media: [{
                galleryLink: 'event', index: 0
            }]
        },
        {
            type: DynamicContentType.Event,
            title: 'Gates of Meaford Event on May 20th, 2023',
            elements: [
                {
                    title: 'LOCATION',
                    location: {
                        name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    },
                },
                {
                    title: 'DATE',
                    subtitle: 'Saturday May 20th, 2023\n\n12 - 4 pm',
                }
            ],
            dividerAfter: true,
        },
        {
            type: DynamicContentType.Form,
            body: "You're invited to enjoy snacks and refreshing drinks on May 20th, 2023 from 12 - 4 pm, as we unveil our latest floor plans at the Gates of Meaford!\n\nExperience a whole new dimension of our homes with our cutting-edge VR technology, designed to showcase every inch of our most popular floor plan.\n\nDiscover the exquisite Gates of Meaford project, featuring detached, semi-detached and bungalofts starting from $649,000.\n\nOur sales office is located in Collingwood at Highway 26 & Gun Club Road, is easily accessible, and we eagerly look forward to your esteemed presence. Join us for an unforgettable event! ",
            subtitle: "Fields marked with a * are required",
            requestType: AnalyticsEvent.EventRegistration,
            redirect: '#thankyou',
            fields: [
                {
                    id: 'firstname',
                    label: 'FIRST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'lastname',
                    label: 'LAST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'email',
                    label: 'EMAIL',
                    required: true,
                    size: 2,
                },
                {
                    id: 'phone',
                    label: 'PHONE',
                    required: true,
                    inputProps: { phone: true },
                    size: 2,
                },
                {
                    id: 'address',
                    label: 'ADDRESS',
                    required: false,
                    size: 2,
                },
                {
                    id: 'city',
                    label: 'CITY',
                    required: true,
                    size: 2,
                },
                {
                    id: 'province',
                    label: 'PROVINCE',
                    dropdown: Provinces,
                    select: 'Select Province',
                    required: false,
                    size: 1,
                },
                {
                    id: 'postalcode',
                    label: 'POSTAL CODE',
                    required: false,
                    size: 1,
                    breakAfter: true,
                },
                {
                    id: 'hometype',
                    label: 'HOME TYPE INTERESTED IN',
                    select: 'Select Home Type',
                    dropdown: ['Detatched', 'Semi-Detached'],
                    required: false,
                    extra: 'Home Type',
                    size: 1,
                },
                {
                    id: 'sqft',
                    label: 'SQUARE FOOTAGE INTERESTED IN',
                    dropdown: ['1000+', '1500+', '2000+', '2500+'],
                    select: 'Select Square Footage',
                    required: false,
                    extra: 'Sqft',
                    size: 1,
                },
                {
                    id: 'bedrooms',
                    label: 'NUMBER OF BEDROOMS INTERESTED IN',
                    dropdown: ['1+', '2+', '3+', '4+'],
                    select: 'Select Bedrooms',
                    required: false,
                    extra: 'Bedrooms',
                    size: 1,
                },
                {
                    id: 'bathrooms',
                    label: 'NUMBER OF BATHROOMS INTERESTED IN',
                    dropdown: ['1+', '2+', '3+', '4+'],
                    select: 'Select Bathrooms',
                    required: false,
                    extra: 'Bathrooms',
                    size: 1,
                },
                {
                    id: 'howdidyouhear',
                    label: 'HOW DID YOU HEAR ABOUT US?',
                    dropdown: ['Agent', 'Referral', 'Signage', 'Facebook', 'Instagram', 'Google', 'Buzz buzz homes', 'Other'],
                    required: false,
                    other: true,
                    select: 'Select one option',
                    extra: 'How did you hear about us',
                    size: 1,
                },
                {
                    id: 'areyouarealtor',
                    label: 'ARE YOU A REALTOR?',
                    choice: ['Yes', 'No'],
                    required: false,
                    initial: 'No',
                    select: 'Select one option',
                    extra: 'Are you a realtor',
                    size: 1,
                },
                {
                    id: 'areyouworkingwithaarealtor',
                    label: 'ARE YOU WORKING WITH A REALTOR?',
                    choice: ['Yes', 'No'],
                    required: false,
                    initial: 'No',
                    select: 'Select one option',
                    extra: 'Are you working with a realtor',
                    size: 1,
                },
                {
                    id: 'consent',
                    label: "By clicking this box or by completing this Registration Form for The Gates of Meaford, I expressly provide my consent to receive electronic messages from Nortterra or The Property LinQ and its affiliated companies retroactively, today and in the future for any projects by Nortterra or The Property LinQ. I understand that I may withdraw my consent by unsubscribing anytime.",
                    required: true,
                    size: 4,
                }
            ],
        },
    ],
    'event': [
        {
            type: DynamicContentType.Header,
            media: [{
                galleryLink: 'eventmarch', index: 0
            }]
        },
        {
            type: DynamicContentType.Event,
            title: 'Gates of Meaford March Break Event',
            elements: [
                {
                    title: 'LOCATION',
                    location: {
                        name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    },
                },
                {
                    title: 'DATE',
                    subtitle: 'Saturday March 9th and Sunday March 10th, 2024\n\n11 am - 6 pm',
                }
            ],
            dividerAfter: true,
        },
        {
            type: DynamicContentType.Form,
            body: "Enjoy:\n\n* Free pizza!\n* Bouncy house for the kids\n* Our virtual reality experience\n\nOn March 9th and 10th, we will be hosting a March Break Event from 11 a.m. – 6 p.m. each day.\n\nWith a theme of 'Dream Home', your kids will get the chance to jump on our bouncy house and create their own dream home drawing in our creativity zone.\n\nGrown-ups looking for some dream home fun? You can try our VR Headset and get a tour of our gorgeous Gates of Meaford Properties.\n\nWe’ll also be offering free pizza, in partnership with an incredible local pizzeria!\n\nWe’d love to see you and your family there!",
            subtitle: "Fields marked with a * are required",
            requestType: AnalyticsEvent.EventRegistration,
            redirect: '#thankyou',
            formOptions: {
                submitMessage: 'Register',
                tags: ['marchbreak'],
            },
            fields: [
                {
                    id: 'firstname',
                    label: 'FIRST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'lastname',
                    label: 'LAST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'email',
                    label: 'EMAIL',
                    required: true,
                    size: 2,
                },
                {
                    id: 'phone',
                    label: 'PHONE',
                    required: true,
                    inputProps: { phone: true },
                    size: 2,
                },
            ],
        },
    ],
    'neighborhood': [{
        type: DynamicContentType.Neighbourhood,
        title: 'Neighbourhood Points of Interest',
        media: { mediaLink: 'Meaford - 2023.kml', default: '1' },
    }],
    'bungalow-launch-event': [
        {
            type: DynamicContentType.Event,
            title: 'Bungalow Launch Event on Victoria Day Weekend!',
            elements: [
                {
                    title: 'LOCATION',
                    location: {
                        name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    },
                },
                {
                    title: 'DATE',
                    subtitle: 'May 18 + 19 from 12pm-3pm',
                }
            ],
            dividerAfter: true,
        },
        {
            type: DynamicContentType.Form,
            body: "Register for our FREE event this Victoria Day long weekend! Enjoy a complimentary BBQ lunch and get VIP access to exclusive incentives when you purchase a new bungalow or bungaloft model.",
            subtitle: "Fields marked with a * are required",
            requestType: AnalyticsEvent.EventRegistration,
            redirect: '#thankyou-bungalow-launch-event',
            formOptions: {
                submitMessage: 'Register',
                tags: ['victoriadaylongweekend'],
            },
            fields: [
                {
                    id: 'firstname',
                    label: 'FIRST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'lastname',
                    label: 'LAST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'email',
                    label: 'EMAIL',
                    required: true,
                    size: 2,
                },
                {
                    id: 'phone',
                    label: 'PHONE',
                    required: true,
                    inputProps: { phone: true },
                    size: 2,
                },
            ],
        },
    ],
    'thankyou-bungalow-launch-event': [
        {
            type: DynamicContentType.Header,
            media: [{
                galleryLink: 'gom-events', index: 1
            }]
        },
        {
            type: DynamicContentType.Event,
            title: 'Bungalow Launch Event on Victoria Day Weekend!',
            media: [{ galleryLink: 'gom-events', index: 1 }],
            elements: [
                {
                    title: 'LOCATION',
                    location: {
                        name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    },
                },
                {
                    title: 'DATE',
                    subtitle: 'May 18 + 19 from 12pm-3pm',
                }
            ],
            dividerAfter: true,
        },
        {
            type: DynamicContentType.Blurb,
            body: `Thank you for registering for the Bungalow Launch Event on Victoria Day Weekend!`,
            animated: false
        },
    ],
    'bungalow-promo-event': [
        {
            type: DynamicContentType.Event,
            title: 'Bungalow Promotion Event',
            elements: [
                {
                    title: 'LOCATION',
                    location: {
                        name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    },
                },
                {
                    title: 'DATE',
                    body: 'August 24th - 25th, 2024',
                },
                {
                    title: 'TIME',
                    body: '12 - 3pm',
                }
            ],
            dividerAfter: true,
        },
        {
            type: DynamicContentType.Blurb,
            title: "Don't wait to discover your dream home at the Gates of Meaford by Nortterra",
            body: "Discover the newly designed Fairmount floorplan, offering unique features such as a back patio or a loft open to below with clerestory windows at no extra cost. Plus, enjoy additional square footage for more living space."
        },
        /*{
            type: CustomContentType.Blurb,
            title: 'More Space. More Value. More Peace of Mind.',
            body: "For a limited time, Nortterra is offering two upgrade packages to be included with the purchase price for all Fairmount Bungaloft Models. Choose between a covered back patio with slab on grade foundation. and a Muskoka room enclosure, or an open to below loft with vaulted ceilings and clerestory windows."
        },*/
        {
            type: DynamicContentType.Blurb,
            title: 'New Heathcote and Heathcote XL',
            subtitle: 'With new limited-time price',
            body: 'Experience the luxury of our new Heathcote and Heathcote XL models with a **substantial $25,000 reduction** in purchase price for a limited time.',
            links: [{ pageType: PageType.Sitemap, label: 'Browse Floorplans' }]
        },
        {
            type: DynamicContentType.Blurb,
            title: 'Why Choose Gates of Meaford?',
            body: "* **Prime Location:** Enjoy the beauty of Meaford with its proximity to the water, parks, and vibrant community amenities.\n* **Exceptional Quality:** Our homes are built with premium materials and finishes, ensuring lasting value and comfort.\n* **Modern Design:** Thoughtfully designed spaces with high ceilings, spacious lots, and customizable features to suit your lifestyle."

        },
        {
            type: DynamicContentType.Form,
            body: "Register for more information",
            subtitle: "Fields marked with a * are required",
            requestType: AnalyticsEvent.EventRegistration,
            redirect: '#thankyou-bungalow-promo-event',
            formOptions: {
                submitMessage: 'Register',
                tags: ['bungalo-promo-event'],
            },

            fields: [
                {
                    id: 'firstname',
                    label: 'FIRST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'lastname',
                    label: 'LAST NAME',
                    required: true,
                    size: 2,
                },
                {
                    id: 'email',
                    label: 'EMAIL',
                    required: true,
                    size: 4,
                },
                {
                    id: 'phone',
                    label: 'PHONE',
                    required: true,
                    inputProps: { phone: true },
                    size: 2,
                },
                {
                    id: 'postalcode',
                    label: 'Postal Code',
                    required: false,
                    size: 1,
                    breakAfter: true,
                },
                {
                    id: 'howdidyouhear',
                    label: 'HOW DID YOU HEAR ABOUT US?',
                    dropdown: referralSources,
                    required: false,
                    other: true,
                    select: 'Select one option',
                    extra: 'How did you hear about us',
                    size: 1,
                },

                {
                    id: 'realtor',
                    label: 'ARE YOU A REALTOR?',
                    choice: ['Yes', 'No'],
                    required: false,
                    initial: 'No',
                    select: 'Select one option',
                    size: 1,
                },

                // {
                //     id: 'series',
                //     label: 'WHICH PROJECT ARE YOU INTERESTED IN?',
                //     dropdown: ['Both Projects', 'The Summit', 'The Summit 2', 'Not Sure'],
                //     required: false,
                //     other: true,
                //     initial: 'Both Projects',
                //     select: 'Select one option',
                //     size: 1,
                // },
                {
                    id: 'consent',
                    label: "By clicking this box or by completing this Registration Form for Nortterra, I expressly provide my consent to receive electronic messages from Nortterra and its affiliated companies retroactively, today and in the future for any projects by Primont. I understand that I may withdraw my consent by unsubscribing anytime.",
                    required: true,
                    size: 4,
                }
            ],
        },
    ],
    'thankyou-bungalow-promo-event': [
        {
            type: DynamicContentType.Header,
            media: [{
                galleryLink: 'gom-events', index: 1
            }]
        },
        {
            type: DynamicContentType.Event,
            title: 'Bungalow Promotion Event',
            media: [{ galleryLink: 'gom-events', index: 1 }],
            elements: [
                {
                    title: 'LOCATION',
                    location: {
                        name: 'The Property LinQ\nGeorgian Bay Presentation Centre',
                        numberAndStreet: '11352 ON-26',
                        city: 'Collingwood',
                        provinceState: 'ON',
                        postalZipCode: 'L9Y 5C6',
                    },
                },
                {
                    title: 'DATE',
                    subtitle: 'August 24th - 25th, 2024',
                },
                {
                    title: 'TIME',
                    subtitle: '12 - 3pm',
                }
            ],
            dividerAfter: true,
        },
        {
            type: DynamicContentType.Blurb,
            body: `Thank you for registering the Gates of Meaford by Nortterra!`,
            animated: false
        },
    ]
}

export const TheSummitContent = {}
export const KingsleySquareContent = {}
export const LeonaCondominiumsContent = {}
export const ThePropertyLinqContent = {}
export const TheWoodsContent = {}
