import React, { useEffect } from 'react'
import {
    AnalyticsEvent,
    AppData, DynamicContentType, CustomEventType, FloorplanFilter, ProjectFilter, PromptOptions, ThumbSize,
} from 'app/types'
import {
    Media, SlideShow, WaterMark, Button, ScrollHelper,
} from 'components'
import { IntersectionHelper } from 'views/DynamicPage/IntersectionHelper'
import { Link } from 'react-router-dom'
import { showPrompt } from 'actions/appActions'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { RequestInfo } from 'views/AppPage/RequestInfo'
import { CustomContent } from './CustomContent'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { LocationLink } from 'views/HomePage/LocationLink'


export const TheWoodsContentData = {
    "about": [
        {
            type: DynamicContentType.Header,
            media: [{ galleryLink: 'preview' }],
            alt: true,
        },
        {
            type: DynamicContentType.ProjectInfo,
            media: [{ galleryLink: 'highlight' }],
            title: 'Status Update',
            body: "We are now accepting applications for October 2024 occupancy.",
            links: [{ label: 'Request Info', link: '#request', icon: "fas fa-paper-plane" }],
        },
        {
            type: DynamicContentType.Blurb,
            title: 'Introducing The Woods',
            body: "At The Woods you’ll find a unique combination of craftsmanship, small town pride, and next level features and finishes. Designer kitchens with large elegant countertops and finely crafted cabinetry are found in every suite. All in a serene community surrounded by nature that offers a world of convenience at your fingertips.",
            media: [{ galleryLink: 'info', index: 0, size: 6 }]
        },
        {
            type: DynamicContentType.Media,
            media: [{ galleryLink: 'video' }]
        },
        // {
        //     title: 'Condo Fee Information',
        //     content: <span>
        //         Condo Fee: $0.16 / sqft
        //         What is Included:
        //         <ul>
        //             <li>Lawn Maintenance</li>
        //             <li>Snow Removal</li>
        //             <li>Common Area Elements such as septic and general maintenance of the exterior building and lands</li>
        //             <li>1 Designated Parking Space</li>
        //         </ul>
        //     </span>,
        // },
        {
            type: DynamicContentType.Blurb,
            title: 'Deposit Information',
            body: "* 1/2 Month's Damage Deposit",
            media: [{ galleryLink: 'info', index: 2, size: 6 }]
        },
        // {
        //     title: 'Deposit Information',
        //     content: <span>
        //         Deposit payment schedule
        //         <ol>
        //             <li>$5,000 deposit upon signing APS</li>
        //             <li>After 15 days the remainder of the first 5% Deposit</li>
        //         </ol>
        //     </span>,
        // },
        /*{
            title: 'Investment Information',
            content: <span>
                The Unit Is
                <ul>
                    <li>Assignable</li>
                    <li>Long Term Rentable</li>
                    <li>Air bnb'able</li>
                </ul>
            </span>,
        },*/
        {
            type: DynamicContentType.Blurb,
            title: 'Amenities',
            body: "* On Site Above Ground Parking\n* In Suite Laundry\n* Electric Heat; ductless mini split (cooling & heating)\n* 5% of the units are Accessible Style Units",
            media: [{ galleryLink: 'info', index: 4, size: 6, position: 'left' }]
        },
        {
            type: DynamicContentType.Blurb,
            title: 'Get to Know the Community',
            body: "The area is a quiet community situated along Pockwock Rd. in Upper Hammonds Plains. In existence since 1815, it is one of the most historic communities for Afro-Nova Scotians. The area is perfectly situated in the Halifax Regional Municipality. With a backdrop of evergreen forests and nearby lakes, the homes are perfect for nature lovers who want to unwind and feel sheltered from the hustle of the big city but still want easy access to all the amenities and attractions the city offers.\n\nGeographically, the area of Upper Hammonds Plains is adjacent to Hammonds Plains in the east and south, Upper Tantallon in the South, Head of St. Margarets Bay and Mount Uniacke in the west, and Mount Uniacke and Upper Sackville in the North.",
            media: [{ galleryLink: 'info', index: 5, size: 6 }]
        },
        {
            type: DynamicContentType.Map,
            alt: true,
        }
    ],
    'gettingaround': [
        {
            type: DynamicContentType.Header,
            title: "Getting Around",
            media: [{ galleryLink: 'gettingaround', index: 0 }],
        },
        {
            type: DynamicContentType.Blurb,
            title: null,
            body: "Located minutes from Highways 213, 103, 102 and the Bedford Highway, which serves as a key north-south connection for both local and regional travel. The Bedford Highway also provides a direct link between the Regional Centre and suburbs along the west and north sides of the Bedford Basin.\n\nThe area is also a short distance from Bedford and Halifax’s prized urban communities featuring a fantastic assortment of Big Box Stores, grocery stores, world-renowned dining options, cafes, and more. The Woods homes have the big city convenience of nearby amenities and dining options, all while being surrounded with some of nature's most breathtaking, waterfronts, parks, and trails!",
            media: [{ galleryLink: 'gettingaround', index: 1, position: 'left' }, { galleryLink: 'gettingaround', index: 2 }, { galleryLink: 'gettingaround', index: 3 }],
        },
        {
            type: DynamicContentType.Blurb,
            title: 'Major Highways',
            body: "Conquer your morning commute with absolute ease! Residents at The Woods are conveniently located just minutes from Highways 213, 103, and the 102 making travelling throughout the Halifax Regional Municipality a breeze.",
            media: [{ galleryLink: 'gettingaround', index: 4, size: 6 }]
        },
    ],
    'thingstodo': [
        {
            type: DynamicContentType.Header,
            title: 'Things to do',
            media: [{ galleryLink: 'thingstodo', index: 0 }],
        },
        // 
        // type: CustomContentType.Blurb,
        // title: 'Attractions & Amenities',
        // },
        {
            type: DynamicContentType.Blurb,
            title: 'Hammond Plains',
            body: "Hammonds Plains is located minutes from\n\n* Glen Arbour Golf Course\n* Splash Adventure Water Park\n* Splashifax Waterparks\n* Mind Hack Room Escape\n* Hatfield Farm",
            media: [{ galleryLink: 'thingstodo', index: 1, size: 6, position: 'left' }]
        },
        {
            type: DynamicContentType.Blurb,
            body: "Escape to the natural beauty of Hammonds Plains’ Spruce Island, Dorey’s Island or Upper Duck Rocks. Enjoy, reenergize, and get back to nature with a visit to Pockwock Falls, Blue Mountain, or take an RV staycation at the Woodhaven RV Park.\n\nHammonds Plains is also a short commute to Hubbards Provincial Park, Queensland Beach, and Mt. Saint Vincent University.",
            media: [{ galleryLink: 'thingstodo', index: 2, size: 6 }]
        },
        // {
        // type: CustomContentType.Blurb,
        // title: 'Nearby Communities',
        // },
        {
            type: DynamicContentType.Blurb,
            title: 'Bedford',
            body: "Minutes from Bedford, an urban community with a fantastic collection of parks and trails\n\n* Take a short drive and stroll through DeWolf Park, located right on the Bedford Waterfront, Admiral Cove, or Sandy Lake Park\n* Join the Bedform Basin Yacht Club\n* Enjoy Bedford's Sports Complex & Tennis Centre\n* Visit the Scott Manor House Historical Museum\n\nShopping destinations include Sunnyside Mall, a unique mix of locally owned boutiques, national retailers, and restaurants, as well as small shops and eateries along the Bedford Highway",
            media: [{ galleryLink: 'thingstodo', index: 3, size: 6, position: 'left' }]
        },
        {
            type: DynamicContentType.Blurb,
            title: 'Halifax',
            body: "Close proximity to Halifax the capital and largest municipality in Nova Scotia, and Atlantic Canada.\n\n* Halifax is home to some of the best seafood and fantastic world-renowned assortment of restaurants in Canada\n* Halifax’s prized downtown core features the bustling Halifax Waterfront and Boardwalk\n* Museums, shops, historical properties, art galleries, parks, public gardens, pastry shops, coffee houses, and so much more…\n* Also home to Dalhousie University and St. Mary’s University",
            media: [{ galleryLink: 'thingstodo', index: 4, size: 6 }]
        },
        {
            type: DynamicContentType.Blurb,
            title: 'Timberlea',
            body: "* Home to The Links at Brunello, Ranked #3 Best New Golf Course in North America\n* Bluff Wilderness Hiking Trails",
            media: [{ galleryLink: 'thingstodo', index: 5, size: 6, position: 'left' }]
        },
        {
            type: DynamicContentType.Blurb,
            title: "Peggy's Cove Coastal Region",
            body: "A premier travel destination of the province and home to world-renowned village of Peggy’s Cove and the Iconic Peggy’s Point Lighthouse, Peggy’s Cove comprises much of the rural section on the western side of the HRM.\n\nAside from the natural beauty of the region, visitors can enjoy a multitude of engaging experiences, interesting activities, and exciting recreational opportunities.\n\nWhether you’re looking for hiking trails, golf courses, beautiful beaches, kayaking, or restaurant offerings with some of the freshest Nova Scotian Seafood, Peggy’s Cove is an attraction that can be visited and enjoyed over and over again.",
            media: [{ galleryLink: 'thingstodo', index: 6, size: 6 }]
        },
    ],
    'featuresandfinishes': [
        {
            type: DynamicContentType.Features,
            title: 'Features & Finishes',
            elements: [
                {
                    title: 'Front Exterior Door',
                    body: 'Upgrades:\n\n* 3 panel glass exterior front door\n\nStandard:\n\n* 1 solid steel door with one view hole',
                },
                {
                    title: 'Entire Flooring (excluding bathrooms and laundry)',
                    body: 'Upgrades:\n\n* Hardwood floors, hardwood stairs\n* Tiled Front Door foyer & laundry & bathrooms Floors\n\nStandard:\n\n* All waterproof laminate flooring throughout unit (including front door foyer & bathrooms & laundry)',
                },
                {
                    title: 'Doors, Windows & Trim',
                    body: 'Upgrades:\n\n* Single panel, black lever\n\nStandard:\n\n* 6 panel hollow core door with black knob\n* Black hinges & striker plate, door stop',
                },
                {
                    title: 'Built in cabinetry in the walk in closet behind kitchen wall',
                    body: 'Upgrades:\n\n* Built in cabinetry, bench, shelving within the closet on 2nd level\n\nStandard:\n\n* Std painted walls, shelf and clothing rack no built-ins.\n* Black hinges & striker plate, door stop',
                },
                {
                    title: '2 Piece Bathroom 2nd Level',
                    body: 'Upgrades:\n\n* Built it floating shelving above toilet in 2 piece, black faucets, black vanity light above mirror, black towel bar & toilet roll\n* Black framed mirror\n\nStandard:\n\n* No built in shelving, chrome faucets, chrome light above the sink\n* Chrome toilet & towel bar holders black\n* Non floating pedestal sink\n* Mirror glued on to size of sink no frame',
                },
                {
                    title: 'Black & Silver Metal Railing',
                    body: 'Upgrades:\n\n* Black & silver metal railing\n\nStandard:\n\n* Drywalled knee wall',
                },
                {
                    title: 'Kitchen Island',
                    body: 'Upgrades:\n\n* Level 3 quarts with waterfall on island\n* High Gauge steel double deep undermount sink\n* Black Kitchen faucet\n* Pendant lighting\n* Stainless Steel Kitchen Appliance package\n\nStandard:\n\n* Level 1 quarts\n* Standard gauge & depth single sink undermount sink\n* Chrome faucet\n* Black kitchen appliance package\n* Shaker style kitchen doors in thermofoil',
                },
                {
                    title: 'Living Room',
                    body: 'Upgrades:\n\n* Vertical wood accent wall with floating entertainment shelf\n\nStandard:\n\n* No built ins or accent wall, there will be standard paint finish',
                },
                {
                    title: 'Dining Room',
                    body: 'Upgrades:\n\n* Vertical wood accent wall with floating entertainment shelf\n\nStandard:\n\n* No built ins or accent wall, there will be standard paint finish',
                },
                {
                    title: 'Window Coverings',
                    body: 'Upgrades:\n\n* Roll down window coverings\n\nStandard:\n\n* No window coverings provided',
                },
                {
                    title: 'Laundry Room',
                    body: 'Upgrades:\n\n* Side by side laundry with stacked laundry kit, built in cabinetry and shelving with quartz countertop and single bowl under mount sink, brass faucet\n\nStandard:\n\n* Stacked laundry only',
                },
                {
                    title: '3 Piece Bathroom',
                    body: 'Upgrades:\n\n* Titled shower with black glass doors\n* Black faucets, Shower head\n* Floating shelf and quartz countertop with floating vanity\n* Black towel bar & toilet roll\n* Black vanity light above mirror\n* Black framed mirror\n\nStandard:\n\n* No built in shelving shelving, chrome faucets, chrome light above the sink\n* Chrome toilet & towel bar holders\n* Standard sink, vanity with one door and two drawers\n* Mirror glued on to size of sink no frame',
                },
                {
                    title: 'Den on 3rd Level',
                    body: 'Upgrades:\n\n* Vertical wood accent wall with floating shelf above built in floating desk\n* Built in wood window nook\n* Pot lights\n\nStandard:\n\n* Builders 2 bulb led bedroom light and blank walls painted',
                },
                {
                    title: 'Primary Bedroom',
                    body: 'Upgrades:\n\n* Vertical wood accent wall with pendant light\n* Pot lights\n* Custom wood shelving with custom clothing rack\n* Closet light\n\nStandard:\n\n* Builders 2 bulb led bedroom light and blank walls painted\n* Standard wire mesh clothing rack\n* No closet lighting',
                },
                {
                    title: 'Window, Door and Floor Trim',
                    body: 'Upgrades:\n\n* Same as Standard\n\nStandard:\n\n* 3.5’ Flat stock trim with mitered edge for doors and windows\n* 5” flat stock for floor',
                },
            ]
        }
    ]
}