import React, { useEffect, useState, useRef } from 'react'
import { PromptType, UpgradeOptionData, UpgradeOptionVariationData, UpgradeProductData, BuildingTypeData, FloorplanData, FloorplanVariationData, Visibility } from 'app/types'
import { Button, Media, Icon, IconButton, Input } from 'components'
import * as fnc from 'helpers/fnc'
import { showPrompt } from 'actions/appActions'
import { getPrice, getConfig, getProductName, getPackageName, floorplanVariationBedBathCode } from './upgrades'
import { useAppDispatch, useAppSelector } from 'app/hooks'

function orderSort(a, b) {
    return a.order - b.order
}

interface UpgradeProductProps {
    app,
    maps: Dict,
    pckge: Dict,
    spec: Dict,
    group: Dict,
    selection: Dict,
    customized: boolean,
    packageDefault: boolean,
    drillable: boolean,
    ix: number,
    orderProduct: UpgradeProductData,
    orderProducts: UpgradeProductData[],
    handleOrder,
    selectOption,
    onChange: () => void,
    onProduct: () => void,
    onProductValue: () => void,
    onInfo: () => void,
    showPricing: boolean,
    admin: boolean,
    disableChanges: boolean,
    readOnly: boolean,
}

export function UpgradeProduct(props: UpgradeProductProps) {
    const {
        app,
        maps,
        group,
        spec,
        selection,
        customized,
        packageDefault,
        drillable,
        ix,
        orderProduct,
        orderProducts,
        handleOrder,
        selectOption,
        onChange,
        onProduct,
        onProductValue,
        showPricing,
        admin,
        onInfo,
        disableChanges,
        readOnly,
    } = {
        drillable: false,
        ix: -1,
        orderProduct: null,
        orderProducts: null,
        ...props,
    }

    const { pckge, option, optionVariation, component, product } = spec

    const isCustom = option.custom
    const dispatch = useAppDispatch()
    const [editing, setEditing] = useState(false)
    const [editData, setEditData] = useState(null)
    const priceCache = useRef(null)
    const lastCache = useRef(null)

    const productName = getProductName(maps, pckge, option, product, true)
    /*let productName = product ? (product.displayName || product.name) : 'No Product'

    // If name includes - Finish, strip it out
    if (productName.includes('-')) {
        productName = productName.split('-')[0]
    }*/
    /*if (product?.finish) {
        // Check if second token is a finish descriptor
        const checkTokens = product.finish.split(' ')
        if (checkTokens.length > 1) {
            checkTokens.push(product.finish)
        }
        checkTokens.find((x) => {
            if (productName.includes(` - ${x}`)) {
                productName = productName.replace(` - ${x}`, '')
                return true
            }
            return false
        })
    }
    if (product.finish && productName.includes(`- ${product.finish}`)) {
        productName = productName.replace(`- ${product.finish}`, '')
    }*/

    const memberPackages = []
    if (pckge && product) {
        // If part of a package, append true source package name
        const key = `${option.id}_${product.id}`
        if (key in maps.productPackage) {
            const packageSet = {}
            let packagePrefix = ''
            maps.productPackage[key].forEach((x) => {
                const sourcePackage = maps.package[x]
                memberPackages.push(sourcePackage)
                const keyB = sourcePackage.name.split(' ')[0]
                if (!(keyB in packageSet)) {
                    packageSet[keyB] = new Set()
                }
                let sourcePackageOption = maps.packageOption[sourcePackage.id]
                let sourcePackageName = getPackageName(maps, sourcePackage, sourcePackageOption)
                packageSet[keyB].add(sourcePackageName)
            })
            if (Object.keys(packageSet).length > 0) {
                packagePrefix = Object.keys(packageSet).map((x) => {
                    return `${Array.from(packageSet[x]).map((y, iy) => iy > 0 ? y.replace(`${x} `, '') : y).join(',')}`
                }).join(', ')

                // productName = `${packagePrefix} - ${productName}`
            }
        }
    }

    const selected = product && product.id in selection

    // Pricing should instead be member packages
    let priceConfig
    const sinceCache = Date.now() - (lastCache.current || 0)
    if(priceCache.current && sinceCache < 1000) {
        priceConfig = priceCache.current
    } else {
        priceConfig = memberPackages.length == 1
            ? getPrice(maps, { ...spec, pckge: memberPackages[0] }, { selection: selected ? selection[product.id] : null, relative: true, debug: false, relativePackage: pckge })
            : getPrice(maps, spec, { selection: selected ? selection[product.id] : null, relative: true, debug: false, relativePackage: pckge })
        priceCache.current = priceConfig
        lastCache.current = Date.now()
    }
    let { price, priceUnit, quantity, override, config, packageDelta, currentPrice, currentPriceUnit } = priceConfig

    const pricingChanged = selected && ((price != 0 && currentPrice != null && currentPrice != fnc.roundCents(price)) || (currentPriceUnit != null && currentPriceUnit != fnc.roundCents(priceUnit)))
    let note = null

    const deselectable = !component.products?.find((x) => x.isDefault) || option.multiSelect

    if (product && selected && 'note' in selection[product.id]) {
        note = selection[product.id].note
    }

    let priceElem = null
    const pricingChangedElem = pricingChanged ? <Button tertiary icon="fas fa-exclamation-triangle" onClick={(e) => { e.stopPropagation(); handlePriceUpdate() }}>Outdated pricing</Button> : null
    let original = false
    if (!selected && pckge != null && product) {
        const defaultProduct = pckge.products.find((x) => x.upgradeOptionId == option.id)
        original = defaultProduct && defaultProduct.upgradeProductId == product.id
    }
    let selectIcon = original ? 'fas fa-undo' : (selected ? 'fas fa-check' : 'fas fa-circle')
    if (product != null) {
        if (showPricing && (pckge == null || pckge.priceCost == null || !packageDefault)) {
            if (price == 0) {
                if (!isCustom) {
                    // priceElem = 'Please inquire'
                } else if (!note) {
                    priceElem = <span className="custom-details">Enter option details below</span>
                }
            } else if (quantity && price == priceUnit * quantity && quantity > 1) {
                priceElem = <div className="column quantity-pricing">
                    <span>{`${quantity} @ ${fnc.toMoney(priceUnit)} each`}</span>
                    <span>{pricingChangedElem}{`${fnc.toMoney(priceUnit * quantity)}`}</span>
                </div>
            } else {//if (component.configureQuantity) {
                if (pckge && !packageDefault && pckge.priceCost != null && packageDelta != null && packageDelta != 0) {
                    priceElem = packageDelta > 0 ? `+${fnc.toMoney(packageDelta)}` : fnc.toMoney(packageDelta)
                } else {
                    priceElem = fnc.toMoney(price)
                }
                priceElem = <div className="column">
                    <span>{pricingChangedElem} {priceElem}</span>
                </div>
                /*} else {
                    priceElem = fnc.toMoney(price)*/
            }
        }

        /*jif (product.priceSqft) {
            priceElem = <span>{`$${price} / Sq. Ft.`}</span>
        } else if (product.priceSqin) {
            priceElem = <span>{`$${price} / Sq. In.`}</span>
        } else if (product.priceCost) {
            priceElem = <span>{`$${price}`}</span>
        }*/
        let checkClass = null
        if (optionVariation.multiSelect) {
            selectIcon = selected ? 'fas fa-check' : 'fas fa-plus'
        }
    }
    let galleryCount = 0
    let galleryMedia = []
    if (drillable) {
        component.products.forEach((x) => {
            const product = maps.product[x.upgradeProductId]
            if (product && product.media.length > 0) {
                galleryMedia.push(product.media[0])
            }
        })
        galleryCount = Math.max(1, Math.min(Math.floor((galleryMedia.length) / 3) * 3, 6))
    }
    let orderElem = null
    if (onChange && ix != -1 && orderProducts) {
        orderElem = <div className="top-right order-wrapper">
            {ix > 0 && <IconButton className="order-button" icon="fas fa-chevron-up" onClick={(e) => { e.stopPropagation(); handleOrder(orderProducts, orderProduct, ix - 1) }} />}
            {ix < orderProducts.length - 1 && <IconButton className="order-button" icon="fas fa-chevron-down" onClick={(e) => { e.stopPropagation(); handleOrder(orderProducts, orderProduct, ix + 1) }} />}
        </div>
    }

    let childProducts = null
    if (product && product.products && product.products.length > 0) {
        childProducts = [...product.products].sort(orderSort).map((x, idx) => {
            if (!x) {
                return
            }
            const nestedProduct = maps.product[x.childUpgradeProductId]
            let nestedOrderElem = null
            if (onChange) {
                nestedOrderElem = <div className="bottom-right order-wrapper">
                    {idx > 0 && <IconButton className="order-button" icon="fas fa-chevron-up" onClick={(e) => { e.stopPropagation(); handleOrder(product.products, x, idx - 1) }} />}
                    {idx < product.products.length - 1 && <IconButton className="order-button" icon="fas fa-chevron-down" onClick={(e) => { e.stopPropagation(); handleOrder(product.products, x, idx + 1) }} />}
                </div>
            }
            return <div className="upgrade-product nested" key={`${x.childUpgradeProductId}-${idx}`} onClick={(e) => { e.stopPropagation(); onInfo(group, option, optionVariation, component, nestedProduct) }}>
                <div className="upgrade-product-wrapper clickable">
                    {nestedProduct && <React.Fragment>
                        <div className="upgrade-product-media">
                            {nestedProduct.media.length > 0 && <Media thumb={true} thumbSize={128} key={`${nestedProduct.id}-media`} fadeIn app={app} mediaId={nestedProduct.media[0]} />}
                        </div>
                        <div className="upgrade-product-details">
                            {/* {<IconButton secondary icon="fas fa-info" onClick={(e) => { e.stopPropagation(); onInfo(nestedProduct) }} />} */}
                            <span>{nestedProduct.name}</span>
                            {/* {nestedProduct.sku && <span>({nestedProduct.sku})</span>} */}
                            {/* {priceElem && <span className="upgrade-product-price">{priceElem}</span>} */}
                            {nestedOrderElem}
                        </div>
                    </React.Fragment>}
                </div>
            </div>
        })
    }

    useEffect(() => {
        if (isCustom && !note && !price) {
            handleEditProduct(true)
        }
    }, [])

    useEffect(() => {
        if (pckge == null) {
            return
        }
        // Get package customizations

    }, [pckge, selection])

    function handleNote(x) {
        setEditData({ ...editData, note: x })
    }

    function handleQuantity(x) {
        setEditData({ ...editData, quantity: x })
    }
    function handlePrice(x) {
        setEditData({ ...editData, price: parseFloat(x) })
    }

    function handlePriceUpdate(x) {
        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Update Price', message: getPricingUpdateMessage(price, priceUnit, currentPrice, currentPriceUnit), confirmMessage: 'Update Pricing' }))
            .then((x) => {
                if (x.payload) {
                    onProduct(group, option, optionVariation, component, product, true, true, true)
                }
            })

    }

    function handleEditProduct(bool) {
        if (bool) {
            setEditData({ note: note, quantity: quantity, price: component.configureQuantity ? priceUnit : price })
            setEditing(true)
        } else if (editData) {
            if (editData.note != null) {
                onProductValue(group, option, optionVariation, component, product, 'note', editData.note)
            }

            if (editData.quantity != null) {
                onProductValue(group, option, optionVariation, component, product, 'quantityOverride', editData.quantity)
            }

            if (editData.price != null) {
                if (editData.price == '') {
                    onProductValue(group, option, optionVariation, component, product, 'priceOverride', null)
                } else {
                    onProductValue(group, option, optionVariation, component, product, 'priceOverride', editData.price)
                }
            }
            setEditing(false)
        }
    }

    function handleReset(e) {
        e.stopPropagation()
        // Get default product
        const defaultProduct = pckge.products.find((x) => x.upgradeOptionId == option.id)
        const prod = maps.product[defaultProduct.upgradeProductId]
        if (defaultProduct && onProduct) {
            onProduct(group, option, optionVariation, component, prod, true)
        }
    }

    function toggleProduct() {
        if (disableChanges || isCustom) {
            return
        }

        const applyToggle = async () => {

            if (pricingChanged) {
                const result = await dispatch(showPrompt({ type: PromptType.Confirm, title: 'Price has changed', message: 'Pricing has been updated since the last time you viewed this product. If you remove this selection the pricing for this item will be brought up to date.', confirmMessage: 'Deselect and Update Pricing' }))
                if (!result.payload) {
                    return
                }

            }

            const config = getConfig(maps, spec)
            const sel = selected ? selection[product.id] : null
            if (sel) {
                if ((sel.quantityOveride != null || sel.priceOverride != null || note != null)) {
                    const ret = await dispatch(showPrompt({ type: PromptType.Confirm, title: 'Remove Configured Selection?', message: 'Customizations will be lost' }))
                    if (ret.payload) {
                        if (onProduct) {
                            onProduct(group, option, optionVariation, component, product, true)
                        }
                        setEditing(false)
                    }
                } else if (deselectable) {
                    if (onProduct) {
                        onProduct(group, option, optionVariation, component, product, true)
                    }
                } else {
                    setEditing(false)
                }
            } else {
                if (onProduct) {
                    onProduct(group, option, optionVariation, component, product)
                }
            }
        }
        applyToggle()
    }
    function removeConfiguration() {
        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Remove Configuration?', message: 'Cannot be undone' }))
            .then((x) => {
                if (x.payload) {
                    if (isCustom) {
                        if (onProduct) {
                            onProduct(group, option, optionVariation, component, product, true)
                        }
                    } else {
                        setEditing(false)
                        onProductValue(group, option, optionVariation, component, product, 'note', null)
                        onProductValue(group, option, optionVariation, component, product, 'quantityOverride', null)
                        onProductValue(group, option, optionVariation, component, product, 'priceOverride', null)
                    }
                }
            })
    }
    const editorOptions = editing ? <td rowSpan={component.configureQuantity ? 3 : 2} style={{ width: '40px' }}>
        <div className="column">
            <IconButton icon="fas fa-check" noBg onClick={() => handleEditProduct(false)} />
            <IconButton icon="fas fa-times" noBg onClick={() => setEditing(false)} />
            <IconButton noBg icon="fas fa-trash-alt" onClick={removeConfiguration} />
        </div>
    </td> : null

    if (!selected && (disableChanges || readOnly)) {
        return null
    }

    const editable = !drillable && selected && !editing && admin && !pckge

    const key = `${component.id}_${product?.id}`
    return <div className={`upgrade-product${isCustom ? ' custom' : ''}${original ? ' original' : ''}${customized ? ' customized' : ''}${selected ? ' selected' : ''}${!selected || deselectable ? ' clickable' : ''}${drillable ? ' drillable' : ''}${disableChanges ? ' disable-changes' : ''}${childProducts ? ' parent' : ''}${editable ? ' editable' : ''}${product && (!product.media || product.media.length == 0) ? ' compact' : ''}`} key={key} onClick={drillable ? () => selectOption(option, component) : toggleProduct}>
        <div className="row upgrade-product-info">
            <div className={`upgrade-product-wrapper${!product ? ' clickable' : ''}`}>
                {product && <React.Fragment>
                    {!isCustom && <div className="upgrade-product-media" style={product.media.length == 0 ? { width: '30px', minWidth: '30px' } : null}>
                        {product.media && product.media.length > 0 && <Media thumb={true} thumbSize={128} key={`${product.id}-media`} fadeIn app={app} mediaId={product.media[0]} />}
                        <div className="check" key={`${product.id}_${selectIcon}`}><Icon icon={selectIcon} className="animate__animated animate__zoomIn animate__fastest" /></div>
                    </div>}
                    <div className="upgrade-product-details">
                        {isCustom && note != null && <div className='upgrade-product-note'><span>{note}</span></div>}
                        {!isCustom && !drillable && <IconButton tertiary icon="fas fa-info" onClick={(e) => { e.stopPropagation(); onInfo(group, option, optionVariation, component, product) }} />}
                        {memberPackages.length > 0 && <div className="row member-packages">
                            {memberPackages.map((x) => {
                                // return <span><Icon icon="fas fa-swatchbook" noBg/>{x.name}</span>
                                let packageOption = maps.packageOption[x.id]
                                let packageName = getPackageName(maps, x, packageOption)
                                return <span key={x.id}>{packageName}</span>
                            })}
                        </div>}
                        <div className="row"><span>{productName}</span>{customized && <Icon noBg icon="fas fa-asterisk" className="customized-icon" />} </div>
                        {/* {product.sku && <span>({product.sku})</span>} */}
                        {/* {product?.finish && <span>Finish: {product?.finish}</span>} */}
                        {priceElem && <span className="upgrade-product-price">{priceElem}</span>}
                        {orderElem}
                        {editable && <div className="upgrade-product-options bottom-right">
                            <IconButton alt icon="fas fa-pen" onClick={(e) => { e.stopPropagation(); handleEditProduct(true); }} />
                        </div>}
                    </div>
                </React.Fragment>}
                {!product && <div className="upgrade-product-details" onClick={() => selectOption(option, component)}>
                    <h4>{optionVariation.components.length > 1 ? component.name : `Configure ${option.name}`}</h4>
                    {orderElem}
                </div>}
            </div>
            {/*drillable && <div className="arrow-column grid" onClick={() => selectOption(option, component)}>
                <div className={`product-gallery images-${galleryCount}`}>
                    {galleryMedia.splice(0, galleryCount).map((z, iz) => {
                        return <div className="product-gallery-wrapper" key={iz}>
                            <Media thumb={true} thumbSize={galleryCount > 1 ? 128 : 256} fadeIn app={app} mediaId={z} />
                        </div>
                    })}
                </div>
                <Button tertiary>See More</Button>
            </div>*/}

            {!drillable && !editing && !isCustom && note != null && <div className='upgrade-product-note' onClick={(e) => e.stopPropagation()}>
                <span>
                    {note}
                </span>
            </div>}
        </div>
        {drillable && <div className="arrow-row" onClick={null/*() => selectOption(option, component)*/}>
            <Button icon="fas fa-chevron-right">Customize</Button>
            {customized && !(readOnly || disableChanges) && <Button icon="fas fa-undo" onClick={handleReset}>Reset</Button>}
        </div>}
        {editing && <React.Fragment>
            <div className="row upgrade-product-editor" onClick={(e) => e.stopPropagation()}>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <span>
                                    Price
                                </span>
                            </td>
                            <td>
                                <Input price value={editData?.price > 0 ? editData?.price : null} onChange={handlePrice} onClick={(e) => e.stopPropagation()} />
                            </td>
                            {editorOptions}
                        </tr>
                        {component.configureQuantity && <tr>
                            <td>
                                <span>
                                    Quantity
                                </span>
                            </td>
                            <td>
                                <Input value={editData?.quantity} selectOnFocus integer onChange={(x) => handleQuantity(parseFloat(x))} onClick={(e) => e.stopPropagation()} />
                            </td>
                        </tr>}
                        <tr>
                            <td>
                                <span>
                                    Note
                                </span>
                            </td>
                            <td>
                                <Input textarea value={editData.note} selectOnFocus onChange={handleNote} onClick={(e) => e.stopPropagation()} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </React.Fragment>}
        {childProducts && <div className="row child-products" onClick={(e) => e.stopPropagation()}>
            {childProducts}
        </div>}
    </div>
}