import { getAxiosInstance, handleResponse, handleError, getOptionString } from 'helpers/requestHandler'
import {
    AppMetaData,
    AppData,
    AppConfig,
    EmailType,
    EmailOptions,
    MediaData,
    Analytics,
    AvailabilityAnalytics,
    EmailAnalytics,
    FloorplanAnalytics,
    PageAnalytics,
    Dict,
    UrlOption,
    UpgradeProductData,
    UpgradesAnalytics,
    UpgradeDocumentData,
    UpgradeOrderRecordData,
    NestedDict,
    UserData,
    UpgradeViewData,
    TrackingAnalytics,
} from 'app/types'
import { authHeader } from 'helpers/authHeader'

export async function getConfig(options: UrlOption): Promise<MetaData[]> {
    let url = '/config'
    if (options) {
        url += getOptionString(options)
    }
    return getAxiosInstance().get<AppConfig>(url)
        .then(handleResponse)
        .catch(handleError)
}

export async function getAllApps(options): Promise<AppMetaData[]> {
    return getAxiosInstance().get<AppMetaData>(`/app${getOptionString(options)}`)
        .then(handleResponse)
        .catch(handleError)
}

export async function getApp(appLink: string, organizationLink: string = null, critical: boolean = false, admin: boolean = false, salescenter: boolean = false): Promise<AppData> {
    const options = []
    if (critical) {
        options.push('critical')
    }
    if (admin) {
        options.push('admin')
    }
    if(salescenter) {
        options.push('salescenter')
    }
    if (organizationLink) {
        options.push(`organization=${organizationLink}`)
    }
    let optionsString = ''
    if (options.length > 0) {
        optionsString = `?${options.join('&')}`
    }
    return getAxiosInstance().get<AppMetaData>(`/app/${appLink}${optionsString}`, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function getFavourites(appIds: string[] = null, organization: OrganizationData = null): Promise<FloorplanData[]> {
    return getAxiosInstance().post<AppMetaData>(`/app/favourites${organization ? `?organization=${organization.link}` : ''}`, { appIds }, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function getUpgrades(app: AppData): Promise<UpgradeView[]> {
    return getAxiosInstance().get<AppMetaData>(`/app/${app.meta.link}/upgrades`, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function putUpgradeProduct(app: AppData, upgradeProduct: UpgradeProductData): Promise<UpgradeProductData[]> {
    return getAxiosInstance().post<AppMetaData>(`/app/${app.meta.link}/upgrades/product/${upgradeProduct.id}/`, upgradeProduct, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function postUpgradeLog(app: AppData, data: UpgradeLogData): Promise<UpgradeLogData[]> {
    return getAxiosInstance().post<AppMetaData>(`/app/${app.meta.link}/upgrades/log/`, data, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function getUpgradeLogs(app: AppData, user: UserData, upgradeView: UpgradeViewData): Promise<UpgradeLogData[]> {
    return getAxiosInstance().post<AppMetaData>(`/app/${app.meta.link}/upgrades/logs`, { userId: user.id, upgradeViewId: upgradeView.id }, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function postUpgradeNotify(app: AppData, organization: OrganizationData, data: UpgradeLogData): Promise<UpgradeLogData[]> {
    return getAxiosInstance().post<AppMetaData>(`/app/${app.meta.link}/upgrades/notify/`, { ...data, organizationId: organization?.id }, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function postUpgradeComplete(app: AppData, organization: OrganizationData, data: UpgradeLogData): Promise<UpgradeLogData[]> {
    return getAxiosInstance().post<AppMetaData>(`/app/${app.meta.link}/upgrades/complete/`, { ...data, organizationId: organization?.id }, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function getUpgradeOrder(app: AppData, token: string): Promise<UpgradeView[]> {
    return getAxiosInstance().get<AppMetaData>(`/app/${app.meta.link}/upgrades/order`, authHeader(token))
        .then(handleResponse)
        .catch(handleError)
}

export async function postUpgradePdf(app: AppData, order: Dict): Promise<string> {
    return getAxiosInstance().post<string>(`/app/${app.meta.link}/upgrades/pdf`, order, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function postUpgradeDocument(app: AppData, documents: UpgradeDocumentData[]): Promise<UpgradeDocumentData[]> {
    return getAxiosInstance().post<AppMetaData>(`/app/${app.meta.link}/upgrades/document`, { documents }, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function postUpgradeDocumentDownload(app: AppData, documents: UpgradeDocumentData[]): Promise<UpgradeDocumentData[]> {
    return getAxiosInstance().post<AppMetaData>(`/app/${app.meta.link}/upgrades/document/download`, { documents: documents.map((x) => x.mediaId) }, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function getUpgradeLastChange(app: AppData): string {
    return getAxiosInstance().get<AppMetaData>(`/app/${app.meta.link}/upgrades/lastchange`, authHeader())
        .then(handleResponse)
        .catch(handleError)
}


export async function getAllMedia(options: UrlOption): Promise<MediaData[]> {
    return getAxiosInstance().get<AppMetaData>(`/media${getOptionString(options)}`)
        .then(handleResponse)
        .catch(handleError)
}

export async function postEmail(
    emailType: EmailType,
    data: EmailOptions = null,
    options,
) {
    return getAxiosInstance().post<string>(`/email/${emailType}${getOptionString(options)}`, data)
        .then(handleResponse)
        .catch(handleError)
}

export async function putAvailability(app: AppData, delta: Dict) {
    return getAxiosInstance().post<string>(`/app/${app.meta.link}/availability`, delta, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function putPricing(app: AppData, delta: Dict) {
    return getAxiosInstance().post<string>(`/app/${app.meta.link}/pricing`, delta, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function putWorksheets(app: AppData, delta: Dict) {
    return getAxiosInstance().post<string>(`/app/${app.meta.link}/worksheets`, delta, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function postAnalytics(
    type: Analytics,
    data: EmailAnalytics | AvailabilityAnalytics | FloorplanAnalytics | PageAnalytics | UpgradesAnalytics | TrackingAnalytics) {
    let url = `/app/analytics/${type}`
    return getAxiosInstance().post<string>(url, data, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function getAnalytics(app: AppData, type: Analytics, options: Dict = null) {
    let url = `/app/analytics/${type}`
    if (app) {
        options = { appId: app.meta.id, ...options }
    }
    if (options != null) {
        url = `${url}${getOptionString(options)}`
    }
    return getAxiosInstance().get<string>(url, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function getSales(app: AppData) {
    return getAxiosInstance().get<string>(`/app/${app.meta.link}/sales`, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function getNewsletter(app: AppData) {
    return getAxiosInstance().get<string>(`/app/${app.meta.link}/newsletter`, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function postBooking(data: Dict, options) {
    return getAxiosInstance().post<string>(`/email/book${getOptionString(options)}`, data)
        .then(handleResponse)
        .catch(handleError)
}
export async function postBookingResubmit(data: Dict) {
    return getAxiosInstance().post<string>(`/email/book/${data.id}/resubmit`, data, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function postError(data: Dict) {
    return getAxiosInstance().post('/error', { error: data != null ? data : 'Unknown error' }, authHeader())
        .then(handleResponse)
        .catch(handleError)
}

export async function reportError(id: number) {
    return getAxiosInstance().post(`/error/${id}`, authHeader())
        .then(handleResponse)
}

export async function getVimeoJson(id) {
    const url = `https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${id}`
    return getAxiosInstance().get(url, { headers: { 'Access-Control-Allow-Origin': '*' } })
    // return getAxiosInstance().get(`http://vimeo.com/api/v2/video/${id}.json`)
    // fetch('https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/' + video_id)
}