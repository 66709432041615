import { addMediaFromUrl } from 'actions/adminActions'
import { MediaType } from 'app/types'
import { link } from 'fs'
import { getMediaUrl } from 'helpers/config'
import { logger } from 'helpers/logger'

export function getMediaLink(mediaLink: string, options: { [key: string]: string } = null) {
    if (options) {
        let extension = null
        let rx = /.*\.([A-Za-z]+)$/g
        try {
            extension = rx.exec(mediaLink)[1]
        } catch (e) {
            logger.error(`Couldn't identify image extension in ${mediaLink}`)
            return
        }
        const linkParts = [getMediaUrl()]

        if (options.mediaTypeId == MediaType.EmailResource) {
            linkParts.push('email')
        } else {
            if (options.app && options.app.meta) {
                linkParts.push('app')
                linkParts.push(options.app.meta.id)
            } else if (options.builder) {
                linkParts.push('builder')
                linkParts.push(options.builder.id)
            } else if (options.organization) {
                linkParts.push('organization')
                linkParts.push(options.organization.id)
            }
        }

        let finalMediaLink = mediaLink
        if (options.suffix) {
            finalMediaLink = (finalMediaLink.replace(`.${extension}` `${options.suffix}.${extension}`))
        }
        if (options.thumb && extension == 'jpg') {
            linkParts.push('thumbnails')
            finalMediaLink = `${finalMediaLink.replace('.jpg', `_thumb${options.thumbSize ? options.thumbSize : 256}.jpg`)}`
        }
        linkParts.push(finalMediaLink)
        return linkParts.join('/')
    }

    return `${getMediaUrl()}/${mediaLink}`
}

export function getLegacyMediaId(_m, app, builder, organization, allMedia, debug = false) {
    if (!_m) {
        return null
    }
    const { galleryLink, mediaLink, mediaName, index, link, type, id } = _m
    if (type == 2) {
        debug = true
    }
    let ret = null
    let retB = null
    if (_m.mediaId) {
        ret = _m.mediaId
    } if (_m.galleryId) {
        retB = _m.galleryId
        let galleries = null
        if (app) {
            galleries = app.galleries
        } else if (builder) {
            galleries = builder.galleries
        } else if (organization) {
            galleries = organization.galleries
        }
        const gallery = galleries.find((x) => x.id == retB)
        ret = gallery?.media.map((x) => x.mediaId)
    } else if (galleryLink) {
        let galleries = null
        if (app) {
            galleries = app.galleries
        } else if (builder) {
            galleries = builder.galleries
        } else if (organization) {
            galleries = organization.galleries
        }
        const gallery = galleries?.find((x) => x.link == galleryLink)
        if (gallery) {
            if (index != null && index < gallery.media.length) {
                ret = gallery.media[index].mediaId
            } else if (mediaLink != null) {
                ret = gallery.media.find((x) => {
                    const mediaId = x?.mediaId
                    if (mediaId in allMedia && allMedia[mediaId].link.startsWith(mediaLink)) {
                        return true
                    }
                    return false
                })?.mediaId
            } else if (mediaName != null) {
                ret = gallery.media.find((x) => {
                    const mediaId = x?.mediaId
                    if (mediaId in allMedia && allMedia[mediaId].name.startsWith(mediaName)) {
                        return true
                    }
                    return false
                })?.mediaId
            } else {
                retB = gallery.id
                ret = gallery.media.map((x) => x.mediaId)
            }
        }
    } else if (mediaLink) {
        ret = Object.values(allMedia).find((x) => x.link == mediaLink)?.id
    } else if (link && type) {
        if (type == MediaType.Tour) {
            const modelhome = app.modelhomes.find((x) => x.link == link || x.mediaId == id)
            ret = modelhome.mediaId
            // if (modelhome) {
            // return modelhome.previewMediaId
            // }
        } else if (type == MediaType.Spin) {
            const spin = app.spins.find((x) => x.link == link || x.mediaId == id)
            ret = spin.id
            // if (spin) {
            // return spin.previewMediaId
            // }
        }
    }
    return { mediaId: ret, galleryId: retB }
}