import React, { useEffect, useState, useRef } from 'react'
import {
    // Router,
    useHistory,
} from 'react-router-dom'
import {
    rebuildHistory,
} from 'actions/appActions'
import {
    CustomEventType,
} from 'app/types'
import { useAppSelector, useAppDispatch } from 'app/hooks'

export function HistoryManager(props) {
    const history = useHistory()
    const dispatch = useAppDispatch()
    const location = useRef(null)
    const initialized = useAppSelector((state:RootState) => state.app.initialized)
    const project = useAppSelector((state:RootState) => state.app.projectCurrent[0])
    const [historyInitialized, setHistoryInitialized] = useState(false)

    // History
    useEffect(() => {
        function handlePush(e) {
            history.push(e.detail)

        }
        function handleReplace(e) {
            history.replace(e.detail)
        }
        function handleBack(e) {
            history.goBack()
        }
        window.addEventListener(CustomEventType.HistoryPush, handlePush)
        window.addEventListener(CustomEventType.HistoryReplace, handleReplace)
        window.addEventListener(CustomEventType.HistoryBack, handleBack)
    }, [])

    useEffect(() => {
        if (initialized >= 2 && !historyInitialized) {
            history.listen((newLocation) => {
                if (!location.current || newLocation.pathname != location.current) {
                    location.current = newLocation.pathname
                    dispatch(rebuildHistory())
                }
            })
            dispatch(rebuildHistory())
            setHistoryInitialized(true)
        }
    }, [history, initialized, historyInitialized, project])

    // useEffect(() => {
        // if(initialized >= 2)
        // dispatch(rebuildHistory())
    // }, [project])

    return props.children
}