import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
    Button,
    Input,
    IconButton,
    DropdownMenu,
    Checkbox,
    BackButton,
    Tile,
    Media,
    MediaTile,
    VectorInput,
    Slider,
    DateSelector
} from 'components'
import { EditorFieldType, MediaType, PromptType, Dict, ThumbSize, RootState, OrganizationData, LLMGenerateType } from 'app/types'
import * as fnc from 'helpers/fnc'
import { showPrompt } from 'actions/appActions'
import { GalleryTile } from 'components'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import MarkdownIt from 'markdown-it'
import MdEditor from 'react-markdown-editor-lite'
import { formatLocation } from 'views/HomePage/LocationLink'
import { icons } from 'app/constants'
import { ColorPicker } from '../ColorPicker'
import { int } from '@babylonjs/core'
import { logger } from 'helpers/logger'
import { LLMContentHelper } from 'content/LLMContentHelper'

const mdParser = new MarkdownIt(/* Markdown-it options */);

type GenericEditorDef = {
    title: string,
    tooltip: string,
    fixed: boolean,
    hiddenSingle: boolean,
    addDefault: boolean,
    fields: GenericEditorFields,
}

type GenericEditorFields = {
    [string]: {
        default: string | number | dict | string[] | number[] | dict[],
        label: string,
        type: EditorFieldType,
        mediaTypeId: MediaType,
        fields: GenericEditorFields,
        key: string,
        hiddenNested: boolean,
        enum: (data: Dict, config: Dict) => { value: string, text: string },
        optional: boolean,
        disableOnNull: boolean,
        autoComplete: (data: Dict, config: Dict) => string[],
        limit: number,
    }
}

function getDefaults(fields) {
    let obj = Object.keys(fields).reduce((acc, x) => {
        if (fields[x].type == EditorFieldType.Array) {
            return { ...acc, [x]: [...fields[x].default] }
            /*const newObj = {}
            if (fields[x].def?.hiddenSingle || fields[x].def?.addDefault) {
                newObj = { ...acc, [x]: [getDefaults(fields[x].def.fields)] }
            } else {
                newObj = { ...acc, [x]: [...fields[x].default] }
            }*/

        }
        return { ...acc, [x]: fields[x].default }
    }, {})
    obj.tempId = fnc.uniqueId()
    return obj
}

function tempIsEqual(a, b) {
    return a && b && (((a.id || a.tempId) == (b.id || b.tempId)) || (a.id == null && b.id == null && ((a.link && b.link && a.link == b.link) || (a.name && b.name && a.name == b.name))))
}

interface GenericEditorProps {
    app: AppData,
    organization: OrganizationData,
    title: string,
    label: string,
    data: Dict,
    config: Dict,
    def: GenericEditorDef,
    onChange: () => void,
    onBack: () => void,
    onDelete: () => void,
    onReorder: () => void,
    options: Dict[],
    onSelect: () => void,
    onDuplicate: () => void,
    nested: boolean,
    hidden: boolean,
    depth: number,
    selection: Dict,
    parent: React.RefObject,
    prev: string,
    compact: boolean,
    isSingle: boolean,
    parentSize: number
    parentSet: Array,
    parentIndex: int,
    onValidateRef: React.RefObject,
}

export function GenericEditor(props: GenericEditorProps) {
    const { app, organization, config, def, onChange, onBack, onDelete, options, onSelect, onDuplicate, onReorder, nested, hidden, tree, depth, selection, parent, prev, compact, isSingle, parentSize, parentSet, parentIndex, onValidateRef } = { depth: 0, selection: {}, ...props }
    const [selected, setSelected] = useState(null)
    const [showTooltip, setShowTooltip] = useState(null)
    const [filter, setFilter] = useState(null)
    const [collapsed, setCollapsed] = useState(true)
    const [LLMHelper, setLLMHelper] = useState(null)
    const [llmRefresh, setLLMRefresh] = useState(0)
    const [elem, setElem] = useState(null)
    if (!def || !def.fields) {
        return null
    }

    const { tooltip, fields } = { tooltip: '', fields: [], ...def }
    let title = def.title || def.label
    const data = def.data ? def.data(app, data, config) : props.data

    const dispatch = useAppDispatch()
    const media = useAppSelector((state: RootState) => state.app.media)


    const columns = []
    const childFields = Object.keys(fields)
        .filter((x) => fields[x].type == EditorFieldType.Array && fields[x].def)
    const editFields = Object.keys(fields)
        .filter((x) => (fields[x].type != EditorFieldType.Fixed || (fields[x].show && !(nested && !fields[x].showNested))) && !fields[x].hidden)


    // Determine which depth should be rendered
    const maxSelection = Math.max(...Object.keys(selection).map((x) => parseInt(x))) + 1

    let hiddenChild = null
    childFields.forEach((x) => {
        if (fields[x].def?.hiddenSingle && data[x].length < 2) {
            hiddenChild = x
        }
    })

    function validateData() {
        let validation = []
        const validateDepth = (f, d, depth = 0) => {
            Object.keys(f).forEach((x) => {
                if (f[x].type == EditorFieldType.Array || f[x].type == EditorFieldType.Multi) {
                    if (f[x].def) {
                        d[x].forEach((y) => {
                            validateDepth(f[x].def.fields, y, depth + 1)
                        })
                    }
                } else {
                    if (f[x].required && !d[x]) {
                        // logger.error("Missing!", x, f, f[x], d)
                        validation.push("Missing required field '" + x + "' for " + d.label)
                    }
                }
            })
        }
        validateDepth(fields, data)
        return validation
    }
    if (onValidateRef) {
        onValidateRef.current = validateData
    }

    function findHiddenDepth(f, d, dpth) {
        if (!d) {
            return 0
        }
        let children = Object.keys(f).filter((x) => f[x].def)
        for (let i = 0; i < children.length; i += 1) {
            const x = children[i]
            if (f[x].def.hiddenSingle && d[x] && d[x].length < 2) {
                return 1 + findHiddenDepth(f[x].def.fields, d[x][0], dpth + 1)
            }
        }
        return 0
    }

    let hiddenDepth = findHiddenDepth(fields, data, depth)
    let selectedHiddenDepth = 0
    if (selected) {
        if (selected.field in fields && fields[selected.field].def) {
            selectedHiddenDepth = findHiddenDepth(fields[selected.field].def.fields, selected.value, depth + 1)
        }
    }

    const topLevel = depth == maxSelection || (depth == maxSelection - hiddenDepth)
    const buried = depth < maxSelection - selectedHiddenDepth - 1

    useEffect(() => {
        // Set default selected if selectable
        if (hiddenChild) {
            handleSelectArray(hiddenChild, data[hiddenChild][0])
        }
        /*Object.keys(fields).forEach((x) => {
            if (fields[x].def && fields[x].def?.hiddenSingle && x in data && data[x].length < 2) {
                handleSelectArray(x, data[x][0])
            }
        })*/
    }, [])

    useEffect(() => {
        // Force hidden single selection
        if (!selected && hiddenChild) {
            handleSelectArray(hiddenChild, data[hiddenChild][0])
        }
    }, [selected])

    useEffect(() => {
        const maxSelection = Math.max(Object.keys(selection).length)
        if (maxSelection < depth) {
            setSelected(null)
        } else {
            setSelected(selection[depth])
        }
    }, [selection])

    if (!data) {
        return <h3> Oops </h3>
    }

    function handleTooltip(e, x) {
        if (parent && parent.current) {
            const rect = parent.current.getBoundingClientRect()
            const newTooltip = { key: x, left: e.pageX - rect.left, top: e.pageY - rect.top }
            setShowTooltip(newTooltip)
        } else {
            fnc.attachTooltipDocument(e, x)
        }
    }

    function clearDependentValue(field) {
        Object.keys(fields).forEach((x) => {
            if (fields[x].dependsOn == field) {
                clearDependentValue(x)
            }
        })
        handleValue(field, null)
    }

    function handleValue(field, value) {
        // Some transformations
        switch (field) {
            case 'sqft':
                if ('sqin' in data) {
                    data.sqin = value * 12
                }
                break
            case 'sqin':
                if ('sqft' in fields) {
                    data.sqft = Math.floor(10 * value / 12) / 10
                }
                break
            default:
                break
        }
        if (fields[field].setter) {
            data[field] = fields[field].setter(data, field, value)
        } else if (fields[field].type == EditorFieldType.Enum && fields[field].multi) {
            data[field] = value
            /*const idx = data[field].findIndex((x) => x == value)
            if (idx == -1) {
                data[field] = [...data[field], value]
            } else {
                data[field].splice(idx, 1)
            }*/
        } else if (Array.isArray(fields[field]) && value == null) {
            data[field] = []
        } else {
            if (data[field] == value) {
                return
            }
            data[field] = value
        }
        if (LLMHelper && LLMHelper.id == field) {
            setLLMRefresh(Date.now())
        }
        onChange()
    }

    function clearSelected() {
        if (onSelect) {
            onSelect(null, null, null, depth)
        }
    }
    function clearChildSelected() {
        if (onSelect) {
            for (let i = 1; i <= hiddenDepth; i += 1) {
                onSelect(null, null, null, depth + i)
            }
        }
    }

    function handleSelectArray(field, value) {
        if (onSelect) {
            onSelect(fields, field, value, depth)
        }
    }

    function handleAddArray(field) {

        function addProxy(subFields, f, dat, d = 0) {
            let newObj = getDefaults(subFields)

            if (buried && d == 0) {
                clearChildSelected()
            }

            if (!f in dat) {
                return
            }

            let addSubObject = null
            Object.keys(subFields).forEach((x) => {
                if (subFields[x].def?.hiddenSingle || subFields[x].def?.addDefault) {
                    addSubObject = x
                }
            })

            if (addSubObject) {
                addProxy(subFields[addSubObject].def?.fields, addSubObject, newObj, d + 1)
                /*const subObj = getDefaults(subFields[addSubObject].def?.fields)
                if (subObj.name) {
                    subObj.name = `${subObj.name}-1`
                }
                if (subObj.link) {
                    subObj.link = `${subObj.link}-1`
                }
                newObj[addSubObject].push(subObj)*/
            }

            if (newObj.name) {
                newObj.name = `${newObj.name}-${dat[f].length + 1}`
            }
            if (newObj.link) {
                if (dat[f].length == 0) {
                    newObj.link = newObj.link
                } else {
                    newObj.link = `${newObj.link}-${dat[f].length + 1}`
                }
            }
            // First check if the order is set up correctly
            if (dat[f].length > 0 && 'order' in dat[f][0]) {
                const expectedSum = (dat[f].length + 1) * dat[f].length / 2
                const sum = dat[f].reduce((acc, x) => acc + x.order, 0)
                if (expectedSum != sum) {
                    // For what is actually in there now, and then apply new ordering
                    const currentOrder = dat[f].map((x, ix) => ({ ix, order: x.order })).sort((a, b) => a.order - b.order)
                    currentOrder.forEach((x, i) => dat[f][x.ix].order = i)
                }
            }

            if (tree) {
                // Move all other elements order + 1
                newObj.order = 0
                if (dat[f].length > 0 && 'order' in dat[f][0]) {
                    dat[f].forEach((x) => x.order += 1)
                }
                dat[f].unshift(newObj)
            } else {
                newObj.order = dat[f].length
                dat[f].push(newObj)
            }
        }
        // Plug into a different data structure
        if (fields[field].addMap) {
            addProxy(fields[field].def.fields, field, fields[field].addMap(data, config))
        } else {
            addProxy(fields[field].def.fields, field, data)
        }
        onChange()
    }

    function handleRemoveArray(field, value) {
        const idx = data[field].findIndex((x) => tempIsEqual(x, value))
        if (idx != null) {
            data[field].splice(idx, 1)
        }
        onChange()
    }

    function handleReorderArray(field, value, direction) {
        const idx = data[field].findIndex((x) => tempIsEqual(x, value))
        // See if we need to initialize the element orders
        const orderSum = data[field].reduce((acc, x) => acc + x.order, 0)
        const expectedSum = data[field].length * (data[field].length - 1) / 2
        if (orderSum != expectedSum) {
            data[field].forEach((x, i) => x.order = i)
        }
        if (idx != null) {
            const order = data[field][idx].order
            if (order + direction >= 0 && order + direction < data[field].length) {
                const elem = data[field][idx]
                // Find element with order + direction
                const swapOrder = order + direction
                const swapElem = data[field].find((x) => x.order == swapOrder)
                if (swapElem) {
                    swapElem.order = order
                    elem.order = swapOrder
                } else {
                    elem.order = swapOrder
                }
            }
        }
        onChange()
    }

    function handleMedia(field) {
        let currentMedia = []
        let multi = fields[field].multi
        let mediaTypeId = fields[field].mediaTypeId || null
        if (Array.isArray(data[field])) {
            currentMedia = data[field]
        } else {
            currentMedia = [data[field]]
        }
        // Reduce if necessary
        currentMedia = currentMedia.map((x) => x && x.mediaId ? x.mediaId : x)

        dispatch(showPrompt({ type: PromptType.Media, selected: currentMedia, app, organization, mediaTypeId, multi }))
            .then((x) => {
                if (x.payload) {
                    let result = x.payload.filter((x) => x != 'null' && x != 'undefined')
                    if (fields[field].order) {
                        result = result.map((x, ix) => ({ mediaId: x, order: ix }))
                    }
                    if (multi) {
                        handleValue(field, result)
                    } else if (x.payload.length > 0) {
                        handleValue(field, result[0])
                    } else {
                        handleValue(field, null)
                    }
                }
            })
    }

    function handleGallery(field) {
        dispatch(showPrompt({ type: PromptType.Gallery, selected: data[field], app, organization }))
            .then((x) => {
                if (x.payload) {
                    if (x.payload == 'none') {
                        handleValue(field, null)
                    } else {
                        handleValue(field, x.payload)
                    }
                }
            })
    }

    function handleLocation(field) {
        const { type, label, ...options } = fields[field]
        dispatch(showPrompt({ type: PromptType.Location, selected: data[field], app, organization, ...options }))
            .then((x) => {
                if (x.payload) {
                    handleValue(field, x.payload)
                } else if (x.payload !== false) {
                    handleValue(field, null)
                }
            })
    }

    function handleDelete() {
        // Delete selected
        if (selected) {
            const idx = data[selected.field].findIndex((x) => tempIsEqual(x, selected.value))
            if (idx != null) {
                data[selected.field].splice(idx, 1)
            }
            clearSelected()
            onChange()
        }
    }

    function handleDuplicate() {
        const field = selected.field
        // Create a duploicate and add it to data
        const dupe = fnc.copyObj(selected.value)
        let name = dupe.name
        let inc = 0
        while (true && inc < 1000) {
            const nameCount = data[field].filter((x) => x.name == name).length
            if (nameCount > 0) {
                inc += 1
                name = `${dupe.name} (${inc})`
            } else {
                dupe.name = name
                break
            }
        }

        if ('link' in dupe && dupe.link != null && dupe.link.length > 0) {
            let link = dupe.link
            inc = 0
            while (true && inc < 1000) {
                const linkCount = data[field].filter((x) => x.link == link).length
                if (linkCount > 0) {
                    inc += 1
                    link = `${dupe.link} (${inc})`
                } else {
                    dupe.link = link
                    break
                }
            }
        }

        // Clear all ids and generate tempIds recursively on objects
        const setupTemp = (o) => {
            if ('id' in o) {
                o.id = null
            }
            o.tempId = fnc.uniqueId()
        }

        if (field == 'options') {
            setupTemp(dupe)
            dupe.variations.forEach((x) => {
                x.upgradeOptionId = dupe.tempId
                setupTemp(x)
                x.components.forEach((y) => {
                    y.upgradeOptionVariationId = x.tempId
                    setupTemp(y)
                    y.products.forEach((z) => {
                        z.upgradeOptionComponentId = y.tempId
                    })
                })
            })
        } else if (field == 'variations') {
            setupTemp(dupe)
            dupe.components.forEach((y) => {
                y.upgradeOptionVariationId = dupe.tempId
                setupTemp(y)
                y.products.forEach((z) => {
                    z.upgradeOptionComponentId = y.tempId
                })
            })
        } else if (field == 'components') {
            setupTemp(dupe)
            dupe.products.forEach((z) => {
                z.upgradeOptionComponentId = y.tempId
            })
        } else if (field == 'products') {
            setupTemp(dupe)
            dupe.products.forEach((z) => {
                z.upgradeOptionId = y.tempId
            })
        }
        data[field].push(dupe)

        onChange()
    }

    function handleLLMHelper(e, field, options) {
        if (LLMHelper && LLMHelper.id == field) {
            setLLMHelper(null)
            return
        }

        const newLLMHelper = { id: field, title: `Generate ${field}`, options, type: LLMGenerateType.SingleField }
        if (parent && parent.current) {
            const rect = parent.current.getBoundingClientRect()
            newLLMHelper.left = rect.left
            newLLMHelper.top = rect.top
            newLLMHelper.target = parent.current
        } else if (elem) {
            const rect = elem.getBoundingClientRect()
            newLLMHelper.left = rect.left + rect.width + 20
            newLLMHelper.top = rect.top + e.pageY - e.target.clientHeight
            if (field == 'body') {
                // Get target.parent.parent.top
                newLLMHelper.top = e.target.parentElement.parentElement.parentElement.getBoundingClientRect().top
            }
            newLLMHelper.target = e.target
        } else {
            newLLMHelper.left = e.pageX + 20
            newLLMHelper.top = e.pageY
        }
        setLLMHelper(newLLMHelper)
    }

    function handleLLMHelperClose() {
        setLLMHelper(null)
    }

    function getSelected() {
        if (!selected || !(selected.field in data)) {
            return null
        }
        const selectedData = data[selected.field].map((x) => {
            if (selected.field in fields && fields[selected.field].dataMap) {
                return fields[selected.field].dataMap(x, config)
            }
            return x
        }).find((x) => {
            return tempIsEqual(selected.value, x)
        })
        return selectedData
    }

    function getFilters() {
    }

    function getFieldHeader(x) {
        if (fields[x].label) {
            return fields[x].label
        }
        return x.toReadable()
    }


    function getFieldInput(field, compact = false) {
        let text = getFieldHeader(field)
        let key = field

        let type = fields[field].type != null ? fields[field].type : EditorFieldType.String
        let childElements = null
        if (fields[field].optional) {
            childElements = <IconButton icon="fas fa-times" noBg onClick={() => handleValue(field, null)} />
        }
        if (fields[field].LLM) {
            childElements = []
            if (LLMHelper && LLMHelper.id == field) {
                childElements.push(<LLMContentHelper value={data[field]} {...LLMHelper} app={app} organization={organization} onSubmit={(x) => handleValue(field, x)} onClose={handleLLMHelperClose} scrollElem={elem} />)
                key = `${key}_${llmRefresh}`
            }
            childElements.push(<IconButton icon="fas fa-hand-sparkles" noBg onClick={(e) => handleLLMHelper(e, field, fields[field].LLM)} />)
        }
        const tooltip = fields[field].tooltip

        let autoComplete = null
        if (fields[field].autoComplete && typeof fields[field].autoComplete == 'function') {
            autoComplete = fields[field].autoComplete(data, config)
        }
        let elem = null
        let source = null
        if (app) {
            source = app
        } else if (organization) {
            source = organization
        }
        switch (type) {
            case EditorFieldType.Fixed:
                elem = <Input key={key} fixed value={data[field]}><IconButton noBg icon="fas fa-copy" onClick={() => fnc.copyToClipboard(data[field])} /></Input>
                break
            case EditorFieldType.Integer:
                if (fields[field].slider && fields[field].slider.min && fields[field].slider.max) {
                    elem = <Slider key={key} simple value={data[field]} min={fields[field].slider.min} max={fields[field].slider.max} step={fields[field].slider.step} onChange={(x, y) => {
                        handleValue(field, parseInt(x))
                    }
                    }> {childElements}</Slider >
                } else {
                    elem = <Input key={key} value={data[field]} number onChange={(x) => handleValue(field, parseInt(x))}>{childElements}</Input>
                }
                break
            case EditorFieldType.Float:
                elem = <Input key={key} forceUpdate value={data[field]} onChange={(x) => handleValue(field, parseFloat(x))}>{childElements}</Input>
                break
            case EditorFieldType.Checkbox:
                elem = <Checkbox key={key} value={data[field]} onChange={(x) => handleValue(field, x)}>{childElements}</Checkbox>
                break
            case EditorFieldType.Vector:
                elem = <VectorInput key={key} value={data[field]} onChange={(x) => handleValue(field, x)} fields={fields[field]?.vectorFields} />
                break
            case EditorFieldType.Array:
                elem = <div className="button-group">
                    {data[field].map((x, ix) => {
                        return <Button key={ix} className={selected && tempIsEqual(selected, x) ? ' selected' : ''} onClick={() => handleSelectArray(field, x)}>{x?.name}</Button>
                    })}
                    <Button icon="fas fa-plus" onClick={() => handleAddArray(field)}>{`Add ${text.singular()}`}{childElements}</Button>
                </div>
                // text = null
                break
            case EditorFieldType.Media:
                if (data[field] && Array.isArray(data[field]) && data[field].length > 0) {
                    let mediaItems = data[field].map((x) => x.mediaId ? x.mediaId : x)
                    if (mediaItems.length == 1) {
                        elem = <MediaTile key={media[mediaItems[0]]} app={app} organization={organization} hoverShow media={media[mediaItems[0]]} onClick={() => handleMedia(field)} />
                    } else {
                        mediaItems = mediaItems.map((x) => ({ id: x }))
                        elem = <GalleryTile app={app} organization={organization} gallery={{ name: `${data[field].length} ${fields[field].label.plural()}`, media: mediaItems }} onClick={() => handleMedia(field)} />
                    }
                } else if (data[field] && !Array.isArray(data[field]) && media[data[field]]) {
                    if (media[data[field]]) {
                        elem = <MediaTile key={media[data[field]]} app={app} organization={organization} hoverShow media={media[data[field]]} onClick={() => handleMedia(field)} />
                    } else {
                        elem = <Button onClick={() => handleMedia(field)}>Invalid Media{childElements}</Button>
                    }
                } else {
                    elem = <Button onClick={() => handleMedia(field)}>{`Select ${text}`}{childElements}</Button>
                }
                // text = null
                break
            case EditorFieldType.Gallery:
                if (data[field]) {
                    const gallery = source.galleries.find((x) => x.id == data[field])
                    if (gallery) {
                        elem = <GalleryTile app={app} gallery={gallery} onClick={() => handleGallery(field)} />
                    }
                } else {
                    elem = <Button onClick={() => handleGallery(field)}>{`Select ${text}`}{childElements}</Button>
                }
                // text = null
                break
            case EditorFieldType.Location:
                if (data[field]) {
                    const location = source.locations.find((x) => x.id == data[field])
                    if (!location) {
                        elem = <Button onClick={() => handleLocation(field)}>Select Location</Button>
                    } else {
                        let [locationString, googleString] = formatLocation(location)
                        elem = <Button onClick={() => handleLocation(field)}>{location.name ? location.name : locationString}</Button>
                    }
                } else {
                    elem = <Button onClick={() => handleLocation(field)}>Select Location</Button>
                }
                break
            case EditorFieldType.Floorplan:
                return null
            case EditorFieldType.Enum:
                if (fields[field].enum) {
                    const items = fields[field].enum(data, config)?.map((x) => ({ text: x.name, value: 'id' in x ? x.id : x.tempId }))
                    let text = null
                    if ((true && (data[field] == null || (Array.isArray && data[field].length == 0))) || (compact && data[field] == null)) {
                        text = `Select ${fields[field].label || field}`
                    }
                    if (items) {
                        if (Object.values(items).length > 0) {
                            elem = <DropdownMenu search={Object.keys(items).length > 10} items={Object.values(items)} onChange={(x) => handleValue(field, x)} value={data[field]} multi={fields[field].multi} text={text} />
                        }
                    }
                }
                // text = null
                break

            case EditorFieldType.Icon:
                const allIcons = Object.keys(icons).map((x) => ({ value: icons[x], icon: icons[x], text: null/*text: x.toReadable()*/ }))
                let finalIcon = data[field]
                if (Object.keys(icons).includes(finalIcon)) {
                    finalIcon = icons[data[field]]
                }
                elem = <DropdownMenu
                    items={allIcons}
                    onChange={(x) => handleValue(field, x)}
                    value={finalIcon}
                    icon={finalIcon}
                    asGrid={true}
                    gridSize={5}
                    inputOverride={true}
                    text={!data[field] ? 'Select Icon' : allIcons.find((x) => x.icon == data[field])?.text} />
                break
            case EditorFieldType.Price:
                elem = <Input key={key} value={data[field]} onChange={(x) => {
                    handleValue(field, parseFloat(x))
                }} autoComplete={autoComplete} price={true}>{childElements}</Input>
                break
            case EditorFieldType.TextField:
                elem = <div className="row hover-options-parent">
                    <MdEditor
                        key={key}
                        view={{ menu: true, md: true, html: false }}
                        plugins={['header', 'font-bold', 'font-italic', 'font-underline', 'list-unordered', 'list-ordered', 'block-quote', 'block-wwap', 'link', 'mode-toggle']}
                        style={{ height: '300px' }} value={data[field] ? data[field] : ''} renderHTML={text => text ? mdParser.render(text) : ''} onChange={(y) => handleValue(field, y.text)}>
                    </MdEditor>
                    <div className="bottom-right hover-options" style={{ right: '20px' }}>
                        {childElements}
                    </div>
                </div>
                break
            case EditorFieldType.Color:
                elem = <ColorPicker color={data[field]} onChange={(x) => handleValue(field, x)} />
                break
            case EditorFieldType.StringTag:
                let items = fields[field].enum(data, config)
                const selected = data[field] ? data[field].split(',') : []
                const handleTagAdd = (tag) => {
                    if (!selected.includes(tag)) {
                        const newTags = [...selected, tag].filter(t => t.length > 0).join(',')
                        handleValue(field, newTags)
                    } else {
                        const newTags = selected.filter(t => t !== tag && t.length > 0).join(',')
                        handleValue(field, newTags)
                    }
                }
                items = Array.from(new Set([...items, ...selected]))
                elem = <div className="column string-tags">
                    <DropdownMenu value={selected} items={items.map((x) => ({ text: x, value: x }))} onChange={handleTagAdd} onAdd={handleTagAdd} />
                </div>
                break
            case EditorFieldType.Date:
                elem = <DateSelector collapse selected={data[field] ? new Date(data[field]) : new Date()} onChange={(x) => handleValue(field, fnc.formatDateToYYYYMMDD(x))} />
                break
            case EditorFieldType.String:
            default:
                elem = <Input key={key} value={data[field]} onChange={(x) => handleValue(field, x)} autoComplete={autoComplete}>{childElements}</Input>
                break
        }

        if (compact && text != null) {
            return <div className="row compact-row-group">
                <span className="compact-row-label">{text}
                    {tooltip && <IconButton noBg icon={icons.infoCircle} onMouseOver={(e) => handleTooltip(e, tooltip)} />}
                </span>
                {elem}
            </div>
        }
        return elem
    }

    // Render tree
    if (tree) {
        return childFields.map((x, ix) => {
            let text = (fields[x].def.title) || x.toTitleCase()
            const treeTitle = <span className="tree-title-group">
                {prev && <span>{prev}</span>}
                <h4>{text.plural()}</h4>
            </span>

            const selectedData = getSelected()
            let desc = selectedData?.name

            if (!data[x]) {
                return null
            }
            const hide = selected && fields[x].def?.hiddenSingle && data[x].length < 2
            const showFilter = data[x] && data[x].length > 10

            let finalData = data[x]
            if (finalData && fields[x].sort) {
                if (typeof fields[x].sort == 'function') {
                    finalData = [...data[x]].sort(fields[x].sort)
                } else {
                    finalData = [...data[x]].sort((a, b) => {
                        return a.name.localeCompare(b.name)
                    })
                }
            }
            return <React.Fragment key={ix}>
                {!hide && <div className="row tree-branch fadeIn">
                    <div className="row tree-topbar">
                        <div className="row tree-title" onClick={clearChildSelected}>
                            <span>
                                {treeTitle}
                                {fields[x].def.tooltip && <Button onClick={(e) => handleTooltip(e, x)} onMouseMove={(e) => handleTooltip(e, x)} onMouseLeave={() => setShowTooltip(null)} className="question-button" >?</Button>}
                            </span>
                            {!def.fixed && <IconButton noBg icon="fas fa-plus" onClick={(e) => { e.stopPropagation(); handleAddArray(x); }} />}
                        </div>
                        {showFilter && <Input className="tree-filter" value={filter} onChange={setFilter} clear />}
                    </div>
                    {finalData && finalData.length > 0 && <div className="row button-group" style={showFilter ? { marginTop: 0 } : null}>
                        {finalData.map((y) => {
                            let yData = fields[x].dataMap ? fields[x].dataMap(y, config) : y
                            let finalName = ''
                            if (fields[x]?.def.nameMap) {
                                finalName = fields[x].def.nameMap(yData, config)
                            } else if (yData.name) {
                                finalName = yData.name
                            }
                            return { ...y, finalName, data: yData }
                        }).sort((a, b) => {
                            if (fields[x].sort === true) {
                                return a.finalName.localeCompare(b.finalName)
                            } else if (fields[x].sort && typeof fields[x].sort == 'function') {
                                return fields[x].sort(a, b)
                            }
                            return 1
                        }).filter((y) => {
                            if (filter != null) {
                                return y.finalName.toLowerCase().includes(filter.toLowerCase())
                            }
                            return true
                        }).map((y, iy) => {
                            let yData = y.data

                            if (!yData || (!topLevel && buried && selected && !tempIsEqual(selected.value, yData))) {
                                return null
                            }

                            return <Button key={iy} className={`tree-button${selected && tempIsEqual(selected.value, yData) ? ' selected' : ''}${!topLevel && buried ? ' buried' : ''}`} onClick={() => handleSelectArray(x, yData)}>
                                {y.finalName}
                            </Button>
                        })}
                    </div>}
                </div>}
                {selectedData && <GenericEditor
                    key={selectedData.id || selectedData.tempId}
                    app={app}
                    organization={organization}
                    def={fields[x].def}
                    parent={parent}
                    data={selectedData}
                    config={config}
                    selection={selection}
                    onChange={onChange}
                    onSelect={onSelect}
                    onDelete={handleDelete}
                    prev={hide ? prev : desc}
                    tree={true}
                    depth={depth + 1} />}
                {(showTooltip && showTooltip.key == x) && <span className="tree-tooltip" style={{ left: showTooltip.left + 10, top: showTooltip.top }}>{fields[x].def.tooltip}</span>}

            </React.Fragment>
        })
    }
    if (topLevel || nested) {
        let optionalGroup = []
        let rows = []

        function dependencyCheck(field) {
            if (fields[field].dependsOn) {
                const dependsField = fields[field].dependsOn
                const dependsValue = data[dependsField]
                if ('dependsOnValue' in fields[field] && dependsValue != fields[field].dependsOnValue) {
                    return false
                }

                if (!dependsValue || (dependsField in config && !(dependsValue in config[dependsField]))) {
                    return false
                }
            }
            return true
        }

        function getField(x, ix) {
            if (!(x in fields)) {
                logger.error("Missing field", x, fields)
                return null
            }
            let text = fields[x].label ? fields[x].label?.singular() : x?.singular().toTitleCase()
            let subRows = []
            if (fields[x].type == EditorFieldType.Array && fields[x].def) {
                return null
            }

            if (nested && fields[x].hiddenNested) {
                return null
            }
            if (!dependencyCheck(x)) {
                return null
                // const dependsField = fields[x].dependsOn
                // const dependsValue = data[dependsField]
                // if (!dependsValue || !(dependsField in config && dependsValue in config[dependsField])) {
                // return null
                // }
            }

            if (fields[x].type == EditorFieldType.Multi) {
                if (fields[x].operation) {
                    const fieldOp = fields[x].operation
                    if (fieldOp.input) {
                        subRows.push(<td colSpan={2}>
                            <Input onSubmit={(x) => fieldOp.callback(data, { ...config, media }, x, onChange)} placeholder={fieldOp.label} />
                        </td>)
                    } else {
                        subRows.push(<td colSpan={2}>
                            <Button onClick={() => fieldOp.callback(data, { ...config, media }, null, onChange)}>{fieldOp.label}</Button>
                        </td>)
                    }
                }
                if (!Array.isArray(data[x])) {
                    // pass
                } else if (data[x] && data[x].length > 0) {
                    let sortedData = []
                    if (fields[x].reorder) {
                        sortedData = [...data[x]].sort((a, b) => a.order - b.order)
                    } else {
                        sortedData = data[x]
                    }
                    sortedData?.forEach((y, iy) => {
                        let idKey = 'id' in y ? y.id : ('tempId' in y ? y.tempId : iy)
                        let orderKey = 'order' in y ? y.order : iy
                        subRows.push(
                            <GenericEditor
                                key={`${idKey}`}
                                app={app}
                                organization={organization}
                                def={fields[x].def}
                                data={y}
                                parentSet={sortedData}
                                parentIndex={iy}
                                parentSize={sortedData.length}
                                config={config}
                                parent={parent}
                                selection={selection}
                                onChange={onChange}
                                onSelect={onSelect}
                                onDelete={() => handleRemoveArray(x, y)}
                                onReorder={fields[x].reorder ? (dir) => handleReorderArray(x, y, dir) : null}
                                depth={depth + 1}
                                compact={true}
                                nested={true} />)
                    })
                }

                let subText = (fields[x].label || x.toTitleCase()).singular()
                if (!fields[x].limit || !data[x] || data[x]?.length < fields[x].limit) {
                    const buttonElem = <Button style={{ width: 'auto', margin: '0 0 0 auto' }} icon="fas fa-plus" onClick={() => handleAddArray(x, true)} >Add {subText}</Button>
                    if (compact) {
                        subRows.push(buttonElem)
                    } else {
                        subRows.push(<tr key="department-add">
                            {/* <th>{data[x].length == 0 && subText}</th> */}
                            <td colSpan={2}>
                                {buttonElem}
                            </td>
                        </tr>)
                    }
                }
                return subRows
            } else if (fields[x].nested) {
                subRows.push(
                    <GenericEditor
                        key={x}
                        app={app}
                        organization={organization}
                        def={fields[x].def}
                        data={data}
                        config={config}
                        parent={parent}
                        selection={selection}
                        onChange={onChange}
                        onSelect={onSelect}
                        depth={depth + 1}
                        compact={true}
                        nested={true} />)
                return subRows
            }

            // if (nested && fields[x].hiddenNested) {
            //     return null
            // }
            // if (!dependencyCheck(x)) {
            //     const dependsField = fields[x].dependsOn
            //     const dependsValue = data[dependsField]
            //     if (!dependsValue || !(dependsValue in config[dependsField])) {
            //         return null
            //     }
            // }
            if (fields[x].optional) {
                if (data[x] == null) {
                    optionalGroup.push(x)
                    return null
                }
            } else if (optionalGroup.length > 0) {
                // Add optional
                subRows.push(<tr key={`${x}optional`}>
                    {/* <th></th> */}
                    <td colSpan={2}>
                        <div className="row" style={{ justifyContent: 'flex-end' }}>
                            <DropdownMenu
                                text="Add Field"
                                items={optionalGroup.map((x) => ({ text: fields[x].label || x.toTitleCase(), value: x }))}
                                onChange={(y) => handleValue(y, '')} />
                        </div>
                    </td>
                </tr>)
                optionalGroup = []
            }
            if (fields[x].heading) {
                subRows.push(<tr key={`${x}-heading`}>
                    <td colSpan={2} className="heading">
                        <h5>{fields[x].heading}</h5>
                    </td>
                </tr>)
            }

            let className = ''
            if (fields[x].disableOnNull && data[x] == null) {
                className = 'disabled'
            }
            if (compact) {
                subRows.push(<span key={ix}>{getFieldInput(x, true)}{fields[x].dependsOn && <IconButton noBg icon="fas fa-times" onClick={() => clearDependentValue(x)} />}</span>)
            } else {
                subRows.push(<tr key={x} className={className}>
                    <th className="noselect">{getFieldHeader(x)}</th>
                    <td>{getFieldInput(x)}{fields[x].dependsOn && <IconButton noBg icon="fas fa-times" onClick={() => clearDependentValue(x)} />}</td>
                </tr>)
            }
            return subRows
        }
        if (compact) {
            let children = editFields.map(getField)
            /*let children = editFields.map((x, ix) => {
                if (!dependencyCheck(x)) {
                    return null
                }
                const input = getFieldInput(x)
                if (input) {
                    return <span key={ix}>{getFieldInput(x, true)}{fields[x].dependsOn && <IconButton noBg icon="fas fa-times" onClick={() => clearDependentValue(x)} />}</span>
                }
                return null
            })*/
            rows.push(<tr key={title} className="nested">
                <th>
                    <span className="nested-title" onClick={() => setCollapsed(!collapsed)} style={{ cursor: 'pointer' }}>
                        {title}
                        <div className="column">
                            {def.tooltip && <IconButton noBg icon={icons.infoCircle} onMouseOver={(e) => handleTooltip(e, def.tooltip)} />}
                            <IconButton noBg icon={collapsed ? icons.chevronRight : icons.chevronDown} onClick={() => setCollapsed(!collapsed)} />
                        </div>
                    </span>
                </th>
                {!collapsed && <td><div className="row compact-row">{children}</div></td>}
                {collapsed && <td><div className="row compact-row">{children.filter((x, ix) => {
                    const field = fields[editFields[ix]]
                    if (field.primary === false) {
                        return false
                    }
                    return ix == 0 || fields[editFields[ix]]?.primary
                })}</div></td>}
            </tr>)
        } else {
            rows = editFields.map(getField)
        }


        let options = []

        if (hiddenChild) {
            if (getSelected()) {
                rows.push(<GenericEditor
                    key={selected.value.id || selected.value.tempId}
                    app={app}
                    organization={organization}
                    def={fields[hiddenChild].def}
                    data={getSelected()}
                    config={config}
                    parent={parent}
                    isSingle={data[hiddenChild].length <= 1}
                    selection={selection}
                    onChange={onChange}
                    onSelect={onSelect}
                    depth={depth + 1}
                    nested={true} />)
            }
            if (!fields[hiddenChild].def.fixed) {
                let childText = fields[hiddenChild].label ? fields[hiddenChild].label.singular() : hiddenChild.singular().toTitleCase()
                rows.push(<tr key="variation">
                    <td colSpan={2}>
                        <Button icon="fas fa-plus" style={{ width: 'auto', margin: '0 0 0 auto' }} onClick={() => handleAddArray(hiddenChild, true)} >Add {childText}</Button>
                    </td>
                </tr>)
            }
        }

        let actions = []

        if (onReorder) {
            // Check if parent set is ordered
            let order = data?.order

            // See if order matches sum of indices (0 + 1 + 2 etc)
            const orderSum = parentSet.reduce((acc, x) => acc + x.order, 0)
            const expectedSum = parentSet.length * (parentSet.length - 1) / 2
            if (orderSum != expectedSum) {
                // if (parentSet.filter((x) => x.order > 0).length == 0) {
                order = parentIndex
            }
            if (order) {
                actions.push(<IconButton noBg key="reorder-up" icon={icons.chevronUp} onClick={() => onReorder(-1)} />)
            }
            if (order < parentSize - 1) {
                actions.push(<IconButton noBg key="reorder-down" icon={icons.chevronDown} onClick={() => onReorder(1)} />)
            }
            // rows.push(<tr key="reorder" className={nested ? ' nested' : ''}>
            //     {nested && <th></th>}
            //     <td colSpan={nested ? 1 : 2}>
            //         <div className="row">
            //             <IconButton icon={icons.chevronUp} onClick={() => onReorder(-1)} />
            //             <IconButton icon={icons.chevronDown} onClick={() => onReorder(1)} />
            //         </div>
            //     </td>
            // </tr>)
        }

        if (nested && onDelete && (!def.hiddenSingle || !isSingle) && !def.fixed && !def.noDelete) {
            actions.push(<IconButton noBg key="delete" icon="fas fa-trash" onClick={onDelete} />)
        }
        if (nested && onDuplicate && !def.noDelete) {
            actions.push(<IconButton noBg key="duplicate" icon="fas fa-clone" onClick={() => onDuplicate(data)} />)
        }

        if (actions.length > 0) {
            rows.push(<tr key="actions" className={`content-actions${nested ? ' nested' : ''}`}>
                {nested && <th></th>}
                <td colSpan={nested ? 1 : 2}>
                    <div className="row" style={{ justifyContent: 'flex-end' }}>
                        {actions}
                    </div>
                </td>
            </tr>)
        }

        if (nested) {
            rows.push(<tr key="divider" className="divider">
                <th />
                <td />
            </tr>)
            return rows
        }

        return <div className="content-editor scrollable fadeIn" ref={setElem}>
            {title && <div className="content-editor-topbar">
                {onBack && !nested && <BackButton onClick={onBack} />}
                <div className="content-editor-title">
                    <h4>Edit {title}</h4>
                </div>
                {/*subtitle && <div className="content-editor-subtitle">
                    <h5>{subtitle}</h5>
                </div>}*/}
            </div>}
            <table>
                <tbody>
                    {rows}
                </tbody>
            </table>
        </div>
    } else if (getSelected()) {
        return <GenericEditor
            key={selected.value.id || selected.value.tempId}
            app={app}
            organization={organization}
            title={selected.field in fields && fields[selected.field].def && fields[selected.field].def.title || selected.field.toTitleCase()}
            def={selected.field in fields ? fields[selected.field].def : null}
            data={getSelected()}
            config={config}
            selection={selection}
            onChange={onChange}
            onSelect={onSelect}
            onDelete={handleDelete}
            onDuplicate={handleDuplicate}
            depth={depth + 1} />
    }
    return null
}
