import { Config, PageType, ProjectType } from 'app/types'
import { logger } from 'helpers/logger'

export function projectSubtitle(app: AppData, config: Config, pricing = true) {
    let subtitle = app.meta.subtitle || ''
    if (app.meta.projectTypes.length == 1 && app.meta.projectTypes[0] == ProjectType.Condo) {
        // pass
    } else if (subtitle.length == 0) {
        app.meta.projectTypes.forEach((x, ix) => {
            if (ix > 0 && ix == app.meta.projectTypes.length - 1) {
                subtitle += ' and '
            } else if (ix > 0) {
                subtitle += ', '
            }
            // subtitle += config.projectTypes[x].name.plural()
            subtitle += config.projectTypes[x].name
        })
        subtitle += ' Homes'
    }

    if (pricing && app.meta.pricing && app.meta.pricing.length > 0) {
        subtitle += ` ${app.meta.pricing}`
    }
    return subtitle
}

export function getPageTypeName(page: PageData, config: Config) {
    return config.pageTypes[page.pageTypeId]?.name
}

export function pageTags(pages) {
    return pages?.reduce((acc, x) => {
        if (x.tags) {
            return [...acc, ...x.tags.toLowerCase().split(',')]
        } else {
            return acc
        }
    }, []).distinct()
}