import { UpgradeOrderStatus, UpgradeTier, Visibility } from 'app/types'
import { logger } from 'helpers/logger'
import * as fnc from 'helpers/fnc'
import { getBuildingTypes } from 'actions/actionHelpers'
export function selectionKey(group, option, optionVariation, component, pckge = null) {
    if (!option || !optionVariation || !component) {
        return
    }
    // TODO, if option is not global, prepend group id
    let key = `${option.id}_${optionVariation.id}_${component.id}`
    if (pckge) {
        key = `${pckge.id}_${key}`
    }
    if (!option.global && group) {
        key = `${group ? group.id : 'unknown'}_${key}`
    }
    return key
}

export function tourKey(app, buildingType, floorplanVariation, selection) {
    if (!option) {
        return null
    }
    const {
        option, optionVariation, component, product,
    } = selection
    const key = `TheSummit-${buildingType.link}-${option.link}-${product.id}`
    return key
}

export function configKey(spec) {
    const { buildingType, floorplan, floorplanVariation, option, optionVariation, component, product, pckge, bedBath, unit } = spec
    return [buildingType, floorplan, floorplanVariation, option, optionVariation, component, product, pckge, bedBath, unit].filter((x) => {
        if (x && typeof x === 'object' && x.id == null) {
            return false
        }
        return x
    }).map((x) => (typeof x === 'object' ? x.id : x)).join('_')
}


const configCache = {}
export function getConfig(maps, spec, options) {
    let { buildingType, floorplan, floorplanVariation, option, optionVariation, component, product, pckge, bedBath, unit } = spec
    let { price, area, debug, flatten, cache } = { price: false, area: false, debug: false, flatten: false, cache: true, ...options }
    // Config keys from least specific to most specific
    if (bedBath == null && floorplanVariation != null) {
        bedBath = floorplanVariationBedBathCode(floorplanVariation)
    }
    let logDebug = (...args) => {
        if (!debug) {
            return
        }
        args.forEach((x) => {
            if (x && typeof x === 'object') {
                logger.info(x)
            } else {
                logger.info(x)
            }
        })
    }

    const cacheKey = configKey(spec)
    if (cache && cacheKey in configCache) {
        return configCache[cacheKey]
    }

    if (!maps?.optionConfiguration) {
        return null
    }
    // TODO, more optimal way to determine which keys should be called
    let keys = [cacheKey]

    const addKeys = (bt) => {
        if (unit) {
            if (pckge) {
                keys.push(() => configKey({ buildingType: bt, option, optionVariation, component, product, pckge, unit }))
            }
            if (product) {
                keys.push(() => configKey({ buildingType: bt, option, optionVariation, component, product, unit }))
            }
            keys.push(() => configKey({ buildingType: bt, option, optionVariation, component, unit }))
        }
        if (floorplan) {
            if (floorplanVariation) {
                if (product) {
                    keys.push(() => configKey({ buildingType: bt, floorplan, floorplanVariation, option, optionVariation, component, product }))
                }
                keys.push(() => configKey({ buildingType: bt, floorplan, floorplanVariation, option, optionVariation, component }))
            }
            if (pckge) {
                keys.push(() => configKey({ buildingType: bt, floorplan, option, optionVariation, component, product, pckge }))
            }
            if (product) {
                keys.push(() => configKey({ buildingType: bt, floorplan, option, optionVariation, component, product }))
            }
            keys.push(() => configKey({ buildingType: bt, floorplan, option, optionVariation, component }))
        }
        if (bedBath) {
            /*if (pckge) {
                keys.push(() => configKey({ buildingType: bt, option, optionVariation, component, product, pckge, bedBath }))
            }*/
            if (product) {
                keys.push(() => configKey({ buildingType: bt, option, optionVariation, component, product, bedBath }))
            }
            keys.push(() => configKey({ buildingType: bt, option, optionVariation, component, bedBath }))
        }
        if (pckge) {
            keys.push(() => configKey({ buildingType: bt, option, optionVariation, component, product, pckge }))
        }
        if (product) {
            keys.push(() => configKey({ buildingType: bt, option, optionVariation, component, product }))
        }
        keys.push(() => configKey({ buildingType: bt, option, optionVariation, component }))
    }
    if (buildingType) {
        addKeys(buildingType)
    }
    addKeys('global')

    let keySet = new Set()


    // Go from most specific to least specific
    let ret = []
    for (let i = 0; i < keys.length; i += 1) {
        if (keys[i] == null) {
            continue
        }
        const key = typeof keys[i] == 'function' ? keys[i]() : keys[i]
        if (keySet.has(key)) {
            continue
        }
        keySet.add(key)
        if (maps && maps.optionConfiguration && key in maps.optionConfiguration) {
            const config = maps.optionConfiguration[key]
            if (config && !(area == null && config.areas == null)
                && !(price != null && config.price == null && config.quantity == null && config.sqft == null && config.sqin == null && config.cost == null && config.finalCost == null && config.finalPrice == null && config.markup == null)) {

                if (flatten) {
                    ret.push(maps.optionConfiguration[key])
                } else {
                    configCache[cacheKey] = maps.optionConfiguration[key]
                    return maps.optionConfiguration[key]
                }
            }
        }
    }

    // If option uses floorplan sqft, unshift the plan sqft
    if (floorplan && floorplanVariation && option?.useFloorplanSqft) {//name.includes('Flooring')) {
        if (!floorplanVariation.id && floorplan.variations?.length > 0) {
            ret.unshift({
                upgradeOptionId: option?.id,
                upgradeComponentId: component?.id,
                upgradeOptionVariationId: optionVariation?.id,
                floorplanId: floorplan.id,
                floorplanVariationId: floorplan.variations[0].id,
                sqft: floorplan.variations[0].sqft,
            })
        } else if (floorplanVariation.id) {
            ret.unshift({
                upgradeOptionId: option?.id,
                upgradeComponentId: component?.id,
                upgradeOptionVariationId: optionVariation?.id,
                floorplanId: floorplan.id,
                floorplanVariationId: floorplanVariation.id,
                sqft: floorplan.variations[0].sqft,
            })
        }
    }
    if (flatten) {
        // Flatten all the configs into a single config, with the most specific config last
        const config = {}
        ret.reverse().forEach((x) => {
            Object.keys(x).forEach((key) => {
                if (x[key] != null || !(key in x)) {
                    config[key] = x[key]
                }
            })
        })
        configCache[cacheKey] = config
        return config
    }
    return null
}

export function getPrice(maps, spec, options) {
    const { buildingType, floorplan, floorplanVariation, option, optionVariation, component, product, pckge, bedBath, unit } = spec
    let { selection, customizations, group, debug, relative, relativePackage, cache, breakdown, standardPricing, admin, subcost, ignoreQuantityAndSqft, ignoreCalculated } = { ...options }

    const logDebug = (...args) => {
        if (!debug) {
            return
        }
        args.forEach((x) => {
            if (x && typeof x === 'object') {
                logger.info(x)
            } else {
                logger.info(x)
            }
        })
    }
    if (!option || !optionVariation || !component || (!option.custom && (!product && !pckge))) {
        return [0, 0, 0, {}, null]
    }

    let price = 0
    let cost = 0
    let currentPrice = null
    let currentPriceUnit = null
    let calculateCost = false
    let calculatePrice = false
    let quantity = null
    let sqft = null
    let calculation = admin ? {
        cost: '',
        finalCost: '',
        price: '',
        finalPrice: '',
    } : {}


    const isPackage = pckge && (!product || product.upgradePackageId != null || !product.id)
    let packageDelta = 0
    let packageDeltaCost = 0
    let packageDeltaUnit = 0
    let lowerOrEqualTier = false
    let priceSpec = { ...spec }
    let fixedPackagePrice = false
    if (pckge && !isPackage) {
        // Determine if parent package has fixed pricing
        if (!relativePackage && pckge.upgradeTierId > UpgradeTier.Standard) {
            let parentSpec = getPackageSpec(pckge, maps)
            parentSpec = { ...priceSpec, ...parentSpec }
            parentSpec.product = { option: parentSpec.option, pckge, upgradePackageId: pckge.id }
            const parentConfig = getConfig(maps, parentSpec, { price: true, flatten: true })
            if (parentConfig.finalCost || parentConfig.finalPrice) {
                fixedPackagePrice = true
            }
        }

        delete priceSpec.pckge
    }
    const override = {}
    const config = getConfig(maps, priceSpec, { price: true, debug, flatten: true, cache })

    const calculate = (x, y, override = false) => {
        if (admin) {
            if (override) {
                calculation[x] = y
            } else {
                calculation[x] += y
            }
        }
    }

    if (config && config.finalCost) {
        cost = config.finalCost
    } else if (config && config.cost) {
        cost = config.cost
        calculateCost = true
    }

    if (selection?.priceOverride != null) {
        price = selection.priceOverride
        override.price = true
    } else if (config && config.finalPrice) {
        price = config.finalPrice
    } else if (config && config.price) {
        price = config.price
        calculatePrice = true
    } else if (product && product.id) {
        /*if (product.priceSqft && config?.sqft) {
            price = product.priceSqft * config.sqft
            calculate('price', `${product.priceSqft} sqft * ${config.sqft} sqft`)
        } else if (product.priceSqin && config?.sqft) {
            price = (product.priceSqin / 12.0) * config.sqft
            calculate('price', `(${product.priceSqin} sqin / 12) * ${config.sqft} sqft`)
        }*/
        // } else if (pckge && (!product || product.upgradePackageId != null)) {
    } else if (isPackage) {
        if (pckge.priceCost) {
            price = pckge.priceCost
            // Check for customizations
            /* if (customizations && pckge.id in customizations) {
                pckge.products.forEach((y) => {
                    const optionB = maps.option[y.upgradeOptionId]
                    const optionVariationB = optionB.variations[0]
                    const componentB = optionVariationB.components[0]
                    const key = selectionKey(group, optionB, optionVariationB, componentB)
                    let customProduct = null
                    if (key in customizations[pckge.id]) {
                        customProduct = maps.product[customizations[pckge.id][key]]
                        const [priceB, priceUnitB, quantityB] = getPrice(maps, buildingType, floorplan, floorplanVariation, optionB, optionVariationB, componentB, customProduct, null, selection)
                        price += priceB
                        // Find default product
                        /*const defaultProd = pckge.products.find((x) => x.upgradeOptionId == optionB.id)
                        if (defaultProd && defaultProd.upgradeProductId != customProduct.id) {
                            const productB = maps.product[defaultProd.upgradeProductId]
                            const [priceB, priceUnitB, quantityB] = getPrice(maps, buildingType, floorplan, floorplanVariation, optionB, optionVariationB, componentB, productB, null, selection)
                            const [priceC, priceUnitC, quantityC] = getPrice(maps, buildingType, floorplan, floorplanVariation, optionB, optionVariationB, componentB, customProduct, null, selection)
                            packageDelta += (priceC - priceB)
                            price += (priceC - priceB)
                        } */

            // Get package for product
            /* const packageKey = `${option.id}_${customProduct.id}`
                        const customProductPackages = maps.productPackage[packageKey]
                        // See what tiered fees have been
                        customProductPackages?.filter((x) => x.upgradeTierId > pckge.upgradeTierId).find((x) => {
                            const config = getConfig(maps, buildingType, floorplan, floorplanVariation, optionB, optionVariationB, componentB, customProduct, x, true, false, false)
                            if (config && config.tieredFee != null) {
                            }
                        })*
 
                    }
                })
            } */
        } else {
            // Sum up costs
            /*pckge.products.forEach((y) => {
                const optionB = maps.option[y.upgradeOptionId]
                const optionVariationB = optionB.variations[0]
                const componentB = optionVariationB.components[0]
                const productB = maps.product[y.upgradeProductId]
                const customPrice = false
                /* if (customizations && pckge.id in customizations) {
                    const key = selectionKey(group, optionB, optionVariationB, componentB)
                    if (key in customizations[pckge.id]) {
                        const customProduct = maps.product[customizations[pckge.id][key]]
                        const [priceB, priceUnitB, quantityB, overrideB, configB] = getPrice(maps, buildingType, floorplan, floorplanVariation, optionB, optionVariationB, componentB, customProduct, pckge, selection)
                        price += priceB
                        customPrice = true
                    }
                } *
                if (!customPrice) {
                    // Check for customizations
                    const {
                        price: priceB, priceUnit: priceUnitB, quantity: quantityB, cost: costB,
                    } = getPrice(maps, buildingType, floorplan, floorplanVariation, optionB, optionVariationB, componentB, productB, pckge, { selection })
                    price += priceB
                }
            })*/
        }
    }
    // Selection overrides
    // if (!ignoreCalculated && selection?.priceCalc != null) {//} && !selection.package) {
    //     frozenPrice = selection.priceCalc
    //     if (selection?.priceSqft && config.quantity) {
    //         price = frozenPriceUnit = selection.priceSqft
    //     }
    // } 

    if (admin && calculation.cost?.length == 0 && cost > 0) {
        calculate('cost', `${fnc.toMoney(cost)}`)
        calculate('finalCost', `${fnc.toMoney(cost)}`)
    }
    if (admin && calculation.price?.length == 0 && price > 0) {
        calculate('price', `${fnc.toMoney(price)}`)
        calculate('finalPrice', `${fnc.toMoney(price)}`)
    }


    if (isPackage && !selection?.priceOverride) {
        let priceSet = price != 0
        // Sum up costs
        if ((pckge.upgradeTierId > UpgradeTier.Standard || standardPricing) && !config.finalCost && !config.finalPrice) {
            pckge.products.forEach((y) => {
                if (!maps.option) {
                    logger.info("Invalid maps", maps)
                    return
                }
                const optionB = maps.option[y.upgradeOptionId]
                const optionVariationB = optionB.variations[0]
                const componentB = optionVariationB.components[0]
                const productB = maps.product[y.upgradeProductId]
                const customPrice = false
                // Check for customizations
                const specB = { buildingType, floorplan, floorplanVariation, option: optionB, optionVariation: optionVariationB, component: componentB, product: productB, pckge }
                const priceConfigB = getPrice(maps, specB, { relative, relativePackage, admin })
                const {
                    price: priceB, priceUnit: priceUnitB, quantity: quantityB, cost: costB, config: configB, override: overrideB
                } = priceConfigB
                if (!priceSet) {
                    price += priceB
                    calculate('price', calculation.price?.length > 0 ? ` + ${fnc.toMoney(priceB)}` : `${fnc.toMoney(priceB)}`)

                }
                cost += costB
                calculate('cost', calculation.cost?.length > 0 ? ` + ${fnc.toMoney(costB)}` : `${fnc.toMoney(costB)}`)
            })
        }
        // If package is set, check for tiered fees in customization
        // !product.id indicates is package not part of package
        if (!standardPricing && customizations && pckge.id in customizations) {
            // Sum up price from pckge products
            pckge.products.forEach((y, iy) => {
                const optionB = maps.option[y.upgradeOptionId]
                if (optionB.hidden) {
                    return
                }
                const optionVariationB = optionB.variations[0]
                const componentB = optionVariationB.components[0]
                // const productB = maps.product[y.upgradeProductId]
                let customPrice = false
                const key = selectionKey(group, optionB, optionVariationB, componentB)
                if (key in customizations[pckge.id]) {
                    // Get package for product
                    const customProduct = maps.product[customizations[pckge.id][key]]
                    const customProductPackages = maps.productPackage[customProduct.id]?.map((x) => maps.package[x])
                    // Get relative price of this product
                    customProductPackages?.filter((x) => {
                        return x && x?.upgradeTierId > pckge.upgradeTierId
                    }).find((x) => {
                        // Find option for this package and product
                        /*let optionC = x.products.find((x) => x.upgradeProductId == customProduct.id)
                        if (!optionC) {
                            return
                        }
                        optionC = maps.option[optionC.upgradeOptionId]
                        let optionVariationC = null
                        let componentC = null
                        optionC.variations.find((y) => {
                            y.components.find((z) => {
                                const found = z.products.find((a) => a.upgradeProductId == customProduct.id)
                                if (found) {
                                    optionVariationC = y
                                    componentC = z
                                    return true
                                }
                                return false
                            })
                        })*/
                        // const { price: priceC, config: configC } = getPrice(maps, { buildingType, floorplan, floorplanVariation, option: optionC, optionVariation: optionVariationC, component: componentC, product: customProduct, pckge: x }, { selection, relative: true, relativePackage: pckge, debug: customProduct.name.includes('White Attica') })
                        const { price: priceC, config: configC, calculation: calculationC } = getPrice(maps, { buildingType, floorplan, floorplanVariation, option: optionB, optionVariation: optionVariationB, component: componentB, product: customProduct, pckge: x }, { selection, relative: true, relativePackage: pckge, debug, admin })
                        price += priceC
                        priceSet = true
                        calculate('price', ` + ${fnc.toMoney(priceC)}`)

                    })

                    // price += priceB
                    // customPrice = true
                }
                // if (!customPrice) {
                // Check for customizations
                // const [priceB, priceUnitB, quantityB] = getPrice(maps, buildingType, floorplan, floorplanVariation, optionB, optionVariationB, componentB, productB, pckge, selection)
                // price += priceB
                // }
            })
        }

        if (!standardPricing && !priceSet && pckge.upgradeTierId == UpgradeTier.Standard) {
            cost = 0
            price = 0
            calculate('cost', ' * 0 (b/c standard)')
            calculate('price', ' * 0 (b/c standard)')
        }

        calculate('finalCost', calculation.cost, true)
        calculate('finalPrice', calculation.price, true)

        // Package relative pricing
        if (relative && pckge.relativePricing && pckge.upgradeTierId > UpgradeTier.Standard) {
            // Find lowest package tier, price is relative to that
            const prices = component.products.map((x) => {
                const p = maps.package[x.upgradePackageId]
                if (!p) {
                    // logger.error("Missing package", x.upgradePackageId, component, product, pckge, selection)
                    return { price: 0, cost: 0, id: x.upgradePackageId, tier: 0 }
                }
                const { price: p2, unitPrice, cost: c2 } = getPrice(maps, { buildingType, floorplan, floorplanVariation, option, optionVariation, component, product, pckge: p }, { standardPricing: true, admin })
                return { price: p2, cost: c2, id: x.upgradePackageId, tier: p.upgradeTierId }
            })
            // Find min price
            const minPrice = Math.min(...prices.map((x) => x.price))
            const minCost = Math.min(...prices.map((x) => x.cost))
            packageDelta = -minPrice
            packageDeltaCost = -minCost
            // price = price - minPrice
        }
    }
    // } else if (relative && pckge.relativePricing && pckge.upgradeTierId > UpgradeTier.Standard) {
    //     // Find price for this option in lower tier package
    //     let prices = []
    //     Object.values(maps.package).forEach((x) => {
    //         if(x.upgradeTierId < pckge.upgradeTierId) {
    //             x.products.filter((y) => y.upgradeOptionId == option.id).forEach((y) => {
    //                 const opt = maps.option[y.upgradeOptionId]
    //                 const prod = maps.product[y.upgradeProductId]
    //                 const { price: p2, unitPrice, cost: c2 } = getPrice(maps, { buildingType, floorplan, floorplanVariation, option: opt, optionVariation: opt.variations[0], component: opt.variations[0].components[0], product: prod, pckge: x }, { standardPricing: true })
    //                 prices.push({ price: p2, cost: c2 })
    //             })
    //         }
    //     })
    //     const minPrice = Math.min(...prices.map((x) => x.price))
    //     const minCost = Math.min(...prices.map((x) => x.cost))
    //     packageDelta = -minPrice
    //     packageDeltaCost = -minCost
    // }


    // Product in package relative pricing
    let pckgeMarkup = null
    if (product && pckge && relative && relativePackage) {
        if (pckge == relativePackage && breakdown) {
            // Adopt markup of package
            const pckgeOption = maps.packageOption[relativePackage.id]
            const pckgeOptionVariation = pckgeOption.variations[0]
            const pckgeComponent = pckgeOptionVariation.components[0]
            const pckgeConfig = getConfig(maps, { buildingType, floorplan, floorplanVariation, option: pckgeOption, optionVariation: pckgeOptionVariation, component: pckgeComponent, pckge: relativePackage }, { price: true, debug: option?.name == 'Cabinets', flatten: true })
            if (pckgeConfig && pckgeConfig.markup != null) {
                pckgeMarkup = pckgeConfig.markup
            }
        } else if (pckge.upgradeTierId <= relativePackage.upgradeTierId) {
            lowerOrEqualTier = true
        } else {
            // lowerOrEqualTier = true
            const defaultProd = relativePackage.products.find((x) => x.upgradeOptionId == option.id)
            const pckgeOption = maps.packageOption[relativePackage.id]
            // Get set of other packages available in this option
            if (pckgeOption) {
                const allPackages = new Set(pckgeOption.variations[0].components[0].products.map((x) => x.upgradePackageId))
                if (defaultProd) {
                    const productB = maps.product[defaultProd.upgradeProductId]
                    let packageB = maps.productPackage[productB.id]?.map((x) => typeof x == 'object' ? x : maps.package[x]).filter((x) => allPackages.has(x.id))
                    if (packageB.length > 0) {
                        packageB = packageB[0]
                    } else {
                        packageB = null
                    }

                    // If tiers metch dont apply
                    if (packageB) {
                        if (packageB.upgradeTierId == pckge.upgradeTierId) {
                            lowerOrEqualTier = true
                            // Only apply relative pricing if
                        } else if (pckge.relativePricing) {
                            const { price: priceB, cost: costB, priceUnit: priceUnitB, quantity: quantityB } = getPrice(maps, { buildingType, floorplan, floorplanVariation, option, optionVariation, component, product: productB, pckge: packageB }, { selection, standardPricing: true, ignoreQuantityAndSqft: !isPackage, admin })
                            packageDelta = -priceB
                            packageDeltaUnit = -priceUnitB
                            packageDeltaCost = -costB
                        }
                    }
                }
            }
        }
    }

    if (selection?.quantityOverride != null) {
        quantity = selection.quantityOverride
        override.quantity = true
        calculatePrice = true
    } else if (config && config.quantity != null) {
        quantity = config.quantity
        calculatePrice = true
    }

    if (config && config.sqft != null) {
        sqft = config.sqft
        calculatePrice = true
    }

    let calcPrice = price
    let calcCost = cost
    if (config?.finalPrice) {
        if (!override.price) {
            price = config.finalPrice
            calcPrice = config.finalPrice
            calculate('price', `${fnc.toMoney(price)}`, true)
            calculate('finalPrice', `${fnc.toMoney(price)}`, true)
            calculatePrice = false
        }
    } else if (isPackage) {
        calculatePrice = true
    }

    // Relative adjustments
    /*if (!calculatePrice && packageDelta != 0 && calcPrice + packageDelta >= 0) {
        calcPrice += packageDelta
        calculate('finalPrice', ` - ${fnc.toMoney(-packageDelta)}`)
        if (!isPackage) {
            price += packageDeltaUnit
            calculate('price', ` - ${fnc.toMoney(-packageDeltaUnit)}`)
        }
    }*/


    if (packageDeltaCost != 0 && calcCost + packageDeltaCost >= 0) {
        cost += packageDeltaCost
        calcCost += packageDeltaCost
        // calculate('cost', ` - ${fnc.toMoney(-packageDeltaCost)}`)
        calculate('finalCost', ` - ${fnc.toMoney(-packageDeltaCost)}`)
        // if (!isPackage) {
        // price += packageDeltaUnit
        // }
    } else if (calculation.finalCost?.length == 0) {
        calculate('finalCost', `${fnc.toMoney(calcCost)}`)
    }

    // Markup
    let markup = null
    if (calcPrice == 0 || (isPackage && config?.markup && calculatePrice && (standardPricing || pckge?.upgradeTierId > UpgradeTier.Standard))) {
        if (false && cost == 0 && product?.priceCost) {
            price = calcPrice = product.priceCost
        } else if (pckgeMarkup) {
            calcPrice = price = cost * (1 + pckgeMarkup / 100)
            markup = pckgeMarkup
            calculate('price', `(${calculation.finalCost}) * ${(100 + pckgeMarkup)}%`, true)
            /*if (cost) {
            } else {
                calculate('price', `${fnc.toMoney(cost)} * ${(100 + pckgeMarkup)}%`, true)
            }*/
        } else if (config?.markup) {
            markup = config.markup
            calcPrice = price = cost * (1 + config.markup / 100)
            calculate('price', `(${calculation.finalCost}) * ${(100 + config.markup)}%`, true)
            // calculate('price', `${fnc.toMoney(cost)} * ${(100 + config.markup)}%`, true)
        } else {
            price = calcPrice = cost
            calculate('price', `${calculation.finalCost}`, true)
            // calculate('price', `${fnc.toMoney(cost)}`, true)
        }
    }

    if (config?.finalCost) {
        calcCost = config.finalCost
        calculate('finalCost', `${fnc.toMoney(calcCost)}`, true)
        calculateCost = false
    }

    if (calculation.finalPrice?.length == 0) {
        calculation.finalPrice = calculation.price
    }

    if (calculation.finalCost?.length == 0) {
        calculation.finalCost = calculation.cost
    }

    if (!ignoreQuantityAndSqft && sqft != null) {
        if (calculatePrice) {
            calcPrice *= sqft
            calculate('finalPrice', `(${calculation.finalPrice}) * ${sqft} sqft`, true)
        }
        if (calculateCost) {
            calcCost *= sqft
            calculate('finalCost', `(${calculation.finalCost}) * ${sqft} sqft`, true)
        }
    }
    if (!ignoreQuantityAndSqft && quantity != null) {
        if (calculatePrice) {
            calcPrice *= quantity
            calculate('finalPrice', `(${calculation.finalPrice}) * ${quantity} units`, true)
        }
        if (calculateCost) {
            calcCost *= quantity
            calculate('finalCost', `(${calculation.finalCost}) * ${quantity} units`, true)
        }
    }
    // if (packageDelta != 0 && calcPrice + packageDelta >= 0) {
    //     calcPrice += packageDelta
    //     calculate('finalPrice', ` - ${fnc.toMoney(-packageDelta)}`)
    //     if (!isPackage) {
    //         price += packageDeltaUnit
    //         calculate('price', ` - ${fnc.toMoney(-packageDeltaUnit)}`)
    //     }
    // }
    // if (packageDeltaCost != 0 && calcCost + packageDeltaCost >= 0) {
    //     calcCost += packageDeltaCost
    //     calculate('finalCost', ` - ${fnc.toMoney(-packageDeltaCost)}`)
    //     // if (!isPackage) {
    //     // price += packageDeltaUnit
    //     // }
    // }

    if (fixedPackagePrice || (lowerOrEqualTier && !breakdown)) {//} && ((pckge && customizations && pckge.id in customizations) || pckge == relativePackage)) {
        calcPrice = 0
        price = 0
        calculate('finalPrice', ' * $0 (lower or equal tier)')
    }

    currentPrice = fnc.roundCents(calcPrice)
    currentPriceUnit = fnc.roundCents(price)

    // Get state pricing
    if (!fixedPackagePrice && (!selection || (!selection.priceOverride && selection.option?.id == option?.id))) {
        if (selection?.priceCalc != null && selection?.priceCalc != 0) {//} && !selection.package) {
            calcPrice = selection.priceCalc
        }
        if (selection?.priceSqft) {
            // Round to 2 decimal places
            price = selection.priceSqft
        }
    }

    const ret = {
        price: calcPrice, priceUnit: price, quantity, sqft, override, config, packageDelta, cost: calcCost, costUnit: cost, calculation, markup, currentPrice, currentPriceUnit, selection, fixedPackagePrice
    }
    return ret
}

export function globalChangesKey(app) {
    return `globalchanges-${app.meta.id}`
}

export function getGlobalChanges(app) {
    return sessionStorage.getItem(globalChangesKey(app))
}

export function clearGlobalChanges(app) {
    sessionStorage.setItem(globalChangesKey(app), null)
}


function getLink(x) {
    if (!x) {
        return null
    }
    const tokens = x.split('_')
    if (tokens.length > 1) {
        return tokens[1]
    }
    return tokens[0]
}

export function getTourLink(sel, selections, app, maps, group) {
    const { product, option, variation, component } = sel
    let link = getLink(option.link)
    if (option.variations.length > 1) {
        link = `${link}-${variation.link}`
    }
    if (variation?.components?.length > 1) {
        link = `${link}-${component.link}`
    }

    // Check if part of a package, is so link to package instead
    let packageLink = null
    if (app.meta.link == '8188yonge' || app.meta.link == 'thesummit') {
        let key = `${option.id}_${product.id}`
        if (key in maps.productPackage && maps.productPackage[key].length > 0) {
            // If multiple packages, concatenate the final hyphen separated tokens
            if (maps.productPackage[key].length > 1) {
                let packageSet = {}
                maps.productPackage[key].forEach((x) => {
                    const p = maps.package[x]
                    if (!p || !p.link) {
                        return
                    }
                    const tokens = p.link.split('-')
                    let firstToken = tokens[0]
                    if (firstToken in packageSet) {
                        packageSet[firstToken].push(tokens.length > 1 ? tokens[1] : '')
                    } else {
                        packageSet[firstToken] = [tokens.length > 1 ? tokens[1] : '']
                    }
                })
                Object.keys(packageSet).forEach((x) => {
                    let suffix = packageSet[x].join('')
                    if (suffix.length > 0) {
                        suffix = `-${suffix}`
                    }
                    if (packageLink) {
                        packageLink = `${packageLink}-${x}${suffix}`
                    } else {
                        packageLink = `${x}${suffix}`
                    }
                })
            } else {
                packageLink = maps.package[maps.productPackage[key][0]].link
            }
        }
    }
    let suffix = ''

    if (packageLink) {
        suffix = `-${packageLink}`
    } else {
        if (product.visualProductId) {
            suffix = `-${product.visualProductId}`
        } else if (product.link != null) {
            suffix = `-${product.link}`
        } else {
            suffix = `-${product.id}`
        }

        if (variation.multiSelect) {
            suffix = `${suffix}-on`
        }
    }

    let linkSuffix = suffix
    // showOptions.add(link)

    // Specific case of dependent component where dependency is not a matching product
    const applyVisualComponent = (cmp) => {
        // Find dependency
        let depSelections = []
        Object.keys(selections).forEach((x) => {
            selections[x].forEach((z) => {
                if (z.component.id == cmp.visualComponentId) {
                    depSelections.push({ key: x, sel: z })
                }
            })
        })
        let depKey = null
        if (depSelections.length == 1) {
            depKey = depSelections[0].key
        } else {
            depKey = depSelections.find((x) => {
                if (sel.group != null) {
                    return x.sel.group?.id == sel.group?.id
                }
                return x.sel.group?.id == group?.id
            })?.key
        }
        if (depKey) {
            const depSelection = selections[depKey][0]
            if (depSelection.product.upgradePackageId) {
                // If prefix only, only apply upgrade tier
                if (cmp.prefixOnly) {
                    suffix = `${suffix}-${UpgradeTier[depSelection.package.upgradeTierId].toLowerCase()}`
                } else {
                    // Otherwise use full package link
                    suffix = `${suffix}-${depSelection.package.link.toLowerCase()}`
                }
            } else {
                // Find the tier of the dependency
                if (cmp.prefixOnly && depSelection.package) {
                    const pckgeId = maps.productPackage[depSelection.product.id][0]
                    const pckge = maps.package[pckgeId]
                    suffix = `${suffix}-${UpgradeTier[pckge.upgradeTierId].toLowerCase()}`
                } else if (!component.products.find((z) => z.upgradeProductId == depSelection.product.id)) {
                    const key = depSelection.product?.link || depSelection.product?.id
                    if (key) {
                        suffix = `${suffix}-${key}`
                    }
                }
            }
        }
    }
    if (component.visualUpgradeComponentId) {
        applyVisualComponent({ visualComponentId: component.visualUpgradeComponentId, order: 0, prefixOnly: true })
    } else if (component.visualComponents.length > 0) {
        component.visualComponents.sort((a, b) => a.order - b.order).forEach(applyVisualComponent)
    }

    let finalLinks = [`${link}${suffix}`]
    if (component.tourLinks.length > 0) {
        component.tourLinks.forEach((x) => {
            if (x.includeDependencies) {
                finalLinks.push(`${x.link}${suffix}`)
            } else {
                finalLinks.push(`${x.link}${linkSuffix}`)
            }
        })
    }
    return finalLinks
}

export function floorplanVariationBedBath(x) {
    return `${x.beds} Bed${x.beds > 1 ? 's' : ''}${x.den ? ' + Den ' : ''} + ${x.baths} Bath${x.baths > 1 ? 's' : ''}`
}
export function floorplanVariationBedBathCode(x) {
    if (x) {
        return `${x.beds}bd+${x.den ? 'dn+' : ''}${x.baths}bth`
    }
    return null
}

export function getPackageName(maps, pckge, option) {
    let pckgeName = pckge?.name
    if (pckgeName?.includes(' - ')) {
        const pckgeType = pckgeName.split(' - ')[0]
        let pckgeTier = pckgeName.split(' - ')
        pckgeTier.shift()
        pckgeTier = pckgeTier.join(' - ')

        // If productName includes optionName, remove it
        let optionPackageName = `${option?.name.replace(' Package', '')}`
        if (pckgeType.singular().includes(optionPackageName)) {
            pckgeName = pckgeTier//pckgeName.replace(`- ${optionPackageName}`, '').trim()
        }
    }
    return pckgeName
}

export function getOptionName(maps, pckge, option) {
    let optionName = option?.name
    if (pckge != null) {
        let pckgeName = pckge?.name
        const pckgeType = pckgeName.split(' - ')[0]
        let prefix = `${pckgeType} - `
        if (optionName.includes(prefix)) {
            optionName = optionName.replace(prefix, '')
        } else if (optionName.includes('Finish -')) {
            optionName = optionName.replace('Finish - ', '')
        }
    }
    return optionName
}

export function getProductName(maps, pckge, option, product, stripFinish = false) {
    let productName = (product && (product.displayName || product.name)) ? (product.displayName || product.name) : ""
    if (pckge != null) {
        let pckgeName = pckge?.name
        if (pckgeName.includes(' - ')) {
            const pckgeType = pckgeName.split(' - ')[0]
            let pckgeTier = pckgeName.split(' - ')
            pckgeTier.shift()
            pckgeTier = pckgeTier.join(' - ')
            let prefix = `${pckgeType} - `
            if (productName.includes(prefix)) {
                productName = productName.replace(prefix, '')
            } else {
                prefix = `${option.name} - `
                if (productName.includes(prefix)) {
                    productName = productName.replace(prefix, '')
                }
                prefix = `${option.name?.singular()} - `
                if (productName.includes(prefix)) {
                    productName = productName.replace(prefix, '')
                }
            }
        }
    } else {
        const prefix = `${option.name?.singular()} - `
        if (productName?.includes(prefix)) {
            productName = productName.replace(prefix, '')
        }
    }
    if (stripFinish && product && product.finish) {
        const suffix = ` - ${product?.finish}`
        if (productName.includes(suffix)) {
            productName = productName.replace(suffix, '')
        }
    }
    return productName
}

export function unpackConfig(x) {
    return {
        buildingType: x.buildingTypeId,
        floorplan: x.floorplanId,
        floorplanVariation: x.floorplanVariationId,
        option: x.upgradeOptionId,
        optionVariation: x.upgradeOptionVariationId,
        component: x.upgradeComponentId,
        product: x.upgradeProductId,
        pckge: x.upgradePackageId,
        bedBath: x.bedBath,
        unit: x.unitId,
    }
}

export function getRelativePackage(maps, pckge, option = null) {
    if (pckge && pckge.relativePricing) {
        if (!option) {
            const opt = maps.packageOption[pckge.id]
            const packageSet = new Set(opt.variations[0].components[0].products.map((p) => p.upgradePackageId))
            return Object.values(maps.package).find((pckgeB) => {
                return pckgeB != pckge && pckgeB.upgradeTierId < pckge.upgradeTierId && packageSet.has(pckgeB.id)
            })
        } else {
            return Object.values(maps.package).find((pckgeB) => {
                return pckgeB.upgradeTierId < pckge.upgradeTierId && pckgeB.products.find((x) => x.upgradeOptionId == option.id)
            })
        }
    }
    return null
}

export function getOptionComponent(option) {
    if (!option) {
        return null
    }
    if (option.variations.length == 0) {
        return null
    }
    const variation = option.variations[0]
    if (variation.components.length == 0) {
        return null
    }
    return variation.components[0]
}

export function getOptionProducts(option) {
    return getOptionComponent(option)?.products || []
}


export function buildMaps(upgrades, app, spec = {}) {
    const { upgradeView, floorplan, floorplanVariation, buildingType } = spec
    // Create component map

    const newMaps = {
        product: fnc.objIdMap(upgrades.products),
        option: fnc.objIdMap(upgrades.options),
        component: {},
        department: fnc.objIdMap(upgrades.departments),
        category: {},
        subcategory: {},
        unit: fnc.objIdMap(app.units),
        floorplan: fnc.objIdMap(app.floorplans),
        area: fnc.objIdMap(upgrades.areas),
        tier: fnc.objIdMap(Object.keys(UpgradeTier).filter((x) => isNaN(parseInt(x))).map((x) => ({ id: UpgradeTier[x], name: x }))),
        package: fnc.objIdMap(upgrades.packages),
        optionIsPackageOption: {},
        optionGroups: {},
        optionConfiguration: {},
        variationOptions: {},
        variationOptionGroups: {},
        buildingType: { ...fnc.objIdMap(getBuildingTypes(app)), 'global': { name: 'Global', floorplans: [] } },
        floorplanVariationUpgradeOption: fnc.objIdMap(upgrades.floorplanVariationUpgradeOption, 'floorplanVariationUpgradeOption', 'floorplanVariationId'),
        floorplanVariationUpgradeOptionGroup: fnc.objIdMap(upgrades.floorplanVariationUpgradeOptionGroup, 'floorplanVariationUpgradeOptionGroup', 'floorplanVariationId'),
        area: fnc.objIdMap(upgrades.areas),
        views: fnc.objIdMap(upgrades.views),
        packageOption: {}, // Determine which options are in a package
        packageProduct: {}, // Determine which products are in a package
        packageGroup: {}, // Determine which group a package belongs to
        optionPackage: {}, // Determine which package if any an option is in
        groupOptions: {}, // Determine which options belong to which groups
        optionInGroup: {},
        optionGroupOptionMap: {},
        packageOptionMap: {},
        productPackage: {}, // Determine which package if any a product is in
        optionInPackage: {}, // Determine if an option is in a package
        componentDependencies: {}, // Determine component backward dependencies
        groupPackageOptionFilter: {}, // If package options need to be filtered by group
        globalOptions: {},
        visibleOptions: {},
    }

    // Config
    upgrades.optionConfigurations.forEach((x) => {
        const key = configKey(unpackConfig(x))
        newMaps.optionConfiguration[key] = x
    })

    // Departments
    upgrades.departments.forEach((x) => {
        x.categories.forEach((y) => {
            newMaps.category[y.id] = y
            y.subcategories.forEach((z) => {
                newMaps.subcategory[z.id] = z
            })
        })
    })

    // Packages
    upgrades.packages.forEach((x) => {
        x.products.forEach((y) => {
            const productId = y.upgradeProductId
            const optionId = y.upgradeOptionId
            newMaps.packageOptionMap[optionId] = 1

            const key = `${optionId}_${productId}`
            if (!(key in newMaps.productPackage)) {
                newMaps.productPackage[key] = []
            }

            if (!(productId in newMaps.productPackage)) {
                newMaps.productPackage[productId] = []
            }
            if (!(optionId in newMaps.optionInPackage)) {
                newMaps.optionInPackage[optionId] = {}
            }
            if (!(optionId in newMaps.optionPackage)) {
                newMaps.optionPackage[optionId] = []
            }
            newMaps.optionInPackage[optionId][x.id] = 1
            newMaps.productPackage[key].push(x.id)
            newMaps.optionPackage[optionId].push(x)
            newMaps.productPackage[productId].push(x.id)
        })
    })


    upgrades.packages.forEach((x) => {
        x.products.forEach((y) => {
        })
    })
    // Options
    upgrades.options.forEach((x) => {
        x.variations.forEach((y) => {
            y.components.forEach((z) => {
                newMaps.component[z.id] = z
                z.products.forEach((a) => {
                    if (a.upgradePackageId != null) {
                        const p = newMaps.package[a.upgradePackageId]
                        newMaps.packageOption[p.id] = x
                        newMaps.packageProduct[p.id] = a
                        newMaps.optionIsPackageOption[x.id] = 1
                        if (!(x.id in newMaps.optionPackage)) {
                            newMaps.optionPackage[x.id] = {}
                        }
                        newMaps.optionPackage[x.id][a.upgradePackageId] = 0

                    }
                    if (!(a.upgradeProductId in newMaps.productPackage)) {
                        newMaps.globalOptions[x.id] = 1

                        if (!(a.upgradeProductId in newMaps.productPackage)) {
                            newMaps.productPackage[a.upgradeProductId] = []
                        }
                        newMaps.productPackage[a.upgradeProductId].push({ id: 'global', name: 'Global', products: [] })
                    }
                })
            }) != null
        })
    })

    // Bed Baths
    newMaps.bedBath = {}
    app.floorplans.forEach((x) => {
        x.variations.forEach((y) => {
            const bedBath = floorplanVariationBedBath(y)
            const bedBathCode = floorplanVariationBedBathCode(y)
            if (y.beds || y.baths) {
                newMaps.bedBath[bedBathCode] = { name: bedBath, id: bedBathCode }
            }
        })
    })

    // Floorplan variations
    newMaps.floorplanVariations = {}
    newMaps.floorplanVariationOption = {}
    app.buildingTypes.map((x) => newMaps.buildingType[x.id]).forEach((x) => {
        newMaps.buildingType[x.id] = x
        x.variations?.forEach((y) => {
            newMaps.floorplanVariations[y.id] = y
            y.options.forEach((z) => {
                if (!(z in newMaps.floorplanVariationOption)) {
                    newMaps.floorplanVariationOption[z] = []
                }
                newMaps.floorplanVariationOption[z].push(y)
            })
        })
    })

    // Floorplan variation
    if (floorplanVariation && floorplanVariation.id in newMaps.floorplanVariationUpgradeOptionGroup) {
        newMaps.floorplanVariationUpgradeOptionGroup[floorplanVariation.id].forEach((x) => {
            newMaps.variationOptionGroups[x.upgradeOptionGroupId] = x
        })
    }


    if (upgradeView) {
        // Gather package filter
        upgradeView.optionGroups.forEach((x) => {
            x.options.forEach((y) => {
                if (!(y.upgradeOptionId in newMaps.optionGroupOptionMap)) {
                    newMaps.optionGroupOptionMap[y.upgradeOptionId] = []
                }
                newMaps.optionGroupOptionMap[y.upgradeOptionId].push(x)

                const visibility = parseInt(y.visibilityId)
                if (visibility == Visibility.Filter && y.upgradeOptionId in newMaps.optionInPackage) {
                    // Get package
                    Object.keys(newMaps.optionInPackage[y.upgradeOptionId]).forEach((packageId) => {
                        if (!(x.id in newMaps.groupPackageOptionFilter)) {
                            newMaps.groupPackageOptionFilter[x.id] = {}
                        }
                        if (!(packageId in newMaps.groupPackageOptionFilter[x.id])) {
                            newMaps.groupPackageOptionFilter[x.id][packageId] = {}
                        }
                        newMaps.groupPackageOptionFilter[x.id][packageId][y.upgradeOptionId] = 1
                    })
                }
            })
        })

        const dependencySet = new Set()
        upgradeView.optionGroups.forEach((group) => {
            // Search for packages
            newMaps.groupOptions[group.id] = []
            group.options.forEach((x) => {
                const option = newMaps.option[x.upgradeOptionId]
                if (option) {
                    newMaps.groupOptions[group.id].push(option.id)
                    if (!(option.id in newMaps.optionInGroup)) {
                        newMaps.optionInGroup[option.id] = {}
                    }
                    newMaps.optionInGroup[option.id][group.id] = 1
                    newMaps.visibleOptions[option.id] = 1
                }
                option?.variations.forEach((y) => {
                    y.components.forEach((z) => {
                        if (z.dependencyUpgradeComponentId && !dependencySet.has(z.id)) {
                            if (!(z.dependencyUpgradeComponentId in newMaps.componentDependencies)) {
                                newMaps.componentDependencies[z.dependencyUpgradeComponentId] = []
                            }
                            newMaps.componentDependencies[z.dependencyUpgradeComponentId].push({
                                group: group,
                                option: option,
                                variation: y,
                                component: z,
                            })
                        }
                        z.products.forEach((a) => {
                            if (a.upgradePackageId != null) {
                                const pckge = newMaps.package[a.upgradePackageId]
                                newMaps.packageGroup[a.upgradePackageId] = group

                                pckge.products.forEach((b) => {
                                    const opt = newMaps.option[b.upgradeOptionId]
                                    newMaps.groupOptions[group.id].push(opt.id)

                                    // Ensure not filtered
                                    if (group.id in newMaps.groupPackageOptionFilter
                                        && a.upgradePackageId in newMaps.groupPackageOptionFilter[group.id]
                                        && !(b.upgradeOptionId in newMaps.groupPackageOptionFilter[group.id][a.upgradePackageId])) {
                                        return
                                    }

                                    if (!(opt.id in newMaps.optionInGroup)) {
                                        newMaps.optionInGroup[opt.id] = {}
                                    }
                                    newMaps.optionInGroup[opt.id][group.id] = 1

                                    // Backwards dependencies
                                    const optVar = opt.variations[0]
                                    const cmp = optVar.components[0]
                                    if (cmp.dependencyUpgradeComponentId && !dependencySet.has(cmp.id)) {
                                        dependencySet.add(cmp.id)
                                        if (!(cmp.dependencyUpgradeComponentId in newMaps.componentDependencies)) {
                                            newMaps.componentDependencies[cmp.dependencyUpgradeComponentId] = []
                                        }
                                        newMaps.componentDependencies[cmp.dependencyUpgradeComponentId].push({
                                            group: group,
                                            option: opt,
                                            variation: optVar,
                                            component: cmp,
                                        })
                                    }
                                })
                            }
                        })
                    })
                })
            })
        })
    }

    // Flatten product package sets
    Object.keys(newMaps.productPackage).forEach((x) => {
        newMaps.productPackage[x] = [...new Set(newMaps.productPackage[x])]
    })

    return newMaps
}

export function getPricingUpdateMessage(price, priceUnit, currentPrice, currentPriceUnit) {
    let pricingMessage = 'Pricing has been updated since the last time you viewed this product. Please review and confirm if you would like to update the price.'
    if (currentPriceUnit != priceUnit) {
        pricingMessage += '\n\nUnit pricing: ' + fnc.toMoney(priceUnit) + ' changes to ' + fnc.toMoney(currentPriceUnit) + '. '
    }
    if (currentPrice != price) {
        pricingMessage += '\n\nOverall pricing: ' + fnc.toMoney(price) + ' changes to ' + fnc.toMoney(currentPrice) + '. '
    }
    return pricingMessage
}

export function getPackageSpec(pckge, maps) {
    // let packageOption = m
    const spec = {
        pckge,
    }
    spec.option = maps.packageOption[pckge.id]
    if (spec.option) {
        spec.optionVariation = spec.option.variations[0]
        if (spec.optionVariation) {
            spec.component = spec.optionVariation.components[0]
        }
    }
    // pckge.products.forEach((x) => {
    //     const option = maps.option[x.upgradeOptionId]
    //     if (option) {
    //         spec.products.push({
    //             id: option.id,
    //             name: option.name,
    //             variations: option.variations.map((y) => {
    //                 return {
    return spec
}