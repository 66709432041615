import * as fnc from 'helpers/fnc'
import { logger } from 'helpers/logger'

export function parseKML(kml) {

    const icons = {}
    let elements = []

    function asArray(x) {
        if (!x) {
            return []
        }
        if (Array.isArray(x)) {
            return x
        } else {
            return [x]
        }
    }
    asArray(kml.StyleMap).forEach((x) => {
        try {
            icons[x['@attributes'].id] = {}
        } catch (e) {
            logger.error("Error parsing KML StyleMap ", e)

        }
    })
    asArray(kml.Style).forEach((x) => {
        try {
            const tokens = x['@attributes'].id.split('-')
            const variant = tokens.pop()
            const key = tokens.join('-')
            if (key in icons) {
                icons[key][variant] = { icon: x.IconStyle, label: x.LabelStyle }
            }
        } catch (e) {
            logger.error("Error parsing KML Style ", e)
        }
    })
    let index = 0
    asArray(kml.Folder).forEach((x, ix) => {
        try {
            const elem = { title: x.name && typeof (x.name) == 'string' ? x.name.toLowerCase().toTitleCase() : ix }
            const marks = Array.isArray(x.Placemark) ? x.Placemark : [x.Placemark]
            elem.elements = marks.map((y, iy) => {
                index += 1
                const coords = y.Point.coordinates.replace('\n', '').trim().split(',')
                return {
                    id: index,
                    title: y.name && typeof (y.name) == 'string' ? y.name.toLowerCase().toTitleCase() : iy,
                    locations: [{ latLng: `${coords[1]},${coords[0]}` }],
                    Style: y.styleUrl
                }
            })
            elements.push(elem)
        } catch (e) {
            logger.error("Error parsing KML Folder ", e)
        }
    })

    return { title: kml.name, body: kml.description, elements, icons }
}