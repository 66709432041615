import React, { useEffect, useRef, useState } from 'react'
import {
    CloseButton,
    Button,
    Timer,
    Checkbox,
} from 'components'
import { useAppSelector, useSessionStorage } from 'app/hooks'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import * as fnc from 'helpers/fnc'
import { ContentForm } from 'views/AppPage'

interface ConfirmDialogProps {
    title: string,
    subtitle: string,
    error: string,
    message: string,
    onClose?: () => void,
    onConfirm: () => void,
    onCancel: () => void,
    animation?: string,
    confirmMessage?: string,
    cancelMessage?: string,
    autoCancel?: number,
    buttonProps: Dict,
    className: string,
    dismissKey?: string,
    close: boolean,
    extraButton: Dict[],
    fields: Dict,
}

export function ConfirmDialog(props: ConfirmDialogProps) {
    const {
        title,
        subtitle,
        error,
        message,
        onClose,
        onConfirm,
        onCancel,
        confirmMessage,
        cancelMessage,
        animation,
        autoCancel,
        buttonProps,
        className,
        dismissKey,
        extraButton,
        close,
        fields,
    } = {
        close: true,
        confirmMessage: 'Okay',
        cancelMessage: 'Cancel',
        ...props,
    }

    const screen = useAppSelector((state: RootState) => state.app.screen)
    const [dismiss, setDismiss] = dismissKey ? useSessionStorage(dismissKey) : useState(false)
    const [initialized, setInitialized] = useState(false)
    const [confirmElem, setConfirmElem] = useState(null)

    useEffect(() => {
        if (initialized) {
            return
        }
        if (dismiss) {
            onConfirm()
        } else {
            setInitialized(true)
        }
    }, [dismiss, initialized])

    /*useEffect(() => {
        let timer = null
        if (autoCancel != null) {
            timer = setTimeout(() => {
                handleClose()
            }, autoCancel)
        }
        return () => clearTimeout(timer)
    }, [])*/

    function handleConfirm() {
        if (fields) {
            return
        }
        if (onConfirm) {
            onConfirm()
        }
    }

    function handleExtra(x) {
        if (x.html) {
            fnc.openHTMLInNewWindow(x.html)
        } else {
            fnc.openLinkInNewWindow(x.link)
        }
    }

    function handleCancel() {
        if (onCancel) {
            onCancel()
        } else {
            onClose()
        }
    }

    function handleSubmit(x) {
        return new Promise((resolve, reject) => {
            if (onConfirm) {
                onConfirm(x)
            }
            resolve()
        })
    }

    function getForm() {
        // <ContentForm onSubmit={submitForm} onLoad={setLoaded} onError={setError} submitRef={submitRef} {...formOptions} fields={fields} onFormFilled={setFormFilled} preventNav={false} />
        return <ContentForm onSubmit={handleSubmit} fields={fields} preventNav={false} submitRef={confirmElem} />
    }

    function handleClose() {
        onClose()
    }
    if (!initialized) {
        return
    }

    return <div className={`dialog ${animation}${className ? ` ${className}` : ''}`} onClick={(e) => e.stopPropagation()} onMouseDown={(e) => e.stopPropagation()}>
        <div className="title">
            <h3>{title}</h3>
            {subtitle && <h4>{subtitle}</h4>}
            {error && <h5 className="error">{error}</h5>}
        </div>
        <div className="dialog-wrapper">
            {message && <span className="dialog-message"><ReactMarkdown>{message}</ReactMarkdown></span>}
            {fields && getForm()}
            {!autoCancel && close && <CloseButton large onClick={handleClose} />}
            {dismissKey && <div className="button-group" style={{ marginTop: 0 }}>
                <Checkbox noBg value={dismiss} onChange={() => setDismiss(!dismiss)}>Don't show this again</Checkbox>
            </div>}
            <div className="button-group">
                {confirmMessage && <Button tertiary {...buttonProps} large={screen.isMobile} forwardRef={setConfirmElem} onClick={handleConfirm}>{confirmMessage}</Button>}
                {cancelMessage && <Button {...buttonProps} large={screen.isMobile} onClick={handleCancel}>{cancelMessage}</Button>}
                {extraButton && extraButton.map((x) => <Button tertiary {...buttonProps} large={screen.isMobile} onClick={() => handleExtra(x)}>{x.text}</Button>)}
            </div>
            {autoCancel && <Timer duration={autoCancel} onComplete={handleClose} />}
        </div>
    </div>
}
