import * as fnc from 'helpers/fnc'
import qs from 'qs'
import { AppState, FloorplanFilter, ProjectFilter, QueryType, UserGroup, UserRole } from 'app/types'
import { getWindowLocation } from 'helpers/config'
import { logger } from 'helpers/logger'

const trackingCookies = ['hubspotutk', '__hssc', '__hstc', '__hsfp', 'utm_campaign', 'utm_medium', 'utm_content', 'utm_source', '_hsenc', '_hsmi']

const stateVersion = 1
export function loadState(
    stateName: string,
    fromHash = false,
    fromQuery = false,
): Dict {
    const decodeState = (hashCode: string) => {
        if (hashCode && hashCode.length > 10) {
            const hashState: AppState = fnc.unHashObj(hashCode)
            if (hashState && hashState.version === stateVersion) {
                return hashState
            }
        }
        return {}
    }
    let localHash = localStorage.getItem(`state-${stateName}`)
    let localState = decodeState(localHash)
    let loadedState = {}
    if (stateName in localState) {
        loadedState = localState[stateName]
    }

    if (fromHash) {
        const windowHash = fromHash ? getWindowLocation().hash.substring(1) : null
        const hashState = decodeState(windowHash)
        if (stateName in hashState) {
            loadedState = { ...loadedState, ...hashState[stateName] }
            // Re-save when retrieving from hash
            saveState(stateName, loadedState)
        }
    }
    if (fromQuery) {
        // Get specific query-able states, also load any cached states
        localHash = localStorage.getItem(`query-${stateName}`)
        let finalQuery = {}
        if (localHash != null) {
            finalQuery = fnc.unHashObj(localHash)
        }

        if (getWindowLocation().search.length > 0) {
            const query = qs.parse(getWindowLocation().search.replace('?', ''))
            // Select only certain keys

            const initAnalytics = () => {
                if (!loadedState.analytics) {
                    loadedState.analytics = {}
                }
            }

            const queryKeys = Object.keys(query)
            const queryValues = Object.values(query)
            for (let i = 0; i < queryKeys.length; i += 1) {
                // logger.info("Query parameter: ", queryKeys[i], queryValues[i])
                const key = queryKeys[i]
                if (stateName == 'app') {
                    if (trackingCookies.includes(key)) {
                        initAnalytics()
                        loadedState.analytics[key] = queryValues[i]
                    } else {
                        switch (key.toLowerCase()) {
                            default:
                                break
                            case 'standalone':
                                loadedState.standaloneApp = parseInt(queryValues[i], 10) === 1
                                break
                            case 'salescenter':
                            case 'debug':
                            case 'exclusive':
                                finalQuery[key] = parseInt(queryValues[i], 10) === 1
                                break
                            case 'vip':
                                finalQuery['exclusive'] = parseInt(queryValues[i], 10) === 1
                                break
                            case 'teamid':
                                initAnalytics()
                                loadedState.analytics.teamId = queryValues[i]
                                break
                            case 'refid':
                                initAnalytics()
                                loadedState.analytics.referralId = queryValues[i]
                                break
                            case 'fbclid':
                                initAnalytics()
                                loadedState.analytics.facebookId = queryValues[i]
                                break
                            case 'gclid':
                                initAnalytics()
                                loadedState.analytics.googleId = queryValues[i]
                                break
                            case 'availability':
                                if (QueryType.Floorplans in loadedState.queries) {
                                    loadedState.queries[QueryType.Floorplans][FloorplanFilter.Availability] = queryValues[i].split(',')
                                }
                                break
                            case 'bot':
                                initAnalytics()
                                loadedState.analytics.isBot = parseInt(queryValues[i], 10) === 1
                                break
                            case 'utm_source':
                            case 'utm_medium':
                            case 'utm_campaign':
                            case 'utm_content':
                            case 'utm_term':
                                loadedState.analytics[key] = queryValues[i]
                                break
                        }
                    }
                } else if (stateName == 'user') {
                    switch (key) {
                        default:
                            break
                        case 'guest':
                            const group = queryValues[i]
                            if (group == 'agent') {
                                finalQuery.guestGroup = [UserGroup.ListingAgent]
                            } else {
                                finalQuery.guestGroup = []
                            }
                            break
                    }
                }
            }
        }
        if (Object.keys(finalQuery).length > 0) {
            loadedState = { ...loadedState, ...finalQuery }

            // Save updated state
            const hashCode = fnc.hashObj(finalQuery)
            localStorage.setItem(`query-${stateName}`, hashCode)
        }
    }
    return loadedState
}

export function saveState(stateName: string, state: { [key: string]: string }) {
    const hashCode = fnc.hashObj({ [stateName]: state, version: stateVersion })
    // cookies.set('state', hashCode, { path: '/' })
    if (stateName != null) {
        localStorage.setItem(`state-${stateName}`, hashCode)
    }
    return hashCode
}

export function getSetCached(key: string, value: boolean | string | number, setter: () => void, defaultValue: boolean | string | number) {
    if (value == null) {
        const cached = localStorage.getItem(key)
        setter(cached != null ? cached : defaultValue)
    } else {
        localStorage.setItem(key, value)
        setter(value)
    }
}

export function getSession() {
    // current timestamp in seconds
    const currentTs = Math.floor(Date.now() / 1000)

    // if session-id local storage item does not exist
    // if expiry-ts local storage item does not exist
    // if session time has expired
    let sessionId = localStorage.getItem('session-id')
    let clientId = localStorage.getItem('client-id')
    if (sessionId === null || localStorage.getItem('expiry-ts') === null || currentTs > parseInt(localStorage.getItem('expiry-ts'), 10)) {
        // create a new session with a random id and store
        // random id here is a concat of rand(11111111, 99999999) and timestamp
        sessionId = fnc.uniqueId()//[(Math.floor(Math.random() * (99999999 - 11111111 + 1)) + 11111111), Date.now()].join('')
        localStorage.setItem('session-id', sessionId)
        // set expiry to current timestamp + 30 minutes
        localStorage.setItem('expiry-ts', currentTs + 1800)
    }

    // client id that doesn't expire
    if (clientId == null) {
        clientId = fnc.uniqueId()//[(Math.floor(Math.random() * (99999999 - 11111111 + 1)) + 11111111), Date.now()].join('')
        localStorage.setItem('client-id', clientId)
    }

    return { sessionId, clientId }
}

export function showDisclaimer(key) {
    // current timestamp in seconds
    const currentTs = Math.floor(Date.now() / 1000)
    let disclaimer = localStorage.getItem(`${key}-disclaimer`)
    if (disclaimer === null || localStorage.getItem(`expiry-${key}`) === null || currentTs > parseInt(localStorage.getItem(`expiry-${key}`), 10)) {
        // create a new session with a random id and store
        // random id here is a concat of rand(11111111, 99999999) and timestamp
        disclaimer = 1
        localStorage.setItem(`${key}-disclaimer`, disclaimer)
        // set expiry to current timestamp + 1 week
        // localStorage.setItem('expiry-mhd', currentTs + 3600 * 24 * 7)
        localStorage.setItem(`expiry-${key}`, currentTs + 3600 * 24 * 7)
    } else {
        disclaimer = parseInt(disclaimer, 10) + 1
        localStorage.setItem(`${key}-disclaimer`, disclaimer)
    }
    return disclaimer <= 2
}

export function applyCookies(state: AppState) {
    return
    logger.info("Applying cookies")
    trackingCookies.forEach((cookie) => {
        const value = state.analytics[cookie]
        if (value != null) {
            logger.info("Setting cookie: ", cookie, value)
            fnc.setCookie(cookie, value)
        } else {
            logger.info("No value for cookie: ", cookie)
        }
    })
}