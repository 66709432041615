import { clearEditContent, upsertContent, removeContent, setEditContent, setEditInline, stopEditContent, updateEditContent, setProject, upsertPage, initializeAdmin, initializeAdminProject, removePage, setOrganization, createLLMContent, addMediaFromUrl } from 'actions/adminActions'
import { useAppSelector, useAppDispatch, getMediaSelector, usePage, useHybridContent, useLocalStorage, useDownloadMedia } from 'app/hooks'
import { DynamicContentType, CustomEventType, EditorFieldType, PromptOptions, PromptType, ThumbSize, AnalyticsEvent, FormFieldType, PageType, LocationType, LLMGenerateType, PageTypeId } from 'app/types'
import { Button, DropdownMenu, ErrorBoundary, Icon, IconButton, Spinner, Media, Toggle } from 'components'
import React, { useEffect, useRef, useState } from 'react'
import { GenericEditor } from 'views/AdminPage/Views/GenericEditor'
import * as fnc from 'helpers/fnc'
import { navigate, removeAppContent, resolvePrompt, showPrompt, updateAppContent, updateOrganization, updateProjectCurrent, updatePrompt } from 'actions/appActions'
import { organizationContent, organizationRoutes, organizationStaticContent, projectStaticContent } from 'app/transformers'
import { logger } from 'helpers/logger'
import { CustomContent } from './CustomContent'
import { defaultFormFields, pageIcons } from 'app/constants'
import { getPageTypeName } from 'helpers/project'
import { LLMContentHelper } from './LLMContentHelper'

enum EditorTab {
    Content = 'Content',
    Pages = 'Pages',
    Blogs = 'Blogs',
}

const pageTagEnum = (data, config) => {
    let source = config.app ? config.app : config.organization
    // const matchingPages = source?.pages.filter((x) => x.pageTypeId == data.pageTypeId)
    const matchingPages = source?.pages.filter((x) => x.pageType == PageType.Blog)
    let tagSet = new Set(data.tags?.split(','))
    matchingPages.forEach((x) => {
        let tags = x.tags ? x.tags.split(',') : []
        if (tags.length == 0) return
        tagSet = tagSet.union(new Set(tags))
    })
    let ret =  Array.from(tagSet).filter((x) => x.length > 0)
    return ret
}

const linkDef = {
    title: 'Link',
    tooltip: 'Configurable link to either a page, external url, internal url, or media lightbox',
    fields: {
        label: { type: EditorFieldType.String, default: '', tooltip: 'Message to display on link button' },
        icon: { type: EditorFieldType.Icon, default: 'fas fa-paper-plane', tooltip: 'Icon to display on link button' },
        link: { type: EditorFieldType.String, default: '', tooltip: 'Relative or absolute url for button link' },
        pageId: {
            type: EditorFieldType.Enum, default: null, label: 'Page', tooltip: 'Page to navigate to', enum: (data, config) => {
                if (config.app) {
                    return config.app.pages.map((x) => ({ id: x.id, name: x.name }))
                } else if (config.organization) {
                    return config.organization.pages.map((x) => ({ id: x.id, name: x.name }))
                }
            }
        },
        mediaId: { type: EditorFieldType.Media, default: null, label: 'Media', tooltip: 'Media item to display' }
    }

}

const mediaSizeEnum = [
    { id: 3, name: 'Quarter Width' },
    { id: 6, name: 'Half Width' },
    { id: 9, name: 'Three Quarter Width' },
]

const defaultMedia = {
    mediaId: null,
    galleryId: null,
    index: 0,
    thumbnailMediaId: null,
    position: 'right',
    size: 8,
    alignment: 'left',
    orientation: 'portrait',
    fit: 'cover',
    subtitle: null,
    clickable: true
}

const mediaDef = {
    title: 'Media',
    fields: {
        // label: { type: EditorFieldType.String, default: '' },
        mediaId: { type: EditorFieldType.Media, label: 'Media', default: null, tooltip: "Individual media item to display", primary: true },
        galleryId: { type: EditorFieldType.Gallery, label: 'Gallery', default: null, tooltip: "Gallery to display", primary: true },
        index: { type: EditorFieldType.Integer, default: 0, dependsOn: 'galleryId', label: 'Gallery Index', tooltip: "Index of media item within selected gallery" },
        thumbnailMediaId: { type: EditorFieldType.Media, label: 'Thumbnail', default: null, tooltip: "Media item to display as thumbnail" },
        position: { type: EditorFieldType.Enum, label: 'Position', default: 'right', enum: (data, config) => ['top', 'bottom', 'left', 'right'].map((x) => ({ id: x, name: x.toReadable() })), tooltip: 'Which position within the section to display media' },
        size: { type: EditorFieldType.Integer, default: 8, label: 'Size', tooltip: 'How large to display the media item, (may be any of 1-10)', slider: { min: 1, max: 10, step: 1 } },
        // size: { type: EditorFieldType.Enum, default: 1, label: 'Size', tooltip: 'How large to display the media item', enum: (data, config) => mediaSizeEnum }
        alignment: { type: EditorFieldType.Enum, label: 'Alignment', default: 'left', enum: (data, config) => ['left', 'center', 'right'].map((x) => ({ id: x, name: x.toReadable() })), tooltip: 'How to align the media item within the section' },
        orientation: { type: EditorFieldType.Enum, label: 'Orientation', default: 'portrait', enum: (data, config) => ['portrait', 'landscape'].map((x) => ({ id: x, name: x.toReadable() })), tooltip: 'How the media should be oriented, works with fit to correctly display image' },
        fit: { type: EditorFieldType.Enum, label: 'Fit', default: 'cover', enum: (data, config) => ['contain', 'cover'].map((x) => ({ id: x, name: x.toReadable() })), tooltip: 'Whether to display the media item in full or crop it' },
        subtitle: { type: EditorFieldType.String, default: null, label: 'Subtitle', tooltip: 'Subtitle to display below media item' },
        clickable: { type: EditorFieldType.Checkbox, default: true, label: 'Clickable', tooltip: 'Whether the media item can be viewed in a lightbox' },
    }
}

const locationDef = {
    title: 'Locations',
    fields: {
        locationId: { type: EditorFieldType.Location, default: null, label: 'Location' },
        // format: { type: EditorFieldType.Integer, default: 0  },
        /*name: { type: EditorFieldType.String, default: '' },
        numberAndStreet: { type: EditorFieldType.String, default: '' },
        unit: { type: EditorFieldType.String, default: '' },
        country: { type: EditorFieldType.String, default: '' },
        provinceState: { type: EditorFieldType.String, default: '' },
        city: { type: EditorFieldType.String, default: '' },
        postalZipCode: { type: EditorFieldType.String, default: '' },
        note: { type: EditorFieldType.String, default: '' },
        latLng: { type: EditorFieldType.String, default: '' },*/
    }
}

const choiceDef = {
    title: 'Choice',
    fields: {
        label: { type: EditorFieldType.String, default: '' },
        value: { type: EditorFieldType.String, default: null },
        isDefault: { type: EditorFieldType.Checkbox, default: false, tooltip: 'Whether this choice will be selected by default' },
        order: { type: EditorFieldType.Integer, default: 0, hidden: true },
    }
}
const fieldDef = {
    title: 'Field',
    fields: {
        label: { type: EditorFieldType.String, default: '' },
        formFieldTypeId: {
            type: EditorFieldType.Enum, default: FormFieldType.Text, label: 'Type', enum: (data, config) => {
                return Object.keys(FormFieldType).filter((x) => isNaN(x)).map((x) => ({ id: FormFieldType[x], name: x.toReadable() }))
            }
        },
        body: { type: EditorFieldType.String, default: '', label: 'Description' },
        link: { type: EditorFieldType.String, default: '', tooltip: 'Internal identifier for field', required: true },
        required: { type: EditorFieldType.Checkbox, default: false, tooltip: 'Whether this field is required before submission' },
        isHubspot: { type: EditorFieldType.Checkbox, default: false, tooltip: 'Whether this field will be sent to Hubspot (if configured)' },
        size: { type: EditorFieldType.Integer, default: 1, slider: { min: 1, max: 8, step: 1 }, tooltip: 'How many columns this field should take up (1-8)' },
        breakAfter: { type: EditorFieldType.Checkbox, default: false, tooltip: 'Whether this field should cause the next field to start on a new line' },
        choices: { type: EditorFieldType.Multi, default: [], def: choiceDef, dependsOn: 'formFieldTypeId', dependsOnValue: FormFieldType.Select, reorder: true, tooltip: 'List of choices for select fields' },
        placeholder: { type: EditorFieldType.String, default: '', tooltip: 'Placeholder text for field' },
        defaultValue: { type: EditorFieldType.String, default: '', tooltip: 'Default value for field' },
        adminOnly: { type: EditorFieldType.Checkbox, default: false, adminOnly: true, tooltip: 'Whether this field should only be visible to administrators' },
        order: { type: EditorFieldType.Integer, default: 0, hidden: true },
    }
}
const formDef = {
    title: 'Form',
    fields: {
        analyticsEventTypeId: {
            type: EditorFieldType.Enum, default: CustomEventType.Contact, label: 'Type', enum: (data, config) => {
                return [AnalyticsEvent.RequestInformation, AnalyticsEvent.EventRegistration, AnalyticsEvent.ProjectRegistration].map((x) => ({ id: x, name: AnalyticsEvent[x]?.toReadable() }))
            }
        },
        submitMessage: { type: EditorFieldType.String, default: 'Submit', tooltip: 'Text to display on the submit button' },
        successMessage: { type: EditorFieldType.TextField, default: 'Thank you for your submission!', tooltip: 'Message to display after successful submission' },
        tags: { type: EditorFieldType.String, default: '' },
        alignment: { type: EditorFieldType.Enum, label: 'Alignment', default: 0, enum: (data, config) => ['left', 'center', 'right'].map((x, ix) => ({ id: ix, name: x.toReadable() })), tooltip: 'How to align the media item within the section' },
        fields: { type: EditorFieldType.Multi, default: defaultFormFields, def: fieldDef, reorder: true },
        submitToHubspot: { type: EditorFieldType.Checkbox, default: false, tooltip: 'Whether to submit this form to Hubspot' },
        hubspotFormId: { type: EditorFieldType.String, default: null, dependsOn: 'submitToHubspot', tooltip: 'Internal hubspot form ID to submit to' },
        hubspotUseEmbedded: { type: EditorFieldType.Checkbox, default: false, dependsOn: 'hubspotFormId', tooltip: 'Whether to use the embedded hubspot form instead of the API' },
    }
}

const pointDef = {
    title: 'Point',
    fields: {
        label: { type: EditorFieldType.String, default: '' },
        body: { type: EditorFieldType.TextField, default: '' },
        size: { type: EditorFieldType.Integer, default: 1 },
        x: { type: EditorFieldType.Float, default: 0 },
        y: { type: EditorFieldType.Float, default: 0 },
    }
}


const contentDef = {
    title: 'Content',
    noDelete: true,
    fields: {
        dynamicContentTypeId: {
            type: EditorFieldType.Enum, default: DynamicContentType.Blurb, label: 'Type', enum: (data, config) => Object.keys(DynamicContentType).filter((x) => isNaN(x)).map((x) => {
                return { id: DynamicContentType[x], name: x?.toReadable() }
            }),
        },
        draft: { type: EditorFieldType.Checkbox, default: false, tooltip: 'Whether this content is in draft and should be hidden from non-administrators' },
        title: { type: EditorFieldType.String, default: null, LLM: true },
        subtitle: { type: EditorFieldType.String, default: null, LLM: true },
        header: { type: EditorFieldType.String, default: null },
        body: { type: EditorFieldType.TextField, default: null, LLM: true },
        alignment: { type: EditorFieldType.Enum, default: 0, enum: (data, config) => ['left', 'center', 'right'].map((x, ix) => ({ id: ix, name: x })) },
        locations: { type: EditorFieldType.Multi, default: [], def: locationDef, limit: 1, label: 'Location', locationTypeId: [LocationType.Primary], tooltip: 'List of relevant locations' },
        links: { type: EditorFieldType.Multi, default: [], def: linkDef, limit: 3 },
        media: { type: EditorFieldType.Multi, default: [], def: mediaDef },
        size: { type: EditorFieldType.Enum, default: 0, label: 'Font Size', tooltip: 'Font size', enum: (a, b) => [{ id: 0, name: 'Regular' }, { id: 1, name: 'Large' }] },
        expandable: { type: EditorFieldType.Checkbox, default: false, tooltip: 'Whether this content should be cut off with ability to expand section' },
        anchor: { type: EditorFieldType.String, default: '' },
        tags: { type: EditorFieldType.StringTag, enum: pageTagEnum, default: '' },
        backgroundColor: { type: EditorFieldType.Color, default: null },
        contentStyle: { type: EditorFieldType.Enum, default: 'default', enum: (data, config) => [{ id: 0, name: 'default' }, { id: 1, name: 'full' }] },
        // contentStyle: { type: EditorFieldType.Integer, slider: { min: 0, max: 1, step: 1 }, default: null },//, enum: (data, config) => [0, 1, 2].map((x) => ({ id: x, name: x })) },
        forms: { type: EditorFieldType.Multi, default: [], def: formDef },
        points: { type: EditorFieldType.Multi, default: [], def: pointDef },
    }
}

const subContentBDef = {
    title: 'Element',
    fields: {
        title: contentDef.fields.title,
        subtitle: contentDef.fields.subtitle,
        header: contentDef.fields.header,
        body: contentDef.fields.body,
        links: contentDef.fields.links,
        media: contentDef.fields.media,
        locations: contentDef.fields.locations,
        // locations: { type: EditorFieldType.Multi, default: [], def: locationDef, limit: 1, label: 'Location' },
        points: contentDef.fields.points,
    }
}
const subContentDef = {
    title: 'Element',
    fields: {
        dynamicContentTypeId: { ...contentDef.fields.dynamicContentTypeId, default: DynamicContentType.Element, primary: false },
        title: { ...contentDef.fields.title, primary: true },
        subtitle: contentDef.fields.subtitle,
        header: contentDef.fields.header,
        body: contentDef.fields.body,
        alignment: contentDef.fields.alignment,
        links: contentDef.fields.links,
        media: contentDef.fields.media,
        locations: contentDef.fields.locations,
        forms: contentDef.fields.forms,
        // locations: { type: EditorFieldType.Multi, default: [], def: locationDef, limit: 1, label: 'Location' },
        points: contentDef.fields.points,
        elements: { type: EditorFieldType.Multi, default: [], def: subContentBDef, reorder: true },
    }
}
contentDef.fields.elements = { type: EditorFieldType.Multi, default: [], def: subContentDef, reorder: true }


const contentRootDef = {
    title: 'Custom Content',
    fields: {
        content: { type: EditorFieldType.Array, default: [], def: contentDef },
    }
}


export const pageDef = {
    title: 'Element',
    fields: {
        name: { type: EditorFieldType.String, default: '' },
        title: { type: EditorFieldType.String, default: '' },
        link: { type: EditorFieldType.String, default: '' },
        pageTypeId: {
            type: EditorFieldType.Enum, default: PageType.Info, label: 'Type', enum: (data, config) => {
                return Object.values(config.pageTypes).map((x) => {
                    return { id: x.id, name: x.name.toReadable() }
                })
            }
        },
        icon: { type: EditorFieldType.Icon, default: '', tooltip: 'Icon to display next to the page name in menu' },
        draft: { type: EditorFieldType.Checkbox, default: false, tooltip: 'Whether this page is in draft and should be hidden from non-administrators' },
        exclusive: { type: EditorFieldType.Checkbox, default: false, tooltip: 'Whether this page should be exclusive to either users or those with correct marketing links' },
        salesCenterOnly: { type: EditorFieldType.Checkbox, default: false, tooltip: 'Whether this page should only be visible in the sales center' },
        showInMenu: { type: EditorFieldType.Checkbox, default: true, tooltip: 'Whether this page should be shown in the menu' },
        // tags: { type: EditorFieldType.String, default: '', tooltip: 'Tags to help categorize this page' },
        tags: {
            type: EditorFieldType.StringTag, default: '', enum: pageTagEnum, tooltip: 'Tags to help categorize this page'
        },
        backgroundColor: { type: EditorFieldType.Color, default: null },
        helper: { type: EditorFieldType.Checkbox, default: false, tooltip: 'Whether this page should trigger a helper popup' },
        helperText: { type: EditorFieldType.String, default: '', tooltip: 'Text to display in the helper popup' },
    }
}

const pagesRootDef = {
    title: 'Pages',
    fields: {
        pages: { type: EditorFieldType.Array, default: [], def: pageDef },
    }
}


const blogDef = fnc.copyObj(pageDef)
blogDef.fields.pageTypeId.default = PageType.Blog
// delete blogDef.fields.showInMenu
// delete blogDef.fields.helper
// delete blogDef.fields.helperText
blogDef.fields.showInMenu.hidden = true
blogDef.fields.helper.hidden = true
blogDef.fields.helperText.hidden = true
blogDef.fields.datePublished = { type: EditorFieldType.Date, default: new Date(), label: 'Date Published' }
const blogRootDef = fnc.copyObj(pagesRootDef)
blogRootDef.fields.pages.def = blogDef

const globalFields = ['dynamicContentTypeId', 'draft']

const fieldOptions = {
    [DynamicContentType.Splash]: {
        content: {
            include: ['title', 'subtitle', 'body', 'links', 'elements', 'media', 'alignment']
            // media: {
            // include: 'mediaId', 
            // }
        }
    },
    [DynamicContentType.Latest]: {
        content: {
            include: ['title', 'body', 'links', 'alignment']
        }
    },
    [DynamicContentType.ProjectInfo]: {
        content: {
            include: ['title', 'body', 'links', 'media', 'locations', 'draft', 'alignment'],
            media: {
                include: ['mediaId', 'fit']
            },
            links: {
                // label: { default: 'Register' },
                link: { default: '#register' }
            }
        }
    },
    [DynamicContentType.Event]: {
        content: {
            include: ['title', 'elements', 'media', 'draft', 'alignment'],
            elements: {
                include: ['title', 'body', 'subtitle', 'locations'],
            }
        }
    },
    [DynamicContentType.Footer]: {
        content: {
            include: ['title', 'subtitle', 'body', 'elements', 'media', 'draft', 'alignment', 'links'],
            elements: {
                include: ['title', 'body', 'subtitle', 'locations', 'links'],
            }
        }
    },
    [DynamicContentType.Blurb]: {
        content: {
            exclude: ['locations'],
        }
    },
    [DynamicContentType.Map]: {
        content: {
            include: ['title', 'contentStyle', 'draft'],
        }
    },
    [DynamicContentType.Accordian]: {
        content: {
            elements: {
                include: ['title', 'body']
            }
        }
    },
    [DynamicContentType.Items]: {
        content: {
            include: ['title', 'elements', 'draft', 'alignment'],
            elements: {
                include: ['title', 'media', 'body', 'links']
            }
        }
    },
    [DynamicContentType.RecentBlog]: {
        content: {
            include: ['title', 'body', 'elements', 'draft', 'alignment', 'tags', 'contentStyle', 'size', 'expandable'],
            size: { type: EditorFieldType.Integer, slider: { min: 1, max: 2, step: 1 }, label: 'Rows to Show', tooltip: 'Rows to Show', default: 1 },
            elements: {
                include: ['title', 'media', 'body', 'links']
            },
            contentStyle: { enum: (data, config) => [{ id: 0, name: 'Vertical' }, { id: 1, name: 'Horizontal' }, { id: 2, name: 'Feature with Column' }] }
        }
    },
    [DynamicContentType.Header]: {
        content: {
            include: ['media', 'draft', 'title', 'alignment'],
            media: {
                include: ['mediaId', 'galleryId'],
                galleryId: { primary: true }
            },
            // contentStyle: { enum: [{ id: 0, name: 'Single Image' }, { id: 1, name: 'Mosaic' }] }
        }
    },
    [DynamicContentType.Form]: {
        content: {
            include: ['title', 'subtitle', 'body', 'forms', 'draft', 'alignment'],
        }
    },
    [DynamicContentType.Quotes]: {
        content: {
            include: ['elements', 'draft'],
            elements: {
                label: 'Quote',
                include: ['title', 'body', 'subtitle', 'header', 'media'],
                title: { label: 'Name' },
                body: { label: 'Description' },
                subtitle: { label: 'Organization' },
                header: { label: 'Quote' },
            }
        }
    },
    [DynamicContentType.ScreenSaver]: {
        content: {
            include: ['media', 'draft'],
            media: {
                include: ['galleryId'],
            }
        }
    },
    [DynamicContentType.Points]: {
        content: {
            include: ['title', 'media', 'elements', 'size', 'draft'],
            elements: {
                include: ['title', 'body', 'media', 'points'],
                points: {
                    include: ['x', 'y'],
                    x: { primary: true },
                    y: { primary: true },
                }
            }
        }
    },
    [DynamicContentType.Neighbourhood]: {
        content: {
            include: ['title', 'media', 'elements'],
            elements: {
                include: ['title', 'elements'],
                elements: {
                    include: ['title', 'locations'],
                    locations: {
                        include: ['locationId'],
                        locationId: { locationTypeId: [LocationType.Primary, LocationType.Neighbourhood] }
                    }
                }
            }
        }
    },
    [DynamicContentType.Numbered]: {
        content: {
            include: ['title', 'subtitle', 'body', 'elements', 'links', 'alignment'],
            elements: {
                include: ['title', 'subtitle', 'body', 'links', 'media'],
            }
        }
    },
    [DynamicContentType.Developers]: {
        content: {
            include: ['title', 'subtitle', 'body', 'alignment'],
        }
    },
    [DynamicContentType.Steps]: {
        content: {
            include: ['title', 'subtitle', 'body', 'elements', 'links', 'alignment'],
            elements: {
                include: ['title', 'subtitle', 'body', 'links', 'media'],
            }
        }
    },
    [DynamicContentType.Summary]: {
        content: {
            include: ['title', 'subtitle', 'body', 'links', 'alignment'],
        }
    },
    [DynamicContentType.Tabbed]: {
        content: {
            include: ['title', 'subtitle', 'body', 'elements', 'links', 'alignment'],
            elements: {
                include: ['title', 'subtitle', 'body', 'links', 'media'],
                media: {
                    include: ['mediaId', 'galleryId', 'fit'],
                }
            }
        }
    },
    [DynamicContentType.Grid]: {
        content: {
            include: ['title', 'subtitle', 'elements', 'size', 'media', 'expandable', 'alignment'],
            elements: {
                include: ['title', 'subtitle', 'body', 'links', 'media', 'size'],
                // media: {
                // include: ['mediaId', 'galleryId', 'fit'],
                // , }
            },
            media: {
                include: ['mediaId', 'galleryId'],
            },
            size: { type: EditorFieldType.Integer, default: 1, label: 'Columns', tooltip: 'Number of horizonal columns for the grid (2-6)', slider: { min: 2, max: 6, step: 1 }, enum: null }
        }
    },
    // [,
    // [CustomContentType.Grid]: {
    // content: {
    // include: ['media'],
    // media: {
    // include: ['mediaId', 'galleryId'],
    // }
    // }
    // }
}

// Construct def and consider field options
function getDef(type) {
    if (!(type in fieldOptions)) {
        return fnc.copyObj(contentRootDef)
    }
    const newOptions = { ...fieldOptions[type] }
    const filterDef = (subDef, options) => {
        if (!options) {
            return subDef
        }
        const filteredDef = { ...subDef, fields: { ...subDef.fields } }
        if (options.include) {
            Object.keys(filteredDef.fields).forEach((key) => {
                if (!options.include.includes(key) && !globalFields.includes(key)) {
                    delete filteredDef.fields[key]
                }
            })
        } else if (options.exclude) {
            Object.keys(filteredDef.fields).forEach((key) => {
                if (options.exclude.includes(key) && !globalFields.includes(key)) {
                    delete filteredDef.fields[key]
                }
            })
        }

        // Override defaults
        const overridables = ['type', 'default', 'label', 'primary', 'enum', 'locationTypeId', 'slider', 'tooltip']
        Object.keys(filteredDef.fields).forEach((key) => {
            overridables.forEach((overridable) => {
                if (options[key] && overridable in options[key] &&
                    (Array.isArray(options[key][overridable]) || typeof options[key][overridable] !== 'object' || options[key][overridable] === null || overridable == 'slider')) {
                    filteredDef.fields[key][overridable] = options[key][overridable]
                }
            })
            // if (options[key] && 'default' in options[key]) {
            // filteredDef.fields[key].default = options[key].default
            // }

            // if (options[key] && 'primary' in options[key]) {
            // filteredDef.fields[key] = { ...filteredDef.fields[key], ...options[key].properties }
            // }
        })

        Object.keys(filteredDef.fields).forEach((key) => {
            // if (key in options && options[key].label && typeof options[key].label === 'string') {
            // filteredDef.fields[key].label = options[key].label
            // }
            if (filteredDef.fields[key].def != null && key in options) {
                filteredDef.fields[key].def = filterDef(filteredDef.fields[key].def, options[key])
                if (key in options && options[key].label) {
                    filteredDef.fields[key].def.title = options[key].label
                }
            }
        })
        return filteredDef
    }
    const newDef = filterDef(fnc.copyObj(contentRootDef), newOptions)
    // Apply options to sub objects
    return newDef

}

const defaultPage = {
    id: null,
    title: '',
    name: '',
    link: '',
    icon: 'fas fa-plus',
    draft: true,
    exclusive: false,
    showInMenu: false,
    salesCenterOnly: false,
    pageType: PageType.Custom,
    datePublished: fnc.formatDateToYYYYMMDD(new Date())
}

const defaultContent = {
    dynamicContentTypeId: DynamicContentType.Blurb,
    title: null,
    subtitle: null,
    body: null,
    elements: [],
    links: [],
    media: [],
    locations: [],
    contentStyle: 0,
    forms: [],
    points: [],
}

interface InlineContentEditorProps {
    nested: boolean
}
export function InlineContentEditor(props: InlineContentEditorProps) {
    const { nested } = props
    const dispatch = useAppDispatch()

    // Selectors
    const adminApp = useAppSelector((state) => state.admin.project)
    const adminOrg = useAppSelector((state) => state.admin.organization)
    const editContent = useAppSelector((state) => state.admin.editContent)
    const editInline = useAppSelector((state) => state.admin.editInline)
    const app = useAppSelector((state) => state.app.projectCurrent[0])
    const organization = useAppSelector((state) => state.app.organization)
    const config = useAppSelector((state) => state.app.config)
    const prompt = useAppSelector((state) => state.app.prompt)
    const allMedia = useAppSelector((state) => state.app.media)
    const links = useAppSelector((state) => state.app.links)

    // Hooks
    const page = usePage()
    const hybridContent = useHybridContent()
    const downloadMedia = useDownloadMedia(app, organization)

    // Static Content
    const appStaticContent = projectStaticContent(app)
    const orgStaticContent = !app ? organizationStaticContent(organization) : null

    // States
    const [data, setData] = useState(null)
    const [expanded, setExpanded] = useState(false)
    const [pendingChange, setPendingChange] = useState(false)
    const [elem, setElem] = useState(null)
    const [LLMHelper, setLLMHelper] = useState(null)
    const [show, setShow] = useState(null)
    const [saving, setSaving] = useState(false)
    const [saveText, setSaveText] = useState()
    const [focusContent, setFocusContent] = useState(null)
    const [pageContent, setPageContent] = useState(null)
    const [footerContent, setFooterContent] = useState(null)
    const [listElem, setListElem] = useState(null)
    const [editPage, setEditPage] = useState(null)
    const [currentAppId, setCurrentAppId] = useState(null)
    const [currentOrgId, setCurrentOrgId] = useState(null)

    // Tab state with conditional storage
    const [tab, setTab] = nested ? useState(EditorTab.Content) : useLocalStorage('editor-tab', EditorTab.Content)

    // Refs
    const scrollContentId = useRef()
    const contentRef = useRef()
    const mousePositionRef = useRef()
    const currentPage = useRef()
    const validateRef = useRef()
    const linkEditTimeout = useRef()

    // Pages and posts filtering
    let pageTypeMap = {}//config.pageTypes ? fnc.objIdMap(config.pageTypes) : {}
    let pages = app ? [...app.pages] : organization ? [...organization.pages] : []
    let posts = pages.filter((x) => x.pageTypeId == PageTypeId.Blog)
    pages = pages.filter((x) => {
        return x.pageTypeId != PageTypeId.Blog
    })

    // Static pages
    let staticPages = []
    if (!app && pages) {
        const orgPages = organizationRoutes(organization).filter((x) => x.pageTypeId != PageTypeId.Blog)
        const staticKeys = new Set(orgPages.map((x) => x.link))
        const dynamicKeys = new Set(pages.map((x) => x.link))
        const onlyStaticKeys = staticKeys.difference(dynamicKeys)
        staticPages = orgPages.filter((x) => onlyStaticKeys.has(x.link))
        staticPages.forEach((x) => {
            pages.push(x)
        })
    }


    useEffect(() => {
        if (!organization || (app && app?.meta.id == currentAppId) || links.pageLink == PageType.Admin) {
            return
        }
        dispatch(initializeAdmin({ appLink: app?.meta.link, options: { organization: organization.link, editor: true } }))
        if (app) {
            dispatch(initializeAdminProject({ appLink: app.meta.link, organizationLink: organization.link }))
        }
        setCurrentAppId(app?.meta.id)
        setCurrentOrgId(organization?.id)
    }, [app, organization])


    useEffect(() => {
        // Sync admin app with current app
        if (app && (!adminApp || adminApp.id != app.id)) {
            dispatch(setProject(app))
        } else if (adminApp) {
            dispatch(updateProjectCurrent(adminApp))
        }
    }, [adminApp, app])

    useEffect(() => {
        // Sync admin org with current org
        if (organization && (!adminOrg || adminOrg.id != organization.id)) {
            dispatch(setOrganization(organization))
        } else if (adminOrg && organization && adminOrg.modified != organization.modified) {//} && organization?.id != adminOrg.id) {
            dispatch(updateOrganization(adminOrg))
            if (prompt && prompt.organization) {
                dispatch(updatePrompt({ organization: adminOrg }))
            }
        }
    }, [adminOrg, organization])

    useEffect(() => {
        // Sync admin app with current app
        if (app && (!adminApp || adminApp.id != app.id)) {
            dispatch(setProject(app))
        } else if (adminApp) {
            dispatch(updateProjectCurrent(adminApp))
        }
    }, [adminApp, app])

    useEffect(() => {
        // if (!page) {
        // return
        // }
        if (focusContent && currentPage.current != page?.id) {
            dispatch(clearEditContent())
            setFocusContent(null)
        }
        currentPage.current = page?.id

        const footerPage = pages?.find((x) => x.pageType == PageType.Footer || x.link == 'footer')
        if (app && page && appStaticContent) {
            const content = hybridContent(app, null, organization, page, appStaticContent)
            setPageContent(content)
            if (footerPage) {
                const appContent = hybridContent(app, null, organization, footerPage, appStaticContent)
                setFooterContent(appContent)
            }
            /*if (existingContent) {
                const dbSet = new Set(dbContent.map((x) => x.id))
                const finalContent = []
                existingContent.map((x, ix) => {
                    const dbMatch = dbContent.find((x) => x.staticLink == `${page.link}-${ix}`)
                    if (dbMatch) {
                        finalContent.push(dbMatch)
                        dbSet.delete(dbMatch.id)
                        return
                    }
     
                    const staticContent = { ...x, dynamicContentTypeId: x.type, staticLink: `${page.link}-${ix}`, order: ix }
                    // Do some media conversion from static
                    if (staticContent.media && staticContent.staticLink) {
                        if (Array.isArray(staticContent.media)) {
                            staticContent.media = staticContent.media.map((x) => {
                                if (x.id) {
                                    return x
                                }
                                return { ...x, mediaId: getLegacyMediaId(x, app, null, null, allMedia, false) }
                            })
                        } else {
                            staticContent.media = [{ mediaId: getLegacyMediaId(staticContent.media, app, null, null, allMedia, false) }]
                        }
     
                    }
                    finalContent.push(staticContent)
                })
                dbContent.forEach((x) => {
                    if (dbSet.has(x.id)) {
                        finalContent.push(x)
                    }
                })
            } else {
                setPageContent(dbContent)
            }*/
        } else if (orgStaticContent) {
            const content = hybridContent(null, null, organization, page, orgStaticContent)
            setPageContent(content)
            if (footerPage) {
                const appContent = hybridContent(app, null, organization, footerPage, orgStaticContent)
                setFooterContent(appContent)
            }
        } else if (app) {
            const dbContent = app.dynamicContent?.filter((x) => x.pageId == page?.id)
            setPageContent(dbContent)
            if (footerPage) {
                const appContent = app.dynamicContent?.filter((x) => x.pageId == footerPage.id)
                setFooterContent(appContent)
            }
        } else if (organization) {
            const dbContent = organization.dynamicContent?.filter((x) => x.pageId == page?.id)
            setPageContent(dbContent)
            if (footerPage) {
                const appContent = organization.dynamicContent?.filter((x) => x.pageId == footerPage.id)
                setFooterContent(appContent)
            }
        }

    }, [app, organization, page])

    useEffect(() => {
        if (tab == EditorTab.Content) {
            setData(fnc.copyObj({ ...defaultContent, ...editContent }))
        } else if (tab == EditorTab.Pages || tab == EditorTab.Blogs) {
            setData(fnc.copyObj(editPage))
        }
    }, [editContent, editPage])

    useEffect(() => {
        if (editInline && !show) {
            setShow(true)
        }
    }, [editInline])

    useEffect(() => {
        window.addEventListener(CustomEventType.ContentAction, handleContentAction)
        return () => {
            window.removeEventListener(CustomEventType.ContentAction, handleContentAction)
        }
    })

    useEffect(() => {
        if (editContent?.dynamicContentTypeId == DynamicContentType.Points) {
            const handleEditPoints = (e) => {
                const { element, point } = e.detail
                const elemIdx = data.elements.findIndex((x) => x.id == element.id)
                if (elemIdx > -1) {
                    const elem = data.elements[elemIdx]
                    const pointIdx = elem.points.findIndex((x) => x.id == point.id)
                    if (pointIdx > -1) {
                        data.elements[elemIdx].points[pointIdx] = point
                        handleChange()
                    }
                }
            }
            window.addEventListener(CustomEventType.EditPoint, handleEditPoints)
            return () => {
                window.removeEventListener(CustomEventType.EditPoint, handleEditPoints)
            }
        }
    }, [editContent, data])

    useEffect(() => {
        if (!listElem) {
            return
        }
        const checkOverlap = (elemA, elemB, e) => {
            if (!mousePositionRef.current) return null
            // Check if the mouse event position is overlapping the bounding box of elemA or elemB 
            const rectA = elemA.getBoundingClientRect()
            const rectB = elemB.getBoundingClientRect()
            const { x, y } = mousePositionRef.current
            if (x >= rectA.left && x <= rectA.right && y >= rectA.top && y <= rectA.bottom) {
                return elemA
            }
            if (x >= rectB.left && x <= rectB.right && y >= rectB.top && y <= rectB.bottom) {
                return elemB
            }
            return null

        }
        const matchContent = (listA, listB, className = '.page-content', offset = 0) => {
            // Get all list elemens
            const elementsA = listA.querySelectorAll(className)
            // Figure out which element is closest to the top
            let closest = null
            elementsA.forEach((x) => {
                if (!closest) {
                    closest = x
                    return
                }
                if (Math.abs(x.offsetTop - listA.scrollTop) < Math.abs(closest.offsetTop - listA.scrollTop)) {
                    closest = x
                }
            })
            if (closest) {
                // Get
                const id = closest.getAttribute('data-id')
                if (id && id != scrollContentId.current) {
                    scrollContentId.current = id
                    // Scroll the content to the same id
                    const targetB = listB.querySelector(`[data-id="${id}"]`)
                    if (targetB) {
                        // Scroll smooth
                        listB.scrollTo({ top: targetB.offsetTop + offset, behavior: 'smooth' })
                    }
                }
            }
        }

        const scrollContent = (e) => {
            if (checkOverlap(contentRef.current, listElem, e) != contentRef.current) {
                return
            }
            matchContent(contentRef.current, listElem, '.dynamic-content', -60)
        }

        const scrollList = (e) => {
            if (!contentRef.current) {
                return
            }
            if (checkOverlap(contentRef.current, listElem) != listElem) {
                return
            }
            matchContent(listElem, contentRef.current, '.page-content', -100)
        }

        const getMousePosition = (e) => {
            mousePositionRef.current = { x: e.clientX, y: e.clientY }
        }

        const interval = setInterval(() => {
            contentRef.current = document.querySelector('.dynamic.scrollable')
            if (contentRef.current) {
                contentRef.current.addEventListener('scroll', scrollContent)
                clearInterval(interval)
                return
            }
        }, 1000)
        listElem.addEventListener('scroll', scrollList)
        window.addEventListener('mousemove', getMousePosition)
        return () => {
            listElem.removeEventListener('scroll', scrollList)
            if (contentRef.current) {
                contentRef.current.removeEventListener('scroll', scrollContent)
            }
            clearInterval(interval)
            window.removeEventListener('mousemove', getMousePosition)
        }
    }, [page, listElem, pageContent])

    function handleContentAction(e) {
        const { action, data, page } = e.detail
        const { id, staticLink, direction } = data
        logger.info("Content Action", action, data, page, pageContent, footerContent)
        let content = pageContent.find((x) => x.id == id || (x.staticLink && x.staticLink == staticLink))
        let isFooter = false
        if (!content) {
            content = footerContent?.find((x) => x.id == id || (x.staticLink && x.staticLink == staticLink))
            isFooter = true
        }
        if (!content && action != 'add') {
            logger.error("Failed to find content with id " + id + " or staticLink " + staticLink, footerContent)
            dispatch(showPrompt({ title: 'Error', message: 'Failed to find content', type: PromptType.Confirm, cancelMessage: null }))
            return
        }
        if (tab != EditorTab.Content) {
            setTab(EditorTab.Content)
            return
        }
        setTimeout(() => {
            switch (action) {
                case 'add':
                    handleAddContent(data, -1, page)
                    break
                case 'edit':
                    if (!content.id || (content.staticLink && content.staticLink == content.id)) {
                        handleAddContent(content, -1, page)
                    } else {
                        dispatch(setEditContent(fnc.copyObj(content)))
                    }
                    break
                case 'order':
                    handleOrder(content, direction, isFooter)
                    break
                case 'delete':
                    handleDelete(content)
                    break
                case 'cancel':
                    handleCancel()
                    break
                case 'copy':
                    handleCopy(content)
                    break
                default:
                    break
            }
        }, 0)
    }

    function handleSave(content, validate = false, _tab = tab) {
        if (validateRef.current && (!Array.isArray(content) || content.length == 1)) {
            const validation = validateRef.current()
            if (validation && validation.length > 0) {
                let validationMessage = "Please solve the following issues before saving:"
                validationMessage += "\n\n" + validation.map((x) => "* " + x).join("\n\n")
                dispatch(showPrompt({ type: PromptType.Confirm, title: 'Validation Error', message: validationMessage, cancelMessage: null }))
                return
            }
            // if(!validateRef.current())
            // }

        }
        let proms = []
        if (_tab == EditorTab.Content) {
            if (Array.isArray(content)) {
                proms = content.map((x) => dispatch(upsertContent({ app, organization, data: x })))
            } else {
                proms = [dispatch(upsertContent({ app, organization, data: content }))]
            }
        } else if (_tab == EditorTab.Pages || _tab == EditorTab.Blogs) {
            if (Array.isArray(content)) {
                // proms.push(dispatch(upsertPage({ app, organization, page: content[0] })))
                proms = content.map((x) => dispatch(upsertPage({ app, organization, page: x })))
            } else {
                proms = [dispatch(upsertPage({ app, organization, page: content }))]

            }
            // proms.push(dispatch(upsertPage({ app, page: editPage })))
        }

        setSaveText('Saving')
        setSaving(true)
        // setTimeout(() => {
        return Promise.all(proms)
            .then((x, ix) => {
                setTimeout(() => {
                    setSaving(false)
                    setPendingChange(false)
                    if (x.find((x) => x.status == 'error')) {
                        setSaveText('Error')
                    } else {
                        setSaveText('Saved!')
                    }
                }, 10)
                return x.map((y) => {
                    if (Array.isArray(y.payload)) {
                        return y.payload[0]
                    } else {
                        return y.payload
                    }
                })
            })
            .catch(logger.error)
        // }, 10)
    }

    function handleCancel() {
        const close = () => {
            setPendingChange(false)
            if (editPage) {
                setEditPage(null)
            } else {
                dispatch(clearEditContent())
            }
        }
        if (pendingChange) {
            dispatch(showPrompt(PromptOptions.UnsavedChanges))
                .then((x) => {
                    if (x.payload) {
                        close()
                    }
                })
        } else {
            close()
        }
    }

    function handleClose() {
        const close = () => {
            setShow(false)
            setTimeout(() => {
                // Find all visible custom content elements
                let elements = document.querySelectorAll('.dynamic-content.visible')
                const ids = new Set()
                elements.forEach((x) => {
                    ids.add(x.getAttribute('id'))
                })
                // Get all attribute ids
                dispatch(setEditInline(false))
                setShow(null)
                setData(null)

                // Find all elements again
                setTimeout(() => {
                    elements = document.querySelectorAll('.dynamic-content')
                    // Add visible class
                    elements?.forEach((x) => {
                        const id = x.getAttribute('id')
                        if (ids.has(id)) {
                            x.classList.add('visible')
                        }
                    })
                }, 0)
            }, 100)
            setPendingChange(false)
        }
        if (pendingChange) {
            dispatch(showPrompt(PromptOptions.UnsavedChanges))
                .then((x) => {
                    if (x.payload) {
                        close()
                    }
                })
        } else {
            close()
        }
    }

    function handleDelete(content) {
        if (tab == EditorTab.Content) {
            dispatch(showPrompt({ type: PromptType.Confirm, title: 'Delete Content', message: 'Are you sure you want to delete this content?', confirmText: 'Delete' }))
                .then((x) => {
                    if (x.payload) {
                        if (editContent?.id == content.id) {
                            handleCancel()
                        }
                        dispatch(removeContent({ app, organization, data: content }))
                    }
                })
        } else if (tab == EditorTab.Pages) {
            dispatch(showPrompt({ type: PromptType.Confirm, title: 'Delete Page', message: 'Are you sure you want to delete this page?', confirmText: 'Delete' }))
                .then((x) => {
                    if (x.payload) {
                        if (editPage?.id == content.id) {
                            handleCancel()
                        }
                        dispatch(removePage({ app, organization, page: content }))
                    }
                })
        } else if (tab == EditorTab.Blogs) {
            dispatch(showPrompt({ type: PromptType.Confirm, title: 'Delete Blog Post', message: 'Are you sure you want to delete this blog post?', confirmText: 'Delete' }))
                .then((x) => {
                    if (x.payload) {
                        if (editPage?.id == content.id) {
                            handleCancel()
                        }
                        dispatch(removePage({ app, organization, page: content }))
                    }
                })
        }
    }

    function handleGoToContent(x) {
        if (x.link) {
            dispatch(navigate(x.link))
        }
    }

    async function handleCopy(content) {
        const fixContent = (x) => {
            if (tab == EditorTab.Content) {

                let {
                    id, pageId, staticLink, ...y
                } = x
                y.elements = y.elements.map(fixContent)
                y.links = y.links?.map((x) => ({ ...x, id: null, dynamicContentId: null }))
                y.media = y.media?.map((x) => ({ ...x, id: null, dynamicContentId: null }))
                y.points = y.media?.map((x) => ({ ...x, id: null, dynamicContentId: null }))
                return y
            } else if (tab == EditorTab.Pages || tab == EditorTab.Blogs) {
                let {
                    id, ...y
                } = x
                y.name = `${y.name} (Copy)`
                y.link = `${y.link}-copy`
                return y
            }
        }
        const newContent = fixContent(fnc.copyObj(content))
        let ret = await handleAddContent(newContent, newContent.order + 1)

        let newPage = ret.find((x) => {
            return x.page.link == newContent.link
        })?.page


        if (newPage && (tab == EditorTab.Pages || tab == EditorTab.Blogs)) {
            // Recursively fix all child elements
            let copyContent = hybridContent(app, null, organization, content, null)
            // setPageContent(content)
            for (const x of copyContent) {
                copyContent = fnc.replaceObjectValue(copyContent, 'id', null)
                copyContent = fnc.replaceObjectValue(copyContent, 'pageId', newPage.id)
                copyContent = fnc.replaceObjectValue(copyContent, 'parentContentId', null)
            }
            dispatch(navigate(newPage.link))
            handleAddContent(copyContent, -1, newPage, EditorTab.Content)
        }
    }

    function handleChange() {
        setPendingChange(true)
        setSaveText('Save')
        if (tab == EditorTab.Pages || tab == EditorTab.Blogs) {
            setEditPage(data)
            if (!page || page?.link != data.link) {
                clearTimeout(linkEditTimeout.current)
                linkEditTimeout.current = setTimeout(() => {
                    dispatch(navigate(data.link))
                }, 100)
            }
            return
        }
        dispatch(updateEditContent(data))
    }

    function handleEditContent(content) {
        if (tab === EditorTab.Content) {
            dispatch(setEditContent(fnc.copyObj(content)))
            setEditPage(null)
        } else {
            setEditPage(fnc.copyObj(content))
        }
    }

    function handleAddAllPages() {
        const newPages = staticPages.map((x, ix) => ({
            ...x,
            pageTypeId: Object.values(config.pageTypes).findIndex((x) => x.name == "custom"),
            order: ix,
            organizationId: organization.id,
        }))
        handleSave(newPages)
    }

    function handleAddAllContent() {
        handleSave(pageContent.filter((x) => x.id == null))
    }

    async function handleAddPageContent() {
        const ret = await handleAddContent(null)
        if (ret.length > 0 && ret[0] != null) {
            dispatch(setEditContent(fnc.copyObj(ret[0])))
        }
    }

    async function handleAddPage(e, withAI = false) {
        const ret = await handleAddContent(null)
        const newPage = ret[0]?.page
        if (newPage) {
            setEditPage(newPage)
            // Navigate to the page link
            dispatch(navigate(newPage.link))
        }
        if (withAI) {
            handleLLMHelper(e, LLMGenerateType.PageContent)
        }
    }

    function handleAddContent(existing, insertIndex = -1, _page = null, _tab = tab) {
        let finalPage = _page ? _page : page
        let toSave = []
        let defaultData = null
        let dataArray = null
        switch (_tab) {
            case EditorTab.Content:
                dataArray = pageContent
                defaultData = defaultContent
                break
            case EditorTab.Pages:
                dataArray = pages
                defaultData = { ...defaultPage, name: 'Page', link: 'page' }
                break
            case EditorTab.Blogs:
                dataArray = posts
                defaultData = { ...defaultPage, name: 'Post', link: 'post', pageType: PageType.Blog }
                break
        }

        const addHelper = (arr, newContent, exist) => {
            let maxOrder = arr.length > 0 ? Math.max(...arr?.map((x) => x.order)) : 0
            if (maxOrder == null) {
                maxOrder = 0
            }
            let newOrder = 0
            if (insertIndex >= 0 && insertIndex <= maxOrder && insertIndex != null) {
                newOrder = insertIndex
            } else if (exist && 'order' in exist && exist.order != null) {
                if (exist.staticLink) {
                    newOrder = exist.order + 1
                } else {
                    newOrder = exist.order
                }
            } else if (arr.length > 0) {
                newOrder = maxOrder + 1
            }

            if (insertIndex >= 0 && insertIndex <= maxOrder) {
                // Take all elements at and after index and increase order by 1
                arr.forEach((x) => {
                    if (x.order >= insertIndex) {
                        toSave.push({ ...x, order: x.order + 1 })
                    }
                })
            }
            newContent.order = newOrder

            if (!app) {
                newContent.organiziationId = organization.id
            } else {
                newContent.appId = app.meta.id
            }
            if (_tab == EditorTab.Content) {
                newContent.pageId = finalPage?.id
            } else {
                newContent.name += ` ${newContent.order + 1}`
                newContent.link += `-${newContent.order + 1}`
            }

            return newContent
        }
        if (Array.isArray(existing)) {
            existing.forEach((x) => {
                toSave.push(addHelper(dataArray, { ...defaultData, ...x }, x))
            })
        } else {
            toSave.push(addHelper(dataArray, { ...defaultData, ...existing }, existing))
        }
        const ret = handleSave(toSave, false, _tab)
        return ret
        // return handleSave(toSave, false, _tab)

        // dispatch(upsertContent({ app, data: newContent }))
        // .then((x) => {
        // dispatch(updateEditContent(x.payload))
        // dispatch(updateAppContent(x.payload))
        // })
    }

    function handleOrder(content, direction, isFooter = false) {
        // Find the element to swap with
        const swapElements = (arr, matchId) => {
            const currentIdx = arr.findIndex((x) => x.id == matchId)
            const swapElementOrder = arr[currentIdx].order + direction
            const swapElementIdx = arr.findIndex((x) => x.order == swapElementOrder)
            if (swapElementIdx < 0 || swapElementIdx >= arr.length) {
                return arr
            }
            const swappedElement = { ...arr[swapElementIdx], order: arr[currentIdx].order }
            const newElement = { ...arr[currentIdx], order: arr[currentIdx].order + direction }
            handleSave([newElement, swappedElement])
        }
        if (tab == EditorTab.Content) {
            if (isFooter) {
                swapElements(footerContent, content.id)
            } else {
                swapElements(pageContent, content.id)
            }
        } else if (tab == EditorTab.Pages) {
            swapElements(pages, content.id)
        }
    }

    function handleFocusContent(content) {
        if (focusContent?.id != null && focusContent?.id == content.id) {
            setFocusContent(null)
        } else {

            // Find element with attribute data-id={id} and scroll to it
            let elem = document.querySelector(`[data-id="${content.id}"]`)
            if (!elem) {
                elem = document.querySelector(`[data-id="${content.staticLink}"]`)
            }
            if (elem) {
                elem.scrollIntoView({ behavior: 'smooth', block: 'center' })
            }
            setFocusContent(content)
        }
    }

    async function addLLMContent(content, replaceAll = false) {
        let finalData = []
        if (!Array.isArray(content)) {
            content = [content]
        }
        const maxOrder = pageContent?.length > 0 ? Math.max(...pageContent?.map((x) => x.order)) : 0
        let ix = 0
        let proms = []
        for (const x of content) {
            const validateObj = async (x, def, ix) => {
                logger.info('Validating', x, def)
                let issues = []
                let finalObj = { order: maxOrder + ix }
                if ('type' in x) {
                    finalObj.dynamicContentTypeId = x.type
                }

                if (def.title === 'Media' && x.url) {
                    try {
                        const url = x.url
                        proms.push(new Promise((resolve, reject) => {
                            downloadMedia(url)
                                .then((x) => {
                                    resolve({ original: url, replace: x })
                                }).catch(reject)
                        }))
                    } catch (e) {
                        logger.error("Error retrieving media", e)
                    }
                }

                logger.info("Final obj", fnc.copyObj(finalObj))

                for (const key of Object.keys(x)) {
                    if (key === 'type') continue

                    if (!(key in def.fields) && key !== 'url') {
                        logger.info('Field not in definition', key, def)
                        issues.push(`Field ${key} not in definition`)
                        continue
                    }

                    if (Array.isArray(x[key]) && def.fields[key].def) {
                        // Use a for loop to await each validation
                        for (let iy = 0; iy < x[key].length; iy++) {
                            try {
                                x[key][iy] = await validateObj(x[key][iy], def.fields[key].def, iy)
                            } catch (e) {
                                issues.push(`Error validating field ${key} at index ${iy}: ${e}`)
                            }
                        }
                        finalObj[key] = x[key]
                    } else {
                        finalObj[key] = x[key]
                    }
                }
                return finalObj
            }

            try {
                const result = await validateObj(x, contentDef, ix)
                // Replace media in result
                finalData.push(result)
            } catch (e) {
                logger.info('Validation error', e)
            }

            ix += 1
        }
        // Media replacement
        const mediaResult = await Promise.all(proms)
        if (mediaResult.length > 0) {
            let finalDataString = JSON.stringify(finalData)
            mediaResult.forEach((x) => {
                const { original, replace } = x
                const replacementMedia = { ...defaultMedia, ...replace }
                const properties = Object.keys(replacementMedia).map((x) => {
                    if (typeof replacementMedia[x] === "string") {
                        return `"${x}":"${replacementMedia[x]}"`
                    } else {
                        return `"${x}":${replacementMedia[x]}`
                    }
                }).join(",")
                finalDataString = finalDataString.replace(`"url":"${original}"`, properties).replace("\"null\"", "null").replace('\"true\"', 'true').replace('\"false\"', 'false')
            })
            finalData = JSON.parse(finalDataString)
        }
        handleAddContent(finalData, null, editPage, EditorTab.Content)
    }

    function handleLLMHelper(e, type) {
        let title = '';
        let defaultValue = 'Generate content related to a new home buyer.'
        switch (type) {
            case LLMGenerateType.SingleContent:
                title = 'Add AI Content';
                break
            case LLMGenerateType.PageContent:
                title = 'Generate AI Page';
                break
            case LLMGenerateType.SocialMediaPost:
                title = 'Generate AI Social Media Post';
                defaultValue = 'Generate a social media post for a new home buyer.';
                break
        }
        const newLLMHelper = {
            id: 'content',
            type,
            options: { defaultValue, data: pageContent },
            target: e.target,
            title
        }
        if (elem) {
            const rect = elem.getBoundingClientRect()
            newLLMHelper.left = rect.left + rect.width + 100
            newLLMHelper.top = rect.top + 100//e.pageY - e.target.clientHeight
        } else {
            newLLMHelper.left = e.pageX + 20
            newLLMHelper.top = e.pageY
        }
        newLLMHelper.top = Math.min(newLLMHelper.top, window.innerHeight - 600)
        setLLMHelper(newLLMHelper)
    }

    function handleLLMHelperClose() {
        setLLMHelper(null)
    }

    async function handleLLMHelperValue(x) {
        if (LLMHelper.type == LLMGenerateType.SingleContent) {
            // Add single content
            await addLLMContent(x)
        } else if (LLMHelper.type == LLMGenerateType.PageContent) {
            // Clear all content and replace with
            // if (LLMHelper.type == LLMGenerateType.PageContent && pageContent.length > 0) {
            //     // Clear content first
            //     setEditContent([])
            //     let proms = pageContent.map((y) => {
            //         return dispatch(removeContent({ app, organization, data: y }))
            //     })
            //     await Promise.all(proms)
            // }
            await addLLMContent(x)
        }
        return null
        // return 'Content generated'
    }

    function handleSelectPage(x) {
        dispatch(navigate(x.link))
    }

    function getEditor() {
        // switch(editContent.type) {
        // }
        if (editContent) {
            const config = {
                app,
                organization
            }

            const finalData = {
                'content': [data]
            }
            const selection = {
                0: {
                    field: 'content',
                    fields: contentDef.fields,
                    value: editContent,
                }
            }
            // const def = getDef(editContent.type || editContent.dynamicContentTypeId)
            const def = getDef(editContent.dynamicContentTypeId)

            return <GenericEditor app={app} organization={organization} title={DynamicContentType[editContent.dynamicContentTypeId]} data={finalData} selection={selection} config={config} def={def} onChange={handleChange} noDelete onValidateRef={validateRef} />
        } else if (editPage) {
            const pageConfig = {
                app,
                organization,
                ...config
            }
            const finalData = {
                'pages': [data]
            }
            const selection = {
                0: {
                    field: 'pages',
                    fields: blogDef.fields,
                    value: editPage
                }
            }

            const defPages = fnc.copyObj(tab == EditorTab.Pages ? pagesRootDef : blogRootDef)
            const title = getPageTypeName(editPage, config)
            return <GenericEditor app={app} title={title} data={finalData} selection={selection} config={pageConfig} def={defPages} onChange={handleChange} noDelete />

        }
    }

    function getContentSelector() {
        // Check if any static content
        let hasStatic = false
        if (tab == EditorTab.Content) {
            hasStatic = pageContent?.find((x) => x.id == null)
        } else if (tab == EditorTab.Pages && organization && !app) {
            hasStatic = staticPages.length > 0
        }

        const source = app ? app : (organization ? organization : null)
        if (!source) {
            return
        }

        return <div className="content-editor scrollable" ref={setListElem}>
            <div className="content-editor-topbar">
                <div className="content-editor-title">
                    <h4>{EditorTab[tab]}</h4>
                </div>
            </div>
            {tab == EditorTab.Blogs && pages && <div className="page-content-list">
                {[...posts].sort((a, b) => a.order - b.order).map((x, ix) => {
                    const title = getPageTypeName(x, config)

                    return <div className="row page-content column" id={`${x.id}-${ix}`}>
                        <span className="row page-type">
                            {title?.toReadable()} - {x.order}{x.draft ? ` (DRAFT)` : ''}
                        </span>
                        <div className="row">
                            <div className="column" style={{ width: 'auto' }}>
                                <Icon className="page-icon" noBg icon={x.icon ? x.icon : pageIcons[title]} />
                            </div>
                            <div className="column" style={{ width: '100%' }}>
                                <h4>{x.name}</h4>
                                {/*(x.exclusive || x.draft || x.salescenterOnly) && <ul>
                                    {x.exclusive && <li>VIP</li>}
                                    {x.draft && <li>Draft</li>}
                                    {x.salescenterOnly && <li>Sales Center Only</li>}
                </ul>*/}
                            </div>
                        </div>
                        <div className="bottom right page-content-options">
                            <React.Fragment>
                                <IconButton noBg icon="fas fa-arrow-right" onClick={() => handleGoToContent(x)} />
                                <IconButton noBg icon="fas fa-pen" onClick={() => handleEditContent(x)} />
                                {x.order > 0 && <IconButton noBg icon="fas fa-arrow-up" onClick={() => handleOrder(x, -1)} />}
                                {x.order < pages.length - 1 && <IconButton noBg icon="fas fa-arrow-down" onClick={() => handleOrder(x, 1)} />}
                                <IconButton noBg icon="fas fa-clone" onClick={() => handleCopy(x)} />
                                <IconButton noBg icon="fas fa-trash" onClick={() => handleDelete(x)} />
                            </React.Fragment>
                        </div>
                    </div>
                })}
                <Button className="add-content" icon="fas fa-plus" tertiary onClick={(e) => handleAddPage(e, false)}>Add Blog Manually</Button>
                <Button className="add-content" icon="fas fa-hand-sparkles" tertiary disabled={saving || editing} onClick={(e) => handleAddPage(e, true)}>Add Blog using AI</Button>
            </div>}
            {tab == EditorTab.Pages && pages && <div className="page-content-list">
                {hasStatic && <div className={`row page-content column`} onClick={() => handleAddAllPages()}>
                    <h5>Add All Static Pages</h5>
                </div>}
                {[...pages].sort((a, b) => a.order - b.order).map((x, ix) => {
                    const isStatic = x.id == null
                    const title = getPageTypeName(x, config)

                    return <div className="row page-content column" id={`${x.id}-${ix}`}>
                        <span className="row page-type">
                            {title?.toReadable()} - {x.order}{x.draft ? ` (DRAFT)` : ''}
                        </span>
                        <div className="row">
                            <div className="column" style={{ width: 'auto' }}>
                                <Icon className="page-icon" noBg icon={x.icon ? x.icon : pageIcons[title]} />
                            </div>
                            <div className="column" style={{ width: '100%' }}>
                                <h4>{x.name}</h4>
                                {(x.exclusive || x.draft || x.salescenterOnly) && <ul>
                                    {x.exclusive && <li>VIP</li>}
                                    {x.draft && <li>Draft</li>}
                                    {x.salescenterOnly && <li>Sales Center Only</li>}
                                </ul>}
                            </div>
                        </div>
                        <div className="bottom right page-content-options">
                            {!isStatic && <React.Fragment>
                                <IconButton noBg icon="fas fa-arrow-right" onClick={() => handleGoToContent(x)} />
                                <IconButton noBg icon="fas fa-pen" onClick={() => handleEditContent(x)} />
                                {x.order > 0 && <IconButton noBg icon="fas fa-arrow-up" onClick={() => handleOrder(x, -1)} />}
                                {x.order < pages.length - 1 && <IconButton noBg icon="fas fa-arrow-down" onClick={() => handleOrder(x, 1)} />}
                                <IconButton noBg icon="fas fa-clone" onClick={() => handleCopy(x)} />
                                <IconButton noBg icon="fas fa-trash" onClick={() => handleDelete(x)} />
                            </React.Fragment>}
                            {isStatic && <IconButton noBg icon="fas fa-plus" onClick={() => handleAddContent(x)} />}
                        </div>
                    </div>
                })}
                <Button className="add-content" icon="fas fa-plus" tertiary onClick={(e) => handleAddPage(e, false)}>Add Page Manually</Button>
                <Button className="add-content" icon="fas fa-hand-sparkles" tertiary disabled={saving || editing} onClick={(e) => handleAddPage(e, true)}>Add Page using AI</Button>
            </div>}
            {tab == EditorTab.Content && !page && <div className="page-content-list">
                <h5 style={{ margin: '20px', width: 'auto', whitespace: 'pre-wrap' }}>Please navigate to valid page</h5>
                {app?.pages.map((x) => <div className="row">
                    <Button tertiary onClick={() => handleSelectPage(x)}>{x.name}</Button>
                </div>)}
                {!app && organization?.pages.map((x) => <div className="row">
                    <Button tertiary onClick={() => handleSelectPage(x)}>{x.name}</Button>
                </div>)}
            </div>}
            {tab == EditorTab.Content && page && <div className="page-content-list">

                {hasStatic && <div className={`row page-content column`} onClick={() => handleAddAllContent()}>
                    <h5>Add All Static Content</h5>
                </div>}
                {pageContent && [...pageContent].sort((a, b) => a.order - b.order).map((x, ix) => {
                    const contentType = x.dynamicContentTypeId || x.type
                    const isStatic = x.id == null

                    return <div className={`row page-content column${x.id == null ? ' static' : ''}`} data-id={x.id} onClick={() => handleFocusContent(x)}>
                        <span className="row page-type">
                            {DynamicContentType[contentType]?.toReadable()} - {x.order} - {x.staticLink}{x.draft ? ` (DRAFT)` : ''}
                        </span>
                        {x.title && x.title.length > 0 && <div className="row">
                            <h4>{x.title}</h4>
                        </div>}
                        {x.body && x.body.length > 0 && <div className="row">
                            {x.body && x.body.length > 50 && <span>{x.body.slice(0, 50)}...</span>}
                            {x.body && x.body.length <= 50 && <span>{x.body}</span>}
                        </div>}
                        {x.media && <div className="row page-content-media-wrapper">
                            {x.media.map((y) => {

                                let mediaElem = null
                                if (y.mediaId != null) {
                                    mediaElem = <Media mediaId={y.mediaId} thumb={true} thumbSize={ThumbSize.Small} app={app} />
                                } else if (y.galleryId != null) {
                                    const gallery = source.galleries.find((z) => z.id == y.galleryId)
                                    if (gallery?.media.length > 0) {
                                        mediaElem = gallery.media.slice(0, 3).map((z) => { return <Media mediaId={z.mediaId} thumb={true} thumbSize={ThumbSize.Small} app={app} /> })
                                    }
                                }
                                if (mediaElem != null) {
                                    return <div className="column page-content-media">{mediaElem}</div>
                                }
                            })}
                        </div>
                        }

                        <div className="bottom right page-content-options">
                            {!isStatic && <React.Fragment>
                                <IconButton noBg icon="fas fa-pen" onClick={() => handleEditContent(x)} />
                                {x.order > 0 && <IconButton noBg icon="fas fa-arrow-up" onClick={() => handleOrder(x, -1)} />}
                                {x.order < pageContent.length - 1 && <IconButton noBg icon="fas fa-arrow-down" onClick={() => handleOrder(x, 1)} />}
                                <IconButton noBg icon="fas fa-clone" onClick={() => handleCopy(x)} />
                                <IconButton noBg icon="fas fa-trash" onClick={() => handleDelete(x)} />
                            </React.Fragment>}
                            {isStatic && <IconButton noBg icon="fas fa-plus" onClick={() => handleAddContent(x)} />}
                        </div>

                    </div>
                })}
                <Button className="add-content" icon="fas fa-plus" tertiary onClick={() => handleAddPageContent()}>Add Content Manually</Button>
                <Button className="add-content" icon="fas fa-hand-sparkles" tertiary disabled={saving || editing} onClick={(e) => handleLLMHelper(e, LLMGenerateType.SingleContent)}>Add Content using AI</Button>
                <Button className="add-content" icon="fas fa-hand-sparkles" tertiary disabled={saving || editing} onClick={(e) => handleLLMHelper(e, LLMGenerateType.SocialMediaPost)}>Generate Social Media Post using AI</Button>
            </div>}
        </div>
    }


    if (show == null) {
        return
    }
    const editing = editContent || editPage


    let tabItems = [
        { value: EditorTab.Content, icon: "fas fa-pen", text: 'Content' },
        { value: EditorTab.Pages, icon: "fas fa-sticky-note", text: 'Pages' },
        { value: EditorTab.Blogs, icon: "fas fa-newspaper", text: 'Blogs' }
    ]

    return <React.Fragment>
        <div className={`inline-content-editor column${expanded ? ' expanded' : ''}${saving ? ' saving' : ''}`} key={tab} ref={setElem}>
            <ErrorBoundary>
                {LLMHelper && <LLMContentHelper {...LLMHelper} onSubmit={handleLLMHelperValue} onClose={handleLLMHelperClose} app={app} organization={organization} />}
                <div className="top right">
                    {/* {editing && <IconButton noBg icon={expanded ? "fas fa-compress" : "fas fa-expand"} onClick={() => setExpanded(!expanded)} />} */}
                    {(saving || (editing && pendingChange && data)) && <Button className="animate__animated animate__fadeInUp animate__fastest" onClick={() => handleSave(data)} tertiary={!saving}>
                        {saveText}
                        {saving && <Spinner showAfter={0} />}
                    </Button>}
                </div>
                <div className="top left">
                    {!editing && <IconButton noBg icon={"fas fa-times"} onClick={handleClose} />}
                    {editing && <IconButton noBg icon="fas fa-arrow-left" onClick={handleCancel} />}
                </div>
                {!editing && getContentSelector()}
                {editing && getEditor()}
                {/* <div className="row editor-options"> */}
                {/* {editContent && <Button onClick={handleCancel}>Cancel</Button>} */}
                {/* {editContent && <Button onClick={() => handleDelete(data)}>Delete</Button>} */}
                {/* {!editContent && <Button onClick={handleClose}>Close</Button>} */}
                {/* </div> */}
                {!editing && !nested && <div className="row editor-tabs">
                    <Toggle value={tab} onChange={setTab} items={tabItems} />
                </div>}
            </ErrorBoundary>
        </div>
        {/* {!nested && page && <InlineContentEditor nested={true} />} */}
    </React.Fragment>
}