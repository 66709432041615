import React, { useState } from 'react'
import { Icon } from './Icon'
import { Link } from 'react-router-dom'
import { Dict } from 'app/types'

interface ButtonProps {
    id: string,
    icon?: string,
    hoverIcon?: string,
    className?: string,
    large?: boolean,
    small?: boolean,
    secondary?: boolean,
    tertiary?: boolean,
    noBg?: boolean,
    underline: boolean,
    alt?: boolean,
    rounded?: boolean,
    fullWidth?: boolean,
    style?: Dict,
    onClick?: () => void,
    children?: JSX.Element[],
    noTab?: boolean,
    link: string,
    href: string,
    forwardRef: React.RefObject,
    listElement: boolean,
    counter: number,
    disabled: boolean
}

export function Button(props: ButtonProps) {
    const {
        className,
        large,
        small,
        onClick,
        icon,
        hoverIcon,
        secondary,
        tertiary,
        noBg,
        underline,
        alt,
        rounded,
        fullWidth,
        style,
        noTab,
        link,
        href,
        listElement,
        selectOption,
        forwardRef,
        counter,
        disabled,
        ...buttonProps
    } = props

    const [hover, setHover] = useState(null)

    function handleMouseEnter() {
        setHover(true)
    }

    function handleMouseLeave() {
        setHover(false)
    }

    function handleClick(e) {
        if (onClick) {
            onClick(e)
        }
    }

    function handleKeyPress(e) {
        if (onClick) {
            onClick(e)
        }
    }

    function getIcon() {
        if (hoverIcon != null) {
            return hover ? hoverIcon : icon
        }
        return icon
    }

    function getIconAnimation() {
        if (hoverIcon != null && hover != null) {
            return `animate__animated animate__fastest ${hover ? 'animate__rotateInNoFade' : 'animate__rotateOutNoFade'}`
        }
        return ''
    }

    const attrs = `${large ? ' button-large' : ''}${small ? ' button-small' : ''}${className ? ` ${className}` : ''}${icon ? ' with-icon' : ''}${secondary ? ' button-secondary' : ''}${tertiary ? ' button-tertiary' : ''}${fullWidth ? ' full-width' : ''}${alt ? ' alt' : ''}${noBg ? ' no-bg' : ''}${rounded ? ' rounded' : ''}${underline ? ' underline' : ''}${disabled ? ' disabled' : ''}`
    let buttonElem
    if (listElement) {
        buttonElem = <li tabIndex={noTab ? '-1' : '0'} className={`button noselect${attrs}`} style={style} onClick={handleClick} onKeyPress={handleKeyPress} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={forwardRef} {...buttonProps}>
            {icon && <Icon large={large} icon={getIcon()} className={getIconAnimation()} />}
            <span>
                {props.children}
            </span>
            {counter != null && counter > 0 && <div className="bottom-right counter">
                {counter}
            </div>}
        </li>
    } else {
        buttonElem = <button type="button" tabIndex={noTab ? '-1' : '0'} className={`button noselect${attrs}`} style={style} onClick={handleClick} onKeyPress={handleKeyPress} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} ref={forwardRef} {...buttonProps}>
            {icon && <Icon large={large} icon={getIcon()} className={getIconAnimation()} />}
            <span>
                {props.children}
            </span>
            {counter != null && counter > 0 && <div className="bottom-right counter">
                {counter}
            </div>}
        </button>
    }
    if (href) {
        return <a href={href} target="_blank" {...buttonProps}>
            {buttonElem}
            {counter != null && counter > 0 && <div className="bottom-right counter">
                {counter}
            </div>}
        </a>
    }
    if (link) {
        return <Link to={link}>{buttonElem}</Link>
    }
    return buttonElem
}
