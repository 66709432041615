import React, { useState, useEffect } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { Map, Icon } from 'components'
import { RootState, MarkerType, CustomEventType } from 'app/types'
import { getMediaLink } from 'helpers/media'
import { LocationLink } from './LocationLink'
import { ProjectDetails } from './ProjectDetails'
import { projectSubtitle } from 'helpers/project'
import { navigateAsync } from 'actions/appActions'
import { organizationMapStyles } from 'app/transformers'

interface ProjectMapProps {
    id: string,
    apps: AppData[],
    focusIds: string[],
    onProjectFocus: () => void,
    onProjectSelect: () => void,
    onOrganization: () => void,
    splitIndex?: number,
}
export function ProjectMap(props: ProjectMapProps) {
    const { id, apps, focusIds, onProjectFocus, onProjectSelect, onOrganization, splitIndex, ...otherProps } = { ...props }
    const config = useAppSelector((state: RootState) => state.app.config)
    const media = useAppSelector((state: RootState) => state.app.media)
    const favourites = useAppSelector((state: RootState) => state.user.favourites)
    const organization = useAppSelector((state: RootState) => state.app.organization)
    const dispatch = useAppDispatch()
    const [markers, setMarkers] = useState(null)

    useEffect(() => {
        window.addEventListener('hashchange', handleNavigateProject)
        return () => {
            window.removeEventListener('hashchange', handleNavigateProject)
        }
    }, [])

    useEffect(() => {
        if (apps == null) {
            return
        }
        const newMarkers = []
        // Add apps
        apps.forEach((x) => {
            const status = x.meta.projectStatusId ? config.projectStatuses[x.meta.projectStatusId].name : null
            if (x.meta.location != null && x.meta.location.latLng) {
                const favourite = x.meta.id in favourites
                const element = ReactDOMServer.renderToString(<div className="app-popup">
                    <div className="project-tile-preview">
                        <img src={getMediaLink(media[x.meta.mediaId]?.link, { app: x })} />
                        {status && <span className="project-tile-status">{status}</span>}
                    </div>
                    <div className="project-tile-details">
                        <div className="column">
                            <h5>{x.meta.name}</h5>
                            {x.meta.location && <div className="row location-wrapper">
                                <Icon noBg icon="fas fa-map-marker-alt" />
                                <LocationLink location={x.meta.location} minimal={true} />
                            </div>}
                            <span className="project-tile-detail">{projectSubtitle(x, config)}</span>
                            {/* <a href={`#${x.meta.link}`} className="project-tile-browse"><span className="row">Browse Community<Icon noBg icon="fas fa-chevron-right" /></span></a> */}
                            <a className="project-tile-browse"><span className="row">Browse Community<Icon noBg icon="fas fa-chevron-right" /></span></a>
                        </div>
                    </div>
                </div>)

                const [lat, lng] = x.meta.location.latLng.split(',').map((x) => parseFloat(x))
                newMarkers.push({
                    id: x.meta.id,
                    type: MarkerType.App,
                    position: { lat, lng },
                    value: { ...x },
                    focusCallback: onProjectFocus,
                    selectCallback: onProjectSelect,
                    element: element,
                    favourite: favourite,
                })
            }
        })


        // Add organizations
        /*let filteredOrganizations = null
        if (organization == null) {
            filteredOrganizations = config.organizations
        } else {
            filteredOrganizations = [organization]
        }
        filteredOrganizations.forEach((x) => {
            x.locations.forEach((y) => {
                if (y != null && y.latLng) {
                    const element = ReactDOMServer.renderToString(<div className="app-popup">
                        <div className="popup-preview">
                            <img src={getMediaLink(media[x.mediaId].link)}/>
                        </div>
                        <div className="popup-details">
                            <span>
                                <LocationLink location={y} minimal={true}/>
                            </span>
                        </div>
                    </div>)

                    const [lat, lng] = y.latLng.split(',').map((x) => parseFloat(x))
                    newMarkers.push({
                        id: x.id,
                        type: MarkerType.Organization,
                        position: { lat, lng },
                        value: x,
                        callback: onOrganization,
                        element: element,
                    })
                }
            })
        })*/
        setMarkers(newMarkers)
    }, [apps])

    function handleNavigateProject(e) {
        const hash = e.newURL.split('#')
        if (hash.length > 1) {
            const link = hash[1]
            const app = config.apps.find((x) => x.meta.link == link)
            if (app) {
                dispatch(navigateAsync({ app, options: { replaceHistory: true } }))
            }
        }
    }

    if (markers == null) {
        return null
    }

    const styles = organizationMapStyles(organization)

    return <Map id={id} organization={organization} markers={markers} hideMobile focusIds={focusIds} defaultZoom={10} isSplit={splitIndex != null} styles={styles} options={{ mapTypeControl: false, streetViewControl: false }} {...otherProps} />
}