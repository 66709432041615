import React, { useRef, useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import {
    navigateAsync,
    navigateBackAsync,
    stopCompare,
    setCompareMode,
    toggleMobileMode,
    parseNav,
} from 'actions/appActions'
import {
    TopBar,
    Button,
    BackButton,
    Split,
    ShareButton,
    Toggle,
    SideNavBar,
} from 'components'
import {
    RootState,
    AppData,
    ListStyle,
} from 'app/types'
import { ProjectPage } from 'views/HomePage/ProjectPage'

interface ComparePageProps {
    app: AppData
    apps: AppData[],
    onlyFavourites: boolean,
    excludeAppPath: boolean,
}

export function ComparePage(props: ComparePageProps) {
    const { app, apps, onlyFavourites, excludeAppPath } = props
    const dispatch = useAppDispatch()
    const navigation = useAppSelector((state: RootState) => state.app.navigation)
    const history = useAppSelector((state: RootState) => state.app.history)
    const current = useAppSelector((state: RootState) => state.app.current)
    const config = useAppSelector((state: RootState) => state.app.config)
    const standaloneApp = useAppSelector((state: RootState) => state.app.standaloneApp)
    const customDomain = useAppSelector((state: RootState) => state.app.customDomain)
    const organization = useAppSelector((state: RootState) => state.app.organization)
    const projectCurrent = useAppSelector((state: RootState) => state.app.projectCurrent)
    const compareMode = useAppSelector((state: RootState) => state.app.compareMode)
    const compareCommunities = useAppSelector((state: RootState) => state.app.compareCommunities)
    const mobileMode = useAppSelector((state: RootState) => state.app.mobileMode)
    const [compareElem, setCompareElem] = useState(null)
    const [compareWidth, setCompareWidth] = useState(0)
    const [refreshKey, setRefreshKey] = useState()
    const [splitElements, setSplitElements] = useState([])
    const [initialPick, setInitialPick] = useState(false)

    const [showListStyle, setShowListStyle] = useState(false)

    useEffect(() => {
        const navA = parseNav(navigation[0], organization, { organization, customDomain, standaloneApp, projectCurrent, config })
        const navB = parseNav(navigation[1], organization, { organization, customDomain, standaloneApp, projectCurrent, config })
        setShowListStyle(navA.appLink == null || navB.appLink == null)
    }, [navigation])

    useEffect(() => {
        if (compareElem) {
            setCompareWidth(compareElem.clientWidth)
        }
        setTimeout(() => {
            if (compareElem) {
                setCompareWidth(compareElem.clientWidth)
            }
        }, 100)
    }, [compareElem])

    useEffect(() => {
        const newElem = [null, null]
        for (let i = 0; i < 2; i += 1) {
            if (navigation[i] == null) {
                continue
            }
            const nav = parseNav(navigation[i], organization, { organization, customDomain, standaloneApp, projectCurrent, config })
            const otherNav = parseNav(navigation[1 - i], organization, { organization, customDomain, standaloneApp, projectCurrent, config })
            let skip = false
            if (i > 0 && otherNav.appLink == null && !initialPick) {
                newElem[i] = <h1 key={`select-${i}`} className="noselect">Select a {onlyFavourites ? 'floor plan' : 'community'}<br /><i className={`fas fa-arrow-${i == 0 ? 'right' : 'left'}`} /></h1>
                skip = true
            }
            if (i > 0 && !initialPick) {
                setInitialPick(true)
                if (skip) {
                    continue
                }
            }
            newElem[i] = <ProjectPage
                debug={i == 0}
                key={i}
                onlyFavourites={onlyFavourites}
                splitIndex={i}
                appLink={nav.appLink}
                pageLink={nav.pageLink}
                dataLink={nav.dataLink} />
        }
        setSplitElements(newElem)
    }, [navigation])

    function handleStopCompare() {
        dispatch(stopCompare())
        // If comparing apps, go back to search page
        if (apps) {
            dispatch(navigateAsync({ pageType: '', options: { replaceHistory: true } }))
        }
    }

    function handleMobileMode() {
        dispatch(toggleMobileMode())
    }

    // Not initialize
    if (navigation.length === 1 || navigation[1] === null) {
        return null
    }
    const contentStyle = {
        maxWidth: `calc(100% - ${compareWidth}px`
    }
    if (compareElem) {
        contentStyle.maxWidth = `calc(100% - ${compareElem.clientWidth}px`
    }

    return <div id="compare-view" className={`row fadeIn ${compareCommunities ? ' communities' : ''}`}>
        <div className="column content-column" style={contentStyle}>
            <Split>
                {splitElements}
            </Split>
        </div>
        <SideNavBar
            app={projectCurrent[0]}
            forwardRef={setCompareElem}
            onlyFavourites={onlyFavourites}
            topOptions={<Button className="stop" icon="fas fa-times" tertiary onClick={handleStopCompare}>
                Stop Compare
            </Button>}
            bottomOptions={(showListStyle && !onlyFavourites) ? <Toggle items={[
                {
                    text: 'Map',
                    value: ListStyle.Map,
                },
                {
                    text: 'List',
                    value: ListStyle.Grid,
                },
            ]}
                value={mobileMode}
                onChange={handleMobileMode} /> : null
            }
            apps={projectCurrent}
            selected={compareMode} />

        {/*<div className="column nav-column side-nav-bar animate__animated animate__slideInRight animate__faster" ref={setCompareElem}>
            <div className="side-nav-options">
                <Button className="stop" icon="fas fa-times" tertiary onClick={handleStopCompare}>
                    Stop Compare
                </Button>
            </div>
            {!onlyFavourites && <div className="project-feature-bar">
                <ProjectFeatures apps={projectCurrent} selected={compareMode} />
            </div>}
            <div className="side-nav-options end">
                {showListStyle && !onlyFavourites && <Toggle items={[
                    {
                        text: 'Map',
                        value: ListStyle.Map,
                    },
                    {
                        text: 'List',
                        value: ListStyle.Grid,
                    },
                ]}
                    value={mobileMode}
                    onChange={handleMobileMode} />}
                <ShareButton text="Share View" />
            </div>
            </div>*/}
    </div>
}
