import React, { useEffect, useRef, useState } from 'react'
import {
    PromptType,
    UpgradeOptionData, Visibility,
} from 'app/types'
import {
    Media, Icon, IconButton, Button
} from 'components'
import * as fnc from 'helpers/fnc'
import { useAppDispatch, useAppSelector, useSessionStorage } from 'app/hooks'
import { getPackageName, getPrice, getPricingUpdateMessage, getRelativePackage, selectionKey } from './upgrades'
import { Input } from 'components/Core/Input'
import { showPrompt } from 'actions/appActions'

function orderSort(a, b) {
    return a.order - b.order
}

interface UpgradePackageProps {
    app,
    option: Dict,
    group: Dict,
    maps: Dict,
    selection: Dict,
    customizations: Dict,
    drillable: boolean,
    ix: number,
    spec: Dict,
    orderPackage: Dict,
    orderPackages: Dict[],
    handleOrder,
    selectOption,
    onChange: () => void,
    onPackage: () => void,
    onPackageReset: () => void,
    onProduct: () => void,
    onProductValue: () => void,
    onInfo: () => void,
    showPricing: boolean,
    admin: boolean,
    disableChanges: boolean,
    readOnly: boolean,
}

export function UpgradePackage(props: UpgradePackageProps) {
    const {
        app,
        group,
        maps,
        customizations,
        ix,
        handleOrder,
        orderPackage,
        orderPackages,
        selectOption,
        onChange,
        onPackage,
        onPackageReset,
        onProduct,
        onProductValue,
        showPricing,
        admin,
        onInfo,
        disableChanges,
        readOnly,
        spec,
    } = {
        ix: -1,
        orderPackage: null,
        orderPackages: null,
        ...props,
    }
    const { option, optionVariation: variation, component, pckge } = spec
    if (!pckge) {
        return <div className="upgrade-product">
            <h3> Missing Package</h3>
        </div>
    }

    function checkFiltered(upgradeOptionId) {
        return !(group.id in maps.groupPackageOptionFilter && pckge.id in maps.groupPackageOptionFilter[group.id])
            || upgradeOptionId in maps.groupPackageOptionFilter[group.id][pckge.id]
    }

    const dispatch = useAppDispatch()
    const media = useAppSelector((state: RootState) => state.app.media)
    const [editData, setEditData] = useState(null)
    const [editing, setEditing] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const [optionMap, setOptionMap] = useState(null)
    const priceCache = useRef(null)
    const lastCache = useRef(null)

    const key = selectionKey(group, option, variation, component)
    const selection = key in props.selection ? props.selection[key].find((x) => x?.product?.upgradePackageId == pckge.id) : null
    const selected = selection != null
    const selectIcon = selected ? 'fas fa-check' : 'fas fa-circle'

    const relativePackage = getRelativePackage(maps, pckge)
    let priceConfig = null
    const sinceCache = Date.now() - (lastCache.current || 0)
    if (priceCache.current && sinceCache < 1000) {
        priceConfig = priceCache.current
    } else {
        priceConfig = getPrice(maps, spec, { flatten: true, customizations, group, debug: true, relative: true, selection, admin, usePackage: true, relativePackage })
        priceCache.current = priceConfig
    }
    let { price, priceUnit, quantity, config, calculation, currentPrice, currentPriceUnit } = priceConfig
    const pricingChanged = selected && (price != 0 && currentPrice != null && currentPrice != fnc.roundCents(price))
    let priceElem = null
    const pricingChangedElem = pricingChanged ? <Button tertiary icon="fas fa-exclamation-triangle" onClick={(e) => { e.stopPropagation(); handlePriceUpdate() }}>Outdated pricing</Button> : null
    if (showPricing) {
        if (price == 0) {
            priceElem = ""//'Please inquire'
        } else if (quantity) {
            priceElem = <div className="column quantity-pricing">
                <span>{fnc.toMoney(price)}</span>
            </div>
        } else {
            priceElem = fnc.toMoney(price)
        }
    } else {
        priceElem = null//'Please inquire'
    }
    if (pricingChangedElem) {
        priceElem = <span>{pricingChangedElem}{priceElem}</span>
    }
    let galleryCount = 0
    let galleryMedia = []

    const customized = pckge.id in customizations && Object.keys(customizations[pckge.id]).length > 0


    const packageName = getPackageName(maps, pckge, option)
    const gallerySet = new Set()

    pckge.products.forEach((x) => {
        let product = maps.product[x.upgradeProductId]
        let productOption = maps.option[x.upgradeOptionId]
        if (productOption?.hidden) {
            return
        }
        let productOptionVariation = productOption?.variations[0]
        let productOptionComponent = productOptionVariation?.components[0]
        let keyB = selectionKey(group, productOption, productOptionVariation, productOptionComponent)
        let custom = false

        // Check that this product option exists for this group


        // Ensure not filtered
        if (group.id in maps.groupPackageOptionFilter
            && pckge.id in maps.groupPackageOptionFilter[group.id]
            && !(x.upgradeOptionId in maps.groupPackageOptionFilter[group.id][pckge.id])) {
            return
        }

        // Check if this product is filtered
        // if (!checkFiltered(x.upgradeOptionId)) {
        // return
        // }

        if (pckge.id in customizations && keyB in customizations[pckge.id]) {
            product = maps.product[customizations[pckge.id][keyB]]
            custom = true
        }

        if (product && product.media.length > 0 && !gallerySet.has(product.media[0])) {
            galleryMedia.push({ media: product.media[0], custom })
            gallerySet.add(product.media[0])
        }

        if (product.products.length > 0) {
            product.products.forEach((y) => {
                const prod = maps.product[y.childUpgradeProductId]
                if (prod && prod.media.length > 0 && !gallerySet.has(prod.media[0])) {
                    galleryMedia.push({ media: prod.media[0] })
                    gallerySet.add(prod.media[0])
                }
            })
        }
        // Unique gallery media only

        /*jconst key = selectionKey(group, option, null, product)
        if (!(key in selection)) {
            selected = false
        }*/
    })

    if (galleryMedia.length > 4) {
        galleryCount = Math.max(1, Math.min(Math.floor((galleryMedia.length) / 2) * 2, 8))
    } else {
        galleryCount = galleryMedia.length
    }
    /* if (drillable) {
        component.products.forEach((x) => {
            const product = maps.product[x.upgradeProductId]
            if (product && product.media.length > 0) {
                galleryMedia.push(product.media[0])
            }
        })
        galleryCount = Math.max(1, Math.min(Math.floor((galleryMedia.length) / 3) * 3, 6))
    } */

    let orderElem = null
    if (onChange && ix != -1 && orderPackages) {
        orderElem = <div className="top-left order-wrapper">
            {ix > 0 && <IconButton className="order-button" icon="fas fa-chevron-up" onClick={(e) => { e.stopPropagation(); handleOrder(orderPackages, orderPackage, ix - 1) }} />}
            {ix < orderPackages.length - 1 && <IconButton className="order-button" icon="fas fa-chevron-down" onClick={(e) => { e.stopPropagation(); handleOrder(orderPackages, orderPackage, ix + 1) }} />}
        </div>
    }

    let note = null
    if (selected && 'note' in selection) {
        note = selection.note
    }

    useEffect(() => {
        const newOptionMap = {}
        pckge.products.forEach((x) => {
            const option = maps.option[x.upgradeOptionId]
            const key = selectionKey(group, option, option?.variations[0], option?.variations[0]?.components[0])
            newOptionMap[key] = x.upgradeProductId
        })
        setOptionMap(newOptionMap)
    }, [pckge])

    function handleDrillDown(x) {
        if (selectOption) {
            selectOption(pckge, option)
        }
    }

    function handleNote(x) {
        setEditData({ ...editData, note: x })
    }

    function handlePrice(x) {
        setEditData({ ...editData, price: parseFloat(x) })
    }

    function handleReset(e) {
        e.stopPropagation()
        onPackageReset(group, pckge)
    }

    function handleEditProduct(bool) {
        if (bool) {
            setEditData({ note: note, quantity: quantity, price: priceUnit })
            setEditing(true)
        } else {
            const optionVariation = option?.variations[0]
            if (editData.note != null) {
                onProductValue(group, option, optionVariation, component, null, 'note', editData.note, pckge)
            }

            if (editData.quantity != null) {
                onProductValue(group, option, optionVariation, component, null, 'quantityOverride', editData.quantity, pckge)
            }

            if (editData.price != null) {
                if (editData.price == '' || isNaN(editData.price)) {
                    onProductValue(group, option, optionVariation, component, null, 'priceOverride', null, pckge)
                } else {
                    onProductValue(group, option, optionVariation, component, null, 'priceOverride', editData.price, pckge)
                }
            }
            setEditing(false)
        }
    }

    function removeConfiguration() {
        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Remove Configuration?', message: 'Cannot be undone' }))
            .then((x) => {
                if (x.payload) {
                    const optionVariation = option?.variations[0]
                    setEditing(false)
                    onProductValue(group, option, optionVariation, component, null, 'note', null, pckge)
                    onProductValue(group, option, optionVariation, component, null, 'priceOverride', null, pckge)
                }
            })
    }

    function togglePackage() {
        if (selected) {
            if (pckge.configurable) {
                selectOption(pckge, option)
            }
        } else if (!disableChanges) {
            onPackage(group, option, variation, component, pckge)
        }
    }

    function handleInfo(e) {
        e.stopPropagation()
        const variation = option.variations[0]
        const component = variation.components[0]
        onInfo(group, option, variation, component, { upgradePackageId: pckge.id })
    }

    function handlePriceUpdate(x) {
        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Update Price', message: getPricingUpdateMessage(price, 0, currentPrice, 0), confirmMessage: 'Update Pricing' }))
            .then((x) => {
                if (x.payload) {
                    onPackage(group, option, variation, component, pckge, true)
                }
            })

    }

    // function getChildProducts() {
    //     if (!expanded) {
    //         return
    //     }

    //     const childProducts = [...pckge.products].sort(orderSort).map((x, idx) => {
    //         if (!x) {
    //             return
    //         }
    //         const nestedOption = maps.option[x.upgradeOptionId]
    //         let nestedProduct = maps.product[x.upgradeProductId]
    //         let nestedOrderElem = null
    //         const customized = key in customizations[pckge.id]

    //         // Check if option is overriden in selection
    //         const key = selectionKey(nestedOption, nestedOption?.variations[0], nestedOption?.variations[0]?.components[0])
    //         if (key in selection && selection[key].length > 0) {
    //             const product = selection[key][0].product
    //             if (product != nestedProduct) {
    //                 nestedProduct = product
    //             }
    //         } else if (customized) {
    //             nestedProduct = maps.product[customizations[pckge.id][key]]
    //         }

    //         if (onChange) {
    //             nestedOrderElem = <div className="bottom-right order-wrapper">
    //                 {idx > 0 && <IconButton className="order-button" icon="fas fa-chevron-up" onClick={(e) => { e.stopPropagation(); handleOrder(pckge.products, x, idx - 1) }} />}
    //                 {idx < pckge.products.length - 1 && <IconButton className="order-button" icon="fas fa-chevron-down" onClick={(e) => { e.stopPropagation(); handleOrder(pckge.products, x, idx + 1) }} />}
    //             </div>
    //         }

    //         return <div className={`upgrade-product nested row ${customized ? ' customized' : ''}`} key={`${x.upgradeProductId}-${idx}`} onClick={(e) => { e.stopPropagation(); onInfo(group, nestedOption, null, null, nestedProduct) }}>
    //             <div className="upgrade-product-wrapper clickable">
    //                 <div className="column">
    //                     <div className="row"><h4>{nestedOption.name}</h4>{customized && <Icon noBg icon="fas fa-asterisk" className="customized-icon" />}</div>
    //                     <h5>{nestedProduct.name}</h5>
    //                     <span>{nestedProduct.description}</span>
    //                 </div>
    //                 <div className="column">
    //                     <div className="upgrade-product-media">
    //                         {nestedProduct.media.length > 0 && <Media thumb={true} thumbSize={128} key={`${nestedProduct.id}-media`} fadeIn app={app} mediaId={nestedProduct.media[0]} />}
    //                     </div>
    //                     {nestedOrderElem}
    //                 </div>
    //             </div>

    //             {admin && selected && pckge.configurable && <div className="arrow-row" onClick={(e) => {
    //                 e.stopPropagation()
    //                 handleDrillDown(nestedOption, nestedProduct)
    //             }}>
    //                 <Button icon="fas fa-chevron-right">Customize</Button>
    //             </div>}
    //         </div>
    //     })
    //     if (childProducts) {
    //         return <div className="row child-products" onClick={(e) => e.stopPropagation()}>
    //             {childProducts}
    //         </div>
    //     }
    // }

    if (!selected && (disableChanges || readOnly)) {
        return null
    }


    const editorOptions = editing ? <td rowSpan={component.configureQuantity ? 3 : 2} style={{ width: '40px' }}>
        <div className="column">
            <IconButton icon="fas fa-check" noBg onClick={() => handleEditProduct(false)} />
            <IconButton icon="fas fa-times" noBg onClick={() => setEditing(false)} />
            <IconButton noBg icon="fas fa-trash-alt" onClick={removeConfiguration} />
        </div>
    </td> : null

    const editable = selected && !editing && admin

    return <div className={`upgrade-package upgrade-product drillable ${pckge.configurable && !disableChanges ? '' : 'disabled'}${selected ? ' selected' : ' clickable'}${selected && customized ? ' customized' : ''}${disableChanges ? ' disable-changes' : ''}${editable ? ' editable' : ''}`} key={key} onClick={togglePackage}>
        <div className="row upgrade-product-info">
            <div className="upgrade-product-wrapper clickable">
                <div className="upgrade-product-details">
                    <span className="noselect">{packageName}</span>
                    {/* {priceElem && <span className="upgrade-product-price">{priceElem}</span>} */}
                    {selected && customized && <Icon noBg icon="fas fa-asterisk" className="customized-icon" />}
                    {(!pckge.adminOnlyDetails || admin) && <IconButton tertiary icon="fas fa-info" onClick={handleInfo} />}
                    {editable && <div className="upgrade-product-options bottom-right">
                        <IconButton alt icon="fas fa-pen" onClick={(e) => { e.stopPropagation(); handleEditProduct(true); }} />
                    </div>}
                </div>
                <div className="column">
                    <div className="upgrade-package-media row">
                        {galleryMedia.length > 0 && <div className={`product-gallery images-${galleryCount}`}>
                            {galleryMedia.splice(0, galleryCount).map((z, iz) => <div className="product-gallery-wrapper" key={iz}>
                                <Media thumb={true} thumbSize={galleryCount > 1 ? 128 : 256} fadeIn app={app} mediaId={z.media} />
                                {z.custom && <Icon noBg icon="fas fa-asterisk" className="customized-icon" />}
                            </div>)}
                        </div>}
                    </div>
                    {priceElem && <span className="upgrade-product-price row">{priceElem}</span>}
                </div>
                <div className="check" key={selectIcon}><Icon icon={selectIcon} className="animate__animated animate__zoomIn animate__fastest" /></div>
                {orderElem}

                {/*<IconButton tertiary className="expand-row row" onClick={(e) => {
                    e.stopPropagation()
                    setExpanded(!expanded)
                }} icon={expanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down'} />*/}
            </div>
            {/* drillable && <div className="arrow-column grid" onClick={() => selectOption(option, component)}>
                <div className={`product-gallery images-${galleryCount}`}>
                    {galleryMedia.splice(0, galleryCount).map((z, iz) => {
                        return <div className="product-gallery-wrapper" key={iz}>
                            <Media thumb={true} thumbSize={galleryCount > 1 ? 128 : 256} fadeIn app={app} mediaId={z} />
                        </div>
                    })}
                </div>
                <Button tertiary>See More</Button>
            </div> */}
            {/* drillable && <div className="arrow-column" onClick={null}>
                <Icon icon="fas fa-chevron-right"/>
            </div> */}
            {!editing && note != null && <div className='upgrade-product-note' onClick={(e) => e.stopPropagation()}>
                <span>
                    {note}
                </span>
            </div>}
        </div>

        {selected && pckge.configurable && <div className="arrow-row">
            <Button icon="fas fa-chevron-right">{disableChanges ? 'Details' : 'Customize'}</Button>
            {customized && !(readOnly || disableChanges) && <Button icon="fas fa-undo" onClick={handleReset}>Reset</Button>}
        </div>}

        {editing && <React.Fragment>
            <div className="row upgrade-product-editor" onClick={(e) => e.stopPropagation()}>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <span>
                                    Price
                                </span>
                            </td>
                            <td>
                                <Input price value={editData?.price} onChange={handlePrice} onClick={(e) => e.stopPropagation()} />
                            </td>
                            {editorOptions}
                        </tr>
                        <tr>
                            <td>
                                <span>
                                    Note
                                </span>
                            </td>
                            <td>
                                <Input textarea value={editData.note} onChange={handleNote} onClick={(e) => e.stopPropagation()} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </React.Fragment>}
        {/* {getChildProducts()} */}
    </div>
}
