import React, { useState, forwardRef, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { CustomEventType, Dict, MediaType, ScreenSize, ThumbSize } from 'app/types'
import { getMediaSelector, getMediaLinkSelector, useAppSelector, usePreviewMedia } from 'app/hooks'
import { Spinner, Icon, IconButton, MediaIcon } from 'components'
import Player from '@vimeo/player'
import { getVimeoJson } from 'services/appServices'
import { getOptionString } from 'helpers/requestHandler'
import * as fnc from 'helpers/fnc'
import { logger } from 'helpers/logger'
import { icons } from 'app/constants'

const defaultProps = {
    mediaTypeId: MediaType.Image,
    thumb: false,
    thumbSize: ThumbSize.Medium,
    cropped: false,
    fadeIn: false,
    fadeInDelay: 0,
    fadeInStyle: {},
    spinner: false,
    autoplay: false,
}

const HOVER_PADDING = 20
const CLICK_DRAG_THRESHOLD = 50
export function MediaHover({ position, name, url }) {
    const [opacity, setOpacity] = useState(0);
    const [isMax, setIsMax] = useState(false);

    useEffect(() => {
        setTimeout(() => setOpacity(1), 500);
    }, []);

    if (!url) return null;

    const hoverContent = (
        <div 
            className="media-hover" 
            style={{
                position: 'absolute',
                left: `${position.x}px`,
                top: `${position.y}px`,
                opacity,
                transform: isMax 
                    ? `translate(calc(-100% - 20px), 0)` 
                    : `translate(20px, ${(0 - 100 * position.y / window.innerHeight)}%)`
            }}
        >
            <img src={url} alt={name} style={{ maxWidth: '100%', maxHeight: '100%' }} />
            {name && <span>{name}</span>}
        </div>
    );

    return ReactDOM.createPortal(hoverContent, document.body);
    // return createPortal(hoverContent, document.body);
}
// export function MediaHover(props: MediaHoverProps) {
//     const { position, name, url } = props
//     const [opacity, setOpacity] = useState(0)
//     const [isMax, setIsMax] = useState(false)
//     const element = useRef()
//     const img = useRef()

//     useEffect(() => {
//         setTimeout(() => {
//             setOpacity(1)
//         }, 500)
//     }, [])

//     function handleMouseOver(e) {
//         e.stopPropagation()
//     }

//     useEffect(() => {
//         if (!url) {
//             return
//         }

//         const app = document.getElementById('app')
//         element.current = document.createElement('div')
//         element.current.addEventListener('mouseover', handleMouseOver)
//         element.current.className = 'media-hover'

//         img.current = document.createElement('img')
//         img.current.src = url
//         element.current.appendChild(img.current)

//         if (name) {
//             const nameTag = document.createElement('span')
//             nameTag.innerHTML = name
//             element.current.appendChild(nameTag)
//         }

//         app.appendChild(element.current)

//         return () => {
//             element.current.remove()
//             element.current = null
//         }
//     }, [url])

//     useEffect(() => {
//         if (!element.current) {
//             return
//         }

//         element.current.style.position = 'absolute'
//         element.current.style.left = `${position.x}px`
//         element.current.style.top = `${position.y}px`
//         element.current.style.opacity = opacity

//         let transX = '20px'
//         let transY = `${(0 - 100 * position.y / window.innerHeight)}%`
//         if (position.x > window.innerWidth * 0.5) {
//             transX = 'calc(-100% - 20px)'
//         }
//         if (!isMax) {
//             element.current.style.transform = `translate(${transX}, ${transY})`
//         } else {
//             element.current.style.transform = `translate(${transX}, 0)`
//         }

//         const maxHeight = window.innerHeight - HOVER_PADDING * 2
//         if (isMax || img.current.offsetHeight > maxHeight) {
//             setIsMax(true)
//             const scale = maxHeight / element.current.offsetHeight
//             const height = img.current.offsetHeight
//             const width = img.current.offsetWidth

//             img.current.style.maxWidth = '100%'
//             img.current.style.maxHeight = '100%'

//             element.current.style.height = `${height * scale}px`
//             element.current.style.width = `${width * scale}px`
//             element.current.style.top = `${HOVER_PADDING}px`
//             element.current.setAttribute('data-fullscreen', 1)
//         }

//     }, [position, element.current, opacity])

//     return null
// }


interface VideoControlsProps {
    iframe,
    elem,
    options,
    mobile,
}

function VideoControls(props: VideoControlsProps) {
    const { iframe, elem, options, mobile } = props
    const [paused, setPaused] = useState(!options.autoplay)
    const [muted, setMuted] = useState(options.muted)
    const [seek, setSeek] = useState(0)
    const [hoverSeek, setHoverSeek] = useState(0)
    const [seeking, setSeeking] = useState(false)
    const [hovering, setHovering] = useState(false)
    const [player, setPlayer] = useState(null)
    const [duration, setDuration] = useState(0)
    const [clickStart, setClickStart] = useState([0, 0])
    const seekRef = useRef()

    useEffect(() => {
        if (!iframe) {
            return
        }
        // Pause iframe on window event
        const newPlayer = new Player(iframe, options)
        function handlePause() {
            setTimeout(() => {
                setPaused(true)
            }, 100)
            setPaused(true)
        }
        setPlayer(newPlayer)

        newPlayer.getDuration().then(setDuration)
        window.addEventListener(CustomEventType.PauseVideo, handlePause)
        return () => {
            window.removeEventListener(CustomEventType.PauseVideo, handlePause)
        }
    }, [iframe])

    useEffect(() => {
        if (!player) {
            return
        }
        const interval = setInterval(() => {
            player.getCurrentTime().then((time) => {
                setSeek(time / duration)
            })
        }, 100)
        return () => {
            clearInterval(interval)
        }
    }, [player, seek])

    useEffect(() => {
        if (!player) {
            return
        }
        if (seeking) {
            function clearSeek() {
                setSeeking(false)
            }
            window.addEventListener('mouseup', clearSeek)
            return () => {
                window.removeEventListener('mouseup', clearSeek)
            }
        }

    }, [player, seeking])

    useEffect(() => {
        if (!player) {
            return
        }
        player.getPaused().then((playerPaused) => {
            if (paused && !playerPaused) {
                player.pause()
            } else if (!paused && playerPaused) {
                player.play()
            }
        })
    }, [player, paused])

    useEffect(() => {
        if (!player) {
            return
        }
        player.getVolume().then((playerVolume) => {
            if (!muted && playerVolume == 0) {
                player.setVolume(1)
            } else if (muted && playerVolume == 1) {
                player.setVolume(0)
            }
        })
    }, [player, muted])

    function togglePaused(e, swipeAware = false) {
        e.stopPropagation()
        if (swipeAware) {
            const dif = Math.sqrt(Math.pow(e.pageX - clickStart[0], 2) + Math.pow(e.pageY - clickStart[1], 2))
            if (dif < 20) {
                setPaused(!paused)
            }
        } else {
            setPaused(!paused)
        }
    }

    function toggleFullscreen() {
        fnc.toggleFullscreen(elem)
    }

    function toggleVolume(e) {
        e.stopPropagation()
        setMuted(!muted)
    }
    function calcSeek(e) {
        const rect = seekRef.current.getBoundingClientRect()
        const appElement = document.getElementById('app')
        const zoom = fnc.getRootProperty('--zoom', appElement)

        const dif = (e.pageX - (rect.x + 20)) / (rect.width - 40)
        return dif / zoom
    }

    function handleMouseDown(e) {
        e.stopPropagation()
        if (!player || !seekRef.current) {
            return
        }
        setSeeking(true)
        const seek = calcSeek(e)
        setSeek(seek)
        player.setCurrentTime(`${Math.floor(duration * seek * 10) / 10}`)
    }
    function handleMouseMove(e) {
        if (!seekRef.current) {
            return
        }
        const newSeek = Math.max(0, Math.min(100, calcSeek(e)))
        if (seeking) {
            setSeek(newSeek)
            player.setCurrentTime(`${Math.floor(duration * newSeek * 10) / 10}`)
            setHoverSeek(newSeek)
        } else {
            setHoverSeek(newSeek)
        }
    }

    function disableMouseDown(e) {
        e.stopPropagation()
    }
    function handlePauseMouseDown(e) {
        setClickStart([e.pageX, e.pageY])
    }


    if (!iframe || !player) {
        return null
    }

    let seekDotStyle = {}
    let seekFrontStyle = {}
    if (seekRef.current) {
        seekFrontStyle = {
            width: `${(seekRef.current.clientWidth - 40) * seek}px`
        }
        seekDotStyle = {
            left: `${(seekRef.current.clientWidth - 40) * (hovering ? hoverSeek : seek) + 20}px`,
            transition: (hovering) ? 'none' : 'all linear 100ms',
        }
    }

    return <div className='video-media-controls'>
        {paused && <MediaIcon type={MediaType.Video} />}
        <div className="row video-media-pause" style={{ height: '100%' }} onClick={(e) => togglePaused(e, true)} onMouseDown={handlePauseMouseDown} />
        <div className="row video-media-options">
            <IconButton noBg icon={`${paused ? icons.play : icons.pause}`} onClick={togglePaused} onMouseDown={disableMouseDown} />
            <div className="seek" onMouseDown={handleMouseDown} onMouseMove={handleMouseMove} onMouseEnter={() => setHovering(true)} onMouseLeave={() => setHovering(false)} ref={seekRef}>
                <div className='seek-back' />
                <div className='seek-front' style={seekFrontStyle} />
                <div className='seek-dot' style={seekDotStyle} />
            </div>
            {!mobile && <div style={{ display: 'flex', flexDirection: 'row' }} onMouseDown={disableMouseDown}>
                <IconButton noBg icon={`${muted ? icons.volumeMute : icons.volumeUp}`} onClick={toggleVolume} />
                <IconButton noBg icon={icons.expand} onClick={toggleFullscreen} />
            </div>}
        </div>
    </div >
}

interface MediaProps {
    mediaId: string,
    thumb?: boolean,
    thumbSize?: ThumbSize,
    thumbMediaId?: string,
    cropped?: boolean,
    className?: string,
    style?: Dict,
    onClick?: () => void,
    onLoad?: () => void,
    fadeIn?: boolean,
    fadeInDelay?: number,
    fadeInStyle?: {},
    app: AppData,
    builder: BuilderData,
    organization: OrganizationData,
    fallback: boolean,
    hoverShow: boolean,
    url: string,
    debug: boolean,
    fit: string,
    autoplay: boolean,
    focus: boolean,
}

export const Media = forwardRef((props: MediaProps, ref) => {
    const {
        mediaTypeId: A,
        mediaId: B,
        autoplay: C,
        thumb,
        thumbSize,
        thumbMediaId,
        cropped,
        className,
        style,
        onClick,
        onLoad,
        fadeIn,
        fadeInDelay,
        fadeInStyle,
        spinner,
        app,
        builder,
        organization,
        fallback,
        hoverShow,
        url: forceUrl,
        debug,
        fit,
        focus,
        ...otherProps

    } = { ...defaultProps, ...props }

    const getMedia = getMediaSelector()
    const getMediaLink = getMediaLinkSelector()
    const [loaded, setLoaded] = useState(0)
    const [url, setUrl] = useState(null)
    const [originalUrl, setOriginalUrl] = useState(null)
    const [usingFallback, setUsingFallback] = useState(false)
    const [hovering, setHovering] = useState(null)
    const [elem, setElem] = useState(null)
    const [iframe, setIframe] = useState(null)
    const [showThumbnail, setShowThumbnail] = useState(thumb)
    // const [videoSource, setVideoSource] = useState(null)
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const previewMedia = usePreviewMedia()
    const mouseStart = useRef(null)

    const [videoOptions, setVideoOptions] = useState({})

    const mobile = screen.isMobile

    

    let mediaTypeId = MediaType.Image
    let mediaId = props.mediaId
    let media = null
    let name = null
    if (mediaId) {
        media = getMedia(mediaId)
        if (media) {
            mediaTypeId = media.mediaTypeId
        }
        if (mediaTypeId == MediaType.Tour || mediaTypeId == MediaType.Spin) {
            media = previewMedia(app, media)
        }
        if (media) {
            name = media.name
            mediaId = media.id
        } else {
            logger.info("Media not found", mediaId, props)
        }
    }

    useEffect(() => {
        setShowThumbnail(thumb)
    }, [thumb])

    // Set by url prop
    useEffect(() => {
        setLoaded(0)
        if (forceUrl != null) {
            setUrl(forceUrl)
            if (url == null) {
                setOriginalUrl(forceUrl)
            }
        }
    }, [forceUrl])

    useEffect(() => {
        if (!url) {
            return
        }

        if (mediaTypeId == MediaType.Video) {
            setLoaded(2)
        }
    }, [url])


    // Set by media prop
    useEffect(() => {
        if (!mediaId) {
            return
        }
        if (mediaTypeId == MediaType.Image || mediaTypeId == MediaType.EmailResource || (showThumbnail && thumb)) {
            let newUrl = getMediaLink(mediaId, {
                thumb,
                thumbSize,
                thumbMediaId,
                suffix: cropped ? '_cropped' : '',
                app,
                builder,
                organization,
                mediaTypeId,
            })
            if (url != newUrl) {
                setLoaded(0)
                setUrl(newUrl)
                if (url == null) {
                    setOriginalUrl(newUrl)
                }
            }
        } else {
            const newVideoOptions = {
                muted: mobile,
                autoplay: focus,
                background: 1,
                // controls: 0,
                autopause: 1,
            }
            let newUrl = null
            if (mediaTypeId == MediaType.Video) {
                if (media.link.includes('youtube')) {
                    newUrl = `${media.link}${getOptionString({ ...newVideoOptions, muted: newVideoOptions.muted ? 1 : 0 })}`
                } else {
                    newUrl = `${media.link}${getOptionString({ ...newVideoOptions, muted: 1, autoplay: 0 })}`
                    newVideoOptions.muted = true
                }
            } else {
                newUrl = media.link
            }

            setUrl(newUrl)
            setOriginalUrl(url)
            setVideoOptions(newVideoOptions)
        }
    }, [mediaId, thumb, thumbSize, showThumbnail])


    if (!media && !forceUrl) {
        return null
    }

    function handleElem(x) {
        setElem(x)
        if (ref && typeof ref == 'function') {
            ref(x)
        } else if (ref && typeof ref == 'object' && ref.current) {
            ref.current = x
        }
    }

    function handleLoad(e) {
        if (fadeIn && loaded == 0) {
            // Slightly convoluted loading to ensure spinner
            if (spinner) {
                setTimeout(() => {
                    // Fade out spinner
                    setLoaded(1)
                    setTimeout(() => {
                        // Unmount spinner
                        setLoaded(2)
                    }, 200)
                }, fadeInDelay)
            } else {
                setTimeout(() => {
                    setLoaded(2)
                }, fadeInDelay)
            }
        } else {
            setLoaded(2)
        }

        if (onLoad) {
            onLoad(e, true)
        }
    }

    function handleError(e) {
        // logger.error("Error loading media", e)
        if (fallback && !usingFallback && mediaId) {
            setUsingFallback(true)
            const fullImage = getMediaLink(mediaId, { app })
            setUrl(fullImage)
        } else {
            const missingImage = getMediaLink('missing')
            setUrl(missingImage)
        }
    }

    function handleClick(e) {
        const current = { x: e.pageX, y: e.pageY }
        let distance = 0
        if (mouseStart.current) {
            distance = Math.sqrt(Math.pow(current.x - mouseStart.current.x, 2) + Math.pow(current.y - mouseStart.current.y, 2))
        }
        // Only propogate if not a significant drag
        if (distance > CLICK_DRAG_THRESHOLD) {
            return
        }
        if (onClick) {
            onClick(e)
        }

        if (mediaTypeId == MediaType.Video && showThumbnail && !onClick) {
            setShowThumbnail(false)
        }
    }

    function handleMouseStart(e) {
        mouseStart.current = { x: e.pageX, y: e.pageY }
    }

    function handleMouseDown(e) {
        setHovering(null)
    }

    function handleMouseOver(e) {
        setHovering({ x: e.pageX, y: e.pageY })
    }
    function handleMouseMove(e) {
        setHovering({ x: e.pageX, y: e.pageY })
    }
    function handleMouseLeave(e) {
        setHovering(null)
    }


    let finalStyle = style !== null ? style : {}
    let fadeClass = 'show'
    if (fadeIn) {
        fadeClass = `${loaded > 0 ? ' show' : ' hide'}`
        if (fadeInStyle) {
            finalStyle = { ...finalStyle, ...fadeInStyle }
        }
    }

    let mediaElement = null
    if (url) {
        if (mediaTypeId == MediaType.Image || showThumbnail) {
            if (showThumbnail && mediaTypeId != MediaType.Image && !finalStyle?.cursor) {
                finalStyle = { ...finalStyle, cursor: 'pointer' }
            }
            // Filter out invalid props
            const {
                clickable,
                dynamicContentId,
                galleryId,
                thumbnailMediaId,
                appId,
                builderId,
                organizationId,
                fileCount,
                mediaTagId,
                dateCreated,
                dateModified,
                ...validProps
            } = otherProps

            mediaElement = <img
                ref={handleElem}
                className={`media noselect${className ? ` ${className}` : ''} ${fadeClass}${fit != null ? ` fit-${fit}` : ''}`}
                data-type={mediaTypeId}
                data-url={originalUrl}
                data-id={mediaId}
                draggable={false}
                alt={name}
                src={url}
                style={finalStyle}
                onClick={handleClick}
                onLoad={handleLoad}
                onError={handleError}
                onMouseDown={hoverShow ? handleMouseDown : handleMouseStart}
                onMouseOver={hoverShow ? handleMouseOver : null}
                onMouseMove={hoverShow ? handleMouseMove : null}
                onMouseLeave={hoverShow ? handleMouseLeave : null}
                {...validProps} />
        } else if (mediaTypeId == MediaType.Video) {
            let style = {}
            // If window is not 16/9, make video full width
            const ratio = window.innerWidth / window.innerHeight
            if (ratio < 16 / 9) {
                style.width = '100%'
                style.height = 'auto'
            } else {
                // Otherwise make it full height
                style.width = 'auto'
                style.height = '100%'
            }


            if (url.includes('youtube')) {
                mediaElement = <div className="media" data-type={mediaTypeId} ref={handleElem} onClick={null/*handleClick*/} onMouseDown={otherProps.onMouseDown} style={style}>
                    <iframe ref={setIframe} src={url} allowFullScreen frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" autoPlay />
                </div>
            } else if (!url.includes('jpg')) {
                mediaElement = <div className="media" data-type={mediaTypeId} ref={handleElem} onClick={null/*handleClick*/} onMouseDown={otherProps.onMouseDown} style={style}>
                    <VideoControls iframe={iframe} elem={elem} options={videoOptions} mobile={mobile} />
                    <iframe ref={setIframe} src={url} frameBorder="0" allow="autoplay;fullscreen; picture-in-picture " allowFullScreen />
                </div>
            } else {
                return <Spinner />
            }
        }
    }

    // const showIcon = showThumbnail && loaded == 2 && mediaTypeId == MediaType.Video 
    const showIcon = showThumbnail && loaded == 2 && mediaTypeId != MediaType.Image
    if (spinner) {
        return <React.Fragment>
            {loaded < 2 && spinner && <Spinner show={loaded < 1} />}
            <MediaIcon type={mediaTypeId} show={showIcon} />
            {mediaElement}
            {/* {hoverShow && hovering && <MediaHover name={media.name} position={hovering} url={getMediaLink(mediaId, { app })} />} */}
        </React.Fragment>
    }
    return <React.Fragment>
        <MediaIcon type={mediaTypeId} show={showIcon} />
        {mediaElement}
        {(mediaId || url) && hoverShow && hovering && <MediaHover name={name} position={hovering} url={(url && !mediaId) ? url.split('?')[0] : getMediaLink(mediaId, { app, builder, organization, thumb: true, thumbSize: ThumbSize.ExtraLarge })} />}
    </React.Fragment>
})
