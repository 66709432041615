import React, { useEffect, useState, useRef } from 'react'
import {
    PromptType, UpgradeSpaceData, CustomEventType, NotificationStatus, ListStyle, UserRole, UpgradeOrderStatus, ScreenOrientation, UpgradePricingMode, Visibility, ErrorMessage, UserOrderStatus, PromptOptions, ThumbSize, UpgradeTier, FAQType, AnalyticsUpgradesEvent, Analytics, PageType, MediaType, FormFieldType,
} from 'app/types'
import { useAppDispatch, useAppSelector, useLocalStorage, useSessionStorage, getMediaLinkSelector } from 'app/hooks'
import {
    Message, Button, Spinner, Media, Icon, IconButton, SlideShow, CloseButton, FlipNumbers, Swipe, Input, Checkbox, DropdownMenu, DateRange, DateSelector, Tile
} from 'components'
import * as fnc from 'helpers/fnc'
import {
    addProjectMedia,
    completeUpgrade,
    navigateAsync,
    logUpgrade, notifyUpgrade, pushNotification, recordAnalytics, retrieveTemporaryUpgradeOrder, retrieveUpgradePdf, retrieveUpgrades, showPrompt, updateUpgradeProduct, retrieveUpgradeLastChange, createUpgradeDocumentDownload, createUpgradeDocument, retrieveUpgradeLogs, resolvePrompt,
} from 'actions/appActions'
import userActions, {
    activeSessionUpgradeOrderRecord,
    endSessionUpgradeOrderRecord,
    removeUserOrderRecord, retrieveTemporaryToken, retrieveUpgradeOrderRecord, retrieveUser, retrieveUserOrders, saveUpgradeOrderRecord, saveUserOrderRecord,
} from 'actions/userActions'
import { checkVersion, getAppPath, getAppUrl, getWindowLocation, isLocal } from 'helpers/config'
import { logger } from 'helpers/logger'
import { getBuildingTypes } from 'actions/actionHelpers'
import { FloorplanTile } from 'views/FloorplanPage/FloorplanTile'
import { LocationLink } from 'views/HomePage/LocationLink'
import { downloadCSV } from 'helpers/csv'
import { upgradeSummaryCSV, upgradeSummaryPDF } from 'app/transformers'
import {
    selectionKey, configKey, getPrice, getConfig, clearGlobalChanges, getTourLink, getPackageName, getProductName, getOptionName, unpackConfig, floorplanVariationBedBathCode, getRelativePackage, getOptionComponent, buildMaps, getPricingUpdateMessage,
} from './upgrades'
import { UpgradeNavGroup } from './UpgradeNavGroup'
import { UpgradeGroup } from './UpgradeGroup'
import { UpgradePopup, UpgradePopupType } from './UpgradePopup'
import { UpgradeOrderRow } from './UpgradeOrderRow'
import { InfoBubble } from './InfoBubble'
import { setSpinError } from 'actions/spinActions'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { GalleryTile } from 'components'
import Faq from 'react-faq-component'
import { validateEmail, validatePassword, validatePhone } from 'helpers/authHeader'
import { upgradeOrderStatusIcons, faqStyles, icons } from 'app/constants'
import { FAQ } from 'components/FAQ'
import { getMediaLink } from 'helpers/media'
import { UpgradePackage } from './UpgradePackage'
import { isAllOf } from '@reduxjs/toolkit'
import { consoleSandbox } from '@sentry/utils'
import { pbrBlockGeometryInfo } from '@babylonjs/core/Shaders/ShadersInclude/pbrBlockGeometryInfo'

const SYNC_TIMEOUT = 20 * 1000
const SYNC_INTERVAL = 10 * 60 * 1000
const SAVE_DELAY = 1500
const FOCUS_PRODUCT_LIMIT = 4
const ACTIVE_CHECK_INTERVAL = 5 * 1000
const RESUME_SETTINGS = 'resumeSettings'

function orderSort(a, b) {
    return a.order - b.order
}

function hasRole(user: UserData, x: Role) {
    return user && user.roles && user.roles.findIndex((y) => y === x) != -1
}

function hasApp(user: UserData, x: AppData) {
    return user && user.apps && user.apps.findIndex((y) => y.id == x.meta.id) != -1
}

function optionSelection(o, p, pckge) {
    const v = o.variations[0]
    const c = v.components[0]
    if (pckge) {
        return { option: o, variation: v, component: c, product: p, package: pckge }
    } else {
        return { option: o, variation: v, component: c, product: p }
    }
}

// const useNotification = false
interface UpgradePageProps {
    app: AppData,
    upgrades: Dict,
    buildingType: UpgradeSpaceData,
    dataLink: string,
    extraLink: string,
    splitIndex: number,
    onChange: () => void
}

export function UpgradePage(props: UpgradePageProps) {
    const {
        app, dataLink, extraLink, splitIndex, onChange,
    } = props
    const dispatch = useAppDispatch()

    // Config
    const [upgrades, setUpgrades] = useState(null)
    const [views, setViews] = useState(null)
    const [allClients, setAllClients] = useState(null)
    const [allOrders, setAllOrders] = useState([])
    const [userOrder, setUserOrder] = useState(null)
    const [upgradeOrder, setUpgradeOrder] = useState(null)
    const [upgradeView, setUpgradeView] = useState(null)
    const [buildingType, setBuildingType] = useState(null)
    const [floorplan, setFloorplan] = useState(null)
    const [floorplanVariation, setFloorplanVariation] = useState(null)
    const [bedBath, setBedBath] = useState(null)
    const [unit, setUnit] = useState(null)
    const [showPricing, setShowPricing] = useState(false)

    const [selections, setSelections] = useState({})
    const [defaultSelections, setDefaultSelections] = useState({})
    const [missingOrderItems, setMissingOrderItems] = useState(null)
    const [pricingChangedItems, setPricingChangedItems] = useState(null)
    const [loading, setLoading] = useState(0)
    const [initialized, setInitialized] = useState(false)
    const [maps, setMaps] = useState({})
    const [scrollRef, setScrollRef] = useState(null)
    const [focusGroup, setFocusGroup] = useState(null)
    const [focusIndex, setFocusIndex] = useState(null)
    const [focusProduct, setFocusProduct] = useState(null)
    const [focusPackage, setFocusPackage] = useState(null)
    const [focusPage, setFocusPage] = useState(null)

    // Popups
    const [popup, setPopup] = useState(UpgradePopupType.None)
    const [popupData, setPopupData] = useState(null)
    // const [expandedNav, setExpandedNav] = useState(false)
    // const [showSummary, setShowSummary] = useState(false)
    // const [showAddUser, setShowAddUser] = useState(false)
    // const [showEditUser, setShowEditUser] = useState(false)
    /*const [showDownloadSigned, setShowDownloadSigned] = useState(false)
    const [downloadSignedDateRange, setDownloadSignedDateRange] = useLocalStorage('download-signed-date-range', null)
    const [generatingSignedDocuments, setGeneratingSignedDocuments] = useState(false)
    const [downloadSignedLink, setDownloadSignedLink] = useState(null)*/
    // Document popup
    // const [showDocuments, setShowDocuments] = useState(null)

    const [autoScrolling, setAutoScrolling] = useState(false)
    const [savePending, setSavePending] = useState(false)
    const [saving, setSaving] = useState(0)
    const [swipe, setSwipe] = useState(0)
    const [groups, setGroups] = useState(null)
    const [lastChange, setLastChange] = useState(null)
    const [autosave, setAutosave] = useState(false)
    const [nameFilter, setNameFilter] = useLocalStorage('name-filter', null)
    const [unitFilter, setUnitFilter] = useLocalStorage('unit-filter', null)
    const [statusFilter, setStatusFilter] = useLocalStorage('status-filter', null)
    const [editingProduct, setEditingProduct] = useState(false)
    const [savingEditingProduct, setSavingEditingProduct] = useState(false)
    const [upgradeCode, setUpgradeCode] = useState(null)
    const [standaloneView, setStandaloneView] = useState(false)
    const [planIndependent, setPlanIndependent] = useState(false)
    const [showTip, setShowTip] = useSessionStorage('upgrade_tip', true)
    const [visibleGroups, setVisibleGroups] = useState([])
    const [refreshKey, setRefreshKey] = useState(0)
    const [disableChanges, setDisableChanges] = useState(false)
    const [error, setError] = useState(null)
    const [notified, setNotified] = useState(false)
    const [summaryExpand, setSummaryExpand] = useState({})
    const [showBriefFloorplans, setShowBriefFloorplans] = useState(false)
    const [dataWarning, setDataWarning] = useSessionStorage('data_warning', false)
    const [termsAndConditions, setTermsAndConditions] = useState(false)
    const [showFaq, setShowFaq] = useState(false)
    const [faq, setFaq] = useState(null)
    const [showPricingBreakdown, setShowPricingBreakdown] = useLocalStorage('pricing_breakdown', false)
    const [enableDocuSign, setEnableDocuSign] = useLocalStorage('enable_docusign', false)
    const [clientSort, setClientSort] = useLocalStorage('client_sort', 'name')
    const [lastDBChange, setLastDBChange] = useLocalStorage('last_db_change', null)
    const [dbCheck, setDBCheck] = useState(null)
    const [begin, setBegin] = useState(false)
    const [resuming, setResuming] = useState(false)
    const [syncing, setSyncing] = useState(false)
    const [reload, setReload] = useState(0)
    const [splitSignaturePage, setSplitSignaturePage] = useState(false)
    const [generatingPdf, setGeneratingPdf] = useState(false)
    const [orderSession, setOrderSession] = useState(null)

    const screen = useAppSelector((state: AppState) => state.app.screen)
    const user = useAppSelector((state: RootState) => state.user.data)
    const loggedIn = useAppSelector((state: RootState) => state.user.loggedIn)
    const orders = useAppSelector((state: RootState) => state.user.orders)
    const fullscreen = useAppSelector((state: RootState) => state.app.fullscreen)
    const organization = useAppSelector((state: RootState) => state.admin.organization || state.app.organization)
    const temporary = useAppSelector((state: RootState) => state.user.temporary)
    const media = useAppSelector((state: RootState) => state.app.media)
    const version = useAppSelector((state: RootState) => state.app.version)
    const targetScroll = useRef(null)
    const viewRef = useRef(null)
    const scrollTimeout = useRef()
    const saveTimeout = useRef()
    const saveProxy = useRef()
    const saveDelay = useRef(SAVE_DELAY)
    const logTimeout = useRef(null)
    const customizations = useRef({})
    const moveToFirst = useRef(false)
    const moveToGroup = useRef(false)
    const totalCache = useRef(null)
    const upgradesLoading = useRef(false)
    const saveCode = useRef(null)
    const saveQueued = useRef(false)
    const userKey = useRef(null)
    const UPGRADE_ORDER_RECORD_KEY = upgradeView ? `${getAppPath()}/${app.meta.id}/${user ? `${user.id}/` : ''}upgrade_order_record/${upgradeView.id}/${version.code}` : ''
    const TOC_KEY = `${user?.id}_${upgradeCode}_${upgradeOrder?.id}_termsAndConditions`
    const SPLIT_SIGNATURE_KEY = `split-signature-${upgradeOrder?.id}`
    const getMediaLinkSel = getMediaLinkSelector()
    const generatePdfCallback = useRef(null)

    const upgradeAdmin = hasApp(user, app) && hasRole(user, UserRole.UpgradesEdit) && !upgradeCode
    const testAdmin = upgradeAdmin && user.email.includes('inventdev.com')
    // const standaloneView = !upgradeCode && !loggedIn// || upgradeView && upgradeView.planIndependent
    const mobileView = screen.isMobile //(!standaloneView || screen.orientation == ScreenOrientation.Portrait)
    const progressStatus = upgradeAdmin ? UpgradeOrderStatus.Restricted : UpgradeOrderStatus.Progress
    let firstGroup = visibleGroups.find((x) => !isNaN(x.id) || x.id == 'brief')

    // mobileView = true
    // const [pitch, setPitch] = useState(null)
    // const [yaw, setYaw] = useState(null)
    // if (upgrades.length == 0) {
    // return null
    // }

    // const view = upgrades.find((x) => x.tour == (dataLink || "upgradeTest2"))

    function customizationsKey() {
        if (!upgradeView) return null
        return `${app.meta.id}-${upgradeView.id}-custom`
    }

    // Load order record and cross reference to latest configuration, note missing items
    function parseOrderRecord(maps, buildingType, floorplanVariation, unit, order) {
        let missing = []
        let pricingChanged = []
        const newSelections = {}
        const skippedIds = {}

        order.optionProductRecords.forEach((x) => {
            if (buildingType && x.buildingTypeId != buildingType.id) {
                missing.push({ buildingTypeId: x.buildingTypeId })
            }
            if (floorplanVariation && x.floorplanVariationId != floorplanVariation.id) {
                missing.push({ floorplanVariationId: x.floorplanVariationId })
            }

            const isCustom = x.upgradeOptionGroupId != null && x.upgradeOptionId == null
            let group = null
            let option = null
            let variation = null
            let component = null
            let product = null
            let pckge = null
            const quantityOverride = x?.quantityOverride
            const priceOverride = x?.priceOverride
            const priceCalc = x?.priceCalc
            const priceSqft = x?.priceSqft
            const note = x?.note
            const recordMissing = []
            let hasMissing = false
            const recordPricingChanged = []
            let debug = ''
            let key = null
            const checkMissing = () => {
                if (!isCustom) {
                    if (x.upgradeOptionGroupId != null) {
                        group = maps.optionGroups[x.upgradeOptionGroupId]
                        if (!group) {
                            hasMissing = true
                            recordMissing.push({ upgradeOptionGroupId: x.upgradeOptionGroupId })
                            return
                        }
                    }

                    // Check for missing group
                    if (group && Object.keys(maps.variationOptionGroups).length > 0 && !(x.upgradeOptionGroupId in maps.variationOptionGroups)) {
                        hasMissing = true
                        recordMissing.push({ group, upgradeOptionGroupId: x.upgradeOptionGroupId })
                        return
                    }
                    if (x.upgradePackageId != null) {
                        pckge = maps.package[x.upgradePackageId]
                        if (!pckge) {
                            recordMissing.push({ upgradePackageId: x.upgradePackageId })
                            return
                        }
                    }
                    if (!x.upgradePackageId || x.upgradeOptionId != null) {
                        // Check if anything is missing or is changed
                        if (!(x.upgradeOptionId in maps.option)) {
                            recordMissing.push({ option: maps.option[x.upgradeOptionId], upgradeOptionId: x.upgradeOptionId })
                            return
                        }

                        option = maps.option[x.upgradeOptionId]

                        if (option && !x.upgradePackageId && !(x.upgradeOptionId in maps.variationOptions)) {
                            recordMissing.push({ option, upgradeOptionId: x.upgradeOptionId })
                            return
                        }

                        variation = option.variations?.find((y) => y.id == x.upgradeOptionVariationId)

                        if (!variation) {
                            recordMissing.push({ option, upgradeOptionVariationId: x.upgradeOptionVariationId })
                            return
                        }

                        component = variation.components.find((y) => y.id == x.upgradeComponentId)
                        if (!component) {
                            recordMissing.push({ option, upgradeComponentId: x.upgradeComponentId })
                            return
                        }
                        const productDef = component.products.find((y) => y.upgradeProductId == x.upgradeProductId)
                        if (!productDef) {
                            recordMissing.push({ option, product: maps.product[x.upgradeProductId], upgradeProductId: x.upgradeProductId })
                            return
                        }
                        // Package selection
                        if (x.upgradePackageId && !x.upgradeProductId) {
                            product = { option, pckge: maps.package[x.upgradePackageId], upgradePackageId: x.upgradePackageId }
                        } else {
                            product = maps.product[productDef.upgradeProductId]
                            if (!product && !x.upgradePackageId) {
                                recordMissing.push({ upgradeProductId: x.upgradeProductId })
                                return
                            }
                        }

                        if (!x.upgradePackageId && !(option.id in maps.optionGroupOptionMap)) {
                            recordMissing.push({ record: x, order, option, variation, component, product, pckge, reason: 'option not in group' })
                            return
                        }
                    }
                } else {
                    if (x.upgradeOptionId) {
                        if (!(x.upgradeOptionId in maps.option)) {
                            recordMissing.push({ upgradeOptionId: x.upgradeOptionId })
                            return
                        }
                        option = maps.option[x.upgradeOptionId]

                    } else {
                        option = { id: x.upgradeOptionGroupId, custom: x.upgradeOptionGroupId }
                    }
                    group = { id: x.upgradeOptionGroupId }
                    variation = { id: x.upgradeOptionGroupId }
                    component = { id: fnc.uniqueId(10), configureQuantity: true }
                    product = { id: fnc.uniqueId(10) }
                }
                if (pckge && option && component && option.id in maps.optionIsPackageOption) {
                    const packageValid = component.products.find((x) => x.upgradePackageId == pckge.id)
                    if (!packageValid) {
                        recordMissing.push({ group, pckge, option, variation, component, upgradeOptionId: option.id, upgradeComponentId: component.id })
                        // // Change to default product instead and clear all customizations?
                        // const defaultProduct = component.products.find((x) => x.isDefault)
                        // if(defaultProduct) {
                        //     product = defaultProduct
                        // }
                        return
                    }
                }

                if (pckge && !option) {
                    // If just package, key should be group.id where the package resides
                    const group = upgradeView.optionGroups.find((x) => x.options.find((x) => x.upgradePackageId == pckge.id))
                    if (group != null) {
                        key = group.id
                    } else {
                        recordMissing.push({ group, upgradePackageId: pckge.id })
                        return
                    }
                } else {
                    key = selectionKey(group, option, variation, component)
                }
            }
            checkMissing()

            if (pckge && option && product && !pckge.configurable) {
                // Check if product is the default
                const defaultProductId = pckge.products.find((x) => x.upgradeOptionId == option.id)?.upgradeProductId
                if (defaultProductId) {
                    const defaultProduct = maps.product[defaultProductId]
                    if (product?.id != defaultProduct?.id) {
                        recordMissing.push({ group, option, variation, component, product, pckge, reason: 'product not default' })
                        logger.info(`Fixing product default for ${option.name}, (${product?.name} -> ${defaultProduct?.name})`)
                        // product = defaultProduct
                    }
                }
            }

            const selection = {
                group, option, variation, component, product, quantityOverride, priceOverride, priceCalc, priceSqft, note, package: pckge
            }

            selection.missing = recordMissing
            missing = missing.concat(recordMissing)

            // If nothing missing check pricing changes
            if (recordMissing.length == 0) {
                if (key in newSelections) {
                    newSelections[key].push(selection)
                } else {
                    newSelections[key] = [selection]
                }
                // Re-parse record and look for changes
                // const epr = parseOptionProductRecord(maps, buildingType, floorplan, floorplanVariation, unit, selection)
                // if (epr.priceCalc != x.priceCalc) {
                //     recordMissing.push({ record: missingRecord, priceCalc: [x.priceCalc, epr.priceCalc] })
                // }
                // if (epr.priceSqft != x.priceSqft && epr.priceSqft != null && x.priceSqft != null) {
                //     recordMissing.push({ record: missingRecord, priceSqft: [x.priceSqft, epr.priceSqft] })
                // }
                // if (epr.priceSqin != x.priceSqin) {
                //     recordMissing.push({ record: missingRecord, priceSqin: [x.priceSqin, epr.priceSqin] })
                // }
                // if (epr.sqft != x.sqft) {
                //     recordMissing.push({ record: missingRecord, sqft: [x.sqft, epr.sqft] })
                // }
                // if (epr.sqin != x.sqin) {
                //     recordMissing.push({ record: missingRecord, sqin: [x.sqin, epr.sqin] })
                // }

                // Determine if selection can still be made given this configuration


                // Get buildingType configuration
                const spec = { buildingType, floorplan, floorplanVariation, option, optionVariation: variation, component, product, pckge, unit }
                const config = getConfig(maps, spec)
                const priceConfig = getPrice(maps, spec, { relative: false, selection })
                const { price, priceUnit, quantity, currentPrice, currentPriceUnit, fixedPackagePrice } = priceConfig

                if (!option.custom && !x.priceOverride && currentPrice != x.priceCalc && !fixedPackagePrice) {
                    recordPricingChanged.push({ record: x, spec, priceConfig, currentPrice, priceCalc: x.priceCalc })
                }

                pricingChanged = pricingChanged.concat(recordPricingChanged)
            }
        })

        return { options: newSelections, missing, pricingChanged }
    }

    // Package option product record for saving
    function parseOptionProductRecord(maps, buildingType, floorplan, floorplanVariation, unit, selection) {
        const {
            group, option, variation, component, product, package: pckge,
        } = selection
        // Get buildingType configuration
        const spec = { buildingType, floorplan, floorplanVariation, option, optionVariation: variation, component, product, pckge, unit }
        const config = getConfig(maps, spec)
        // const priceConfig = getPrice(maps, spec, { selection, customizations: customizations.current, relative: false })
        const priceConfig = getPrice(maps, spec, { selection, relative: false })
        let { price, priceUnit, quantity, cost } = priceConfig

        const roundDecimals = (x, decimals = 0) => {
            if (!x) {
                return x
            }
            return Math.round(x * Math.pow(10, decimals)) / Math.pow(10, decimals)
        }

        const epr = {
            buildingTypeId: buildingType?.id,
            floorplanId: floorplan?.id,
            floorplanVariationId: floorplanVariation?.id,
            upgradeOptionId: option && !option.custom && option.id && option.id.length == 36 ? option.id : null,
            upgradeOptionVariationId: option && !option.custom && variation.id && variation.id.length == 36 ? variation.id : null,
            upgradeComponentId: option && !option.custom && component.id && component.id.length == 36 ? component.id : null,
            upgradeProductId: option && product && !option.custom && product.id && component.id.length != 10 ? product.id : null,
            upgradePackageId: option && pckge && !option.custom && pckge.id,
            upgradeOptionGroupId: option && option.custom ? option.custom : group?.id,
            upgradePackageId: pckge?.id,
            sqft: roundDecimals(config?.sqft || component?.sqft),
            sqin: roundDecimals(config?.sqin || component?.sqin),
            // priceCost: roundDecimals(product ? product.priceCost : (pckge ? pckge.priceCost : null), 2),
            // priceSqft: roundDecimals(product?.priceSqft, 2),
            // priceSqin: roundDecimals(product?.priceSqin, 2),
            markup: roundDecimals(priceConfig?.markup, 2),
            priceCost: roundDecimals(priceConfig?.cost, 2),
            priceSqft: roundDecimals(priceConfig?.priceUnit, 2),
            priceCalc: roundDecimals(price, 2),
            priceOverride: roundDecimals(selection?.priceOverride, 2),
            quantity: config?.quantity,
            quantityOverride: selection?.quantityOverride,
            note: selection?.note,
        }

        return epr
    }

    function handleClientSort(sort) {
        if (clientSort == sort) {
            setClientSort(`-${sort}`)
        } else {
            setClientSort(sort)
        }
    }

    function clearProgram(maps = true) {
        setBegin(false)
        setFocusGroup(null)
        setBuildingType(null)
        setFloorplan(null)
        setFloorplanVariation(null)
        setUserOrder(null)
        setUpgradeOrder(null)
        setUpgradeView(null)
        setUpgradeCode(null)
        setSelections({})
        customizations.current = {}
        if (maps) {
            setMaps({})
        }
        setInitialized(1)
    }


    function productIsUpgrade(product, pckge) {
        if (pckge.upgradeTierId > UpgradeTier.Standard) {
            return false
        }
        // Check if the product chosen is in a higher package
        const tierA = pckge.upgradeTierId
        const packageBId = maps.productPackage[product.id]
        if (!packageBId) {
            return false
        }
        const packageBs = packageBId.filter((x) => typeof x != 'object').map((x) => maps.package[x])
        if (packageBs.length == 0) {
            return false
        }

        const tiersB = packageBs.map((x) => x.upgradeTierId)
        return tiersB.every((x) => x == tierA)
    }

    function packageIsUpgraded(pckge) {
        if (!customizations.current || !pckge || !(pckge.id in customizations.current)) {
            return false
        }
        // Get all upgrade products
        const upgradeProducts = Object.values(customizations.current[pckge.id]).map((x) => maps.product[x])

        return upgradeProducts.find((x) => productIsUpgrade(x, pckge))
    }

    function calculateAdminFee() {
        let adminFee = 0
        try {
            if (Object.keys(customizations.current).length > 0) {
                const upgradeSet = new Set()
                Object.values(selections).forEach((x) => {
                    x.forEach((y) => {
                        if (y.package && y.package.id in customizations.current && !upgradeSet.has(y.package.id) && packageIsUpgraded(y.package)) {
                            adminFee += y.package.administrationFee
                            upgradeSet.add(y.package.id)
                        }
                    })
                })
            }
        } catch (e) {
            logger.errorWithReport('Failed to calculate admin fee', e)

        }
        return adminFee
    }

    function checkSync() {
        if (!upgradeAdmin) {
            return
        }
        // Check for last change
        dispatch(retrieveUpgradeLastChange(app))
            .then((x) => {
                if (x.payload) {
                    const version = x.payload.version
                    const lastChange = x.payload.lastChange
                    const checkChange = () => {
                        if (lastChange != lastDBChange) {
                            setLastDBChange(lastChange)
                            dispatch(retrieveUpgrades(app))
                                .then((x) => {
                                    setUpgrades(x.payload.data)
                                })
                        }
                    }

                    const newUrl = checkVersion(version)
                    if (newUrl) {
                        const resumeSettings = {
                            userOrderId: userOrder?.id,
                            upgradeOrderId: upgradeOrder?.id,
                            groupId: focusGroup?.id,
                        }
                        localStorage.setItem(RESUME_SETTINGS, JSON.stringify(resumeSettings))
                        getWindowLocation().href = newUrl
                    } else {
                        checkChange()
                    }
                }
            })
    }

    // Initial load
    useEffect(() => {
        if (screen.isMobile && !dataWarning) {
            dispatch(showPrompt({ type: PromptType.Confirm, title: 'Mobile Data Warning', message: 'This application uses a lot of data. Please ensure you are connected to WiFi and not using cellular data to avoid carrier charges.', confirmMessage: 'Got it', cancel: null, cancelMessage: null }))
            setDataWarning(true)
        }
    }, [])

    // Reset on login / out
    useEffect(() => {
        // Key to check when to reload based on user change
        const key = `${user?.id}_${dataLink}_${extraLink}`
        if (userKey.current == key || !upgrades || loading == 1) {
            return
        }
        userKey.current = key

        setLoading(0)
        setInitialized(0)
        setAutosave((user && loggedIn) || dataLink)
        logger.info(`Enable autosave ? ${((user && loggedIn) || dataLink)}`)
        clearProgram()

        const asyncInit = async () => {
            setLoading(1)
            if (dataLink != null && dataLink != 'faq' && extraLink != 'faq') {
                try {
                    setUpgradeCode(dataLink)
                    let ret = await dispatch(retrieveTemporaryToken({ link: dataLink, logOut: false }))
                    if (!ret.payload.success) {
                        throw ret
                        return
                    }
                    await dispatch(retrieveUser(true))
                    ret = await dispatch(retrieveTemporaryUpgradeOrder(app))
                    if (ret.payload.success) {
                        const order = ret.payload.order
                        const userOrder = ret.payload.userOrder
                        const view = upgrades.views.find((x) => x.id == order.upgradeViewId)


                        if (dataLink) {
                            await dispatch(recordAnalytics({ type: Analytics.Upgrades, data: { app, analyticsUpgradesEventTypeId: AnalyticsUpgradesEvent.ViewOrder, upgradeOrderRecordId: order.id } }))
                            setUpgradeCode(dataLink)
                        }
                        handleUserOrder(userOrder, view, false)
                    } else {
                        throw ret
                    }
                    setInitialized(1)
                    // Delay load complete for re-render
                    setTimeout(() => {
                        setLoading(2)
                    }, 0)
                } catch (e) {
                    logger.error(e)
                    if (e?.payload?.message) {
                        setError(e.payload.message)
                    } else {
                        setError(ErrorMessage.DataError)
                    }
                }
            } else {
                if (upgradeAdmin && dataLink != 'faq' && extraLink != 'faq') {
                    try {
                        const x = await dispatch(retrieveUserOrders(app))
                        if (x.payload.success) {
                            setAllClients([...x.payload.data].sort((a, b) => a.email?.localeCompare(b?.email)))
                        }
                    } catch (e) {
                        logger.error(e)
                        setError(ErrorMessage.DataError)
                    }
                }
                setLoading(2)
                setInitialized(1)
            }
        }

        setTimeout(() => {
            asyncInit()
        }, 0)
    }, [upgrades, user, loggedIn, dataLink, extraLink])


    useEffect(() => {
        const x = (upgrades?.views.length == 1 && upgrades?.views[0].simpleDisplay) || (!upgradeAdmin && upgradeCode == null)
        setStandaloneView(x)
        setPlanIndependent(x)
    }, [user, loggedIn, upgradeCode, upgrades])

    useEffect(() => {
        if (props.upgrades) {
            setUpgrades(props.upgrades)
        }
    }, [props.upgrades])

    useEffect(() => {
        if (upgrades) {
            setFaq(upgrades.faqs.find((x) => x.faqTypeId == FAQType.Upgrades))
        }

    }, [upgrades])

    // Initial load of upgrades config
    useEffect(() => {
        if (!app || upgrades || props.upgrades || upgradesLoading.current) {
            return
        }

        let timeout = null
        if (upgradeAdmin) {
            const resumeSettings = localStorage.getItem(RESUME_SETTINGS)
            setResuming(resumeSettings != null)
            // Set limit to resuming time
            timeout = setTimeout(() => {
                setResuming(false)
            }, 20 * 1000) // 20 seconds
        }

        // Annoying workaround to prevent double loading in dev
        upgradesLoading.current = true
        dispatch(retrieveUpgrades(app))
            .then((x) => {
                const newUpgrades = x.payload.data
                setUpgrades(newUpgrades)
                setDBCheck(Date.now())
            })

        return () => {
            clearTimeout(timeout)
        }
    }, [app])

    useEffect(() => {
        if (!allClients || !upgradeAdmin) {
            return
        }
        const resumeSettings = localStorage.getItem(RESUME_SETTINGS)
        let validSettings = false
        if (resumeSettings) {
            const settings = JSON.parse(resumeSettings)
            if (settings.userOrderId) {
                const user = allClients.find((x) => x.orders.find((y) => y.id == settings.userOrderId))
                if (user) {
                    const userOrder = user.orders.find((x) => x.id == settings.userOrderId)
                    const upgradeOrder = userOrder.upgradeOrderRecords.find((x) => x.id == settings.upgradeOrderId)
                    if (upgradeOrder) {
                        const view = upgrades.views.find((x) => x.id == upgradeOrder.upgradeViewId)
                        moveToGroup.current = settings.groupId
                        if (userOrder && view) {
                            validSettings = true
                            handleUserOrder(userOrder, view)
                        }
                    }
                }
            }
            // Clear settings
            setTimeout(() => {
                localStorage.removeItem(RESUME_SETTINGS)
            }, 1000)
        }
        if (!validSettings) {
            setResuming(false)
        }

    }, [allClients])

    // Reloading on last db change
    useEffect(() => {
        if (dbCheck == null || !upgradeAdmin) {
            return
        }
        checkSync()
    }, [dbCheck])

    // Syncing state
    useEffect(() => {
        if (!syncing || !upgradeAdmin) {
            return
        }
        checkSync()
        const interval = setInterval(() => {
            checkSync()
        }, SYNC_INTERVAL)

        const test = () => {
            checkSync()
        }
        window.addEventListener('focus', test)
        return () => {
            window.removeEventListener('focus', test)
            clearInterval(interval)
        }
    }, [syncing])


    useEffect(() => {
        let timeout = null
        let tempSync = syncing
        const activity = () => {
            clearTimeout(timeout)
            if (tempSync) {
                setSyncing(false)
                tempSync = false
            }
            timeout = setTimeout(() => {
                setSyncing(true)
            }, SYNC_TIMEOUT)
        }
        if (!syncing) {
            activity()
        }

        window.addEventListener('mousemove', activity)
        window.addEventListener('keypress', activity)
        return () => {
            clearTimeout(timeout)
            window.removeEventListener('mousemove', activity)
            window.removeEventListener('keypress', activity)
        }
    }, [syncing])

    useEffect(() => {
        if (!upgradeOrder) {
            return
        }
        const checkActive = () => {
            if (isLocal()) {
                if (orderSession == null) {
                    setOrderSession({ active: true, available: true, session: null, queued: null })
                }
                return
            }
            dispatch(activeSessionUpgradeOrderRecord(upgradeOrder))
                .then((x) => {
                    if (x.payload.success && x.payload.session) {
                        const newOrderSession = x.payload.session
                        if (orderSession) {
                            // If we've switched from observing a locked order to it being unlocked
                            if (!orderSession.active && !orderSession.available && !newOrderSession.active && newOrderSession.available) {
                                // Indicate the order has now been unlocked and refresh the order
                                dispatch(showPrompt({ type: PromptType.Confirm, message: "The order has been unlocked. Refresh the order to continue.", confirmMessage: 'Refresh', cancelMessage: null, close: false }))
                                    .then((x) => {
                                        if (x.payload) {
                                            refreshOrder()
                                            // window.location.reload()
                                            return
                                            // handleUserOrder
                                            // dispatch(retrieveUpgradeOrder(upgradeOrder))
                                        }
                                    })
                            }
                        }
                        if (JSON.stringify(newOrderSession) != JSON.stringify(orderSession)) {
                            setOrderSession(newOrderSession)
                        }
                    }
                })
        }
        const interval = setInterval(checkActive, ACTIVE_CHECK_INTERVAL)
        if (orderSession == null) {
            checkActive()
        }
        return () => {
            clearInterval(interval)
        }
    }, [upgradeOrder, orderSession])

    useEffect(() => {
        if (upgradeView == null) {
            return
        }
        let timeout = null
        if (focusGroup != null) {
            const idx = groups.findIndex((x) => x.id == focusGroup.id)
            if (idx != focusIndex) {
                timeout = setTimeout(() => {
                    setFocusIndex(idx)
                }, 0)
            }
            // setDBCheck(Date.now())
        }
        return () => {
            clearTimeout(timeout)
        }
    }, [focusGroup, upgradeView, groups])


    // Whether to show pricing
    useEffect(() => {
        if (!upgradeView) {
            return
        }
        setShowPricing(upgradeView.upgradePricingModeId == UpgradePricingMode.Public || (upgradeAdmin && upgradeView.upgradePricingModeId == UpgradePricingMode.AdminOnly))
        setAutosave((user && loggedIn) || dataLink || upgradeCode)

        // Load customizations
        let cachedCustom = sessionStorage.getItem(customizationsKey())
        if (cachedCustom) {
            cachedCustom = JSON.parse(cachedCustom)
            customizations.current = cachedCustom
        }
    }, [upgradeView, user, loggedIn])

    // Prevent leaving without saving
    useEffect(() => {
        if (!savePending || standaloneView) {
            return
        }
        const messageHandler = (e) => {
            e.returnValue = 'Leave without saving changes?'
        }
        window.addEventListener('beforeunload', messageHandler)
        return () => {
            window.removeEventListener('beforeunload', messageHandler)
        }
    }, [savePending])

    // Scroll handlers
    useEffect(() => {
        if (!scrollRef || autoScrolling || mobileView || fullscreen || !initialized || !upgradeView) {
            return
        }
        scrollRef.addEventListener('scroll', handleScroll)
        handleScroll()
        return () => {
            scrollRef.removeEventListener('scroll', handleScroll)
        }
    }, [scrollRef, focusGroup, autoScrolling, fullscreen, initialized, upgradeView])

    // Initialize visible view set (check permissions)
    useEffect(() => {
        if (!upgrades) { // } || initialized > 0) {
            return
        }
        const newViews = upgrades.views.filter((x) => upgradeAdmin || x.published || upgradeCode)
        if (upgradeView != null) {
            const newView = newViews.find((x) => x.id == upgradeView.id)
            setUpgradeView(newView)
            if (initialized == 0) {
                setInitialized(1)
            }
        } else if (initialized == 0) {
            if ((!upgradeAdmin && newViews.length == 1) || (newViews.length == 1 && newViews[0].simpleDisplay)) {
                setUpgradeView(newViews[0])
            }
            setInitialized(1)
        }
        setViews(newViews)
    }, [upgrades, initialized, user])


    // Initialize all upgrade supporting data
    useEffect(() => {
        if (!upgrades || !views || loading < 2 || (upgradeCode && !loggedIn) || !scrollRef) {
            return
        }
        const newMaps = buildMaps(upgrades, app, { upgradeView, floorplan, floorplanVariation, buildingType })

        let newGroups = []
        newMaps.optionGroups.orders = { id: 'orders', name: 'Records' }
        newMaps.optionGroups.views = { id: 'views', name: 'Upgrade Configurations' }
        newMaps.optionGroups.selection = { id: 'selection', name: 'Building & Plan', options: [{ id: 'building', name: 'Building' }, { id: 'floorplan', name: 'Floorplan' }] }
        newMaps.optionGroups.brief = { id: 'brief', name: 'Welcome' }
        newMaps.optionGroups.summary = { id: 'summary', name: 'Summary' }
        newMaps.optionGroups.booking = { id: 'booking', name: 'Booking' }
        newMaps.optionGroups.finalize = { id: 'finalize', name: 'Submit' }
        newMaps.optionGroups.faq = { id: 'faq', name: 'FAQ' }
        if (!standaloneView && !dataLink) {
            if (upgradeAdmin) {
                newGroups.push(newMaps.optionGroups.orders)
            } else {
                newGroups.push(newMaps.optionGroups.selection)
            }
        }
        if (userOrder) {
            newGroups.push(newMaps.optionGroups.brief)
        }

        const sortedGroups = upgradeView ? [...upgradeView.optionGroups].sort(orderSort) : []
        newMaps.optionGroups = { ...newMaps.optionGroups, ...fnc.objIdMap(sortedGroups) }

        // Only show if terms and conditions accepted or admin
        // if (upgradeAdmin || termsAndConditions) {
        if (!upgradeCode || (termsAndConditions && begin) || standaloneView || !user) {
            if (upgradeAdmin) {
                if (upgradeView && (standaloneView || planIndependent || (userOrder && buildingType && floorplanVariation))) {
                    newGroups = [...newGroups, ...sortedGroups]
                    if (!upgradeView.simpleDisplay) {
                        newGroups.push(newMaps.optionGroups.summary)
                    }
                    if (!standaloneView && userOrder && upgradeOrder) {
                        // newGroups.push(newMaps.optionGroups.booking)
                        newGroups.push(newMaps.optionGroups.finalize)
                    }
                }
            } else {
                if (!upgradeCode && (standaloneView || (buildingType && floorplanVariation)) && views.length > 1) {
                    newGroups.push(newMaps.optionGroups.views)
                }

                if (upgradeView) {
                    newGroups = [...newGroups, ...sortedGroups]
                    if (!upgradeView.simpleDisplay) {
                        newGroups.push(newMaps.optionGroups.summary)
                    }
                    if (!standaloneView && userOrder && upgradeOrder) {
                        // newGroups.push(newMaps.optionGroups.booking)
                        newGroups.push(newMaps.optionGroups.finalize)
                    }
                }
            }
        }

        // Set total groups and visible groups
        setGroups(newGroups)
        const newVisibleGroups = newGroups ? newGroups.filter((x) => {
            // Check if plan is set and if group subset has been configured
            if (!isNaN(x.id)
                && floorplanVariation
                && Object.keys(newMaps.variationOptionGroups).length > 0
                && !(x.id in newMaps.variationOptionGroups)) {
                return false
                // if (Object.keys(newMaps.variationOptionGroups).length > 0) {
                // variationGroup = x.id in maps.variationOptionGroups
                // }
            }
            return upgradeAdmin || x.published !== false
        }).sort(orderSort) : []
        setVisibleGroups(newVisibleGroups)

        const newGroupOptions = { global: [], selection: ['building', 'floorplan'] }
        newMaps.optionGroupOptions = newGroupOptions
        upgradeView?.optionGroups.forEach((group) => {
            newGroupOptions[group.id] = group.options.map((x) => newMaps.option[x.upgradeOptionId])
        })


        if (Object.keys(maps).length > 0 && (((!upgradeView || !planIndependent) && (!buildingType || !floorplanVariation)) || initialized == 0)) {
            setMaps(newMaps)
            setFocusGroup(newGroups[0])
            return
        }

        // Ensure first group focuses on mobile
        if (!focusGroup && mobileView) {
            setFocusGroup(newGroups[0])
        }


        let options = []
        if (floorplanVariation && floorplanVariation.id in newMaps.floorplanVariationUpgradeOption && newMaps.floorplanVariationUpgradeOption[floorplanVariation.id].length > 0) {
            options = newMaps.floorplanVariationUpgradeOption[floorplanVariation.id]
        } else {
            options = Object.keys(newMaps.option).filter((x) => x in newMaps.visibleOptions).map((x) => ({ upgradeOptionId: x, name: null }))
        }

        // Setup variation options
        options?.forEach((x) => {
            const option = newMaps.option[x.upgradeOptionId]
            if (!option) {
                logger.error('Missing option', x, newMaps, upgrades)
                return
            }

            newMaps.variationOptions[x.upgradeOptionId] = x
        })

        // Flatten options
        options = options.map((x) => x.upgradeOptionId)

        // Build selections from order
        if (upgradeView) {
            let newSelections = {}
            let missingItems = []
            let pricingChanged = []
            let order = null
            if (userOrder) {
                order = userOrder.upgradeOrderRecords.find((x) => x.upgradeViewId == upgradeView.id)
            } else if (orders && orders.length > 0) {
                // Find matching order for view
                order = orders.find((x) => x.upgradeViewId == upgradeView.id)
            }
            if (order) {
                const x = parseOrderRecord(newMaps, buildingType, floorplanVariation, unit, order)
                newSelections = x.options
                missingItems = x.missing
                pricingChanged = x.pricingChanged
            } else if (!userOrder && loggedIn) {
                // Fallback on cached
                // See if order was saved in local storage (only if logged in)
                let cachedOrderRecord = localStorage.getItem(UPGRADE_ORDER_RECORD_KEY)
                if (cachedOrderRecord) {
                    try {
                        cachedOrderRecord = JSON.parse(cachedOrderRecord)
                        const x = parseOrderRecord(newMaps, buildingType, floorplanVariation, unit, cachedOrderRecord)
                        newSelections = x.options
                        missingItems = x.missing
                        pricingChanged = x.pricingChanged
                    } catch (e) {
                        throw (e)
                        logger.error('Failed to load order from cache', e)
                    }
                }
            }
            if (order) {
                newMaps.orderStatus = order.upgradeOrderStatusId
            }

            const newDefaultSelections = {}
            // if (Object.keys(newSelections).length == 0 && options) {
            if (options) {
                // Populate options with default
                options.forEach((x) => {
                    const option = newMaps.option[x]
                    const debug = option.name.toLowerCase().includes('millwork')
                    if (!option) {
                        logger.error('Missing option', x, newMaps, upgrades)
                        return
                    }
                    if (!option.variations || option.variations.length == 0) {
                        logger.error('Missing option variations', x, newMaps, upgrades)
                        return
                    }

                    // Dont add default if part of package
                    if (option.id in newMaps.packageOptionMap) {
                        return
                    }

                    if (option.visibilityId == Visibility.Hidden) {
                        return
                    }

                    // Iterate through first variations components
                    option?.variations.forEach((variation, iy) => {
                        if (iy > 0) {
                            return
                        }
                        variation.components.forEach((component) => {
                            if (component && component.products.length > 0) {
                                const defaultProduct = component.products.find((x) => x.isDefault)
                                if (defaultProduct && defaultProduct.upgradeProductId) {
                                    const product = newMaps.product[defaultProduct.upgradeProductId]
                                    newMaps.optionGroupOptionMap[option.id]?.forEach((group) => {
                                        applyProductSelection(newDefaultSelections, group, option, variation, component, product, null, newMaps)
                                    })
                                }
                                if (defaultProduct && defaultProduct.upgradePackageId) {
                                    const pckge = newMaps.package[defaultProduct.upgradePackageId]
                                    newMaps.optionGroupOptionMap[option.id]?.forEach((group) => {
                                        applyProductSelection(newDefaultSelections, group, option, variation, component, { upgradePackageId: pckge.id }, pckge, newMaps)
                                        pckge.products.forEach((y) => {
                                            const p = newMaps.product[y.upgradeProductId]
                                            const o = newMaps.option[y.upgradeOptionId]
                                            const { variation: v, component: c } = optionSelection(o, p)
                                            applyProductSelection(newDefaultSelections, group, o, v, c, p, pckge, newMaps)
                                        })
                                    })
                                }
                            }
                        })
                    })
                })
            }

            if (Object.keys(newSelections).length == 0) {
                newSelections = { ...newDefaultSelections }
            } else {
                // Ensure all defaults have been accounted for
                Object.keys(newDefaultSelections).forEach((x) => {
                    const { group, option, variation, component, package: pckge } = newDefaultSelections[x][0]
                    const key = selectionKey(group, option, variation, component)
                    if (option.variations.length > 1) {
                        if (Object.keys(newSelections).find((x) => newSelections[x].find((y) => y.option.id == option.id)) == null) {
                            newSelections[key] = newDefaultSelections[x]
                        }
                    } else {
                        if (!newSelections[key]) {
                            // If its part of a package, make sure package is in selections
                            if (pckge) {
                                const packageSelected = Object.keys(newSelections).find((x) => newSelections[x].find((y) => y.package?.id == pckge.id))
                                if (packageSelected) {
                                    newSelections[key] = newDefaultSelections[x]
                                }
                            } else {
                                newSelections[key] = newDefaultSelections[x]

                            }
                        }
                    }
                })

                // Also ensure if an option is not customizable that the correct product has been applied
            }

            if (missingItems.length > 0) {
                logger.error('Missing items!', missingItems)
            }

            if (initialized <= 1) {
                setDefaultSelections(newDefaultSelections)
                setMissingOrderItems(missingItems)
                setPricingChangedItems(pricingChanged)
                // setFocusGroup(upgradeView.optionGroups.sort(orderSort)[0])
                setInitialized(2)
            }

            if (!selections || Object.keys(selections).length != Object.keys(newSelections).length) {
                setSelections(newSelections)
            }

            // Determine where to start
            if (loading == 2 && (initialized <= 1 || moveToFirst.current || moveToGroup.current || generatingPdf)) {
                if (generatingPdf && !generatingPdf.generated) {
                    if (generatePdfCallback.current) {
                        setGeneratingPdf({ ...generatingPdf, generated: true })
                        setTimeout(() => {
                            if (!order) {
                                dispatch(showPrompt({ type: PromptType.Confirm, title: 'Missing order', message: 'There is no order to generate a PDF from. Please create one and try again' }))
                            } else {
                                generatePdfCallback.current(null, generatingPdf.data)
                            }
                        }, 100)
                    }
                }
                if (moveToGroup.current) {
                    moveToFirst.current = false
                    const group = newVisibleGroups.find((x) => x.id == moveToGroup.current)
                    if (group) {
                        // setTimeout(() => {
                        setTimeout(() => {
                            handleEditGroup(group, null, null, null, true)
                        }, 100)
                        // }, 100)
                    }
                    setTimeout(() => {
                        setResuming(false)
                    }, 200)
                    moveToGroup.current = false
                } else {
                    // Redraw delay
                    setTimeout(() => {
                        focusFirst(newVisibleGroups)
                    }, 0)
                    moveToFirst.current = false
                }
            }
        }

        setMaps(newMaps)
    }, [allClients, upgrades, upgradeView, buildingType, floorplanVariation, initialized, loggedIn, loading, planIndependent, standaloneView, termsAndConditions, begin, scrollRef, reload])

    // Loading routines
    useEffect(() => {
        if (loading > 0) {
            return
        }
        // Force load after timeout
        const timeout = setTimeout(() => {
            setLoading(1)
            setTimeout(() => {
                setLoading(2)
            }, 200)
        }, 30000)
        return () => {
            clearTimeout(timeout)
        }
    }, [loading])

    // Saving routings
    useEffect(() => {
        if (generatingPdf) {
            setSavePending(false)
            return
        }

        if (!savePending || !upgradeView || !autosave || lastChange == null || disableChanges) {
            return
        }
        if (saveTimeout.current) {
            clearTimeout(saveTimeout.current)
        }

        if (userOrder && upgradeOrder.userOrderRecordId != userOrder.id) {
            return
        }

        let timeout = null
        const testCode = saveCode.current = fnc.uniqueId()
        if (!saveProxy.current) {
            setSaving(0)
        }
        const trySave = (delay) => {
            timeout = setTimeout(() => {
                if (testCode != saveCode.current) {
                    return
                }
                if (saveProxy.current) {
                    trySave(1000)
                    saveQueued.current = true
                } else {
                    saveQueued.current = false
                    handleSave()
                }
            }, delay)
        }
        trySave(saveDelay.current)
        return () => {
            clearTimeout(timeout)
        }
    }, [savePending, lastChange])

    useEffect(() => {
        if (!maps.option || !upgradeView) {
            return
        }
        // Set customization based on selection
        // Look for selections that includes this package
        const newCustomizations = { ...customizations.current }
        const packageMap = {}
        let debug = false
        Object.values(selections).filter((x) => x.find((y) => y.package != null && y.option)).forEach((y) => {
            y.filter((z) => z.package != null && z.option).forEach((z) => {
                const pckge = maps.package[z.package.id]
                if (!pckge.configurable) {
                    if (pckge.id in newCustomizations) {
                        delete newCustomizations[pckge.id]
                    }
                    return
                }
                if (!(pckge.id in packageMap)) {
                    packageMap[pckge.id] = {}
                    pckge.products.forEach((x) => {
                        const option = maps.option[x.upgradeOptionId]
                        const { variation, component } = optionSelection(option)
                        const key = selectionKey(z.group, option, variation, component)
                        packageMap[pckge.id][key] = x.upgradeProductId
                    })
                }
                if (!(z.package.id in newCustomizations)) {
                    newCustomizations[z.package.id] = {}
                }
                const key = selectionKey(z.group, z.option, z.variation, z.component)
                if (key in packageMap[z.package.id] && !z.dependencyUpgradeComponentId) {
                    const defaultProduct = packageMap[z.package.id][key]
                    if (defaultProduct != z.product.id) {
                        newCustomizations[z.package.id][key] = z.product.id
                    } else if (key in newCustomizations[z.package.id]) {
                        delete newCustomizations[z.package.id][key]
                    }
                }
            })
        })
        Object.keys(newCustomizations).forEach((x) => {
            if (Object.keys(newCustomizations[x]).length == 0) {
                delete newCustomizations[x]
            }
        })
        customizations.current = newCustomizations
        setRefreshKey(Date.now())
        sessionStorage.setItem(customizationsKey(), JSON.stringify(newCustomizations))
    }, [upgradeView, selections, maps])

    // Check order status
    useEffect(() => {
        if (!upgradeOrder?.userOrderRecordId) {
            setDisableChanges(false)
            return
        }

        // const shouldDisable = !upgradeAdmin && upgradeOrder.status == UpgradeOrderStatus.Complete
        const shouldDisable = (upgradeAdmin && (!orderSession?.active && !orderSession?.available)) || (!upgradeAdmin && (upgradeOrder.upgradeOrderStatusId == UpgradeOrderStatus.Complete || upgradeOrder.upgradeOrderStatusId == UpgradeOrderStatus.Restricted))
        if (shouldDisable && !notified && !upgradeAdmin) {
            let message = ''
            if (upgradeOrder.upgradeOrderStatusId == UpgradeOrderStatus.Restricted) {
                message = 'Please Note: All your selections have already been submitted. Please complete your order with your design agent. Thank you.'
            } else {
                message = 'Please Note: All your selections have already been submitted. As such you can view your selections but cannot change them. Thank you.'
            }
            dispatch(showPrompt({ type: PromptType.Confirm, title: 'Selections Submitted', message }))
            setBegin(true)
        }

        // See if terms and conditions accepted for this user
        if (false && app.meta.termsAndConditions && userOrder?.userId == user?.id) {
            const cachedToc = localStorage.getItem(TOC_KEY)
            setTermsAndConditions(cachedToc == 'true')
        } else {
            setTermsAndConditions(true)
        }

        setDisableChanges(shouldDisable)
        setNotified(true)
        setSplitSignaturePage(localStorage.getItem(SPLIT_SIGNATURE_KEY) == 'true')
    }, [upgradeOrder, upgradeOrder?.upgradeOrderStatusId, JSON.stringify(orderSession)])

    useEffect(() => {
        const sortClients = (_a, _b) => {
            if (!clientSort) {
                return 1
            }
            const a = _a.user
            const b = _b.user
            const orderA = _a.order//a.orders.find((x) => x.appId == app.meta.id)
            const orderB = _b.order//b.orders.find((x) => x.appId == app.meta.id)
            if (!orderA || !orderB) {
                return 1
            }

            const desc = clientSort.startsWith('-')
            if (clientSort.includes('name')) {
                if (a.name && !b.name) {
                    return desc ? 1 : -1
                } else if (!a.name && b.name) {
                    return desc ? -1 : 1
                } else if (!a.name && !b.name) {
                    return -1
                }
                return desc ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name)
            } else if (clientSort.includes('email')) {
                if (a.email && !b.email) {
                    return desc ? 1 : -1
                } else if (!a.email && b.email) {
                    return desc ? -1 : 1
                } else if (!a.email && !b.email) {
                    return -1
                }
                return desc ? b.email.localeCompare(a.email) : a.email.localeCompare(b.email)
            } else if (clientSort.includes('phone')) {
                if (a.phone && !b.phone) {
                    return desc ? 1 : -1
                } else if (!a.phone && b.phone) {
                    return desc ? -1 : 1
                } else if (!a.phone && !b.phone) {
                    return -1
                }
                return desc ? b.phone.localeCompare(a.phone) : a.phone.localeCompare(b.phone)
            } else if (clientSort.includes('unit')) {

                if (orderA.unitId && !orderB.unitId) {
                    return desc ? 1 : -1
                } else if (!orderA.unitId && orderB.unitId) {
                    return desc ? -1 : 1
                } else if (!orderA.unitId && !orderB.unitId) {
                    return -1
                }
                const unitA = parseInt(app.maps.unit[orderA.unitId].name)
                const unitB = parseInt(app.maps.unit[orderB.unitId].name)
                return desc ? unitA - unitB : unitB - unitA
            } else if (clientSort.includes('floorplan')) {
                if (orderA.floorplanVariationId && !orderB.floorplanVariationId) {
                    return desc ? 1 : -1
                } else if (!orderA.floorplanVariationId && orderB.floorplanVariationId) {
                    return desc ? -1 : 1
                } else if (!orderA.floorplanVariationId && !orderB.floorplanVariationId) {
                    return -1
                }
                const floorplanA = app.maps.floorplan[app.maps.floorplanVariation[orderA.floorplanVariationId].floorplanId]?.name
                const floorplanB = app.maps.floorplan[app.maps.floorplanVariation[orderB.floorplanVariationId].floorplanId]?.name
                return desc ? floorplanA.localeCompare(floorplanB) : floorplanB.localeCompare(floorplanA)
            } else if (clientSort.includes('price')) {
                if (!orderA.price && orderB.price) {
                    return desc ? -1 : 1
                } else if (orderA.price && !orderB.price) {
                    return desc ? 1 : -1
                } else if (!orderA.price && !orderB.price) {
                    return -1
                }
                return desc ? parseFloat(orderA.price) - parseFloat(orderB.price) : parseFloat(orderB.price) - parseFloat(orderA.price)
            }
            return 1
            /*if (unitFilter && unitFilter.length > 0) {
                const unitsA = a.orders.map((y) => app.maps.unit[y.unitId]).filter((x) => x != null)
                const unitsB = b.orders.map((y) => app.maps.unit[y.unitId]).filter((x) => x != null)
                if (unitsA.length > 0 && unitsB.length > 0) {
                    return unitsA[0].name.localeCompare(unitsB[0].name)
                }
            }
            return 1*/
        }

        let newAllOrders = []
        // const finalClients = [...allClients].sort(sortClients).filter(filterClients)
        allClients?.filter(filterClients).forEach((x, ix) => {
            x.orders.filter(filterClientOrders).forEach((z) => {
                newAllOrders.push({
                    user: x,
                    order: z,
                })
            })
        })
        newAllOrders.sort(sortClients)
        setAllOrders(newAllOrders)
    }, [allClients, nameFilter, unitFilter, statusFilter, clientSort])

    // Handle focus Popups
    useEffect(() => {
        if (popup == UpgradePopupType.None && (focusProduct || focusPackage)) {
            if (focusPackage) {
                setPopup(UpgradePopupType.FocusPackage)
            } else if (focusProduct) {
                setPopup(UpgradePopupType.FocusProduct)
            }
        } else if ((popup == UpgradePopupType.FocusProduct && !focusProduct)
            || (popup == UpgradePopupType.FocusPackage && !focusPackage)) {
            setPopup(UpgradePopupType.None)
        }
    }, [focusProduct, focusGroup])

    // Caching
    useEffect(() => {
        let data = { userOrder, upgradeOrder, upgradeView, unit, floorplan }
        data = Object.keys(data).reduce((acc, key) => {
            if (!data[key]) {
                return
            }
            if ('name' in data[key]) {
                return { ...acc, [key]: data[key].name }
            } else if ('id' in data[key]) {
                return { ...acc, [key]: data[key].id }
            }
            return acc
        }, {})

        logger.setSessionCache(data)
    }, [userOrder, upgradeOrder, upgradeView, unit, floorplan])

    useEffect(() => {
        const makeChecks = async () => {
            if (missingOrderItems?.length > 0) {
                let keySet = new Set()
                let missingMessage = 'This is a notice that there were some expired options in this order that have been discontinued and have been removed from this order.\n\nIf you wish to have expired options you will need to add them as a custom option to this order.'
                let packagesToReset = []
                missingOrderItems.forEach((x) => {
                    let itemMessage = ''
                    if (x.group && !x.option && !x.pckge && !x.product) {
                        if (!(keySet.has(x.group.id))) {
                            itemMessage = `All options in ${x.group.name}`
                            keySet.add(x.group.id)
                        } else {
                            return
                        }
                    } else {
                        if (x.option) {
                            itemMessage = `${x.option.name}`
                        }

                        if (x.pckge) {
                            itemMessage += ` - ${x.pckge.name}`
                            packagesToReset.push(x)
                        }
                        if (x.product) {
                            itemMessage += ` - ${x.product.name}`
                        }
                    }
                    missingMessage += `\n\n* ${itemMessage}`
                })

                const ret = await dispatch(showPrompt({ type: PromptType.Confirm, title: 'Notice of Expired Options', message: missingMessage, confirmMessage: 'Got it', cancelMessage: null }))
                for (let i = 0; i < packagesToReset.length; i++) {
                    const { group, pckge, option, variation, component, product } = packagesToReset[i]
                    if (!product) {
                        const defaultPackage = component.products.find((x) => x.isDefault)
                        if (defaultPackage) {
                            await new Promise((resolve) => {
                                const defaultPackgeObj = maps.package[defaultPackage.upgradePackageId]
                                handlePackage(group, option, variation, component, defaultPackgeObj, true, false, true)
                                setTimeout(resolve, 1)
                            })
                        }
                    } else {
                        // handlePackage(group, option, variation, component, pckge, true, false, true, true)
                    }
                }
                setSavePending(true)
                setLastChange(Date.now())
            }
            if (pricingChangedItems?.length > 0) {
                logger.info("PRICING: changed items", pricingChangedItems)
                const ret = await dispatch(showPrompt({ type: PromptType.Confirm, title: 'Pricing Notice', message: 'The selected options in this order reflect the original pricing.\n\nHowever, certain option prices have changed since this order was last opened.\n\nYou can update the original prices to the latest prices in the Summary section.', confirmMessage: 'Take me to the summary section to review prices', cancelMessage: null }))
                if (ret.payload) {
                    handleEditGroup(maps?.optionGroups?.summary)
                }
            }
        }
        makeChecks()
    }, [missingOrderItems, pricingChangedItems])


    // Get cached customization from package class
    function getCustomizations(pckge) {
        if (!pckge || !(pckge.id in customizations.current)) {
            return {}
        }
        return customizations.current[pckge.id]
    }

    function focusFirst(visGroups = null, view = null, force = false) {
        if (!force && (!upgradeView || resuming)) {
            return null
        }
        const upgradeViewOptionGroups = fnc.objIdMap(view ? view.optionGroups : upgradeView.optionGroups)
        let firstGroup = null
        if (upgradeCode) {
            const groups = (visGroups ? visGroups : visibleGroups)
            firstGroup = groups.find((x) => x.id != 'brief')
            if (!firstGroup && groups.length > 0) {
                firstGroup = groups[0]
            }
        } else {
            firstGroup = (visGroups ? visGroups : visibleGroups).find((x) => x.id != 'orders')
            // firstGroup = (visGroups ? visGroups : visibleGroups).find((x) => x.id in upgradeViewOptionGroups)
        }
        handleEditGroup(firstGroup)
        // setFocusGroup(firstGroup)
    }

    function refreshOrder() {

        const resumeSettings = {
            userOrderId: userOrder?.id,
            upgradeOrderId: upgradeOrder?.id,
            groupId: focusGroup?.id,
        }
        localStorage.setItem(RESUME_SETTINGS, JSON.stringify(resumeSettings))
        // Reload page
        getWindowLocation().reload()
        return

        // window.reload()
        dispatch(retrieveUpgradeOrderRecord(upgradeOrder))
            .then((ret) => {
                const newUpgradeOrder = ret.payload.data
                const newAllClients = [...allClients]
                const idx = newAllClients.findIndex((x) => x.id == userOrder.userId)
                if (idx != -1) {
                    const newClient = { ...newAllClients[idx] }
                    const idxB = newClient.orders.findIndex((x) => x.id == userOrder.id)
                    if (idxB != -1) {
                        const idxC = newClient.orders[idxB].upgradeOrderRecords.findIndex((x) => x.id == upgradeOrder.id)
                        if (idxC != -1) {
                            newClient.orders[idxB].upgradeOrderRecords[idxC] = newUpgradeOrder
                            setUserOrder(newClient.orders[idxB])
                            setUpgradeOrder(newClient.orders[idxB].upgardeOrderREcords[idxC])
                            setRefreshKey(Date.now())
                        }
                    }
                    newAllClients[idx] = newClient
                    setAllClients(newAllClients)
                }
            })
    }

    // Add a new order for a user
    function handleAddUserOrders(x) {
        return dispatch(saveUserOrderRecord(x))
            .then((x) => {
                if (x.payload.success) {
                    let newClients = [...allClients]
                    const newUsers = x.payload.data
                    const toAdd = []
                    newUsers.forEach((user) => {
                        const idx = allClients?.findIndex((y) => y.id == user.id)
                        if (idx != -1) {
                            newClients[idx] = user
                        } else {
                            newClients.push(user)
                        }
                    })
                    setAllClients(newClients)
                }
                return x
            })
    }

    function handleUpdateUserOrder(x) {
        return dispatch(saveUserOrderRecord(x))
            .then((x) => {
                if (x.payload.success) {
                    updateClients(x.payload.data)
                    /*const newClients = [...allClients]
                    const newUsers = x.payload.data
                    newUsers.forEach((user) => {
                        const idx = allClients?.findIndex((y) => y.id == user.id)
                        if (idx != -1) {
                            newClients[idx] = user
                        } else {
                            newClients.push(user)
                        }
                    })
                    setAllClients(newClients)*/
                    // clearProgram(false)
                }
                return x
            })
    }
    // Remove a user order
    function handleRemoveUserOrder(user, order) {
        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Delete Order?', message: 'Cannot be undone' }))
            .then((x) => {
                if (x.payload) {
                    const newClients = [...allClients]
                    const idx = newClients.findIndex((x) => x.id == user.id)
                    if (idx != -1) {
                        const idxB = newClients[idx].orders.findIndex((y) => y.id == order.id)
                        if (idxB != -1) {
                            newClients[idx].orders.splice(idxB, 1)
                            if (newClients[idx].orders.length == 0) {
                                newClients.splice(idx, 1)
                            }
                        }
                    }
                    setAllClients(newClients)
                    dispatch(removeUserOrderRecord(order))
                }
            })
    }

    // Edit a user order
    function handleEditUserOrder(user, order) {
        if (!user || !order) {
            return
        }

        const newClients = [...allClients]
        const idx = newClients.findIndex((x) => x.id == user.id)
        if (idx != -1) {
            const idxB = newClients[idx].orders.findIndex((y) => y.appId == order.appId)
            if (idxB != -1) {
                const idxC = newClients[idx].orders[idxB].upgradeOrderRecords.findIndex((z) => z.id == order.id)
                if (idxC != -1) {
                    newClients[idx].orders[idxB].upgradeOrderRecords[idxC] = order
                } else {
                    newClients[idx].orders[idxB].upgradeOrderRecords.push(order)
                }
            }
        }
        setAllClients(newClients)
    }

    function updateClients(newUsers) {
        let orderMap = {}
        allClients.forEach((x, ix) => {
            x.orders.forEach((y) => {
                orderMap[y.id] = { idx: ix, userId: x.id }
            })
        })

        const newClients = [...allClients]
        let toAdd = []
        newUsers.forEach((user) => {
            const idx = allClients?.findIndex((y) => y.id == user.id)
            if (idx != -1) {
                newClients[idx] = user
            } else {
                toAdd.push(user)
                // newClients.push(user)
            }

            user.orders.forEach((z) => {
                if (z.id in orderMap && orderMap[z.id].userId != user.id) {
                    const { idx: idxExisting, userId } = orderMap[z.id]
                    const updatedOrders = allClients[idxExisting].orders = allClients[idxExisting].orders.filter((y) => {
                        return y.id != z.id
                    })
                    newClients[idxExisting].orders = updatedOrders
                }
            })
        })
        setAllClients([...newClients, ...toAdd])
    }

    function handleUpdateUserOrders(x) {
        // Got rhough each order record and change the status
        return dispatch(saveUserOrderRecord(x))
            .then((x) => {
                if (x.payload.success) {
                    updateClients(x.payload.data)

                    clearProgram()
                }
                return x
            })
    }
    // Select client order and initialize
    function handleUserOrder(order, view, goto = true, force = false) {
        if (!order) {
            logger.error('No order')
            return
        }

        if (goto) {
            setTimeout(() => {
                focusFirst()
            }, 0)
        }
        if (!force && upgradeView == view && userOrder?.id == order.id) {
            return
        }

        setUpgradeView(view)
        // Get floorplan variation
        const floorplanVariation = app.maps.floorplanVariation[order.floorplanVariationId]
        const floorplan = app.maps.floorplan[floorplanVariation.floorplanId]

        // Create map of unit building types
        let newBuildingType = null
        if (order.unitId) {
            const unit = app.maps.unit[order.unitId]
            if (unit) {
                const building = app.maps.building[unit.buildingId]
                const buildingType = app.maps.buildingType[building.buildingTypeId]
                newBuildingType = buildingType
            } else {
                logger.error("Missing unit for order", order)
            }
        } else {
            const unitBuildingTypeMap = {}
            app.units.forEach((x) => {
                const building = app.maps.building[x.buildingId]
                const buildingType = app.maps.buildingType[building.buildingTypeId]
                unitBuildingTypeMap[x.id] = buildingType
            })
            if (floorplan.units.length > 0) {
                newBuildingType = unitBuildingTypeMap[floorplan.units[0].id]
            }
        }
        setBedBath(floorplanVariationBedBathCode(floorplanVariation))
        customizations.current = {}
        setSavePending(false)
        setSelections({})
        setFloorplanVariation(floorplanVariation)
        setFloorplan(floorplan)
        setBuildingType(newBuildingType)
        setUserOrder(order)
        setUnit(order && app.maps.unit ? app.maps.unit[order.unitId] : null)
        setReload(Date.now())
        let newOrder = order.upgradeOrderRecords.find((x) => x.upgradeViewId == view.id && x.floorplanVariationId == floorplanVariation.id)
        if (!newOrder) {
            newOrder = {
                userId: order ? order.userId : user?.id,
                appId: app.meta.id,
                floorplanVariationId: floorplanVariation?.id,
                upgradeViewId: view.id,
                userOrderRecordId: order?.id,
                // ...order,
                optionProductRecords: [],
            }

            setTimeout(() => {
                handleEdit(newOrder)
            }, 100)
        }
        setUpgradeOrder(fnc.copyObj(newOrder))
        if (goto) {
            moveToFirst.current = true
        }
    }

    function filterClients(x) {
        if (nameFilter && nameFilter.length > 0 && !x.name?.toLowerCase().includes(nameFilter.toLowerCase()) && !x.email?.toLowerCase().includes(nameFilter.toLowerCase())) {
            return false
        }

        if (statusFilter) {
            if (!x.orders.filter((x) => x.appId == app.meta.id).find((x) => {
                if (Array.isArray(statusFilter)) {
                    if (statusFilter.includes(0) && x.upgradeOrderRecords.length == 0) {
                        return true
                    }
                    return x.upgradeOrderRecords.find((y) => statusFilter.includes(y.upgradeOrderStatusId)) != null
                }
                if (statusFilter == 0 && x.upgradeOrderRecords.length == 0) {
                    return true
                }
                return x.upgradeOrderRecords.find((y) => y.upgradeOrderStatusId == statusFilter) != null
            })) {
                return false
            }
        }

        if (unitFilter && unitFilter.length > 0) {
            const matchingOrder = x.orders.find((y) => {
                const unit = app.maps.unit[y.unitId]
                const tokens = unitFilter.split(',')
                const match = tokens.map((z) => z.trim()).find((z) => {
                    if (z.endsWith('*')) {
                        return unit && unit.name.toLowerCase().startsWith(z.slice(0, -1).toLowerCase())
                    }
                    return unit && unit.name.toLowerCase().includes(z.toLowerCase())
                })
                return match != null
            })
            return matchingOrder != null
        }
        return true
    }

    function filterClientOrders(x) {
        if (statusFilter != null) {
            if (Array.isArray(statusFilter)) {
                if (!x.upgradeOrderRecords.find((z) => statusFilter.includes(z.upgradeOrderStatusId))) {
                    return false
                }
            } else if (!x.upgradeOrderRecords.find((z) => z.upgradeOrderStatusId == statusFilter)) {
                return false
            }
        }

        if (unitFilter && unitFilter.length > 0) {
            const unit = app.maps.unit[x.unitId]
            const tokens = unitFilter.split(',')
            const match = tokens.map((z) => z.trim()).find((z) => {
                if (z.endsWith('*')) {
                    return unit && unit.name.toLowerCase().startsWith(z.slice(0, -1).toLowerCase())
                }
                return unit && unit.name.toLowerCase().includes(z.toLowerCase())
            })
            if (match == null) {
                return false
            }

        }

        return x.appId == app.meta.id
    }

    function handleClientOperation(operation) {
        if (operation.startsWith('notify-')) {
            const viewCode = operation.replace('notify-', '')
            const clients = [...allClients].filter(filterClients)
            const upgradeView = upgrades.views.find((x) => x.id == viewCode)
            if (!upgradeView) {
                logger.error("No upgrade view", viewCode)
            }

            dispatch(showPrompt({ type: PromptType.Confirm, title: `Send Bulk Notification (${clients.length})`, message: `Send bulk notification to ${clients.length} clients?`, confirmMessage: 'Send' }))
                .then((x) => {
                    if (!x.payload) {
                        return
                    }
                    const orders = []
                    clients.forEach((x) => {
                        x.orders.filter(filterClientOrders).forEach((y) => {
                            orders.push({
                                userId: x.id,
                                userOrderRecordId: y.id,
                                upgradeViewId: viewCode,
                            })
                        })
                    })
                    let prom = [
                        dispatch(showPrompt({ ...PromptOptions.NotifySending, title: `Sending Bulk Notification (${orders.length})` })),
                        dispatch(notifyUpgrade({ app, organization, data: { orders } }))
                    ]
                    Promise.all(prom).then((x) => {
                        if (x[1].payload.success) {
                            // onChange(user, x[1].payload.order)
                            const newClients = [...allClients]
                            x[1].payload.sends.forEach((x) => {
                                const order = x.order
                                const idx = newClients.findIndex((x) => x.id == order.userId)
                                if (idx != -1) {
                                    const idxB = newClients[idx].orders.findIndex((y) => y.appId == order.appId)
                                    if (idxB != -1) {
                                        const idxC = newClients[idx].orders[idxB].upgradeOrderRecords.findIndex((z) => z.id == order.id)
                                        if (idxC != -1) {
                                            newClients[idx].orders[idxB].upgradeOrderRecords[idxC] = order
                                        } else {
                                            newClients[idx].orders[idxB].upgradeOrderRecords.push(order)
                                        }
                                    }
                                }
                            })
                            setAllClients(newClients)
                        }
                    })
                })
        }
    }

    function handleIndependentPlan(x) {
        setFloorplanVariation(null)
        setFloorplan(null)
        setBuildingType(null)
        setUserOrder(null)
        setUpgradeOrder(null)
        setUpgradeView(x)
        setPlanIndependent(true)
        moveToFirst.current = true
    }

    // Set view
    function handleUpgradeView(x) {
        setUpgradeView(x)
        moveToFirst.current = true
    }

    // Set building type
    function handleBuildingType(x) {
        setFloorplanVariation(null)
        setFloorplan(null)
        setBuildingType(x)
        setInitialized(1)
    }

    // Set floorplan variation
    function handleFloorplanVariation(x) {
        setFloorplanVariation(x)
        setFloorplan(app.maps.floorplan[x.floorplanId])
        setUnit(upgradeOrder && app.maps.unit ? app.maps.unit[upgradeOrder.unitId] : null)
        setBedBath(floorplanVariationBedBathCode(x))

        if (views.length < 1) {
            moveToFirst.current = true
        }

        // Go to first  group
        setTimeout(() => {
            if (views.length > 1) {
                handleEditGroup(maps.optionGroups.views)
            }
        }, 100)
    }

    async function handleUpdateUpgradeOrder(record) {
        return dispatch(saveUpgradeOrderRecord(record))
            .then((x) => {
                if (x.payload.success) {
                    const newClients = allClients ? [...allClients] : []
                    const newUpgradeOrder = x.payload.data
                    setUpgradeOrder({ ...upgradeOrder, ...newUpgradeOrder })
                    const userIdx = newClients.findIndex((x) => x.id == newUpgradeOrder.userId)
                    if (userIdx != -1) {
                        const user = newClients[userIdx]
                        const userOrderIdx = user.orders.findIndex((x) => x.id == newUpgradeOrder.userOrderRecordId)
                        if (userOrderIdx != -1) {
                            const upgradeOrderIdx = user.orders[userOrderIdx].upgradeOrderRecords.findIndex((x) => x.id == newUpgradeOrder.id)
                            if (upgradeOrderIdx != -1) {
                                user.orders = [...user.orders]
                                user.orders[userOrderIdx].upgradeOrderRecords = [...user.orders[userOrderIdx].upgradeOrderRecords]
                                user.orders[userOrderIdx].upgradeOrderRecords[upgradeOrderIdx] = newUpgradeOrder
                                newClients[userIdx] = user
                                if (userOrder != null && userOrder.id == user.orders[userOrderIdx]?.id) {
                                    setUserOrder(user.orders[userOrderIdx])
                                }
                            }
                        }
                        setAllClients(newClients)
                    }
                } else {
                    // Locked
                    if (x.payload.status == 423) {
                        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Order in use', message: 'This order is currently being edited in another session, please complete the other session or again later', cancelMessage: null }))
                    } else {
                        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Something went wrong', message: 'An error occuring saving this upgrade order, please contact support', cancelMessage: null }))
                    }
                }
                return x
            })
    }

    function handleGeneratePdf(x) {
        const { order, user } = x
        dispatch(showPrompt({
            type: PromptType.Confirm, title: 'Generate PDF', message: 'Here you can generate a new PDF for this upgrade order with the option to change the following fields appear on the order', submitMessage: 'Generate',
            fields: [
                { id: 'purchaser', label: 'Purchaser', formFieldTypeId: FormFieldType.Text, default: user.name },
            ]
        }))
            .then(async (x) => {
                if (x.payload) {
                    // Simulate generating pdf by loading order and calling functionality to generate
                    if (upgrades.views.length == 1) {
                        setGeneratingPdf({ generated: false, data: x.payload })
                        handleUserOrder(order, upgrades.views[0])
                    }
                }

            })
    }

    function handleEndSession(override = false) {
        let message = 'Would you like to unlock and edit this order?\n\nWarning: Unlocking this order will force the order to be locked on the other device.'
        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Order is currently locked', message, submitMessage: 'Unlock Order' }))
            .then((x) => {
                if (x.payload) {
                    dispatch(endSessionUpgradeOrderRecord(upgradeOrder))
                        .then((x) => {
                            setOrderSession(null)
                            if (override) {
                                refreshOrder()
                            } else {
                                clearProgram()
                            }
                        })
                }
            })
    }

    async function handleSave(e, force = false) {
        if (!force && (!savePending || disableChanges)) {
            return
        }

        if (e) {
            e.stopPropagation()
        }

        // Prompt login if no user
        if (!loggedIn) {
            window.dispatchEvent(new CustomEvent(CustomEventType.Register, { detail: { title: 'Sign up to save selections' } }))
            return
        }

        if (!upgradeView || standaloneView) {
            return
        }

        saveProxy.current = true

        // Parse options into record
        const record = {
            userId: userOrder ? userOrder.userId : user?.id,
            appId: app.meta.id,
            floorplanVariationId: floorplanVariation?.id,
            upgradeViewId: upgradeView.id,
            userOrderRecordId: userOrder?.id,
            ...upgradeOrder,
            optionProductRecords: [],
        }

        Object.keys(selections).forEach((x) => {
            selections[x].forEach((y) => {
                record.optionProductRecords.push(parseOptionProductRecord(maps, buildingType, floorplan, floorplanVariation, unit, y))
            })
        })
        setUpgradeOrder(record)

        setSaving(1)

        let code = 0
        if (user) {
            const ret = await handleUpdateUpgradeOrder(record)
            // await new Promise((resolve, reject) => setTimeout(resolve, 100))
            // await new Promise((resolve, reject) => setTimeout(resolve, 5000))

            if (!ret.payload.success) {
                code = 3
                setSaving(code)
            } else {
                code = 2
                setSaving(code)

                const newRecord = ret.payload.data
                // Replace client if relevant
                if (userOrder) {
                    const clientIdx = allClients ? allClients.findIndex((x) => x.id == userOrder.userId) : -1
                    if (clientIdx != -1) {
                        const client = allClients[clientIdx]
                        const newClient = { ...client }
                        const orderIdx = client.orders.findIndex((x) => x.id == userOrder.id)
                        if (orderIdx != -1) {
                            const order = client.orders[orderIdx]
                            const upgradeOrderIdx = order.upgradeOrderRecords.findIndex((x) => x.id == newRecord.id)
                            if (upgradeOrderIdx != -1) {
                                order.upgradeOrderRecords[upgradeOrderIdx] = newRecord
                            } else {
                                order.upgradeOrderRecords.push(newRecord)
                            }
                        }
                        setAllClients([...allClients])
                    }
                }
                // if (upgradeOrder) {
                // setUpgradeOrder({ ...upgradeOrder, ...newRecord })
                // }
            }
            localStorage.setItem(UPGRADE_ORDER_RECORD_KEY, JSON.stringify(record))
        } else {
            localStorage.setItem(UPGRADE_ORDER_RECORD_KEY, JSON.stringify(record))
            code = 2
            setSaving(code)
        }

        saveProxy.current = false
        clearTimeout(saveTimeout.current)
        // If another save not waiting to apply
        if (!saveQueued.current) {
            saveTimeout.current = setTimeout(() => {
                // Make sure anther save wasn't started
                if (!saveProxy.current) {
                    setSaving(code * 2)
                    saveTimeout.current = setTimeout(() => {
                        setSaving(0)
                        setSavePending(false)
                        saveTimeout.current = null
                    }, 500)
                }
            }, 1500)
        }
    }

    function handleSwipeMove(position: Position) {
        const newSwipe = 100 * (position.x / window.innerWidth)
        setSwipe(newSwipe)
    }

    function handleSwipeStart() {
        // setCanSwipe(!zooming)
    }

    function handleSwipeEnd() {
        setSwipe(0)
    }

    function handleScroll(x) {
        const scroll = scrollRef.scrollTop
        const scrollSize = scrollRef.offsetHeight

        let newFocus = focusGroup
        // Find all groups in children
        document.querySelectorAll('.upgrade-focus-group').forEach((x) => {
            const id = x.getAttribute('data-id')
            const group = maps.optionGroups[id]
            const top = x.offsetTop
            const height = x.offsetHeight

            const topLine = Math.max(scroll, top)
            const bottomLine = Math.min(scroll + scrollSize, top + height)
            const buffer = 400

            if (!newFocus || (newFocus != null
                && ((scroll > (top - buffer) && scroll < (top + height + buffer))
                    || (scrollRef.scrollTop + scrollRef.offsetHeight > scrollRef.scrollHeight)))
            ) {
                newFocus = group
            }
        })

        if (newFocus && newFocus.id != focusGroup?.id) {
            setFocusGroup(newFocus)
        }
        targetScroll.current = null
    }

    function handleEditGroup(group, option = null, variation = null, component = null, jumpTo = false) {
        if (!group) {
            return null
        }
        const elem = document.querySelector(`.upgrade-focus-group[data-id='${group.id}']`)
        if (elem) {
            if (!mobileView) {
                clearTimeout(scrollTimeout.current)
                setAutoScrolling(true)
                scrollTimeout.current = setTimeout(() => {
                    setAutoScrolling(false)
                }, 1000)
                if (scrollRef) {
                    scrollRef.scrollTo({
                        top: elem.offsetTop - 100,
                        behavior: jumpTo ? 'auto' : 'smooth',
                    })
                }
            }

            // Send message to UpgradeGroup components
            if (option) {
                window.dispatchEvent(new CustomEvent(CustomEventType.FocusUpgradeOption, { detail: { group, option, variation, component } }))
            } else if (group.options && group.options.filter((x) => x.upgradeOptionId != null).length > 0) {
                const firstOption = maps.option[[...group.options].filter((x) => x.upgradeOptionId != null).sort(orderSort)[0].upgradeOptionId]
                if (firstOption) {
                    const { variation, component } = optionSelection(firstOption)
                    window.dispatchEvent(new CustomEvent(CustomEventType.FocusUpgradeOption, { detail: { group, option: firstOption, variation, component } }))
                }
            }
        }
        setFocusGroup(group)
        setTimeout(() => {
            if (popup == UpgradePopupType.Summary) {
                setPopup(UpgradePopupType.None)
            }
        }, 100)
    }

    function handleGroupOrder(group, order) {
        // Swap order with item at this area
        let A = null
        let B = null
        // Check if we need to initialize
        if (upgradeView.optionGroups.filter((x) => x.order == 0).length > 1) {
            upgradeView.optionGroups.forEach((x, ix) => {
                x.order = ix
            })
        }
        upgradeView.optionGroups.forEach((x, ix) => {
            if (x.order == order) {
                A = x
            } else if (x == group) {
                B = x
                B.order = group.order
            }
        })
        if (A && B) {
            const temp = A.order
            A.order = B.order
            B.order = temp
        }
        setAutoScrolling(true)
        onChange()
        setTimeout(() => {
            handleEditGroup(group)
        }, 100)
    }

    function handleInfo(group, option, optionVariation, component, product) {
        setEditingProduct(null)
        if (product.upgradePackageId) {
            const pckge = maps.package[product.upgradePackageId]
            if (pckge.products.length == null) {
                return
            }

            let firstItem = [...pckge.products].sort(orderSort)[0]
            let p = maps.product[firstItem.upgradeProductId]
            const o = maps.option[firstItem.upgradeOptionId]
            const v = o.variations[0]
            const c = v.components[0]
            const k = selectionKey(group, o, v, c)
            if (pckge.id in customizations.current && k in customizations.current[pckge.id]) {
                p = maps.product[customizations.current[pckge.id][k]]
            }
            setFocusPackage(pckge)
            setFocusProduct({ group, ...optionSelection(o, p) })
        } else {
            setFocusPackage(null)
            setFocusProduct({
                group, option, variation: optionVariation, component, product,
            })
        }
        setFocusPage(0)
    }

    function handleSplitSignaturePage() {
        localStorage.setItem(SPLIT_SIGNATURE_KEY, !splitSignaturePage)
        setSplitSignaturePage(!splitSignaturePage)
    }

    function highlightImage(x) {
        dispatch(showPrompt({ type: PromptType.Lightbox, app, media: [x] }))
    }

    function handleReset() {
        if (disableChanges) {
            return
        }

        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Clear Selections?', message: 'Warning: all your selections will be lost' }))
            .then((x) => {
                if (x.payload) {
                    sessionStorage.setItem(customizationsKey(), JSON.stringify({}))
                    customizations.current = {}
                    setSelections({ ...defaultSelections })
                    setUpgradeOrder({ ...upgradeOrder, upgradeOrderStatusId: UpgradeOrderStatus.Pending })
                    saveDelay.current = 0
                    setLastChange(Date.now())
                    setSavePending(true)

                    clearGlobalChanges(app)

                    if (upgradeView) {
                        focusFirst()
                    }

                    // Delay a frame allow selections to propogates
                    setTimeout(() => {
                        window.dispatchEvent(new CustomEvent(CustomEventType.ClearSelection))
                    }, 0)
                }
            })
    }

    function handleEdit(_upgradeOrder) {
        const editOrder = _upgradeOrder || upgradeOrder
        setUpgradeOrder({ ...editOrder, upgradeOrderStatusId: progressStatus })
        setDisableChanges(false)
        setSavePending(true)
        setLastChange(Date.now())
        // setSavePending(true)
        // handleSave(null, true)
    }

    function handleResend(resend = true) {
        let prom = [
            dispatch(showPrompt(resend ? PromptOptions.EmailSending : PromptOptions.CompleteSending)),
            dispatch(completeUpgrade({ app, organization, data: upgradeOrder }))
        ]
        Promise.all(prom).then((x) => {
            // if (x[1].payload && x[1].payload.success) {
            // }
        })
    }

    async function handleComplete() {
        if (disableChanges) {
            return
        }
        setUpgradeOrder({ ...upgradeOrder, upgradeOrderStatusId: UpgradeOrderStatus.Complete })
        saveDelay.current = 0
        setLastChange(Date.now())
        setSavePending(true)
        dispatch(pushNotification({ status: NotificationStatus.Info, message: 'Upgrades Complete' }))
        // Send complete email
        handleResend(false)
    }

    function handlePackage(group, option, variation, component, pckge, apply = true, updateOnlyPricing = false, force = false, reselect = false) {
        if (disableChanges && !force) {
            return
        }

        let newSelections = { ...selections }
        const key = selectionKey(group, option, variation, component)
        let newSelection = false
        if (key in newSelections) {
            const oldPackage = maps.package[newSelections[key][0].product.upgradePackageId]
            if (oldPackage) {
                // Remove all selections that include this package
                Object.keys(newSelections).filter((x) => {
                    const match = newSelections[x].find((y) => y.package?.id == oldPackage.id)
                    return match != null
                }).forEach((x) => {
                    delete newSelections[x]
                })
            }
            if (!oldPackage || oldPackage.id != pckge.id) {
                newSelection = true
            } else if (key in newSelections) {
                delete newSelections[key]
            }
        } else {
            newSelection = true
        }
        let changes = {}
        if (newSelection || updateOnlyPricing || reselect) {
            // newSelections[key] = [{ package: pckge, option, variation: option?.variations[0], component: option?.variations[0]?.components[0] }]

            newSelections[key] = [{ group, ...optionSelection(option, { upgradePackageId: pckge.id }, pckge) }]
            changes[key] = newSelections[key]
            // Add all options
            pckge.products.forEach((x) => {
                const product = maps.product[x.upgradeProductId]
                // Inherit global property
                const productOption = maps.option[x.upgradeOptionId]
                const { variation, component } = optionSelection(productOption)
                const key = selectionKey(group, productOption, variation, component)
                newSelections[key] = [{
                    group, option: productOption, component, variation, product, package: pckge,
                }]
                changes[key] = newSelections[key]
            })

            // Check for customizations
            const customizations = getCustomizations(pckge)
            Object.keys(customizations).forEach((x) => {
                if (customizations[x] in maps.product) {
                    const product = maps.product[customizations[x]]
                    if (x in newSelections) {
                        newSelections[x] = newSelections[x].map((y) => ({ ...y, product }))
                    }
                }
            })
        }
        // Get dependent package
        if (pckge.dependentPackageId != null && apply) {
            const dependentPackage = maps.package[pckge.dependentPackageId]
            // Determine which group, option, variation, component to select for this package
            // const packageGroup = maps.packageGroup[dependentPackage.id]
            const packageOption = maps.packageOption[dependentPackage.id]
            if (packageOption) {
                const packageVariation = packageOption.variations[0]
                const packageComponent = packageVariation.components[0]
                // First check if a selection exists for this option,
                // If it doesnt, or the package tier is lower, apply the dependent package
                const key = selectionKey(group, packageOption, packageVariation, packageComponent)
                const sel = newSelections[key]
                let shouldApplyDependent = true
                if (sel && sel.length > 0) {
                    const selPackage = sel[0].package
                    if (selPackage && selPackage.upgradeTierId == dependentPackage.upgradeTierId) {
                        shouldApplyDependent = false
                    }
                }
                if (shouldApplyDependent) {
                    const subSel = handlePackage(group, packageOption, packageVariation, packageComponent, dependentPackage, false)
                    newSelections = { ...newSelections, ...subSel }
                }
            }
        }

        // setFocusOption(false)
        if (apply) {
            setSelections(newSelections)
            saveDelay.current = SAVE_DELAY
            setSavePending(true)
            setUpgradeOrder({ ...upgradeOrder, upgradeOrderStatusId: progressStatus })
            setLastChange(Date.now())
        }
        return changes
    }

    function handleTerms() {
        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Terms and Conditions', message: app.meta.termsAndConditions, confirmMessage: 'I agree to all terms and conditions', cancelMessage: 'I disagree' }))
            .then((x) => {
                if (x.payload) {
                    setTermsAndConditions(true)
                    localStorage.setItem(TOC_KEY, true)
                }
            })
    }


    function handleFAQNavigate() {
        userKey.current = null
        setInitialized(0)
        setLoading(0)
        dispatch(navigateAsync({ app, pageType: PageType.Upgrades, options: { dataLink } }))
    }

    function applyProductSelection(sel, group, option, variation, component, product, pckge, _maps = null, updateOnlyPricing = false) {
        if (!_maps) {
            _maps = maps
        }

        const key = selectionKey(group, option, variation, component)
        let bucket = null
        if (!(key in sel)) {
            sel[key] = []
        }
        bucket = sel[key]

        const deleteBucket = () => {
            delete sel[key]
        }
        const setBucket = (x) => {
            sel[key] = x
        }

        let selection = {
            group, option, variation, component, product, package: pckge,
        }
        if (option.global) {
            selection.group = null
        }
        if (component && component.configureQuantity) {
            selection.quantity = 1
        }

        // Apply selection
        const hasDefault = component && component.products?.find((x) => x.isDefault)
        if (!(variation && variation.multiSelect)) {
            const idx = bucket.findIndex((x) => x.product.id == product.id)
            if (idx == -1) {
                setBucket([selection])
            } else if (updateOnlyPricing) {
                bucket[idx] = selection
            } else if (!hasDefault && pckge == null) {
                deleteBucket()
            }
        } else if (bucket && Array.isArray(bucket)) {
            const idx = bucket.findIndex((x) => x.product.id == product.id)
            if (idx == -1) {
                bucket.push(selection)
            } else if (updateOnlyPricing) {
                bucket[idx] = selection
            } else if (!hasDefault && pckge == null) {
                bucket.splice(idx, 1)
                if (bucket.length == 0) {
                    deleteBucket()
                }
            }
        } else {
            setBucket([selection])
        }

        // Check dependency
        if (component.id in _maps.componentDependencies) {
            _maps.componentDependencies[component.id].forEach((x) => {
                // Only if variation currently selected
                const depKey = selectionKey(x.group, x.option, x.variation, x.component)
                if (depKey in sel) {
                    applyProductSelection(sel, x.group, x.option, x.variation, x.component, product, pckge, _maps, updateOnlyPricing)
                    return
                }
            })
        } else if (component.dependencyUpgradeComponentId) {
            // Determine product assigned to this component
            const matching = Object.keys(sel).find((x) => {
                return sel[x].find((y) => y.component.id == component.dependencyUpgradeComponentId)
            })
            // Apply if product is within this component
            if (matching != null) {
                selection.product = sel[matching][0].product
                setBucket([selection])
            }
        }
    }

    function handlePackageReset(group, pckge) {
        const newSelections = { ...selections }
        pckge.products.forEach((x) => {
            const o = maps.option[x.upgradeOptionId]
            const v = o?.variations[0]
            const c = v?.components[0]
            const p = maps.product[x.upgradeProductId]

            applyProductSelection(newSelections, group, o, v, c, p, pckge)
        })

        // setFocusOption(false)
        setSelections(newSelections)
        saveDelay.current = SAVE_DELAY
        setSavePending(true)
        setUpgradeOrder({ ...upgradeOrder, upgradeOrderStatusId: progressStatus })
        setLastChange(Date.now())
    }

    async function handleProduct(group, option, variation, component, product, pckge = null, updateOnlyPricing = false) {
        if (disableChanges) {
            return
        }

        // If package check if we're at customization limit
        if (pckge
            && pckge.customizeLimit > 0
            && pckge.id in customizations.current
            && Object.keys(customizations.current[pckge.id]).length >= pckge.customizeLimit) {
            // Check it not default for package
            const key = selectionKey(group, option, variation, component)
            const defaultProduct = pckge.products.find((x) => x.upgradeOptionId == option.id)
            if (!(key in customizations.current[pckge.id]) && defaultProduct.upgradeProductId != product.id) {
                dispatch(showPrompt({ type: PromptType.Confirm, title: `Customization Limit Reached (${pckge.customizeLimit})`, message: 'You have reached the customization limit for this package.  Please remove a customization to add this product.', cancelMessage: null }));
                return
            }
        }

        const newSelections = { ...selections }

        applyProductSelection(newSelections, group, option, variation, component, product, pckge, null, updateOnlyPricing)
        // setFocusOption(false)
        setSelections(newSelections)
        saveDelay.current = SAVE_DELAY
        setSavePending(true)
        setUpgradeOrder({ ...upgradeOrder, upgradeOrderStatusId: progressStatus })
        setLastChange(Date.now())
    }

    function handleVariation(group, option, variation, pckge = null) {
        if (disableChanges) {
            return
        }

        const newSelections = { ...selections }
        // Set variation, enable defaults
        if (option.variations.length > 1) {
            const otherVariations = new Set(option.variations.filter((x) => x.id != variation.id).map((x) => x.id))
            Object.keys(newSelections).forEach((x) => {
                for (let i = 0; i < newSelections[x].length; i++) {
                    if ((option.global || (newSelections[x][i].group?.id == group?.id)) && otherVariations.has(newSelections[x][i].variation?.id)) {
                        delete newSelections[x]
                        break
                    }
                }
            })
        }

        variation.components.forEach((x) => {
            const key = selectionKey(group, option, variation, x)
            if (!(key in newSelections)) {
                x.products.forEach((y) => {
                    if (y.isDefault) {
                        const product = maps.product[y.upgradeProductId]
                        applyProductSelection(newSelections, group, option, variation, x, product, pckge)
                    }
                })
            }
        })

        // setFocusOption(false)
        setSelections(newSelections)
        saveDelay.current = SAVE_DELAY
        setSavePending(true)
        setUpgradeOrder({ ...upgradeOrder, upgradeOrderStatusId: progressStatus })
        setLastChange(Date.now())
    }

    function handleProductValue(group, option, variation, component, product, field, value, pckge = null) {
        if (disableChanges) {
            return
        }

        const newSelections = { ...selections }
        const key = selectionKey(group, option, variation, component)
        if (key in newSelections) {
            const idx = newSelections[key].findIndex((x) => {
                if (pckge) {
                    return x.package.id == pckge.id
                }
                return x.product.id == product.id
            })
            if (idx != -1) {
                newSelections[key][idx][field] = value
            }
        }

        // setFocusOption(false)
        setSelections(newSelections)
        saveDelay.current = SAVE_DELAY
        setSavePending(true)
        setUpgradeOrder({ ...upgradeOrder, upgradeOrderStatusId: progressStatus })
        /*if (upgradeOrder.upgraderOrderStatusId != UpgradeOrderStatus.Complete) {
            setUpgradeOrder({ ...upgradeOrder, upgradeOrderStatusId: progressStatus})
        }*/
        setLastChange(Date.now())
    }

    async function handleEditingProduct(x) {
        if (x) {
            setEditingProduct(x)
        } else {
            // Submit product
            setSavingEditingProduct(true)
            const ret = await dispatch(updateUpgradeProduct({ app, upgradeProduct: editingProduct }))
            const newProduct = ret.payload.data
            const productIdx = upgrades.products.findIndex((x) => x.id == newProduct.id)
            if (productIdx != -1) {
                const newUpgrades = { ...upgrades }
                newUpgrades.products[productIdx] = newProduct
                if (focusProduct && focusProduct.product.id == newProduct.id) {
                    const newFocus = { ...focusProduct, product: newProduct }
                    setFocusProduct(newFocus)
                }
                setUpgrades(newUpgrades)
            }
            setSavingEditingProduct(false)
            setEditingProduct(null)
        }
    }

    function handleEditingProductValue(key, value) {
        if (!editingProduct) {
            return
        }
        const newEditingProduct = { ...editingProduct, [key]: value }
        setEditingProduct(newEditingProduct)
    }

    function handleBegin() {
        // setDBCheck(Date.now())
        setBegin(true)
        if (upgradeOrder.upgradeOrderStatusId == UpgradeOrderStatus.Pending || upgradeOrder.upgradeOrderStatusId == UpgradeOrderStatus.Ready) {
            setUpgradeOrder({ ...upgradeOrder, upgradeOrderStatusId: UpgradeOrderStatus.Progress })
            setSavePending(true)
            setLastChange(Date.now())
        }
        if (!upgradeCode) {
            nextGroup()
        } else {
            moveToFirst.current = true
        }
    }

    function nextGroup() {
        if (!focusGroup) {
            return
        }
        const current = visibleGroups.findIndex((x) => x.id == focusGroup.id)
        if (current != -1) {
            handleEditGroup(visibleGroups[(current + 1) % visibleGroups.length])
        }
    }

    function prevGroup() {
        if (!focusGroup) {
            return
        }
        const current = visibleGroups.findIndex((x) => x.id == focusGroup.id)
        if (current != -1) {
            handleEditGroup(visibleGroups[(current + visibleGroups.length - 1) % visibleGroups.length])
        }
    }

    function handlePreview(ix, media) {
        dispatch(showPrompt({ type: PromptType.Lightbox, app, startIndex: ix, media }))
    }

    function handleFaq() {
        setPopup(UpgradePopupType.Faq)
        // setShowFaq(true)
    }

    function handleSetAllBooking() {
        dispatch(showPrompt({ type: PromptType.Input, title: 'Set booking link for filtered orders', message: 'Enter the booking link' }))
            .then((x) => {
                // Update all visible orders with this booking link
                const newOrders = allClients.filter(filterClients).reduce((acc, cur) => {
                    cur.orders.forEach((y) => {
                        acc.push({ ...y, bookingLink: x.payload?.length > 0 ? x.payload : null })
                    })
                    return acc
                }, [])
                handleUpdateUserOrder(newOrders)
            })
    }

    async function handleDownloadOrders(type) {
        let rows = []
        switch (type) {
            case 'status':
                allClients.forEach((x) => {
                    x.orders.filter((x) => x.appId == app.meta.id).forEach((y) => {
                        const unit = app.maps.unit[y.unitId]
                        const floorplanVariation = app.maps.floorplanVariation[y.floorplanVariationId]
                        const floorplan = app.maps.floorplan[floorplanVariation?.floorplanId]
                        let data = {
                            email: x.email,
                            name: x.name,
                            userStatus: UserOrderStatus[y.userOrderStatusId],
                            unit: unit?.name,
                            orderStatus: 'None',
                            floorplan: floorplan?.name,
                            variation: floorplan?.variations.length > 1 ? floorplanVariation?.name : null,
                        }
                        if (y.upgradeOrderRecords.length > 0) {
                            y.upgradeOrderRecords.forEach((z) => {
                                const view = upgrades.views.find((x) => x.id == z.upgradeViewId)
                                const dataB = { ...data, orderStatus: UpgradeOrderStatus[z.upgradeOrderStatusId], view: view.name }
                                rows.push(dataB)
                            })
                        } else {
                            rows.push(data)
                        }
                    })
                })
                downloadCSV({
                    filename: `${app.meta.name} Orders.csv`,
                    data: rows,
                    headers: ['email', 'name', 'unit', 'floorplan', 'variation', 'view', 'orderStatus']
                })
                break
            case 'financial':
                const getDefaultProduct = (pckge, option) => {
                    const ret = pckge.products.find((x) => x.upgradeOptionId == option.id)
                    if (ret) {
                        return maps.product[ret.upgradeProductId]
                    }
                    return null
                }

                const pckgeCustomized = {}
                const getPackageCustomized = (pckge, order) => {
                    if (!(pckge.id in pckgeCustomized)) {
                        pckgeCustomized[pckge.id] = null
                        pckge.products.forEach((x) => {
                            const productId = x.upgradeProductId
                            const optionId = x.upgradeOptionId
                            const option = maps.option[optionId]
                            const product = maps.product[productId]
                            const record = order.optionProductRecords.find((y) => y.upgradeOptionId == optionId)

                            if (record && record.upgradeProductId != productId) {
                                // Determine which package tier this customization is in
                                const ogPackages = maps.productPackage[record.upgradeProductId]?.map((x) => maps.package[x]?.upgradeTierId)
                                // Get max ogPackage
                                const max = ogPackages?.length > 0 ? Math.max(...ogPackages) : 0
                                if (max) {
                                    pckgeCustomized[pckge.id] = { ...pckgeCustomized[pckge.id], [max]: 1 }
                                    // pckgeCustomized[pckge.id] = pckge.id in pckgeCustomized ? Math.max(pckgeCustomized[pckge.id], max) : max
                                    // pckgeCustomized[pckge.id] = pckge.id in pckgeCustomized ? Math.max(pckgeCustomized[pckge.id], max) : max
                                } else {
                                    // pckgeCustomized[pckge.id] = pckge.upgradeTierId
                                    pckgeCustomized[pckge.id] = { ...pckgeCustomized[pckge.id], [pckge.upgradeTierId]: 1 }
                                    // pckgeCustomized[pckge.id][pckge.upgradeTierId] = 1
                                }
                            }
                        })

                    }
                    return pckgeCustomized[pckge.id]
                }
                const pckgeOverrided = {}
                const getPackageOverriden = (pckge, order, spec) => {
                    const record = order.optionProductRecords.find((x) => x.upgradePackageId == pckge.id && x.upgradeProductId == null)
                    if (record && record.priceOverride != null && !(pckge.id in pckgeOverrided)) {
                        pckgeOverrided[pckge.id] = true
                    }
                    return pckgeOverrided[pckge.id]
                }
                let proms = []

                dispatch(showPrompt({ type: PromptType.Pending, title: 'Generating Report', message: 'Please wait' }))

                allOrders.forEach((o) => {
                    if (!user?.email?.includes('@inventdev.com') && (o.user?.email?.includes('inventdev' || o.user?.email?.includes('payned') || o.user?.email?.includes('jamesdhooks')))) {
                        return
                    }

                    const order = o.order
                    const unit = app.maps.unit[order.unitId]
                    const floorplanVariation = app.maps.floorplanVariation[order.floorplanVariationId]
                    const floorplan = app.maps.floorplan[floorplanVariation?.floorplanId]
                    let building, buildingType = null
                    if (!unit) {
                        logger.error("Missing unit for order", order)

                    }
                    if (unit?.buildingId) {
                        building = app.maps.building[unit.buildingId]
                        if (building) {
                            buildingType = app.maps.buildingType[building.buildingTypeId]
                        }
                    }
                    const data = { 'UNIT NO.': unit?.name, 'FLOORPLAN': floorplan?.name, 'APPOINTMENT DATE': order.appointmentDate, 'SIGNOFF DATE': order.agreementDate }
                    if (order.upgradeOrderRecords.length > 0) {
                        order.upgradeOrderRecords.forEach((z) => {
                            proms.push(new Promise(async (resolve, reject) => {
                                let rows = []
                                const view = upgrades.views.find((x) => x.id == z.upgradeViewId)
                                // Arbitrary delay before each request to prevent server overload
                                await new Promise((resolve) => setTimeout(resolve, 1000))
                                dispatch(retrieveUpgradeLogs({ app, user: o.user, upgradeView: view }))
                                    .then((ret) => {
                                        let logJSON = null
                                        try {
                                            if (ret.payload?.data?.json?.length > 0) {
                                                logJSON = JSON.parse(ret.payload.data.json)
                                            }
                                        } catch (e) {
                                            console.error(e)
                                        }

                                        if (order.appointmentDate == null && z.documents.length > 0) {
                                            const sortedDocuments = z.documents.sort((a, b) => media[a.mediaId]?.dateCreated - [media[b.mediaId]].dateCreated)
                                            const docMedia = media[sortedDocuments[0].mediaId]
                                            data['APPOINTMENT DATE'] = fnc.formatDateToYYYYMMDD(new Date(docMedia.dateCreated))
                                        } else {
                                            data['APPOINTMENT DATE'] = order.appointmentDate
                                        }

                                        if (order.agreementDate == null && z.documents.length > 1) {
                                            const sortedDocuments = z.documents.sort((a, b) => media[a.mediaId]?.dateCreated - [media[b.mediaId]].dateCreated)
                                            const docMedia = media[sortedDocuments[0].mediaId]
                                            data['SIGNOFF DATE'] = fnc.formatDateToYYYYMMDD(new Date(docMedia.dateCreated))
                                        } else {
                                            data['SIGNOFF DATE'] = order.agreementDate
                                        }

                                        const recordKey = (x) => {
                                            return [x.upgradeOptionId, x.upgradeOptionVariationId, x.upgradeCompoenntId, x.upgradeOptionGroupId, x.upgradeProductId, x.upgradePackageId].join('-')
                                        }

                                        const logCache = {}
                                        logJSON?.optionProductRecords.forEach((x) => {
                                            logCache[recordKey(x)] = x

                                        })

                                        z.optionProductRecords.forEach((_a) => {
                                            const key = recordKey(_a)
                                            let a = _a
                                            let cached = false
                                            if (key in logCache) {
                                                a = logCache[key]
                                                cached = true
                                            }
                                            const option = maps.option[a.upgradeOptionId]
                                            const product = a.upgradeProductId ? maps.product[a.upgradeProductId] : null
                                            const pckge = a.upgradePackageId ? maps.package[a.upgradePackageId] : null
                                            let productName = ''
                                            let departmentName = ''
                                            let itemName = option?.name
                                            if (pckge) {
                                                if (product) {
                                                    // Get source package
                                                    const sourcePackages = upgrades.packages.filter((x) => x.products.find((y) => y.upgradeProductId == product.id))
                                                    // productName = `(${pckge?.name})${product ? ` ${product.name}` : ''}`
                                                    productName = `(${sourcePackages.map((x) => x.name).join(',')})${product ? ` ${product.name}` : ''}`
                                                } else {
                                                    productName = pckge?.name
                                                }
                                            } else if (product) {
                                                productName = product.name
                                            }

                                            if (option) {
                                                if (option.hidden) {
                                                    return
                                                }

                                                option.variations.forEach((b) => {
                                                    if (b.id != a.upgradeOptionVariationId) {
                                                        return
                                                    }
                                                    let variationName = productName
                                                    if (option.variations.length > 1) {
                                                        variationName = `${variationName} (${b.name})`
                                                    }

                                                    b.components.forEach((c) => {
                                                        if(c.id != a.upgradeComponentId) {
                                                            return
                                                        }

                                                        let componentName = variationName
                                                        if (b.components.length > 1) {
                                                            componentName = `${componentName} (${c.name})`
                                                        }

                                                        const spec = { unit, buildingType, floorplan, floorplanVariation, option, optionVariation: b, component: c, product, pckge }
                                                        const priceConfig = getPrice(maps, spec, { selection: a, debug: false })

                                                        let price = priceConfig.price

                                                        // If priceCalc set, and the quantity / sqft matches, update to record price
                                                        if (cached && a.priceCalc != null && a.priceOverride == null && priceConfig.quantity == a.quantity && priceConfig.sqft == a.sqft) {
                                                            price = a.priceCalc
                                                        }

                                                        // a.priceCalc != null ? a.priceCalc : priceConfig.price
                                                        // const markup = a.markup != null ? a.markup : priceConfig.markup
                                                        let cost = a.priceCost != null ? a.priceCost : priceConfig.cost
                                                        let revenue = price - cost
                                                        let margin = (price > 0 && cost > 0) ? revenue / cost * 100 : 0
                                                        // const markup = cost > 0 ? revenue / cost * 100 : 0

                                                        // Check for departments from components
                                                        if (c.departments.length > 0) {
                                                            const dept = maps.department[c.departments[0].upgradeDepartmentId]
                                                            if (dept) {
                                                                departmentName = dept.name.toUpperCase()
                                                            }
                                                        }

                                                        let customized = false
                                                        let pCustomized = pckge ? getPackageCustomized(pckge, z) : false
                                                        let pOverriden = pckge ? getPackageOverriden(pckge, z, spec) : false
                                                        // let applyAdminFee = pCustomized && pCustomized == pckge?.upgradeTierId
                                                        let applyAdminFee = pCustomized && pckge?.upgradeTierId in pCustomized

                                                        // Determine if this is a customization
                                                        if (pckge && product) {
                                                            const defaultProduct = getDefaultProduct(pckge, option)
                                                            if (defaultProduct && defaultProduct.id != product.id) {
                                                                customized = true
                                                            }
                                                        }

                                                        // Handle admin fee
                                                        if (pckge && !product && applyAdminFee && pckge.administrationFee > 0) {
                                                            const adminFee = pckge.administrationFee
                                                            rows.push({ ...data, 'DEPARTMENT': departmentName, 'ITEM': itemName, 'PRODUCT': 'Admin Fee', 'UPGRADE AMOUNT (NET HST)': adminFee, 'COST': '', 'REVENUE': '', 'MARGIN (%)': '' })
                                                            // return
                                                        }

                                                        // If not customized, just default to (included)
                                                        if (pckge && product && !customized) {
                                                            // Don't include breakdown for standard
                                                            if (pckge.upgradeTierId == UpgradeTier.Standard && !pCustomized) {
                                                                return
                                                            }
                                                            return
                                                            price = '(INCLUDED)'
                                                            cost = ''
                                                            revenue = ''
                                                            margin = ''
                                                        } else if (pckge && product && customized && pOverriden) {
                                                            price = '(INCLUDED)'
                                                            cost = ''
                                                            revenue = ''
                                                            margin = ''
                                                        } else {
                                                            price = price.toFixed(2)
                                                            cost = cost.toFixed(2)
                                                            revenue = revenue.toFixed(2)
                                                            margin = margin.toFixed(2)
                                                        }

                                                        // if ((pckge && !product && price == 0 && !pOverriden) || (product && price == 0)) {
                                                        // return
                                                        // }
                                                        if (/*(pckge && !product /*&& price == 0 && !pOverriden) || */(product && price == 0)) {
                                                            return
                                                        }

                                                        const priceConfigB = getPrice(maps, spec, { selection: a, debug: true })
                                                        if (!(price && typeof price === 'string' && price.includes('INCLUDED'))) {
                                                            rows.push({ ...data, 'DEPARTMENT': departmentName, 'ITEM': itemName, 'PRODUCT': componentName, 'UPGRADE AMOUNT (NET HST)': price, 'COST': cost, 'REVENUE': revenue, 'MARGIN (%)': margin })
                                                        }
                                                    })
                                                })
                                            } else {
                                                rows.push({ ...data, 'ITEM': a.note, 'UPGRADE AMOUNT (NET HST)': a.priceOverride })
                                            }
                                        })
                                        // const dataB = { ...data, orderStatus: UpgradeOrderStatus[z.upgradeOrderStatusId], view: view.name }
                                        // rows.push(dataB)
                                        resolve(rows)
                                    })
                            }))
                        })
                    }
                })
                Promise.all(proms).then((x) => {
                    // Combine array of arrays x
                    const finalData = x.reduce((a, b) => a.concat(b), [])
                    dispatch(resolvePrompt())
                    downloadCSV({
                        filename: `${app.meta.name} Breakdown.csv`,
                        data: finalData,
                        headers: ['UNIT NO.', 'FLOORPLAN', 'APPOINTMENT DATE', 'SIGNOFF DATE', 'DEPARTMENT', 'ITEM', 'PRODUCT', 'UPGRADE AMOUNT (NET HST)', 'COST', 'REVENUE', 'MARGIN (%)']
                    })
                })
                break
        }
    }

    function handleViewDocuments() {
        // Compile all documents
        let docs = []
        allClients.filter(filterClients).forEach((x) => {
            x.orders.filter(filterClientOrders).forEach((y) => {
                y.upgradeOrderRecords.forEach((z) => {

                    z.documents.forEach((a) => {
                        docs.push({ ...a, user: x, userOrder: y, upgradeOrder: z })
                    })
                })
            })
        })
        setPopupData({ documents: docs })
        setPopup(UpgradePopupType.Documents)
    }
    // function handleDocument(document, record = null, signed = false) {
    //     if (document == 'add') {
    //         dispatch(showPrompt({ type: PromptType.Media, app, mediaTypeId: MediaType.File, multi: true, upload: true }))
    //             .then((x) => {
    //                 if (x.payload && x.payload.length > 0) {
    //                     const docs = x.payload.map((x) => {
    //                         let signedDate = null
    //                         const m = media[x]
    //                         if (m) {
    //                             // Check media link for a date with regex
    //                             signedDate = fnc.extractDateFromFilename(m.link)
    //                         }
    //                         return {
    //                             upgradeOrderRecordId: record?.id,
    //                             mediaId: x,
    //                             signed: true,
    //                             signedDate,
    //                         }
    //                     })
    //                     // Add documents
    //                     dispatch(createUpgradeDocument({ app, documents: docs }))
    //                         .then((x) => {
    //                             const newRecord = { ...record, upgradeOrderStatusId: UpgradeOrderStatus.Signed }
    //                             x.payload.documents.forEach((x) => {
    //                                 const idx = newRecord.documents.findIndex((y) => {
    //                                     y.upgradeOrderRecordId == x.upgradeOrderRecordId && y.mediaId == x.mediaId
    //                                 })
    //                                 if (idx == -1) {
    //                                     newRecord.documents.push(x)
    //                                 } else {
    //                                     newRecord.documents[idx] = x
    //                                 }

    //                             })
    //                             handleUpdateUpgradeOrder(newRecord)
    //                         })
    //                 }
    //             })

    //     } else {
    //         const link = getMediaLink(document.link, { app })
    //         fnc.openLinkInNewWindow(link)
    //     }
    // }

    // function handleDocumentDelete(media, record = null, signed = false) {
    //     dispatch(showPrompt({ type: PromptType.Confirm, title: 'Delete Document', message: 'Are you sure you want to delete this document?' }))
    //         .then((x) => {
    //             if (x.payload) {
    //                 const newRecord = { ...record }
    //                 newRecord.documents = newRecord.documents.filter((x) => !(x.mediaId == media.id && x.signed == signed))
    //                 handleUpdateUpgradeOrder(newRecord)
    //             }
    //         })
    // }

    // function handleDocumentDateChange(document, record, date) {
    //     const newRecord = { ...record }
    //     const idx = newRecord.documents.findIndex((x) => x.mediaId == document.mediaId && x.signed == document.signed)
    //     if (idx != -1) {
    //         newRecord.documents[idx].signedDate = date
    //         handleUpdateUpgradeOrder(newRecord)
    //     }
    // }

    function getBookingLink() {
        if (userOrder?.bookingLink) {
            return userOrder.bookingLink
        }
        return app.meta.bookingLink
    }

    function getCustomGroup(key, style, ix, count) {
        if (!viewRef.current) {
            return null
        }

        const selectionStyle = { ...style, height: 'auto', maxHeight: 'none' }
        if (!mobileView) {
            selectionStyle.minHeight = `${viewRef.current.clientHeight - (ix == 0 ? 120 : (ix < count - 1 ? 200 : 120))}px`
        } else {
            selectionStyle.maxHeight = '100%'
        }
        if (focusGroup && focusGroup.id !== key) {
            selectionStyle.opacity = 0.5
        }

        let customElem = null
        const uniqueFloorplans = {}
        if (buildingType && buildingType.id in maps.buildingType) {
            maps.buildingType[buildingType.id].floorplans.sort((a, b) => a.name.localeCompare(b.name)).forEach((x) => x.variations.forEach((y) => {
                if (!(x.id in uniqueFloorplans)) {
                    uniqueFloorplans[x.id] = <FloorplanTile
                        key={`${x.id}_${y.id}`}
                        tileStyle={ListStyle.Grid}
                        className={floorplanVariation && floorplanVariation.id == y.id ? 'selected' : ''}
                        app={app}
                        floorplan={x}
                        variation={y}
                        onFloorplan={() => handleFloorplanVariation(y)} />
                }
            }))
        }
        switch (key) {
            case 'selection':
                // selectionStyle.minHeight = 'max-content'
                customElem = <React.Fragment>
                    <div className="column selector">
                        <h3>Select Building Type</h3>
                        <div className={`row tile - list ${mobileView ? 'by-2' : 'by-4'}`}>
                            {app.buildingTypes.map((x) => <Tile
                                key={x.id}
                                aspect
                                className={buildingType && buildingType.id == x.id ? 'selected' : ''}
                                onClick={() => handleBuildingType(x)}>
                                <h3>{x.name}</h3>
                            </Tile>)}
                        </div>
                    </div>
                    {buildingType && <div className="selector scrollable">
                        <h3>Select Plan</h3>
                        <div className={`row tile - list ${mobileView ? 'by-2' : 'by-4'}`}>
                            {Object.values(uniqueFloorplans)}
                        </div>
                    </div>}
                </React.Fragment>
                break
            case 'views':
                customElem = <React.Fragment>
                    <div className="column selector">
                        <h3>Select Upgrade Configuration</h3>
                        <div className={`row tile - list ${mobileView ? 'by-2' : 'by-4'}`}>
                            {views?.map((x) => <Tile
                                key={x.id}
                                aspect
                                className={upgradeView && upgradeView.id == x.id ? 'selected' : ''}
                                onClick={() => handleUpgradeView(x)}>
                                <h3>{x.name}</h3>
                            </Tile>)}
                        </div>
                    </div>
                </React.Fragment>
                break
            case 'booking':
                customElem = <div className="row upgrade-booking">
                    <Message>
                        <span>Thank you for making your online selections.<br /><br />Please book an in person consultation at our décor centre to confirm your selections.<br /><br />Décor centre address: <br /> <LocationLink area={app.meta.officeLocation} alt /></span>
                        <Button large={!mobileView} secondary href={getBookingLink()}>Book your consultation now</Button>
                    </Message>
                </div>
                break
            case 'finalize':
                if (!user || !userOrder || !upgradeOrder || !upgradeView) {
                    return null
                }
                const complete = upgradeOrder.upgradeOrderStatusId == UpgradeOrderStatus.Complete
                customElem = <div className="row upgrade-booking">
                    {!complete && <Message>
                        <span>You're almost done! Review your selections and press the button below to submit your selections. After which you will have the opportunity to book your followup appointment at our decor centre.<br /><br />Note: You are welcome to change your submitted selections when you meet your design consultant at our office.</span>
                        {upgradeView.finalizeMediaId && <div className="row message-media"><Media mediaId={upgradeView.finalizeMediaId} app={app} /></div>}
                        <Button large={!mobileView} secondary onClick={handleComplete}>Complete</Button>
                    </Message>}
                    {complete && <Message>
                        <span>You're selections have been made!<br /><br />Use the link below to book your in person consultation at our décor center. An email with the link has also been sent to you.</span>
                        <a href={getBookingLink()}><span className="booking-link">{getBookingLink()}</span></a>
                        <Button large={!mobileView} secondary onClick={handleResend}>Resend email</Button>
                    </Message>}
                </div>
                break
            case 'brief':
                if (!user || !userOrder || !floorplanVariation) {
                    return null
                }

                let faqElem = null
                if (faq && faq.show) {
                    faqElem = <React.Fragment>
                        <b>Tip:</b>Before you begin, we recommend you <a href="#" onClick={handleFaq}>review our FAQ</a>
                        <br />
                        <br />
                    </React.Fragment>
                }
                const unit = app.maps.unit[userOrder.unitId]
                // Find floorplan images
                const floorplanMedia = [...floorplanVariation?.floorplans].sort(fnc.orderSort)

                let variationPdfLink = null
                if (floorplanVariation.pdfMediaId) {
                    variationPdfLink = getMediaLinkSel(floorplanVariation.pdfMediaId, { app, organization })
                }
                let userName = user?.name
                if (user?.id != userOrder.userId) {
                    const orderUser = allClients.find((x) => x.id == userOrder.userId)
                    if (orderUser) {
                        userName = orderUser.name
                    }
                }
                // const floorplanMedia = floorplanVariation.media
                customElem = <div className="row upgrade-brief">
                    <Message className="scrollable">
                        <div className="row">
                            <div className={`column ${showBriefFloorplans ? "col-6" : "col-12"}`}>
                                <div className="row">
                                    <h1>Welcome {userName}!</h1>
                                </div>
                                <div className="row">
                                    {termsAndConditions && <span style={{ display: 'block', maxWidth: '600px' }}>You're ready to make your online selections.<br /><br />{faqElem}Please confirm your unit details below and press the button when you're ready to begin.</span>}
                                    {!termsAndConditions && <span style={{ display: 'block', maxWidth: '600px' }}>You're ready to make your online selections.<br /><br />{faqElem}Please confirm your unit details below, and review the terms and conditions before you begin.</span>}
                                </div>
                                <div className="row unit-details">
                                    <h4><b>Plan:</b>{floorplan.name}</h4>
                                    <h4><b>Unit:</b>{unit ? unit.name : 'Not Specified'}</h4>
                                    {/* {upgrades.faq && upgrades.faq.show && <Button onClick={handleFaq}>FAQ</Button>} */}
                                    {/* <span><a href="#" onClick={() => setShowBriefFloorplans(!showBriefFloorplans)}>{showBriefFloorplans ? "Hide Floorplans" : "View Floorplans"}</a></span> */}
                                    {floorplanMedia?.length > 0 && <span><a href="#" onClick={() => handlePreview(0, floorplanMedia.map((x) => x.mediaId))}>View Floorplans</a></span>}
                                    {variationPdfLink != null && <span><a href={variationPdfLink} target="_blank">Download Floorplan Pdf</a></span>}
                                </div>
                                {upgradeView.briefMediaId && <div className="row message-media"><Media mediaId={upgradeView.briefMediaId} app={app} /></div>}
                                <div className="row" style={{ flexDirection: 'column' }}>
                                    {!termsAndConditions && <Button large={true} secondary onClick={handleTerms}>Terms and Conditions</Button>}
                                    {termsAndConditions && <Button large={true} secondary onClick={handleBegin}>Begin!</Button>}
                                    {/* {upgrades.faq && upgrades.faq.show && <Faq data={{ title: upgrades.faq.title, rows: upgrades.faq.sections }} styles={faqStyles}/>} */}
                                </div>
                            </div>
                            {showBriefFloorplans && <div className="column col-6">
                                <div className="row">
                                    <h1>Floorplans</h1>
                                </div>
                                <div className={`row upgrade-brief - floorplans${floorplanMedia.length > 1 ? ' multi-plan' : ''}`}>
                                    {floorplanMedia.map((x, ix) => {
                                        return <div className="upgrade-brief-floorplan" onClick={() => handlePreview(ix, floorplanMedia.map((x) => x.mediaId))}>
                                            <h4>{x.name}</h4>
                                            <Media mediaId={x.mediaId} app={app} thumb thumbSize={ThumbSize.Medium} hoverShow />
                                        </div>
                                    })}
                                </div>
                            </div>}
                        </div>
                    </Message >
                </div >
                break
            case 'orders':
                const userMap = {}
                const getHeaderButton = (key) => {
                    let icon = ''
                    if (clientSort == key) {
                        icon = 'fas fa-chevron-down'
                    } else if (clientSort == `-${key}`) {
                        icon = 'fas fa-chevron-up'
                    }

                    if (key == 'bookingLink') {
                        return <th><Button onClick={handleSetAllBooking}>{key.toReadable()}</Button> </th>
                    }
                    return <th><Button noBg icon={icon} onClick={() => handleClientSort(key)}>{key.toReadable()}</Button></th>
                }

                customElem = <React.Fragment>
                    <div className="column selector">
                        <h3>Select Record</h3>
                        {!allClients && loading < 2 && <Spinner showAfter={0} />}
                        {!allClients && loading == 2 && <Message error="failed to load clients" />}
                        {allClients && <div className="client-rows">
                            <div className="row client-options">
                                <Input className="client-search" placeholder="User Search" value={nameFilter} onChange={setNameFilter} clear />
                                <Input className="unit-search" placeholder="Unit Search" value={unitFilter} onChange={setUnitFilter} clear />
                                <DropdownMenu icon={statusFilter ? upgradeOrderStatusIcons[statusFilter] : null}
                                    multi
                                    value={statusFilter ? [...statusFilter] : []}
                                    text={(!statusFilter || statusFilter.length == 0) ? "Search by Status" : `${Array.isArray(statusFilter) ? statusFilter.map((x) => UpgradeOrderStatus[x]).join(',') : UpgradeOrderStatus[statusFilter]}`}
                                    items={[{ text: 'All', value: null }, ...Object.keys(UpgradeOrderStatus).filter((x) => !isNaN(x)).map((x) => ({ text: UpgradeOrderStatus[x], icon: upgradeOrderStatusIcons[x], value: parseInt(x) }))]}
                                    onChange={(x) => {
                                        if (x == null || (Array.isArray(x) && x.includes(null))) {
                                            setStatusFilter(null)
                                        } else {
                                            setStatusFilter(x)
                                        }
                                    }} />
                                <div className="row" style={{ width: 'auto', marginLeft: 'auto' }}>
                                    <DropdownMenu tertiary buttonClass="button-tertiary" icon="fas fa-file-excel" text="Reporting" items={[{ text: 'Order status', value: 'status', icon: "fas fa-tasks" }, { text: 'Financial', value: 'financial', icon: 'fas fa-dollar-sign' }]} onChange={handleDownloadOrders}>CSV</DropdownMenu>
                                    <Button tertiary buttonClass="button-tertiary" icon="fas fa-file-pdf" text="Documents" onClick={handleViewDocuments}>Documents</Button>
                                    <Button tertiary icon="fas fa-user" onClick={() => setPopup(UpgradePopupType.AddUser)}>Add User</Button>
                                    <Button tertiary icon="fas fa-plus" onClick={() => setPopup(UpgradePopupType.AddOrder)}>Add Order</Button>
                                    {upgrades.views.length > 1 && <DropdownMenu text="Email All" icon="fas fa-paper-plane" buttonClass="button-tertiary" items={upgrades.views.map((x) => ({ text: `For View - ${x.name}`, value: `notify - ${x.id}` }))} onChange={handleClientOperation} />}
                                    {upgrades.views.length == 1 && <Button icon="fas fa-paper-plane" tertiary onClick={() => handleClientOperation(`notify - ${upgrades.views[0]}.id`)}>Email All</Button>}
                                </div>
                            </div>
                            <div className="table-wrapper scrollable show-scroll">
                                {mobileView && <table>
                                    <thead>
                                        <tr>
                                            {getHeaderButton('name')}
                                            {getHeaderButton('email')}
                                            {getHeaderButton('phone')}
                                            {getHeaderButton('floorplan')}
                                            {getHeaderButton('unit')}
                                            {getHeaderButton('price')}
                                            {/* {getHeaderButton('bookingLink')} */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allOrders.map((x, ix) => <UpgradeOrderRow key={x.id} app={app} organization={organization} upgrades={upgrades} user={x.user} order={x.order} onDelete={handleRemoveUserOrder} onClick={handleUserOrder} onChange={handleEditUserOrder} maps={maps} last={ix == allClients.length - 1} selected={(userOrder && upgradeView ? { userOrder, upgradeView } : null)} mobileView={mobileView}
                                            onEditUserOrderStart={(x) => {
                                                setPopupData(x)
                                                setPopup(UpgradePopupType.EditOrder)
                                            }}
                                            onShowDocuments={(y) => {
                                                if (!y) {
                                                    return
                                                }
                                                setPopupData({ ...y, title: 'Test' })
                                                setPopup(UpgradePopupType.Documents)
                                            }}
                                            onEditUserOrder={async (x) => await handleUpdateUserOrders(x)}
                                            onEditUpgradeOrder={async (x) => await handleUpdateUpgradeOrder(x)}
                                            onGeneratePdf={async (x) => await handleGeneratePdf(x)}
                                        />)}
                                        {allOrders.length == 0 && <tr><td colSpan={6}>No records to show</td></tr>}
                                    </tbody>
                                </table>}
                                {!mobileView && <table>
                                    <thead>
                                        <tr>
                                            {getHeaderButton('name')}
                                            {getHeaderButton('email')}
                                            {getHeaderButton('phone')}
                                            {getHeaderButton('floorplan')}
                                            {getHeaderButton('unit')}
                                            {getHeaderButton('price')}
                                            {/* {getHeaderButton('bookingLink')} */}
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allOrders.map((x, ix) => <UpgradeOrderRow key={x.id} app={app} organization={organization} upgrades={upgrades} user={x.user} order={x.order} onDelete={handleRemoveUserOrder} onClick={handleUserOrder} onChange={handleEditUserOrder} maps={maps} last={ix == allClients.length - 1} selected={(userOrder && upgradeView ? { userOrder, upgradeView } : null)} mobileView={mobileView}
                                            onEditUserOrderStart={(x) => {
                                                setPopupData(x)
                                                setPopup(UpgradePopupType.EditOrder)
                                            }}
                                            onShowDocuments={(y) => {
                                                if (!y) {
                                                    return
                                                }
                                                const view = maps.views[y.upgradeViewId]
                                                const floorplan = app.maps.floorplanVariationFloorplan[y.floorplanVariationId]
                                                const unit = app.maps.unit[x.order.unitId]
                                                let title = `${x.user.name} Documents`
                                                if (floorplan || unit) {
                                                    title += ' - '
                                                    if (floorplan) {
                                                        title += floorplan.name
                                                        if (unit) {
                                                            title += ` / ${unit.name}`
                                                        }
                                                    } else if (unit) {
                                                        title += unit.name
                                                    }
                                                }
                                                setPopupData({ ...y, title })
                                                setPopup(UpgradePopupType.Documents)
                                            }}
                                            onEditUserOrder={async (x) => await handleUpdateUserOrders(x)}
                                            onEditUpgradeOrder={async (x) => await handleUpdateUpgradeOrder(x)}
                                            onGeneratePdf={async (x) => await handleGeneratePdf(x)}
                                        />)}
                                        {allOrders.length == 0 && <tr><td colSpan={7}>No records to show</td></tr>}
                                    </tbody>
                                </table>}
                            </div>
                        </div>}
                        {/*upgrades.views.length > 0 && <React.Fragment>
                            <h3>Or Select View</h3>
                            <div className="row" style={{ justifyContent: 'center' }}>
                                {upgrades.views.map((x) => <Button key={x.id} onClick={() => handleIndependentPlan(x)}>{x.name}</Button>)}
                            </div>
                                        </React.Fragment>*/}
                    </div>
                </React.Fragment>
                break
            case 'faq':
                if (!upgrades.faq) {
                    return
                }

                const data = {
                    title: upgrades.title,
                    rows: upgrades.sections
                }

                customElem = <div className="row upgrade-faq">
                    <FAQ data={data} styles={faqStyles} />
                </div >
                break
        }

        return <div className={`upgrade-focus-group fadeIn column${mobileView ? ' scrollable' : ''}`} data-id={key} style={{ justifyContent: 'flex-start' }} style={selectionStyle}>
            {customElem}
        </div>
    }

    /* function getSelection(style) {
        if (!viewRef.current) {
            return
        }
     
        const selectionStyle = { ...style, height: 'auto', maxHeight: 'none' }
        if (mobileView) {
            // selectionStyle.minHeight = `${ viewRef.current.clientHeight - 40 - 60 }px`
        } else {
            // selectionStyle.minHeight = `${ viewRef.current.clientHeight - 40 }px`
        }
        if (focusGroup && focusGroup.id !== 'selection') {
            selectionStyle.opacity = 0.5
        }
     
        return <div className="upgrade-focus-group column" data-id="selection" style={{ justifyContent: 'flex-start' }} style={selectionStyle}>
            <div className="column building-selector">
                <h3>Select Building Type</h3>
                <div className={`row tile - list ${ mobileView? 'by-2': 'by-4' }`}>
                    {app.buildingTypes.map((x) => {
                        return <Tile
                            key={x.id}
                            aspect
                            className={buildingType && buildingType.id == x.id ? 'selected' : ''}
                            onClick={() => handleBuildingType(x)}>
                            <h3>{x.name}</h3>
                        </Tile>
     
                    })}
                </div>
            </div>
            {buildingType && <div className="plan-selector">
                <h3>Select Plan</h3>
                <div className={`row tile - list ${ mobileView? 'by-2': 'by-4' }`}>
                    {buildingType.id in maps.buildingType && maps.buildingType[buildingType.id].floorplans.sort((a, b) => a.name.localeCompare(b.name)).map((x) => {
                        return x.variations.map((y) => {
                            return <FloorplanTile
                                key={`${ x.id }_${ y.id }`}
                                tileStyle={ListStyle.Grid}
                                className={floorplanVariation && floorplanVariation.id == y.id ? 'selected' : ''}
                                app={app}
                                floorplan={x}
                                variation={Y}
                                ONFLOORPLAN={() => HANDLEFLOORPLANVARIATION(Y)} />
     
                            /*return <Tile
                                key={`${ x.id }_${ y.id }`}
                                aspect
                                className={floorplanVariation && floorplanVariation.id == y.id ? 'selected' : ''}
                                onClick={() => handleFloorplanVariation(y)}>
                                <h3>{`${ x.name }${ x.variations.length > 1 ? `-${y.name}` : '' }`}</h3>
                            </Tile>*
                        })
                    })}
                </div>
            </div>}
        </div>
    } */


    function getSummary(inPopup = false, style = {}, ix = 0, count = 0) {
        if (!upgradeView || !maps || !maps.option) {
            return
        }
        // Map options to options
        const optionMap = {}
        const variationMap = {}
        const lines = []
        const csvData = []
        Object.keys(selections).forEach((x) => {
            const setKey = (key, selection) => {
                if (!(key in variationMap)) {
                    variationMap[key] = []
                }
                variationMap[key].push(selection)
            }
            const addSelection = (selection) => {
                const {
                    group, option, variation, component, product, package: pckge
                } = selection
                if (option) {
                    if (!(option.id in optionMap)) {
                        optionMap[option.id] = []
                    }
                    optionMap[option.id].push(selection)
                }
                if (variation) {
                    if (!option.global) {
                        let keyA = `${group?.id}_${variation.id}`
                        let keyB = variation.id
                        setKey(keyA)
                        setKey(keyB)
                    } else {
                        let keyA = variation.id
                        setKey(keyA)
                    }
                }
            }
            selections[x].forEach((y) => {
                if (y.option || y.package) {
                    addSelection(y)
                }
            })
        })
        let globalOptions = []
        const globalSet = new Set()
        upgradeView.optionGroups.forEach((x) => {
            x.options.forEach((y) => {
                if (!y) {
                    logger.error('Missing option', x, y)
                    return
                }
                const opt = maps.option[y?.upgradeOptionId]
                if (opt && !(opt.id in maps.optionInPackage) /* not part of package */ && y.visibilityId == Visibility.ReadWrite && opt.global && !globalSet.has(opt.id)) {
                    globalOptions.push(opt)
                    globalSet.add(opt.id)
                }
            })
        })
        // Sort global options, if if name includes package, put first
        const sortOptions = (options) => {
            return options.sort((a, b) => {
                const nameA = a.name ? a.name.toLowerCase() : ""
                const nameB = b.name ? b.name.toLowerCase() : ""
                if (nameA.includes('package') && !nameB.includes('package')) {
                    return -1
                }
                if (!nameA.includes('package') && nameB.includes('package')) {
                    return 1
                }
                return nameA.localeCompare(nameB)
            })
        }
        globalOptions = sortOptions(globalOptions)

        const summaryStyle = { ...style }
        if (!inPopup && viewRef.current) {
            if (!mobileView) {
                // summaryStyle.minHeight = `${ viewRef.current.clientHeight - 200 }px`
            }
            if (focusGroup && focusGroup.id !== 'summary') {
                summaryStyle.opacity = 0.5
            }
        }

        function logSummary() {
            if (logTimeout.current) {
                return
            }

            let client = null
            if (userOrder) {
                const user = allClients.find((x) => x.id == userOrder.userId)
                if (user) {
                    client = user
                }
            }

            const { headers, data } = upgradeSummaryCSV(app, csvData, showPricing)
            let jsonData = null
            if (upgradeOrder) {
                jsonData = { id: upgradeOrder.id, optionProductRecords: upgradeOrder.optionProductRecords }
                jsonData = JSON.stringify(jsonData)
            }

            // Dump CSV to log
            const stringCsv = `${headers.join(',')}\n${data.map((x) => x.join(',')).join('\n')}`
            const log = {
                id: null,
                json: jsonData,
                csv: stringCsv,
                submitUserId: user && user.id ? user.id : null,
                userId: client && client.id ? client.id : (user && user.id ? user.id : null),
                upgradeViewId: upgradeView.id,
                floorplanId: floorplan?.id,
                floorplanVariationId: floorplanVariation?.id,
                unitId: userOrder ? userOrder.unitId : null,
            }

            dispatch(logUpgrade({ app, log }))

            logTimeout.current = setTimeout(() => {
                logTimeout.current = null
            }, 3000)
        }

        function copySummary(e) {
            e.stopPropagation()
            const summary = lines.join('\n')
            fnc.copyToClipboard(summary)
            logSummary()
            dispatch(pushNotification({ status: NotificationStatus.Info, message: 'Summary copied to clipboard' }))
        }

        function csvSummary(e) {
            // let filename: `upgrade-summary_${ fnc.timestamp('YYYY-MM-DD') }.csv`
            const time = fnc.timestamp('YYYY-MM-DD')
            let filename = `upgrade-summary_${time}`
            if (userOrder) {
                const user = allClients.find((x) => x.id == userOrder.userId)
                if (user) {
                    filename = `${user.name ? user.name : user.email}_${filename}`
                }
            }

            const props = {
                filename,
                ...upgradeSummaryCSV(app, csvData, showPricing),
            }

            logSummary()
            downloadCSV(props)
        }

        function pdfSummary(e, customData = null) {
            console.trace()
            generatePdfCallback.current = null

            // let filename: `upgrade-summary_${ fnc.timestamp('YYYY-MM-DD') }.csv`
            const time = fnc.timestamp('YYYYMMDDTHHmmSS')
            let filename = `upgrade-invoice_${time}`
            let user = null
            if (userOrder) {
                user = allClients.find((x) => x.id == userOrder.userId)
                if (user) {
                    filename = `${user.name ? user.name : user.email}_${filename}`
                }
            }
            let purchaser = user?.name
            const unit = userOrder ? app.maps.unit[userOrder.unitId] : null
            const floorplanVariation = userOrder ? app.maps.floorplanVariation[userOrder.floorplanVariationId] : null
            const floorplan = floorplanVariation ? app.maps.floorplan[floorplanVariation.floorplanId] : null

            // Find a second purchaser
            let secondPurchaser = null
            if (userOrder && !user.admin) {
                secondPurchaser = allClients.find((x) => {
                    if (x.admin) {
                        return false
                    }
                    if (x.id == userOrder.userId) {
                        return false
                    }
                    return x.orders.find((y) => y.unitId == unit.id)
                })
            }


            const data = {
                filename,
                data: {
                    title: 'Invoice',
                    subtitle: '',
                    contact: '',//TEL: 12345678910',
                    purchaser: purchaser,
                    secondPurchaser: secondPurchaser?.name,
                    docusign: false,//enableDocuSign,
                    splitSignaturePage,
                    ...customData,
                    unit: unit ? unit.name : '',
                    floorplan: floorplan ? floorplan.name : '',
                    groups: upgradeSummaryPDF(app, csvData, showPricing),
                    userOrderRecordId: userOrder.id,
                    upgradeOrderRecordId: upgradeOrder.id,
                    upgradeViewId: upgradeView.id,

                }
            }
            logSummary()

            const total = calculateTotal(true)

            // Dispatch pdf summary
            const prom = [
                dispatch(showPrompt({ type: PromptType.Pending, title: 'Generating PDF...', successMessage: 'PDF Generated', failureMessage: 'Error generating PDF' })),
                dispatch(retrieveUpgradePdf({ app, data }))
            ]
            Promise.all(prom).then((x) => {
                if (x[1]?.payload?.success) {
                    const prices = x[1].payload.prices
                    const media = x[1].payload.media
                    const document = x[1].payload.document
                    const html = x[1].payload.html
                    const pdf = x[1].payload.pdf
                    // Generate media link
                    let link = null
                    if (media) {
                        link = getMediaLink(media.link, { app })
                        // Add media to application
                        dispatch(addProjectMedia([media]))
                    }

                    if (prices.subtotal.toFixed(2) != total.toFixed(2)) {
                        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Warning, pdf may have missing information', message: `A problem occurred generating the PDF and the pricing may be incomplete, we will investigate the cause.In the meantime please inspect the pdf and make any adjustments. (expected price: ${total.toFixed(2)} calculated price: ${prices.subtotal.toFixed(2)}})`, cancelMessage: null }))
                        // Report error
                        logger.errorWithReport({ message: `Error generating pdf(pricing incorrect ${prices.subtotal.toFixed(2)} != ${total.toFixed(2)})`, data: { prices, total, userOrder, upgradeOrder, pdfData: data } })
                    }

                    if (pdf) {
                        const pdfContent = atob(pdf.content)
                        // Create a Blob from the decoded PDF content
                        const blob = new Blob([new Uint8Array([...pdfContent].map(char => char.charCodeAt(0)))], { type: 'application/pdf' })
                        // Create a link to download the PDF
                        const dataUrl = URL.createObjectURL(blob);
                        window.open(dataUrl, '_blank');
                    }

                    if (document) {
                        let title = 'PDF generated successfully!'
                        let message = 'Press the button below to view the PDF.'
                        if (document.envelopeId) {
                            message = `${message} DocuSign envelope has been sent to purchaser email.`
                        }
                        let buttons = [{ text: 'View PDF', link }]
                        // // // if (html) {
                        // //     buttons.push({ text: 'View Invoice HTML', html })
                        // }

                        dispatch(showPrompt({ type: PromptType.Confirm, title, message, confirmMessage: null, extraButton: buttons, cancelMessage: 'Done' }))

                        if (generatingPdf) {
                            setTimeout(() => {
                                setGeneratingPdf(false)
                                clearProgram()
                            }, 10)
                        }
                        // Add to user order and upgrade order
                        const newOrder = { ...upgradeOrder, documents: userOrder.documents ? [...userOrder.documents, document] : [document] }
                        setUpgradeOrder(newOrder)
                        const orderIdx = userOrder.upgradeOrderRecords.findIndex((x) => x.id == upgradeOrder.id)
                        if (orderIdx != -1) {
                            const newOrders = [...userOrder.upgradeOrderRecords]
                            newOrders[orderIdx] = { ...newOrders[orderIdx], documents: [...newOrders[orderIdx].documents, document] }
                            const newUserOrder = { ...userOrder, upgradeOrderRecords: newOrders }
                            setUserOrder(newUserOrder)

                            // Update user order in all clients
                            const allIdx = allClients.findIndex((x) => x.id == newUserOrder.userId)
                            if (allIdx != -1) {
                                const allOrderIdx = allClients[allIdx].orders.findIndex((x) => x.id == newUserOrder.id)
                                if (allOrderIdx != -1) {
                                    const allOrders = [...allClients[allIdx].orders]
                                    allOrders[allOrderIdx] = newUserOrder
                                    const newAllClients = [...allClients]
                                    newAllClients[allIdx] = { ...newAllClients[allIdx], orders: allOrders }
                                    setAllClients(newAllClients)
                                }
                            }
                        }
                    }
                } else {
                    logger.errorWithReport({ message: `Error generating pdf(failed request)`, data: { userOrder, upgradeOrder, pdfData: data, response: x[1].payload } })
                }
            })
        }
        generatePdfCallback.current = pdfSummary

        function wordSummary() {
            // let filename: `upgrade-summary_${ fnc.timestamp('YYYY-MM-DD') }.csv`
            const time = fnc.timestamp('YYYYMMDDTHHmmSS')
            let filename = `upgrade-invoice_${time} `
            let user = null
            if (userOrder) {
                user = allClients.find((x) => x.id == userOrder.userId)
                if (user) {
                    filename = `${user.name ? user.name : user.email}_${filename} `
                }
            }
            const unit = userOrder ? app.maps.unit[userOrder.unitId] : null
            const floorplanVariation = userOrder ? app.maps.floorplanVariation[userOrder.floorplanVariationId] : null
            const floorplan = floorplanVariation ? app.maps.floorplan[floorplanVariation.floorplanId] : null

            const data = {
                filename,
                data: {
                    title: 'Invoice',
                    subtitle: '',
                    contact: '',//TEL: 12345678910',
                    unit: unit ? unit.name : '',
                    floorplan: floorplan ? floorplan.name : '',
                    name: user?.name,
                    groups: upgradeSummaryPDF(app, csvData, showPricing),
                    upgradeOrderRecordId: upgradeOrder.id,
                    upgradeViewId: upgradeView.id,
                    docusign: false,//enableDocuSign,
                    word: true,
                }
            }
            logSummary()

            const total = calculateTotal(true)

            // Dispatch pdf summary
            const prom = [
                dispatch(showPrompt({ type: PromptType.Pending, title: 'Generating Word Doc...', failureMessage: 'Error generating Word Doc' })),
                dispatch(retrieveUpgradePdf({ app, data }))
            ]
            Promise.all(prom).then((x) => {
                if (x[1]?.payload?.success) {
                    const prices = x[1].payload.prices
                    const media = x[1].payload.media
                    const document = x[1].payload.document
                    // Generate media link
                    let link = null
                    if (media) {
                        link = getMediaLink(media.link, { app })
                        // Add media to application
                        dispatch(addProjectMedia([media]))
                    }

                    if (prices.subtotal.toFixed(2) != total.toFixed(2)) {
                        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Error generating Word Doc', message: 'A problem occurred generating the Word Doc and the pricing is incomplete, we will investigate the cause. In the meantime please inspect the document and make any adjustments.', cancelMessage: null }))
                        // Report error
                        logger.errorWithReport({ message: 'Error generating Word Doc', data: { prices, total, userOrder, upgradeOrder } })
                    }


                    if (document) {
                        let title = 'Word Doc generated successfully!'
                        let message = 'Press the button below to view the Word Doc.'
                        dispatch(showPrompt({ type: PromptType.Confirm, title, message, confirmMessage: 'Download Word Doc', cancelMessage: 'Done' }))
                            .then((x) => {
                                if (x.payload) {
                                    fnc.openLinkInNewWindow(link)
                                }
                            })

                        // Add to user order and upgrade order
                        const newOrder = { ...upgradeOrder, documents: userOrder.documents ? [...userOrder.documents, document] : [document] }
                        setUpgradeOrder(newOrder)
                        const orderIdx = userOrder.upgradeOrderRecords.findIndex((x) => x.id == upgradeOrder.id)
                        if (orderIdx != -1) {
                            const newOrders = [...userOrder.upgradeOrderRecords]
                            newOrders[orderIdx] = { ...newOrders[orderIdx], documents: [...newOrders[orderIdx].documents, document] }
                            const newUserOrder = { ...userOrder, upgradeOrderRecords: newOrders }
                            setUserOrder(newUserOrder)
                        }
                    }
                }
            })
        }

        function shouldHideOption(group, spec) {
            if (!upgradeView.hideDefault) {
                return false
            }

            const { option, variation, component } = spec
            const key = selectionKey(group, option, variation, component)
            const selection = selections[key]
            if (!selection || selection.length == 0) {
                return true
            }
            if (selection.length > 1 || selection[0].product.upgradePackageId != null) {
                return false
            }
            const defaultProduct = component.products?.find((x) => x.isDefault)
            if (selection[0].product?.id == defaultProduct?.upgradeProductId) {
                return true
            }
            return false
        }

        function getSummaryOption(group, option, pckge = null, depth = 0, show = true) {
            let debug = false
            if (!(option.id in optionMap)) {
                return null
            }
            let packageCustomizations = {}
            if (pckge != null) {
                packageCustomizations = getCustomizations(pckge)
            }

            const componentMap = {}
            if (option.id in optionMap) {
                optionMap[option.id].forEach((y) => {
                    if ((y.package && y?.product?.upgradeProductId && (!pckge || y.package.id != pckge.id)) || (group && y.group?.id != group.id)) {
                        return
                    }
                    if (!(y.component.id in componentMap)) {
                        componentMap[y.component.id] = {}
                    }
                    componentMap[y.component.id][y.product.id] = y
                })
            }
            // TODO multiple variations
            let components = null
            let variation = null
            if (option.custom && optionMap[option.id].length > 0) {
                variation = optionMap[option.id][0].variation
                components = optionMap[option.id].map((x) => x.component)
            } else {
                // Check which variation has been selected
                variation = option.variations?.find((x) => {
                    let key = group && !option.global ? `${group?.id}_${x.id}` : x.id
                    return key in variationMap
                })
                // variation = option.variations[0]
                components = variation ? [...variation.components].sort(orderSort) : []
            }

            return components.map((component, ix) => {
                if (!(component.id in componentMap)) {
                    return null
                }
                const options = Object.values(componentMap[component.id])
                const toggleExpand = (id) => {
                    if (id in summaryExpand) {
                        const newExpand = { ...summaryExpand }
                        delete newExpand[id]
                        setSummaryExpand(newExpand)
                    } else {
                        const newExpand = { ...summaryExpand, [id]: true }
                        setSummaryExpand(newExpand)
                    }
                }

                // Check if should hide (because default and not customized)
                if (depth == 0 && options.filter((x) => !shouldHideOption(group, x)).length == 0) {
                    return null
                }

                return <React.Fragment key={`${group?.id} -${ix} `}>
                    {/* {variation.components.length > 1 && <span className="upgrade-summary-group-component-title">
                                    {component.name}
                        </span>} */}
                    {options.length > 1 && <tr className="upgrade-summary-group-entry">
                        <td><span><b>{options[0].option?.name}: </b> {options.length} items</span></td>
                        <td><span><IconButton noBg icon={component.id in summaryExpand ? "fas fa-chevron-down" : "fas fa-chevron-right"} onClick={() => toggleExpand(component.id)} /></span></td>
                        {/* <td><span>{priceElem}<IconButton noBg icon={expanded ? "fas fa-chevron-down" : "fas fa-chevron-right"} onClick={toggleExpand} /></span></td> */}
                    </tr>}

                    {options.map((y, iy) => {
                        const { option, variation, component } = y
                        const key = selectionKey(group, option, variation, component)
                        const product = y.product


                        let finalPackage = y.package
                        let customized = false
                        if (key in packageCustomizations) {
                            // Determine source package to get actual pricing
                            const sourcePackages = maps.productPackage[`${option.id}_${product.id} `]
                            if (sourcePackages?.length == 1) {
                                finalPackage = maps.package[sourcePackages[0]]
                            }
                            customized = true
                        }

                        const bedBath = floorplanVariationBedBathCode(floorplanVariation)
                        const unit = userOrder?.unitId

                        const relativePackage = getRelativePackage(maps, finalPackage, depth > 0 ? option : null)
                        // let { price, priceUnit, quantity, sqft, override, config: priceConfig, calculation } = getPrice(maps, { buildingType, floorplan, floorplanVariation, option, optionVariation: variation, component, product, pckge: finalPackage, bedBath, unit }, { selection: y, customizations: customizations.current, group, relative: true, breakdown: showPricingBreakdown, debug: false, relativePackage: pckge })
                        let pricing = getPrice(maps, { buildingType, floorplan, floorplanVariation, option, optionVariation: variation, component, product, pckge: finalPackage, bedBath, unit }, { selection: y, customizations: customizations.current, group, relative: true, breakdown: showPricingBreakdown, debug: false, relativePackage, })
                        let { price, priceUnit, quantity, sqft, override, config: priceConfig, calculation, currentPrice, currentPriceUnit } = pricing

                        let pricingChanged = (!y.package || option.id in maps.optionIsPackageOption) && ((currentPrice != null && currentPrice != fnc.roundCents(price)) || (currentPriceUnit != null && currentPriceUnit != fnc.roundCents(priceUnit)))
                        const handlePriceUpdate = () => {
                            dispatch(showPrompt({ type: PromptType.Confirm, title: 'Update Price', message: getPricingUpdateMessage(price, price != priceUnit ? priceUnit : 0, currentPrice, currentPrice != currentPriceUnit ? currentPriceUnit : 0), confirmMessage: 'Update Pricing' }))
                                .then((x) => {
                                    if (x.payload) {
                                        if (y.package && !(option.id in maps.optionIsPackageOption)) {
                                            return
                                            // Find true package option
                                            // const packageOption = maps.packageOption[y.package.id]
                                            // const packageKey = selectionKey(group, packageOption, packageOption?.variations[0], packageOption?.variations[0]?.components[0])
                                            // const selection = selections[packageKey]
                                            // if (selection) {
                                            //     handlePackage(group, selection.option, selection.variation, selection.component, selection.pckge, true, true)
                                            //     return
                                            // }
                                        } else if (y.package && option.id in maps.optionIsPackageOption) {
                                            handlePackage(group, option, variation, component, y.package, true, true)
                                            return
                                        }
                                        handleProduct(group, option, variation, component, product, finalPackage, true)
                                    }
                                })
                        }

                        let priceElem = null
                        let priceChangeElem = pricingChanged ? <Button tertiary icon="fas fa-exclamation-triangle" onClick={(e) => { e.stopPropagation(); handlePriceUpdate() }}>Outdated pricing</Button> : null
                        let priceString = ''
                        let subCost = pckge && pckge.priceCost == null
                        // Show quantity calculation
                        if (true) {//true || (pckge == null || pckge.priceCost == null)) {
                            total += price
                            const className = subCost ? ' sub-cost' : ''
                            if (price != null) {
                                if (price == 0 && (!showPricingBreakdown || quantity == null && sqft == null)) {
                                    priceElem = "(included)"
                                } else if (showPricingBreakdown && sqft != null && Math.abs(price - priceUnit * sqft) < 1) {
                                    priceElem = <div className={`column quantity - pricing${className} `}>
                                        <span>{`${sqft} @${fnc.toMoney(priceUnit)} per sqft`}</span>
                                        <span>{priceChangeElem}{`${fnc.toMoney(priceUnit * sqft)} `}</span>
                                    </div>
                                    priceString = `${sqft} @${fnc.toMoney(priceUnit)} = ${fnc.toMoney(priceUnit * sqft)} `
                                } else if (showPricingBreakdown && quantity != null && Math.abs(price - priceUnit * quantity) < 1) {
                                    if (quantity == 0) {
                                        return
                                    }
                                    priceElem = <div className={`column quantity - pricing${className} `}>
                                        <span>{`${quantity} @${fnc.toMoney(priceUnit)} each`}</span>
                                        <span>{priceChangeElem}{`${fnc.toMoney(price)} `}</span>
                                    </div>
                                    priceString = `${quantity} @${fnc.toMoney(priceUnit)} = ${fnc.toMoney(priceUnit * quantity)} `
                                } else {
                                    priceElem = <span className={className}>{priceChangeElem}{fnc.toMoney(price)}</span>
                                    priceString = fnc.toMoney(price)
                                }
                            }
                        }

                        if (subCost && (!customized || price == 0) && !showPricingBreakdown) {
                            priceString = ''
                            priceElem = null
                        }

                        if (price == 0) {
                            priceString = '(included)'
                        }

                        /*if (product?.upgradePackageId != null && !customized) {
                            const pckge = maps.package[product.upgradePackageId]
                            if (pckge.upgradeTierId == UpgradeTier.Standard) {
                                // price = 0
                                priceString = '(included)'
                            }
                        }*/

                        if (!showPricing) {//} || priceElem == null) {
                            // priceElem = <span>Please Inquire</span>
                            // priceElem = <span>Pricing not shown</span>
                            priceElem = null
                        }

                        let optionName = getOptionName(maps, pckge, option)
                        if (option.id in maps.variationOptions && maps.variationOptions[option.id].name) {
                            optionName = maps.variationOptions[option.id].name
                        }

                        let optionLabel = optionName
                        let productName = getProductName(maps, pckge, option, product)
                        let packageName = ''
                        let packageLabel = ''
                        let sourcePackage = null
                        // Add asterisk
                        if (key in packageCustomizations) {
                            // optionName = `${ optionName }* `
                            productName = `${productName}* `
                        }
                        if (option.id in maps.optionPackage && product.upgradePackageId != null && product.id == null) {//option.name.includes(' Package') && product.id == null) {
                            productName = getPackageName(maps, y.package, y.option)
                        }

                        // If part of a package, append true source package name
                        const packageKey = `${option.id}_${product.id}`
                        if (packageKey in maps.productPackage) {

                            let packageId = maps.productPackage[packageKey].find((x) => {
                                if (pckge) {
                                    return pckge.id == x
                                } else {
                                    return true
                                }
                            })
                            if (!packageId) {
                                packageId = maps.productPackage[packageKey][0]
                            }
                            sourcePackage = maps.package[packageId]
                            if (sourcePackage) {
                                // productName = `${ sourcePackage.name } - ${ productName } `
                                packageName = sourcePackage.name
                                packageLabel = ` (${sourcePackage.name})`
                                // productName = `${ productName } (${ sourcePackage.name })`
                            }
                        }

                        if (options.length > 1) {
                            optionLabel = null
                        }
                        function getLocation(p) {
                            const areaConfig = getConfig(maps, { buildingType, floorplan, floorplanVariation, option, optionVariation: variation, component, product: p }, { area: true })
                            return (areaConfig && areaConfig.areas && areaConfig.areas.length > 0 ? areaConfig.areas : (option.areas ? option.areas : []))
                                .map((x) => maps.area[x].name).join(', ')
                        }
                        // Only render children if not customized
                        if (true) {///customized || depth == 0) {
                            // Generate summary line
                            if (option.custom) {
                                const customLine = y.note
                                lines.push(`${y.note} / ${priceString}`)
                            } else {
                                // optionLabel = `${optionName}${option.variations?.length > 1 ? ` - ${variation.name}` : ''}${variation.components && variation.components.length > 1 ? ` - ${component.name}` : ''}`
                                if (product?.upgradePackageId) {
                                    optionLabel = `Selected ${optionName.singular()}`
                                } else if (option.variations.length > 1) {
                                    optionLabel = `${optionName} - ${variation.name}${variation.components && variation.components.length > 1 ? ` - ${component.name}` : ''}`
                                } else {
                                    optionLabel = `${optionName}${variation.components && variation.components.length > 1 ? ` - ${component.name}` : ''}`
                                }
                                if (showPricing) {
                                    lines.push(`${optionLabel}: ${productName}${packageLabel} - ${priceString}`)
                                } else {
                                    lines.push(`${optionLabel}: ${productName}${packageLabel}`)
                                }
                                if (y.note) {
                                    lines.push(y.note)
                                }
                            }

                            if (showPricing) {
                                csvData.push({
                                    area: getLocation(product),
                                    group,
                                    option: { ...option, name: optionName },
                                    variation,
                                    component,
                                    package: sourcePackage,
                                    product: { ...product, name: productName },
                                    override,
                                    price,
                                    priceUnit,
                                    quantity,
                                    note: y.note,
                                    ix: 0,
                                    customized,
                                })
                            } else {
                                csvData.push({
                                    area: getLocation(product),
                                    group,
                                    option: { ...option, name: optionName },
                                    variation,
                                    component,
                                    package: sourcePackage,
                                    product: { ...product, name: productName },
                                    override,
                                    quantity,
                                    note: y.note,
                                    ix: 0,
                                    customized,
                                })
                            }

                            if (!sourcePackage && product && product.products && product.products.length > 0) {
                                product.products.forEach((x, ix) => {
                                    const prod = maps.product[x.childUpgradeProductId]
                                    if (showPricing) {
                                        csvData.push({
                                            area: getLocation(prod),
                                            group: group,
                                            option: { ...option, name: optionName },
                                            variation,
                                            component,
                                            package: sourcePackage,
                                            product: { ...product, name: productName },
                                            subProduct: prod,
                                            override,
                                            price,
                                            priceUnit,
                                            quantity,
                                            note: y.note,
                                            idx: ix,
                                            customized,
                                        })
                                    } else {
                                        csvData.push({
                                            area: getLocation(prod),
                                            group: group,
                                            option: { ...option, name: optionName },
                                            variation,
                                            component,
                                            package: sourcePackage,
                                            product: { ...product, name: productName },
                                            subProduct: prod,
                                            override,
                                            quantity,
                                            note: y.note,
                                            idx: ix,
                                            customized,
                                        })
                                    }
                                })
                            }
                        }

                        // If product is package, drill down
                        if (product?.upgradePackageId != null) {
                            const pckge = maps.package[product.upgradePackageId]
                            const packageCustomizations = getCustomizations(pckge)
                            const customized = Object.keys(packageCustomizations).length > 0
                            const packageLabel = optionLabel
                            const expanded = pckge.id in summaryExpand
                            const packageName = getPackageName(maps, pckge, option)

                            return <React.Fragment key={iy}>
                                {/* <tr className="upgrade-summary-divider"><td colSpan={4}></td></tr> */}
                                <tr className="upgrade-summary-group-entry">
                                    <td><span><b>{packageLabel}:</b> {packageName}</span></td>
                                    {/* <td></td> */}
                                    <td><span>{priceElem}<IconButton noBg icon={expanded ? "fas fa-chevron-down" : "fas fa-chevron-right"} onClick={() => toggleExpand(pckge.id)} /></span></td>
                                    {/* <td><span>{priceElem ? priceElem : 'Please inquire'}<IconButton noBg icon={expanded ? "fas fa-chevron-down" : "fas fa-chevron-right"} onClick={toggleExpand} /></span></td> */}
                                </tr>
                                {y.note && <tr className="upgrade-summary-group-note" key={`${iy}-note`}>
                                    <td colSpan={2}>
                                        <span>{y.note}</span>
                                    </td>
                                </tr>}
                                {pckge.products.sort(orderSort).map((y) => {
                                    const opt = maps.option[y.upgradeOptionId]
                                    if (opt.hidden) {
                                        return
                                    }
                                    return getSummaryOption(group, opt, pckge, 1, expanded)
                                })}
                                {expanded && customized && <tr><td colSpan={4}><span className="upgrade-summary-asterisk">*item changed from default within package</span></td></tr>}
                                <tr className="upgrade-summary-divider"><td colSpan={4}></td></tr>
                            </React.Fragment>
                        }

                        if (!show || (!pricingChanged && options.length != 1 && !(component.id in summaryExpand))) {
                            return
                        }
                        let finalDepth = depth
                        if (options.length > 1) {
                            finalDepth += 1
                        }


                        if (mobileView) {
                            return <React.Fragment key={iy}>
                                {/*iy == 0 && <tr className={`upgrade-summary-group-entry only-title${key in packageCustomizations ? ' customized' : ''}`} key={`${iy}-title`}>
                                    {option.custom && <td colSpan={2} onClick={() => handleEditGroup(group, option, variation, component)}><span>Custom Option</span></td>}
                                    {!option.custom && <td colSpan={2} onClick={() => handleEditGroup(group, option, variation, component)}><span>{optionLabel}</span></td>}
                                </tr>}
                                <tr className="upgrade-summary-group-entry only-product" key={iy}>
                                    {option.custom && <td><span>{y.note}</span></td>}
                                    {!option.custom && <td><span>{productName}</span></td>}
                                    <td>{showPricing && priceElem}</td>
                                </tr>*/}
                                {iy == 0 && <tr className={`upgrade-summary-group-entry ${key in packageCustomizations ? ' customized' : ''} depth-${finalDepth}`} key={iy}>
                                    <td onClick={() => handleEditGroup(group, option, variation, component)}>
                                        {option.custom
                                            ? <span><b>Custom Option:</b> {y.note}</span>
                                            : <span><b>{optionLabel}:</b> {productName}</span>}
                                    </td>
                                    <td>{priceElem}</td>
                                </tr>}
                                {y.note && <tr className="upgrade-summary-group-note" key={`${iy}-note`}>
                                    <td colSpan={2}>
                                        <span>{y.note}</span>
                                    </td>
                                </tr>}
                            </React.Fragment>
                        }
                        if (option.custom) {
                            return <tr className="upgrade-summary-group-entry" key={iy}>
                                <td onClick={() => handleEditGroup(group, option, variation, component)}><span>
                                    {y.note}
                                </span></td>
                                <td>{priceElem}</td>
                            </tr>
                        }

                        return <React.Fragment key={iy}>
                            {ix == 0 && iy == 0 && option.variations.length > 1 && <tr className={`upgrade-summary-group-entry depth-${finalDepth}`} key={`${iy}-title`}>
                                <td onClick={() => handleEditGroup(group, option, variation, component)}><span><b>{option?.name}: </b>{variation.name}</span></td>
                                <td>{/*<span>Pricing not show</span>*/}</td>
                            </tr>}
                            {(component.products.length >= 1 || option.variations.length >= 1/* || option.variations.length == 1*/) && <tr className={`upgrade-summary-group-entry${key in packageCustomizations ? ' customized' : ''} depth-${finalDepth}`} key={iy}>
                                <td onClick={() => handleEditGroup(group, option, variation, component)}>
                                    {option.custom
                                        ? <span>{y.note}</span>
                                        : <span>{optionLabel && <b>{optionLabel}: </b>}{productName}</span>}
                                </td>
                                <td>{priceElem}</td>
                            </tr>}
                            {y.note && <tr className="upgrade-summary-group-note" key={`${iy}-note`}>
                                <td colSpan={2}>
                                    <span>{y.note}</span>
                                </td>
                            </tr>}
                            {option.variations.length > 1 && ix == components.length - 1 && <tr className="upgrade-summary-divider"><td colSpan={4}></td></tr>}
                        </React.Fragment>
                    })
                    }
                </React.Fragment >
            })
        }

        // Deteremine if package is customized
        function getAdminFee() {

            let adminFee = calculateAdminFee()
            if (!adminFee || !showPricing) {
                return
            }

            // Add package admin fee
            let adminFeeElem = null
            lines.push(`Package Adminstration Fee: ${fnc.toMoney(adminFee)}`)

            csvData.push({
                option: "Package Administration Fee",
                price: adminFee,
            })

            return <div className={`upgrade-summary-group`} key={'admin'}>
                <span className="upgrade-summary-group-title">
                    Administration Fees
                </span>
                <div className="upgrade-summary-group-options">
                    <table>
                        <tbody>
                            <tr className="upgrade-summary-group-entry depth-0">
                                <td><span>Package Administration Fee</span></td>
                                {/* <td></td> */}
                                <td><span>{fnc.toMoney(adminFee)}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        }

        let total = 0
        let groupCount = {}
        Object.keys(selections).forEach((x) => {
            selections[x].forEach((y) => {
                let key = y.group?.id
                if (!(key in groupCount)) {
                    groupCount[key] = 0
                }
                if (shouldHideOption(y.group, y)) {
                    return
                }
                groupCount[key]++
            })
        })
        // summaryStyle.minHeight = `${viewRef.current.clientHeight - (ix == 0 ? 120 : (ix < count - 1 ? 200 : 120))}px`

        const summaryElem = <div key="summary" className={`upgrade-summary${inPopup ? ' animate__animated animate__fadeInUp animate__fastest' : ''}${mobileView ? ' scrollable' : ''}`} onClick={(e) => {
            e.stopPropagation()
            // window.dispatchEvent(new CustomEvent(CustomEventType.CloseAllMenus))
        }}>
            {inPopup && <CloseButton onClick={() => setPopup(UpgradePopupType.None)} />}
            <div className="row">
                <h3 style={{ pointerEvents: 'none' }}>Summary</h3>
                {upgradeAdmin && <div className="bottom-right">
                    <IconButton noBg icon="fas fa-copy" onClick={copySummary} tooltip="Copy summary csv" tooltipClass="upgrade-tooltip" />
                    <IconButton noBg icon="fas fa-file-csv" onClick={csvSummary} tooltip="Generate summary csv" tooltipClass="upgrade-tooltip" />
                    <IconButton noBg icon="fas fa-file-pdf" onClick={pdfSummary} tooltip={`Generate summary pdf${enableDocuSign ? ' and docusign envelope' : ''}`} tooltipClass="upgrade-tooltip" />
                    {/* <IconButton noBg icon="fas fa-file-word" onClick={wordSummary} tooltip={`Generate summary docx`} tooltipClass="upgrade-tooltip" /> */}
                    <DropdownMenu buttonClass="alt no-bg" icon="fas fa-cog" items={[
                        { element: <Checkbox value={showPricingBreakdown} onChange={() => setShowPricingBreakdown(!showPricingBreakdown)}>Pricing Breakdown</Checkbox> },
                        { element: <Checkbox value={splitSignaturePage} onChange={handleSplitSignaturePage}>Split Signature Page</Checkbox> },
                        // { element: <Checkbox value={enableDocuSign} onChange={() => setEnableDocuSign(!enableDocuSign)}>Use DocuSign</Checkbox> }
                    ]} />
                </div>}
            </div>
            {globalOptions.length > 0 && <React.Fragment>
                <div className='upgrade-summary-group first' key={'global'}>
                    {/* <Button className="edit-button" noBg icon="fas fa-chevron-up" onClick={!inPopup ? (e) => { e.stopPropagation(); handleEditGroup(group) } : null} /> */}
                    <span className="upgrade-summary-group-title">
                        Overall
                    </span>
                    <div className="upgrade-summary-group-options">
                        <table>
                            <tbody>
                                {globalOptions.map((x) => getSummaryOption(null, x, null))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </React.Fragment>}
            {[...upgradeView.optionGroups].filter((x) => {
                let variationGroup = true
                if (Object.keys(maps.variationOptionGroups).length > 0) {
                    variationGroup = x.id in maps.variationOptionGroups
                }
                return variationGroup && (x.published || upgradeAdmin)
            }).sort(orderSort).map((group, ix) => {
                const options = []
                const groupOptions = ([...group.options]).filter((x) => x.visibilityId == Visibility.ReadWrite).sort(orderSort)
                groupOptions.forEach((x) => {
                    if (x.upgradeOptionId) {
                        const opt = maps.option[x.upgradeOptionId]
                        if (!opt.global) {
                            options.push(opt)
                        }
                    }
                })

                // Check if has custom options
                const hasCustom = Object.keys(selections).find((x) => selections[x].find((y) => y.option.custom))
                const selectionCount = group?.id in groupCount ? groupCount[group.id] : 0
                if (options.length == 0 && selectionCount == 0) {//!hasCustom && options.length == 0) {
                    return null
                }
                options.push({ id: group.id, custom: group.id })
                options.push({ id: group.id, })
                /* if (options.filter((x) => x.id in optionMap).length == 0) {
                    return null
                } */
                let groupName = group.name
                if (group.id in maps.variationOptionGroups && maps.variationOptionGroups[group.id].name != null) {
                    groupName = maps.variationOptionGroups[group.id].name
                }
                if (selectionCount == 0) {
                    return null
                }

                return <div className={`upgrade-summary-group ${ix == 0 && globalOptions.length == 0 ? 'first' : ''}`} key={ix}>
                    {/* <Button className="edit-button" noBg icon="fas fa-chevron-up" onClick={!inPopup ? (e) => { e.stopPropagation(); handleEditGroup(group) } : null} /> */}
                    <span className="upgrade-summary-group-title" onClick={!inPopup ? () => handleEditGroup(group) : null}>
                        {groupName}
                    </span>
                    <div className="upgrade-summary-group-options">
                        <table>
                            <tbody>
                                {sortOptions(options).map((x) => getSummaryOption(group, x, null))}
                                {selectionCount == 0 && <tr className="upgrade-summary-group-entry depth-0">
                                    <td style={{ width: '100%' }}>No Selections</td>
                                    <td></td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            })
            }
            {getAdminFee()}
            {!mobileView && getTotal(false)}
            {/* {((inPopup && !mobileView) || (!inPopup && mobileView)) && getTotal()} */}
        </div >

        if (showPricing) {
            lines.push(`Total : ${fnc.toMoney(total)}`)
            csvData.push({ option: 'total', price: fnc.toMoney(total) })
        }

        if (inPopup) {
            return summaryElem
        }
        return <div className="upgrade-focus-group" data-id="summary" onClick={(e) => {
            e.stopPropagation()
            window.dispatchEvent(new CustomEvent(CustomEventType.CloseAllMenus))
        }} style={summaryStyle}>
            {summaryElem}
        </div>
    }

    function getGroup(x, style = {}, ix = 0, count = 0) {
        switch (x) {
            case 'summary':
                return getSummary(false, style, ix, count)
            case 'orders':
            case 'selection':
            case 'views':
            case 'booking':
            case 'finalize':
            case 'brief':
            case 'faq':
                return getCustomGroup(x, style, ix, count)
        }
    }

    function calculateTotal(cache = true) {
        if (cache && totalCache.current != null) {
            return totalCache.current
        }

        let total = 0
        Object.values(selections).forEach((x) => {
            let selections = []
            if (!Array.isArray(x)) {
                selections.push(x)
            } else {
                selections = x
            }
            selections.forEach((y) => {
                // Recaulate price
                let debug = false//y.option?.name == 'Finish Package'
                const spec = {
                    buildingType: buildingType,
                    floorplan: floorplan,
                    floorplanVariation: floorplanVariation,
                    option: y.option,
                    optionVariation: y.variation,
                    component: y.component,
                    product: y.product,
                    pckge: y.package,
                    bedBath: floorplanVariationBedBathCode(floorplanVariation),
                    unit: userOrder?.unitId
                }
                const priceConfig = getPrice(maps, spec, { selection: y, customizations: customizations.current, group: y.group, relative: true, debug })
                const { price, priceUnit, cost, costUnit, quantity, calculation, config } = priceConfig
                if (!y.package || y.product.upgradePackageId != null) {
                    total += price
                }
            })
        })
        total += calculateAdminFee()
        totalCache.current = total
        return total
    }

    function getTotal(withSave = true) {
        if (!upgradeView) {
            return
        }

        const total = calculateTotal(false)

        let totalScale = 1
        if (total > 99999) {
            totalScale = 0.6
        } else if (total > 9999) {
            totalScale = 0.8
        } else if (total > 999) {
            totalScale = 0.8
        } else if (total > 99) {
            totalScale = 0.9
        }


        const canSave = !disableChanges
        const showSave = canSave && !upgradeView?.simpleDisplay
        const canComplete = (loggedIn || upgradeCode != null) && !disableChanges
        const orderQueued = orderSession && orderSession.queued && orderSession.queued != orderSession.session
        // if (!showPricing && (!savePending || !withSave)) {
        // return null
        // }

        return <div className="upgrade-total">
            {/* <div className="bottom-left">
                <Button secondary style={{ opacity: focusGroup && focusGroup.id == 'summary' ? 0 : 1 }} onClick={() => setShowSummary(!showSummary)} className="summary-button">{showSummary ? 'Hide' : 'Show'} Summary</Button>
            </div> */}

            {(withSave && canSave && showSave && savePending) && <div className={`column tray-column animate__animated animate__fast ${mobileView ? '' : (saving < 4 ? 'animate__fadeInUp' : 'animate__fadeOutDown')}`}>
                <Button
                    tertiary
                    noBg={autosave}
                    className={`save-changes animate__animated ${saving < 4 ? 'animate__fadeInUp  animate__fastest' : 'animate__fadeOut animate__fast'} ${(autosave || saving) ? ' saving' : ''}`}
                    onClick={!saving ? handleSave : null}>
                    {!autosave && saving == 0 && <span>Save Changes</span>}
                    {!autosave && saving == 1 && <React.Fragment><Spinner showAfter={0} /><span>Saving...</span></React.Fragment>}
                    {autosave && (saving == 0 || saving == 1) && <React.Fragment><Icon noBg icon="fas fa-sync" className="animate__animated animate__bounceIn" /><span>Saving...</span></React.Fragment>}
                    {(saving == 2 || saving == 4) && <React.Fragment><Icon noBg icon="fas fa-check" className="animate__animated animate__bounceIn" /><span>Saved!</span></React.Fragment>}
                    {(saving == 3 || saving == 6) && <React.Fragment><Icon noBg icon="fas fa-times" className="animate__animated animate__headShake" /><span>Error</span></React.Fragment>}
                    {/* {!autosave && saving == 0 && focusIndex == 0 && <InfoBubble animationIn="animate__fadeInUp" animationOut="animate__fadeOut" delay={0} chevron={mobileView ? 'bottom-left' : 'bottom-right'}>Save your changes as you go</InfoBubble>} */}
                </Button>
            </div>}
            {!withSave && <div className="column">
                <Button onClick={() => handleEditGroup(maps.optionGroups.summary)} tertiary>Back To Top</Button>
            </div>}
            {upgradeAdmin && orderSession && <div className="column tray-column" style={{ flexDirection: 'row' }}>
                {/* {orderSession == null && <React.Fragment><Spinner showAfter={0} /><span>Checking Session...</span></React.Fragment>} */}
                {orderSession.active === true && orderQueued && <Button onClick={() => handleEndSession()} tertiary>End Session</Button>}
                {orderSession.active == false && !orderSession.available && <Button icon={"fas fa-lock"} className="locked" tertiary onClick={() => handleEndSession(true)}>Order Locked</Button>}
                {/* {orderSession.active == false && orderSession.available && <span>Session Available</span>} */}
            </div>}
            {upgradeView && showSave && (canSave || upgradeAdmin || showPricing) && <div className="column total-column" style={{ alignItems: 'center', flexDirection: 'row' }}>
                {/* {withSave && canComplete && (!upgradeOrder || upgradeOrder.upgradeOrderStatusId != UpgradeOrderStatus.Complete) && upgradeView && focusGroup && (focusGroup.id == 'summary' || focusGroup.id == 'booking') && <Button onClick={handleComplete} tertiary>Complete</Button>} */}
                {canSave && <Button onClick={handleReset} tertiary style={showPricing ? null : { marginRight: '0px' }}>Clear Selections</Button>}
                {!canSave && upgradeAdmin && <Button onClick={handleEdit} tertiary style={showPricing ? null : { marginRight: '0px' }}>Edit Order</Button>}
                {showPricing && <div className="row" onClick={() => handleEditGroup(maps.optionGroups.summary)}>
                    <span>Total</span>
                    <FlipNumbers value={total} style={{ zoom: totalScale }} />
                </div>}
            </div>}
        </div >
    }

    function getFocusProduct() {
        if (!focusProduct) {
            return
        }

        const focusStyle = {}
        if (viewRef.current && viewRef.current.clientHeight < 800) {
            if (focusPackage) {
                focusStyle.zoom = 0.6
            } else {
                focusStyle.zoom = 0.7
            }
        } else {
            if (focusPackage) {
                focusStyle.zoom = 0.7
            } else {
                focusStyle.zoom = 0.8
            }
        }
        const {
            group, option, variation, component, product,
        } = focusProduct

        const areaConfig = getConfig(maps, { buildingType, floorplan, floorplanVariation, option, optionVariation: variation, component, product }, { area: true })
        let areaString = null
        if (areaConfig && areaConfig.areas && areaConfig.areas.length > 0) {
            areaString = areaConfig.areas.map((x) => maps.area[x].name).join(', ')
        } else if (option.areas) {
            areaString = option.areas.map((x) => maps.area[x].name).join(', ')
        }

        let sortedProducts = focusPackage ? [...focusPackage.products].filter((x) => {
            const opt = maps.option[x.upgradeOptionId]
            return !opt.hidden
        }).sort(orderSort) : []
        let visibleProducts = []
        let paginate = false
        let pageCount = 0

        // Paginate
        if (sortedProducts.length > FOCUS_PRODUCT_LIMIT) {
            paginate = true
            pageCount = Math.ceil(sortedProducts.length / FOCUS_PRODUCT_LIMIT)
            visibleProducts = sortedProducts.slice(FOCUS_PRODUCT_LIMIT * focusPage, FOCUS_PRODUCT_LIMIT * (focusPage + 1))
        } else {
            visibleProducts = sortedProducts
        }

        function handleDismiss() {
            if (editingProduct) {
                dispatch(showPrompt({ type: PromptType.Confirm, title: 'Discard changes?', message: 'Unsaved changes will be lost' }))
                    .then((x) => {
                        if (x.payload) {
                            setFocusProduct(null)
                            setFocusPackage(null)
                        } else {
                        }
                    })
            } else {
                setFocusProduct(null)
                setFocusPackage(null)
            }
        }

        function setProduct(idx) {
            const o = maps.option[sortedProducts[idx].upgradeOptionId]
            let p = maps.product[sortedProducts[idx].upgradeProductId]
            const { option, variation, component } = optionSelection(o, p)
            const key = selectionKey(group, option, variation, component)
            if (focusPackage && focusPackage.id in customizations.current && key in customizations.current[focusPackage.id]) {
                p = maps.product[customizations.current[focusPackage.id][key]]
            }
            setFocusProduct({ group, option, variation, component, product: p })
        }

        function nextProduct() {
            const idx = sortedProducts.findIndex((x) => x.upgradeOptionId == focusProduct.option.id)
            if (idx == -1) {
                return
            }
            let newIdx = idx
            if (idx < sortedProducts.length - 1) {
                newIdx = idx + 1
            } else {
                newIdx = 0
            }

            setProduct(newIdx)
            if (paginate) {
                let newPage = Math.floor(newIdx / FOCUS_PRODUCT_LIMIT)
                if (newPage != focusPage) {
                    setFocusPage(newPage)
                }
            }
        }

        function prevProduct() {
            const idx = sortedProducts.findIndex((x) => x.upgradeOptionId == focusProduct.option.id)
            if (idx == -1) {
                return
            }
            let newIdx = idx
            if (idx > 0) {
                // setProduct(idx - 1)
                newIdx = idx - 1
            } else {
                // setProduct(sortedProducts.length - 1)
                newIdx = sortedProducts.length - 1
            }

            setProduct(newIdx)
            if (paginate) {
                let newPage = Math.floor(newIdx / FOCUS_PRODUCT_LIMIT)
                if (newPage != focusPage) {
                    setFocusPage(newPage)
                }
            }
        }

        function nextPage() {
            if (focusPage < pageCount - 1) {
                setFocusPage(focusPage + 1)
            }
        }

        function prevPage() {
            if (focusPage > 0) {
                setFocusPage(focusPage - 1)
            }
        }

        let title = getProductName(maps, focusPackage, focusProduct.option, product)
        if (focusPackage && focusProduct) {
            title = title.replace(`${focusProduct.option.name} -`, '')
            title = `${focusProduct.option.name} - ${title}`
        }

        // return <UpgradePopup onClick={handleDismiss} close={false} withPackage={focusPackage != null} style={focusStyle}>
        return <React.Fragment>
            {focusPackage && <div className="row package-details">
                <div className="column">
                    <div className="focus-opt row animate__animated animate__fadeIn animate__fastest" onMouseDown={(e) => e.stopPropagation()}>
                        <CloseButton onClick={handleDismiss} />
                        <div className="row">
                            <h3>{focusPackage.name}</h3>
                        </div>
                        <div className="row" style={{ marginLeft: 'auto', marginTop: '-5px', marginBottom: '5px' }}>
                            {paginate && <IconButton icon="fas fa-chevron-left" className={focusPage == 0 ? 'disabled' : ''} onClick={prevPage} noBg />}
                            {paginate && <span>{focusPage + 1} / {pageCount}</span>}
                            {!paginate && <span>{visibleProducts.length} products</span>}
                            {paginate && <IconButton icon="fas fa-chevron-right" className={focusPage == pageCount - 1 ? 'disabled' : ''} onClick={nextPage} noBg />}
                        </div>
                    </div>
                    <div className="row product-selector animate__animated animate__fadeIn animate__fastest" onMouseDown={(e) => e.stopPropagation()}>
                        {visibleProducts.map((x, ix) => {
                            const o = maps.option[x.upgradeOptionId]
                            const v = o.variations[0]
                            const c = v.components[0]
                            const k = selectionKey(group, o, v, c)

                            // Check for override
                            let p = maps.product[x.upgradeProductId]
                            let custom = false

                            if (focusPackage.id in customizations.current && k in customizations.current[focusPackage.id]) {
                                p = maps.product[customizations.current[focusPackage.id][k]]
                                custom = true
                            }
                            let m = p.media.length > 0 ? p.media[0] : null
                            let mediaElem = null
                            if (p.products.length > 0) {
                                let productMedia = []
                                p.products.forEach((x) => {
                                    const pB = maps.product[x.childUpgradeProductId]
                                    if (pB?.media.length > 0) {
                                        productMedia.push(media[pB.media[0]])
                                    }
                                })
                                mediaElem = <GalleryTile app={app} gallery={{ media: productMedia }} fadeIn />
                            } else if (p.media.length > 0) {
                                mediaElem = <Media mediaId={m} app={app} fadeIn />
                            }

                            return <div key={`${p.id}-${ix}`} className={`column col-3 product-selector-item${focusProduct.option.id == o.id ? ' selected' : ''}`} onClick={() => {
                                const newFocus = { group, ...optionSelection(o, p) }
                                setFocusProduct(newFocus)
                            }}>
                                <div className="media-wrapper">
                                    {custom && <Icon noBg icon="fas fa-asterisk" className="customized-icon" />}
                                    {mediaElem}
                                </div>
                                <span>{o.name}</span>
                            </div>

                        })}
                    </div>
                </div>
            </div>}
            <div className="focus-product animate__animated animate__fadeIn animate__fastest" onMouseDown={(e) => e.stopPropagation()} onClick={(e) => e.stopPropagation()}>
                <div className="focus-product-title">
                    <h3>{title}</h3>
                </div>
                <div className="focus-product-media">
                    {product.media.length == 1 && <Media mediaId={product.media[0]} app={app} fadeIn />}
                    {product.media.length > 1 && <SlideShow app={app} media={product.media} zoomable={false} showIndex={true} />}
                    {product.media.length == 0 && product.products.length > 0 && <SlideShow app={app} media={product.products.map((x) => maps.product[x.childUpgradeProductId]).filter((x) => x.media.length > 0).map((x) => x.media[0])} zoomable={false} showIndex={true} />}
                    {product.media.length == 0 && product.products.length == 0 && <span className="focus-placeholder">No media</span>}
                </div>
                {(!editingProduct || !upgradeAdmin) && <div className="focus-product-info">
                    {areaString && <span>Location: {areaString}</span>}
                    {/* {product.manufacturer && <span>Manufacturer: {product.manufacturer}</span>} */}
                    {/* {product.sku && <span>SKU: {product.sku}</span>} */}
                    {product.material && <span>Material: {product.material}</span>}
                    {product.style && <span>Style: {product.style}</span>}
                    {product.colour && <span>Colour: {product.colour}</span>}
                    {product.description && <span>Description: <ReactMarkdown>{product.description}</ReactMarkdown></span>}
                    {product.url && <span><a target="_blank" href={product.url} rel="noreferrer">{product.url}</a></span>}
                    {testAdmin && <span>Tour link: {getTourLink(focusProduct, selections, app, maps).join(',')}</span>}
                    {upgradeAdmin && <div className="top-right"><IconButton alt icon="fas fa-pen" onClick={() => handleEditingProduct({ ...product })} /></div>}
                </div>}
                {editingProduct && upgradeAdmin && <div className="focus-product-info editing">
                    {savingEditingProduct && <Spinner showAfter={0} />}
                    <div className="row" style={savingEditingProduct ? { opacity: 0.5, pointerEvents: 'none' } : null}>
                        <div className="column">
                            <table>
                                <tbody>
                                    <tr>
                                        <th><span>Description</span></th>
                                        <td>
                                            <Input textarea placeholder="Enter Description" value={editingProduct.description} onChange={(x) => handleEditingProductValue('description', x)} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="column" style={{ width: 'auto' }}>
                            <IconButton noBg icon="fas fa-check" onClick={() => handleEditingProduct(null)} />
                            <IconButton noBg icon="fas fa-times" onClick={() => setEditingProduct(null)} />
                        </div>
                    </div>
                </div>}
                {showPricing && false && <div className="bottom-right">
                    {product.priceSqft && <span>{fnc.toMoney(product.priceSqft)}/ Sq. Ft.</span>}
                    {product.priceSqIn && <span>{fnc.toMoney(product.priceSqin)}/ Sq. In.</span>}
                    {/* {product.priceCost && <span className="asdf">{fnc.toMoney(product.priceCost)}</span>} */}
                </div>}
                <CloseButton onClick={handleDismiss} />
                {focusPackage && false && <React.Fragment>
                    <div className="left-center">
                        <IconButton icon="fas fa-chevron-left" onClick={prevProduct} noBg />
                    </div>
                    <div className="right-center">
                        <IconButton icon="fas fa-chevron-right" onClick={nextProduct} noBg />
                    </div>
                </React.Fragment>}
            </div>
        </React.Fragment >
    }

    function getNav(compact = false, animation = null) {
        if (!maps.option) {
            return
        }

        const focusIndex = focusGroup ? visibleGroups.findIndex((x) => x.id == focusGroup.id) : -1

        /* const buildingTypeItems = app.buildingTypes.map((x) => ({ text: x.name, value: x }))
        const variationItems = []
        maps.buildingType[buildingType.id].floorplans.forEach((x) => {
            x.variations.forEach((y) => {
                variationItems.push({ text: `${x.name}${x.variations.length > 1 ? `-${y.name}` : ''}`, value: y })
            })
        }) */

        if (!compact) {
            return <div className={`upgrade-nav${animation ? ` animate__animated animate__${animation} animate__fast` : ''}`} onMouseDown={(e) => e.stopPropagation()}>
                {visibleGroups.map((x, ix) => {
                    const options = []
                    if (x.options) {
                        x.options.forEach((y) => {
                            if (y.visibilityId != Visibility.ReadWrite) {
                                return
                            }
                            if (y.upgradeOptionId) {
                                options.push(maps.option[y.upgradeOptionId])
                            } else {
                                options.push(y)
                            }
                        })
                    }
                    return <UpgradeNavGroup
                        key={x.id}
                        group={x}
                        mobileView={false}
                        complete={focusIndex > ix}
                        focus={focusGroup && focusGroup.id == x.id}
                        options={options}
                        onClick={handleEditGroup}
                        maps={maps}
                        onNext={ix < visibleGroups.length - 1 ? () => handleEditGroup(visibleGroups[ix + 1]) : null}
                        onPrev={ix > 0 ? () => {
                            handleEditGroup(visibleGroups[ix - 1])
                        } : null}
                        onPrevOrder={onChange && ix > 1 && Number.isInteger(x.id) ? () => handleGroupOrder(x, x.order - 1) : null}
                        onNextOrder={onChange && ix > 0 && ix < visibleGroups.length - 1 && Number.isInteger(visibleGroups[ix + 1].id) && Number.isInteger(x.id) ? () => handleGroupOrder(x, x.order + 1) : null}
                    />
                })}
            </div>
        }
        return <Swipe className="upgrade-nav"
            onSwipeLeft={focusIndex < groups.length - 1 ? () => handleEditGroup(visibleGroups[focusIndex + 1]) : null}
            onSwipeRight={focusIndex > 0 ? () => handleEditGroup(visibleGroups[focusIndex - 1]) : null}
            onSwipeStart={handleSwipeStart}
            onSwipeEnd={handleSwipeEnd}
            onSwipeMove={handleSwipeMove}
            allowMouseEvents>
            <Button
                tertiary
                className={`prev-button ${focusIndex == 0 ? 'hide' : ''}`}
                onClick={focusIndex > 0 ? () => handleEditGroup(visibleGroups[focusIndex - 1]) : null}>
                Prev
            </Button>
            <Button
                tertiary
                className={`next-button ${focusIndex == visibleGroups.length - 1 ? 'hide' : ''}`}
                onClick={focusIndex < visibleGroups.length - 1 ? () => handleEditGroup(visibleGroups[focusIndex + 1]) : null}>
                Next
                {firstGroup?.id == focusGroup?.id && visibleGroups.length > 1 && showTip && <InfoBubble delay={5000} animationIn="animate__fadeInRight" animationOut="animate__fadeOut" chevron="top-right" onDismiss={() => setShowTip(false)}>
                    <span>Click next to move to the next section</span>
                    <Button onClick={() => setShowTip(false)}>Got it</Button>
                </InfoBubble>}
            </Button>

            {visibleGroups.map((x, ix) => {
                const offset = ix - focusIndex
                // const spacing = Math.min(400, window.innerWidth / 4)
                const style = { position: 'absolute' }
                // style.transform = `translateX(calc(-50% + ${spacing * offset + (window.innerWidth * swipe * 0.25) / 100}px)`
                if (offset == 0) {
                    style.left = '50%'
                    style.transform = `translateX(calc(-50% + ${(window.innerWidth * swipe * 0.25) / 100}px)`
                } else if (offset == 1) {
                    style.left = '150%'
                    style.transform = `translateX(calc(${window.innerWidth * swipe * 0.25 / 100}px))`
                } else if (offset == -1) {
                    style.left = '-50%'
                    // style.transform = `translateX(calc(-100% + ${window.innerWidth * swipe * 0.25 / 100}px))`
                    style.transform = `translateX(-100% + ${window.innerWidth * swipe * 0.25 / 100}px)`
                } else if (offset > 1) {
                    style.left = '200%'
                    // style.transform = `translateX(calc(100% + ${window.innerWidth * swipe * 0.25 / 100}px)`
                } else if (offset < -1) {
                    style.left = '-100%'
                    // style.transform = `translateX(calc(-200% + ${window.innerWidth * swipe * 0.25 / 100}px)`
                }

                const options = x.options ? x.options : maps.optionGroupOptions && maps.optionGroupOptions[x.id]
                if (swipe !== 0) {
                    style.transition = 'none'
                }

                return <UpgradeNavGroup
                    key={x.id}
                    group={x}
                    maps={maps}
                    style={style}
                    mobileView={true}
                    complete={focusIndex > ix}
                    focus={focusGroup && focusGroup.id == x.id}
                    options={options}
                    onClick={offset == 0 ? () => setPopup(popup == UpgradePopupType.Nav ? UpgradePopupType.None : UpgradePopupType.Nav) : handleEditGroup}
                    onNext={offset > 0 ? () => handleEditGroup(groups[ix + 1]) : null}
                    onPrev={offset < 0 ? () => handleEditGroup(groups[ix - 1]) : null}
                // onPrevOrder={onChange ? () => handleGroupOrder(x, ix - 1) : null}
                // onNextOrder={onChange ? () => handleGroupOrder(x, ix + 1) : null}
                />
            })}
        </Swipe>
    }

    function getPopup() {
        if (popup == UpgradePopupType.None) {
            return null

        }
        let focusStyle = {}

        if (popup == UpgradePopupType.FocusProduct || popup == UpgradePopupType.FocusPackage) {
            if (viewRef.current && viewRef.current.clientHeight < 800) {
                if (focusPackage) {
                    focusStyle.zoom = 0.6
                } else {
                    focusStyle.zoom = 0.7
                }
            } else {
                if (focusPackage) {
                    focusStyle.zoom = 0.7
                } else {
                    focusStyle.zoom = 0.8
                }
            }
        }
        let onClick = null
        let onClose = null
        switch (popup) {
            case UpgradePopupType.FocusProduct:
            case UpgradePopupType.FocusPackage:
            case UpgradePopupType.Faq:
                onClick = onClose = () => {
                    setPopup(UpgradePopupType.None)
                    setFocusPackage(null)
                    setFocusProduct(null)
                }
                break
            case UpgradePopupType.Faq:
                onClose = () => {
                    setPopup(UpgradePopupType.None)
                }
                break
            default:
                onClose = () => {
                    setPopup(UpgradePopupType.None)
                }
        }

        return <UpgradePopup
            style={focusStyle}
            key={popup}
            type={popup}
            popupData={popupData}
            onClose={onClose}
            onClick={onClick}
            faq={faq}
            allClients={allClients}
            app={app}
            upgrades={upgrades}
            maps={maps}
            handleAddUserOrders={handleAddUserOrders}
            handleUpdateUserOrder={handleUpdateUserOrder}
            handleUpdateUpgradeOrder={handleUpdateUpgradeOrder}
        >
            {(popup == UpgradePopupType.FocusProduct || popup == UpgradePopupType.FocusPackage) && getFocusProduct()}
            {popup == UpgradePopupType.Nav && getNav(false, 'slideInDown')}
            {popup == UpgradePopupType.Summary && getNav(true, 'slideInUp')}
        </UpgradePopup>

        // if (showFaq) {
        //     return <UpgradePopup onClick={() => setShowFaq(false)} showClose={false}>
        //         <FAQ faq={faq} onClose={() => setShowFaq(false)} />
        //     </UpgradePopup>
        // }
        // if (showAddUser) {
        //     return <UpgradePopup onClick={null}>{/*() => setShowAddUser(false)}>*/}
        //         <ClientRow allClients={allClients} editing app={app} upgrades={upgrades} maps={maps} onAdd={async (x) => {
        //             const ret = await handleAddUserOrders(x)
        //             if (ret.payload.success) {
        //                 setShowAddUser(false)
        //             }
        //             return ret
        //         }} >
        //             <CloseButton onClick={() => setShowAddUser(false)} />
        //         </ClientRow>
        //         {/* {getNav(false, 'slideInDown')} */}
        //     </UpgradePopup>
        // }
        // if (showEditUser) {
        //     return <UpgradePopup onClick={null}>{/*() => setShowAddUser(false)}>*/}
        //         <ClientRow {...showEditUser} app={app} editing upgrades={upgrades} maps={maps}
        //             onEditUserOrder={async (x) => {
        //                 const ret = await handleUpdateUserOrder(x)
        //                 if (ret.payload.success) {
        //                     setShowEditUser(null)
        //                 }
        //                 return ret
        //             }}
        //         >
        //             <CloseButton onClick={() => setShowEditUser(null)} />
        //         </ClientRow>
        //     </UpgradePopup>
        // }
        // if (showDownloadSigned) {
        //     return <UpgradePopup>
        //         <div className={`row upgrade-popup-content download-popup${generatingSignedDocuments ? ' disabled' : ''}`}>
        //             <div className="column">
        //                 <CloseButton onClick={() => setShowDownloadSigned(false)} />
        //                 {/* {generatingSignedDocuments && <Spinner overlay />} */}
        //                 <h3>Download Signed Documents</h3>
        //                 <DateRange onChange={setDownloadSignedDateRange} selected={downloadSignedDateRange} inline />
        //                 <div className="row button-row">
        //                     <Button onClick={() => handleDownloadOrders('signed')} icon="fas fa-download" tertiary>{downloadSignedLink ? 'Regenerate' : 'Generate'}</Button>
        //                 </div>
        //             </div>
        //         </div>
        //     </UpgradePopup>
        // }

        // if (showDocuments) {
        //     const generatedDocuments = showDocuments.documents.filter((x) => !x.signed && !x.envelopeId)
        //     const uploadedDocuments = showDocuments.documents.filter((x) => x.signed)
        //     const docusignDocuments = showDocuments.documents.filter((x) => x.envelopeId != null)

        //     const documentTable = (title, x, options = null) => {
        //         const signable = x.find((x) => x.signed)
        //         return <div className="row document-table">
        //             <div className="column">
        //                 <h3>{title}</h3>
        //                 {options && <div className="top-right">
        //                     {options}
        //                 </div>}
        //                 {x.length == 0 && <h5>No Documents</h5>}
        //                 {x.length > 0 && <table>
        //                     <thead>
        //                         <th>
        //                             Filename
        //                         </th>
        //                         <th>
        //                             Date Created
        //                         </th>
        //                         {signable && <th>
        //                             Date Signed
        //                         </th>}
        //                         <th>

        //                         </th>
        //                     </thead>
        //                     <tbody>
        //                         {x.map((y) => {
        //                             const m = media[y.mediaId]
        //                             let dateCreated = fnc.dateFriendly(new Date(m.dateCreated))
        //                             let filename = m.link
        //                             return <tr>
        //                                 <td>
        //                                     {filename}
        //                                 </td>
        //                                 <td>
        //                                     {dateCreated}
        //                                 </td>
        //                                 {signable && <td>
        //                                     <DropdownMenu text={fnc.dateFriendly(new Date(y.signedDate))} items={[
        //                                         { element: <DateSelector onChange={(x) => handleDocumentDateChange(y, showDocuments, x)} selected={y.dateSigned} /> }
        //                                     ]} />
        //                                 </td>}
        //                                 <td>
        //                                     <div className="row">
        //                                         <IconButton onClick={() => handleDocument(m)} icon="fas fa-download" tertiary />
        //                                         <IconButton onClick={() => handleDocumentDelete(m, showDocuments, signable)} icon="fas fa-trash" />
        //                                     </div>
        //                                 </td>
        //                             </tr>
        //                         })}
        //                     </tbody>
        //                 </table>}
        //             </div>
        //         </div>
        //     }
        //     return <UpgradePopup>
        //         <div className="row upgrade-popup-content document-popup">
        //             <div className="column">
        //                 <CloseButton onClick={() => setShowDocuments(false)} />
        //                 {documentTable("Generated Documents", generatedDocuments)}
        //                 {documentTable("Uploaded Documents", uploadedDocuments, <Button onClick={() => handleDocument('add', showDocuments)} tertiary icon="fas fa-plus">Upload</Button>)}
        //                 {documentTable("Docusign Documents", docusignDocuments)}
        //             </div>
        //         </div>
        //     </UpgradePopup>
        // }
        // if (expandedNav) {
        //     return <UpgradePopup onClick={() => setExpandedNav(false)} withNav>
        //         {getNav(false, 'slideInDown')}
        //     </UpgradePopup>
        // }
        // if (showSummary) {
        //     return <UpgradePopup onClick={() => setShowSummary(false)} close={false}>
        //         {getSummary(true)}
        //     </UpgradePopup>
        // }
    }

    function getGroups() {
        if (!mobileView) {
            return visibleGroups.map((x, ix) => {
                let groupElem = null
                if (!Number.isInteger(x.id)) {
                    groupElem = getGroup(x.id, {}, ix, visibleGroups.length)
                } else {
                    const key = `${ix}-${x.id}-${userOrder?.id}`
                    if (!planIndependent && (!buildingType || !floorplan || !floorplanVariation)) {
                        groupElem = null
                    } else {
                        groupElem = <UpgradeGroup
                            key={key}
                            hasBefore={ix > 0}
                            hasAfter={ix < visibleGroups.length - 1}
                            app={app}
                            spec={{ buildingType, unit, floorplan, floorplanVariation, bedBath, unit }}
                            focus={focusGroup?.id == x.id}
                            onChange={onChange}
                            group={x}
                            maps={maps}
                            viewRef={viewRef}
                            onPackage={handlePackage}
                            onPackageReset={handlePackageReset}
                            onProduct={handleProduct}
                            onVariation={handleVariation}
                            onProductValue={handleProductValue}
                            onInfo={handleInfo}
                            selections={selections}
                            customizations={customizations.current}
                            mobileView={mobileView}
                            showPricing={showPricing && !standaloneView}
                            admin={upgradeAdmin}
                            disableChanges={disableChanges}
                            showGlobalPrompt={!upgradeView?.simpleDisplay} />
                    }
                }

                return <React.Fragment key={`${x.id}-${ix}`}>
                    {ix > 0 && <div className="group-divider">
                        <span style={{ display: 'flex', flexDirection: 'row' }}>
                            <IconButton secondary icon="fas fa-chevron-down" onClick={() => handleEditGroup(x)} />
                        </span>
                        <div style={{ height: '50px' }} />
                        {/* <Icon noBg icon="fas fa-circle" style={{ marginTop: '50px' }} /> */}
                        {/* <Icon noBg icon="fas fa-circle" /> */}
                        {/* <Icon noBg icon="fas fa-circle" style={{ marginBottom: '50px' }} /> */}
                        <span style={{ display: 'flex', flexDirection: 'row' }}>
                            <IconButton secondary icon="fas fa-chevron-up" onClick={() => handleEditGroup(visibleGroups[ix - 1])} />
                        </span>
                    </div>}
                    {groupElem}
                </React.Fragment>
            })
        }
        if (focusGroup) {
            const focusIndex = visibleGroups.findIndex((x) => x.id == focusGroup.id)
            return visibleGroups.map((x, ix) => {
                const offset = ix - focusIndex
                // const spacing = Math.min(300, window.innerWidth / 4)
                const style = { position: 'absolute', transform: `translateX(calc(${offset * 100}% + ${offset * 40}px + ${swipe}%)` }
                const options = maps.optionGroupOptions[x.id]
                if (swipe !== 0) {
                    style.transition = 'none'
                }

                if (!Number.isInteger(x.id)) {
                    return getGroup(x.id, style, ix, visibleGroups.length)
                }

                const key = `${ix}-${x.id}-${userOrder?.id}`
                return <React.Fragment key={`${x.id}-${ix}`}>
                    <UpgradeGroup
                        hasBefore={ix > 0}
                        hasAfter={ix < visibleGroups.length - 1}
                        key={key}
                        app={app}
                        spec={{ buildingType, unit, floorplan, floorplanVariation, bedBath, unit }}
                        focus={x.id == focusGroup.id}
                        onChange={onChange}
                        style={style}
                        group={x}
                        maps={maps}
                        viewRef={viewRef}
                        onPackage={handlePackage}
                        onProduct={handleProduct}
                        onVariation={handleVariation}
                        onProductValue={handleProductValue}
                        onInfo={handleInfo}
                        selections={selections}
                        customizations={customizations.current}
                        mobileView={mobileView}
                        showPricing={showPricing}
                        admin={upgradeAdmin}
                        disableChanges={disableChanges} />
                </React.Fragment>
            })
        }
    }
    if (error) {
        return <Message error={error} />
    }

    // Start render routines
    /*if (initialized == 0 || !groups || loading <= 1 || resuming) {
        return <Spinner overlay />
    }*/

    if (initialized >= 2 && ((upgradeView && !planIndependent && (!buildingType || !floorplanVariation) && focusGroup?.id != 'selection') || !focusGroup || !upgradeView)) {
        logger.error(initialized, upgradeView, planIndependent, buildingType, floorplanVariation, focusGroup, upgradeView)
        // logger.error("Missing focus group", focusGroup)
        if (focusGroup == null) {
            focusFirst(null, null, true)
            return null
        }
        return <Message info="Incorrect configuration" />
    }

    /* const tour = media[floorplanVariation.tourMediaId]
    if (!tour) {
        return <Message info="Tour not found" />
    }
     
                const url = `https://inventdev.com/tours/${tour.link}/#media=1`
     
                let finalUrl = url
    if (Object.keys(options).length > 0) {
        const finalOptions = Object.values(options).filter((x) => true || x.isDefault == "0").map((x) => {
            const key = tourKey(app, buildingType, floorplanVariation, x[0])
                return key
        })
        if (finalOptions.length > 0) {
                    finalUrl += `&son=${finalOptions.join(',')}`
                }
    }
     
                if (editOption && focusOption) {
        if (editOption.yaw) {
                    finalUrl += `&yaw=${editOption.yaw}`
                }
                if (editOption.pitch) {
                    finalUrl += `&pitch=${editOption.pitch}`
                }
    } */
    const className = `${mobileView ? 'mobile' : ''}${standaloneView && groups?.length == 1 ? ' independent' : ''}`

    if (dataLink == "faq" || extraLink == 'faq') {
        if (!upgrades) {
            return <Spinner />
        }
        return <div className="row scrollable show-scroll">
            <div className="column">
                <FAQ faq={faq} />
                {extraLink == 'faq' && dataLink != null && <Button large style={{ marginLeft: 'auto', marginRight: 'auto' }} tertiary onClick={handleFAQNavigate}>Begin Online Experience</Button>}
            </div>
        </div>
    }


    const showNav = !(standaloneView && visibleGroups.length < 2)// && !(app.pages.length > 1)
    const wrapperStyle = { maxWidth: showNav && !mobileView ? 'calc(100% - 300px)' : '100%', }
    if (!showNav) {
        wrapperStyle.height = '100%'
    }
    if (generatingPdf) {
        wrapperStyle.visibility = 'hidden'
    }

    return <div id="upgrade-view" className={className} ref={viewRef} data-focus={focusGroup?.id}>
        {(initialized == 0 || !groups || loading <= 1 || resuming) && <Spinner overlay />}
        <div className="upgrade-wrapper column" style={wrapperStyle}>
            <div className="upgrade-group-wrapper scrollable show-scroll column" ref={setScrollRef}>
                {groups && getGroups()}
            </div>
            {getPopup()}
            {getTotal()}
        </div>
        {showNav && !generatingPdf && <div className={`nav-wrapper column${mobileView ? ' compact' : ' scrollable'}`}>
            {getNav(mobileView)}
        </div>}
    </div>
}
