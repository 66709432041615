import React, { useState, useEffect } from 'react'
import { AnalyticsEvent, AppData, DynamicContentType, CustomEventType, MediaType, PageType, PromptOptions, ThumbSize } from 'app/types'
import { Media, SlideShow, WaterMark, Button, Message } from 'components'
import { IntersectionHelper } from 'views/DynamicPage/IntersectionHelper'
import { Link } from 'react-router-dom'
import { showPrompt } from 'actions/appActions'
import { useAdminPermissions, useAppDispatch, useAppSelector, useDynamicContent, useHybridContent, usePage } from 'app/hooks'
import { DynamicContent } from './DynamicContent'
import { Provinces, referralSources } from 'app/constants'
import { organizationContent, organizationStaticContent, projectStaticContent } from 'app/transformers'

interface DynamicContentViewProps {
    app: AppData,
    oraganization: OrganizationData,
    link: string
    show: boolean,
    mobile: boolean,
    onSelect,
    required: boolean,
}

const waterfallContent = new Set(['footer'])

export function DynamicContentView(props: GatesOfMeafordContentProps) {
    const dispatch = useAppDispatch()
    const { app, builder, organization, link, show, mobile, onSelect, required, ...otherProps } = { required: true, ...props }
    const hybridContent = useHybridContent()
    const adminPermissions = useAdminPermissions()
    const page = usePage()
    const editInline = useAppSelector((state: RootState) => state.admin.editInline)
    let customContent
    if (app) {
        customContent = projectStaticContent(app)
    }
    if (organization && (!customContent || (waterfallContent.has(link) && !(link in customContent))) && !(app?.dynamicContent?.find((x) => x.link == link))) {
        customContent = organizationStaticContent(organization)
    }
    const finalComponents = hybridContent(app, builder, organization, { ...(props.page || page), link }, customContent)
    let isAdmin = false
    if (adminPermissions && app) {
        isAdmin = adminPermissions(app)
    }
    const filterDraft = (x) => {
        if (x.draft && !isAdmin) {
            return false
        }
        return true
    }
    const headerComponent = finalComponents.find((x) => x.dynamicContentTypeId == DynamicContentType.Header && filterDraft(x))
    const bodyComponents = finalComponents.filter((x) => x.dynamicContentTypeId != DynamicContentType.Header && filterDraft(x))
    const bodyElem = bodyComponents.sort((a, b) => a.order - b.order).map((x, ix) => <DynamicContent key={ix} idx={x.order} app={app} organization={organization} {...x} {...otherProps} />)


    function handleAddFooterColumn() {
        window.dispatchEvent(new CustomEvent(CustomEventType.ContentAction, { detail: { action: 'add', data: { dynamicContentTypeId: DynamicContentType.Footer, order: bodyComponents.length }, page: props.page } }))
    }
    const isFooter = page?.pageType == PageType.Footer || link == "footer"
    if (finalComponents.length == 0 && isFooter && required) {
        const test = hybridContent(app, builder, organization, { ...(props.page || page), link }, customContent, true)
        return <Message type="error" info="No content found" />
    }

    if (isFooter) {
        return <React.Fragment>
            {bodyElem}
            {editInline && <Button onClick={handleAddFooterColumn}>Add Column</Button>}
        </React.Fragment>
    }

    return <React.Fragment>
        <IntersectionHelper />
        {headerComponent && <DynamicContent key={0} idx={headerComponent.order} app={app} organization={organization} {...headerComponent} {...otherProps} />}
        <div id="custom-view" className="content-view" key={link} data-link={link}>
            {bodyElem}
        </div>
    </React.Fragment>

}