import React, { useEffect, useState, useRef } from 'react'
import {
    Media,
    IconButton,
    Tile,
    DraggableTile,
    Input,
    Icon,
    Checkbox,
    MediaIcon,
} from 'components'
import {
    ThumbSize,
    MediaType,
    BuilderData,
} from 'app/types'
import { getMediaUrl } from 'helpers/config'
import * as fnc from 'helpers/fnc'
import { Toggle } from './Core/Toggle'
import { useAppSelector, usePreviewMedia } from 'app/hooks'

interface GalleryTileProps {
    app: AppData,
    builder: BuilderData,
    organization: OrganizationData,
    gallery: GalleryData,
    onClick: () => void,
    selected: boolean,
    scrollTo: boolean
    isDialog: boolean,
    listRef: React.RefObject,
    aspect: boolean,
}

export function GalleryTile(props: MediaTileProps) {
    const { app, builder, organization, gallery, selected, onClick, scrollTo, isDialog, listRef, aspect } = props
    const allMedia = useAppSelector(state => state.app.media)
    const tileRef = useRef()
    const previewMedia = usePreviewMedia()

    useEffect(() => {
        if (tileRef && tileRef.current && listRef && listRef.current && scrollTo) {
            const offsetTop = tileRef.current.offsetTop
            listRef.current.scrollTo({ y: `${offsetTop - 20}px` })

        }
    }, [tileRef && tileRef.current, listRef && listRef.current])

    if (!gallery) {
        return null
    }

    let media = gallery.media
    let size = null
    let thumbSize = ThumbSize.Small
    if (gallery.media.length == 1) {
        media = media.slice(0, 1)
        size = 'by-1'
        thumbSize = ThumbSize.Medium
    } else if (gallery.media.length == 2) {
        media = media.slice(0, 2)
        size = 'by-half'
        thumbSize = ThumbSize.Medium
    } else if (gallery.media.length <= 6) {
        media = media.slice(0, 4)
        size = 'by-2'
    } else {
        media = media.slice(0, 9)
        size = 'by-3'
    }

    return <Tile square={!aspect} aspect={aspect} className={`media-tile gallery-tile${selected ? ' selected' : ''}`} style={{ opacity: 1 }} forwardRef={tileRef} onClick={() => onClick(gallery)}>
        <div className={`gallery-media ${size}`}>
            {media.map((x) => {
                const m = allMedia[x.mediaId || x.id]
                const finalMedia = previewMedia(app, m)
                return <div className="gallery-media-item">
                    <MediaIcon media={m} />
                    <Media key={`${finalMedia?.id}${x.dateModified}`} app={app} builder={builder} organization={organization} mediaId={finalMedia?.id} thumb={true} thumbSize={thumbSize} fallback />
                </div>
            })}
        </div>
        {selected && isDialog && <IconButton icon="fas fa-check" onClick={(e) => { e.stopPropagation(); onClick(gallery, e, true) }} />}
        <div className="media-info noselect">
            <span>{props.name || gallery.name}</span>
        </div>
        {props.children}
    </Tile>
}