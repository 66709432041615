import React, { useEffect, useState } from 'react'
import { PromptType, UserOrderRecordData, UpgradeOrderStatus, PromptOptions, CustomEventType, MediaType } from 'app/types'
import { useAppDispatch, useAppSelector, useLocalStorage } from 'app/hooks'
import { Input, Button, Spinner, IconButton, DropdownMenu, Toggle, Checkbox, DateSelector } from 'components'
import { createUpgradeDocument, notifyUpgrade, showPrompt } from 'actions/appActions'
import { validateEmail, validatePhone } from 'helpers/authHeader'
import * as fnc from 'helpers/fnc'
import { transformFloorplanName, transformFloorplanVariationName, transformUnitName } from 'app/transformers'
import { logger } from 'helpers/logger'
import { getMediaLink } from 'helpers/media'
import { upgradeOrderStatusIcons } from 'app/constants'
import { DatePicker } from 'rc-datepicker'
import 'rc-datepicker/lib/style.css'

interface UpgradeOrderRowProps {
    app: AppData,
    organization: OrganizationData,
    user: UserData,
    order: UserOrderRecordData,
    onAdd: () => void,
    onDelete: () => void,
    onClick: () => void,
    onChange: () => void,

    onEditUserOrder: () => void,
    onEditUserOrderStart: () => void,
    onEditUpgradeOrder: () => void,
    onShowDocuments: () => void,
    onGeneratePdf: () => void,
    maps: Dict,
    last: boolean,
    selected: { userOrder: UserOrderRecordData, upgradeView: UpgradeViewData }
    mobileView: boolean,
    record: Dict,
    allClients: Dict[],
    mode: RecordEditorMode,
}

export function UpgradeOrderRow(props: UpgradeOrderRowProps) {
    const { app, organization, upgrades, user, order, onAdd, onDelete, onClick, onChange, onEditUserOrder, onEditUserOrderStart, onEditUpgradeOrder, onShowDocuments, onGeneratePdf, maps, last, selected, mobileView, allClients, mode } = props

    const dispatch = useAppDispatch()
    // const media = useAppSelector((state: RootState) => state.app.media)
    const [editData, setEditData] = useState(onAdd ? (mode == RecordEditorMode.AddOrder ? { appId: app.meta.id } : mode == RecordEditorMode.AddUser) : null)

    function handleDelete(x) {
        if (onDelete) {
            onDelete(user, x)
        }
    }

    function handleEdit(x) {
        if (onEditUserOrderStart) {
            onEditUserOrderStart({ user, order: x })
        }
    }

    function handleReassign(x) {
        if (onEditUserOrderStart) {
            onEditUserOrderStart({ user, order: x, reassign: true })
        }
    }

    function handleGeneratePdf(x) {
        if (onGeneratePdf) {
            onGeneratePdf({ user, order: x })
        }
    }

    function handleClick(order, view) {
        onClick(order, view, true)
    }

    function handleDocumentDelete(media, record = null, signed = false) {
        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Delete Document', message: 'Are you sure you want to delete this document?' }))
            .then((x) => {
                if (x.payload) {
                    const newRecord = { ...record }
                    newRecord.documents = newRecord.documents.filter((x) => !(x.mediaId == media.id && x.signed == signed))
                    if (onEditUpgradeOrder) {
                        onEditUpgradeOrder(newRecord)
                    }
                }
            })
    }

    function handleNotifyView(view) {
        // Find upgrade order for this 
        const record = user.orders.find((x) => x.appId == app.meta.id)
        if (record) {
            dispatch(showPrompt({ type: PromptType.Confirm, title: 'Email User?', message: `Are you sure you want to invite ${user.name} to the upgrades program?` }))
                .then((x) => {
                    if (!x.payload) {
                        return
                    }
                    const order = record.upgradeOrderRecords.find((x) => x.upgradeViewId == view.id)

                    const notifyData = {
                        userId: user.id,
                        userOrderRecordId: record.id,
                        upgradeViewId: view.id,
                    }
                    let prom = [
                        dispatch(showPrompt(PromptOptions.NotifySending)),
                        dispatch(notifyUpgrade({ app, organization, data: { orders: [notifyData] } }))
                    ]
                    Promise.all(prom).then((x) => {
                        if (x[1].payload.success) {
                            if (onChange) {
                                onChange(user, x[1].payload.sends[0].order)
                            }
                        }
                    })
                })
        }
    }

    function handleAction(x, y) {
        switch (y) {
            case 'edit':
                handleEdit(x)
                break
            case 'delete':
                handleDelete(x)
                break
            case 'pdf':
                handleGeneratePdf(x)
                break
            case 'reassign':
                handleReassign(x)
                break
            default:
                break
        }
    }

    if (!user) {
        return <h3>Missing user</h3>
    }

    return <React.Fragment>
        {user.orders.filter((x) => {
            if (order) {
                return x.id == order.id && x.appId == app.meta.id
            }
            return x.appId == app.meta.id
        }).map((_y, iy) => {
            let y = _y
            if (selected && selected.userOrder.id == _y.id) {
                y = selected.userOrder
            }
            let floorplanVariation = null
            let floorplan = null
            let floorplanName = null
            let unitName = null
            try {
                floorplanVariation = app.maps.floorplanVariation[y.floorplanVariationId]
                floorplan = app.maps.floorplan[floorplanVariation.floorplanId]
                floorplanName = floorplan.name
                if (floorplan.variations.length > 1) {
                    floorplanName = `${floorplanName} - ${floorplanVariation.name} `
                }

                if (y?.unitId && y.unitId in app.maps.unit) {
                    unitName = y?.unitId ? app.maps.unit[y?.unitId].name : ''
                }
            } catch (e) {
                logger.error(e)
                return <tr><td colSpan={6}>Error loading user order {y.email}</td></tr>
            }

            const viewSet = {}
            y.upgradeOrderRecords.forEach((x) => {
                viewSet[x.upgradeViewId] = x
            })

            const actions = [
                { icon: 'fas fa-pen', text: 'Edit', value: 'edit' },
                { icon: 'fas fa-exchange-alt', text: 'Reassign', value: 'reassign' },
                { icon: 'fas fa-file-pdf', text: 'Generate PDF', value: 'pdf' },
                /*, { icon: 'fas fa-trash', text: 'Delete', value: 'delete' }*/
            ]

            const options = <React.Fragment>
                {upgrades.views.map((z, iz) => {
                    const isSelected = selected && selected.userOrder && selected.upgradeView && selected.userOrder.id == y.id && selected.upgradeView.id == z.id
                    const record = viewSet[z.id]
                    const status = record ? record.upgradeOrderStatusId : UpgradeOrderStatus.Ready

                    const items = []
                    /*items.push({ value: z, test: z.name, icon: 'fas fa-paper-plan' })
                    if (record?.documents.length > 0) {
                        record.documents.forEach((x, ix) => {
                            const docMedia = media[x.mediaId]
                            if (docMedia) {
                                const extension = docMedia.link.split('.').pop().toUpperCase()
                                items.push({
                                    header: ix == 0 ? 'Documents' : null, value: docMedia, text: `${extension} - ${fnc.dateFriendly(new Date(docMedia.dateCreated), { time: true, days: true, dayOfTheWeek: true })}`,
                                })
                            }
                            return { value: null, text: 'Missing Document' }
                        })
                    }*/
                    Object.keys(UpgradeOrderStatus).filter((x) => isNaN(x)).forEach((x) => {
                        items.push({ text: x.toReadable(), value: UpgradeOrderStatus[x], icon: upgradeOrderStatusIcons[UpgradeOrderStatus[x]] })
                    })

                    const signedDocuments = record?.documents.filter((x) => x.signed)
                    const unsignedDocuments = record?.documents.filter((x) => !x.signed)

                    return <React.Fragment>
                        <Button alt key={`${z.id} -${iz} `} icon={upgradeOrderStatusIcons[status]} className={`view-option ${isSelected ? 'selected' : ''} `} onClick={() => {
                            handleClick(y, z)
                        }}>{z.name}</Button>
                        {/* <DropdownMenu buttonClass={`alt view-option`} value={status} icon={upgradeOrderStatusIcons[status]} items={items} onChange={(a) => handleAction(record, a)} /> */}
                        <Button alt counter={record?.documents.length} onClick={() => onShowDocuments(record)} icon="fas fa-file-pdf">Documents</Button>
                        {/*<DropdownMenu counter={unsignedDocuments.length > 0} className={!unsignedDocuments.length ? 'disabled' : ''} items={unsignedDocuments.map((x) => {
                            const docMedia = media[x.mediaId]
                            if (docMedia) {
                                const extension = docMedia.link.split('.').pop().toUpperCase()
                                return {
                                    value: docMedia, text: `${extension} - ${fnc.dateFriendly(new Date(docMedia.dateCreated), { time: true, days: true, dayOfTheWeek: true })}`,
                                }
                            }
                            return { value: null, text: 'Missing Document' }
                        })} buttonClass="alt" icon="fas fa-file-pdf" onChange={handleDocument} />
                        <DropdownMenu counter={signedDocuments.length > 0} items={[{ value: 'add', text: 'Add Signed Document', count: false }, ...signedDocuments.map((x) => {
                            const docMedia = media[x.mediaId]
                            if (docMedia) {
                                const extension = docMedia.link.split('.').pop().toUpperCase()
                                let dateString = ''
                                if (x.signedDate != null) {
                                    dateString = `Signed ${fnc.dateFriendly(new Date(x.signedDate), { time: false, days: true, dayOfTheWeek: true })}`
                                } else {
                                    dateString = `Uploaded ${fnc.dateFriendly(new Date(docMedia.dateCreated), { time: true, days: true, dayOfTheWeek: true })}`
                                }
                                return {
                                    value: docMedia, text: `${extension} - ${dateString}`
                                }
                            }
                            return { value: null, text: 'Missing Document' }
                        })]} buttonClass="alt" icon="fas fa-file-signature" onChange={(x) => handleDocument(x, record, true)} onDelete={(x) => handleDocumentDelete(x, record, true)} />*/}
                    </React.Fragment>
                })}
                {upgrades.views.length > 1 && <DropdownMenu items={upgrades.views.map((x) => ({ value: x, text: x.name }))} onChange={(x) => handleNotifyView(x)} buttonClass="alt" icon="fas fa-paper-plane" text="Notify" />}
                {upgrades.views.length == 1 && <Button onClick={() => handleNotifyView(upgrades.views[0])} alt icon="fas fa-paper-plane">Email</Button>}
                <DropdownMenu items={actions} onChange={(x) => handleAction(y, x)} buttonClass="alt" icon="fas fa-ellipsis-v" />
                {/* <IconButton alt icon="fas fa-pen" onClick={() => handleEdit(y)} /> */}
                {/* <IconButton alt icon="fas fa-trash" onClick={() => handleDelete(y)} /> */}
            </React.Fragment>

            if (mobileView) {
                return <React.Fragment key={`${y.id} -${iy} `}>
                    <tr className="client-order-record only-title">
                        <td>
                            <span>{user.name}</span>
                        </td>
                        <td>
                            <span>{user.email}</span>
                        </td>
                        <td>
                            <h5>{fnc.phoneNumber(user.phone)}</h5>
                        </td>
                        <td>
                            <span>{floorplanName}</span>
                        </td>
                        <td>
                            <span>{unitName}</span>
                        </td>
                        <td>
                            <span>{y.price ? fnc.toMoney(y.price) : 'Not Set'}</span>
                        </td>
                        {/*<td>
                            <span>{y.bookingLink}</span>
                        </td>*/}
                    </tr>
                    <tr className="client-order-record">
                        <td colSpan={5}>
                            <div className="row">
                                {options}
                            </div>
                        </td>
                    </tr>
                </React.Fragment>
            } else {
                return <tr className={`client-order-record`} key={`${y.id} -${iy} `}>
                    <td>
                        <span>{user.name}</span>
                    </td>
                    <td>
                        <span>{user.email}</span>
                    </td>
                    <td>
                        <span>{fnc.phoneNumber(user.phone)}</span>
                    </td>
                    <td>
                        <span>{floorplanName}</span>
                    </td>
                    <td>
                        <span>{unitName}</span>
                    </td>
                    <td>
                        <span>{y.price ? fnc.toMoney(y.price) : 'Not Set'}</span>
                    </td>
                    {/*<td>
                        <span>{y.bookingLink}</span>
            </td>*/}
                    <td>
                        <div className="column" style={{ flexDirection: 'row' }}>
                            {options}
                        </div>
                    </td>
                </tr>
            }
        })}
    </React.Fragment>
}