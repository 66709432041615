import React, { useEffect, useState } from 'react'
import { PromptType, UserOrderRecordData, UpgradeOrderStatus, PromptOptions, CustomEventType, MediaType } from 'app/types'
import { useAppDispatch, useAppSelector, useLocalStorage } from 'app/hooks'
import { Input, Button, Spinner, IconButton, DropdownMenu, Toggle, Checkbox, DateSelector } from 'components'
import { createUpgradeDocument, notifyUpgrade, showPrompt } from 'actions/appActions'
import { validateEmail, validatePhone } from 'helpers/authHeader'
import * as fnc from 'helpers/fnc'
import { transformFloorplanName, transformFloorplanVariationName, transformUnitName } from 'app/transformers'
import { logger } from 'helpers/logger'
import { getMediaLink } from 'helpers/media'
import { upgradeOrderStatusIcons } from 'app/constants'
import { DatePicker } from 'rc-datepicker'
import 'rc-datepicker/lib/style.css'

export enum RecordEditorMode {
    AddUser = 'addUser',
    EditUser = 'editUser',
    ReassignUser = 'reassignUser',
    AddOrder = 'addOrder',
    EditOrder = 'editOrder'
}

export enum UserAddMode {
    Single = 'single',
    Multi = 'multi'
}

interface RecordEditorProps {
    app: AppData,
    organization: OrganizationData,
    user: UserData,
    order: UserOrderRecordData,

    onChange: () => void,
    onChangePending: () => void,
    maps: Dict,
    last: boolean,
    selected: { userOrder: UserOrderRecordData, upgradeView: UpgradeViewData }
    mobileView: boolean,
    record: Dict,
    allClients: Dict[],
    mode: RecordEditorMode,
}

export function UpgradeRecordEditor(props: RecordEditorProps) {
    const { app, organization, upgrades, user, order, onChange, onChangePending, maps, last, selected, mobileView, allClients } = props


    const dispatch = useAppDispatch()
    const media = useAppSelector((state: RootState) => state.app.media)
    const [mode, setMode] = useState(props.mode)
    const [editData, setEditData] = useState(mode == RecordEditorMode.AddOrder ? { appId: app.meta.id } : (mode == RecordEditorMode.AddUser ? { name: null, email: null, phone: null, userId: 'new' } : null))
    const [refreshKey, setRefreshKey] = useState(Date.now())
    const [editing, setEditing] = useState(false)
    const [addMode, setAddMode] = useLocalStorage('user-add-mode', UserAddMode.Single)
    const [csvFormat, setCsvFormat] = useLocalStorage('user-add-csv-format', 'name, email, phone, floorplan, unit, price')
    const [csvData, setCsvData] = useState(null)

    const [onlyUpdate, setOnlyUpdate] = useState(false)
    // const [upgradeUsers, setUpgradeUsers] = useState([])
    // const [changingUser, setChangingUser] = useState(false)
    // const [editUser, setEditUser] = useState(null)
    const [userMode, setUserMode] = useState(1)

    const isEditing = mode == RecordEditorMode.EditOrder || mode == RecordEditorMode.EditUser

    useEffect(() => {
        if (mode == RecordEditorMode.AddOrder || mode == RecordEditorMode.AddUser) {
            setEditData({ appId: app.meta.id, name: null, email: null, phone: null, userId: 'new' })
        } else if (mode == RecordEditorMode.EditOrder || mode == RecordEditorMode.ReassignUser) {
            setEditData({ ...order, appId: app.meta.id, name: order?.name || user?.name, email: order?.email || user?.email, phone: order?.phone || user?.phone })
        } else if (mode == RecordEditorMode.EditUser) {
            setEditData({ ...user })
        }
    }, [])

    function handleDataField(field, value) {
        const newEditOrder = { ...editData, [field]: value }
        if (field == 'userId') {
            const user = allClients.find((x) => x.id == value)
            if (user) {
                newEditOrder.name = user.name
                newEditOrder.email = user.email
                newEditOrder.phone = user.phone
            }
        }

        if (field == 'floorplanVariationId' && 'unitId' in newEditOrder) {
            delete newEditOrder.unitId
        }
        setEditData(newEditOrder)
        if (onChangePending) {
            onChangePending(Date.now())
        }
    }

    function handleToggleUserMode() {
        setUserMode(1 - userMode)
        setEditData({ ...editData, userId: 'new', name: null, email: null, phone: null })
    }

    function handleEditUpgradeOrder(a, b) {
        // Fix action
        const idx = editData.upgradeOrderRecords.findIndex((z) => z.id == a.id)
        if (idx != -1) {
            const newOrder = { ...editData }
            const newRecords = [...newOrder.upgradeOrderRecords]
            newRecords[idx] = { ...newRecords[idx], upgradeOrderStatusId: b }
            newOrder.upgradeOrderRecords = newRecords
            setEditData(newOrder)
        }
    }

    function submitData(data) {
        setEditing(true)
        onChange(data, mode)
            .then((x) => {
                if (!x.payload.success || (x.payload.failures && x.payload.failures.length > 0)) {
                    let message = ''
                    if (x.payload.failures && x.payload.failures.length > 0) {
                        message = `${x.payload.failures.map((x) => JSON.stringify(x))}`
                    } else {
                        message = x.payload.message
                    }
                    dispatch(showPrompt({ type: PromptType.Confirm, title: 'Failed to edit user records', message }))
                } else if (mode == props.mode) {
                    setEditData({ appId: app.meta.id })
                }
                setEditing(false)
                setRefreshKey(Date.now())
                if (mode != props.mode) {
                    setMode(props.mode)
                }
            })
        /*
        if (onEditUserOrder) {
            setEditing(true)
            onEditUserOrder(data)
                .then((x) => {
                    if (!x.payload.success || (x.payload.failures && x.payload.failures.length > 0)) {
                        let message = ''
                        if (x.payload.failures && x.payload.failures.length > 0) {
                            message = `${x.payload.failures.map((x) => JSON.stringify(x))}`
                        } else {
                            message = x.payload.message
                        }
                        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Failed to edit user records', message }))
                    } else {
                        setEditData({ appId: app.meta.id })
                    }
                    setEditing(false)
                    setRefreshKey(Date.now())
                })
        } else if (onAddOrder) {
            setEditing(true)
            onAddOrder(data)
                .then((x) => {
                    if (!x.payload.success || (x.payload.failures && x.payload.failures.length > 0)) {
                        let message = ''
                        if (x.payload.failures && x.payload.failures.length > 0) {
                            message = `${x.payload.failures.map((x) => JSON.stringify(x))}`
                        } else {
                            message = x.payload.message
                        }
                        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Failed to add user records', message }))
                    } else {
                        setEditData({ appId: app.meta.id })
                    }
                    setEditing(false)
                    setRefreshKey(Date.now())
                })
        } else if (onAddUser) {
            setEditing(true)
            onAddUser(data)
                .then((x) => {
                    if (!x.payload.success || (x.payload.failures && x.payload.failures.length > 0)) {
                        let message = ''
                        if (x.payload.failures && x.payload.failures.length > 0) {
                            message = `${x.payload.failures.map((x) => JSON.stringify(x))}`
                        } else {
                            message = x.payload.message
                        }
                        dispatch(showPrompt({ type: PromptType.Confirm, title: 'Failed to add user', message }))
                    } else {
                        setEditData({ appId: app.meta.id })
                    }
                    setEditing(false)
                    setRefreshKey(Date.now())
                })
        }*/
    }

    function handleEditUser(x) {
        setMode(RecordEditorMode.EditUser)
    }

    function handleSubmit() {
        if (editing) {
            return
        }
        function validateUser(x) {
            if (mode == RecordEditorMode.AddOrder && !x.floorplanVariationId && !x.error?.floorplan) {
                return { floorplan: true, message: ['Missing floorplan'] }
            }
            if (!x.name && !x.email) {
                return { name: true, message: ['Missing name'] }
            }

            // if (!x.unitId) {
            // return 'Missing unit'
            // }
            // if (!x.email) {
            // return 'Missing email'
            // }
            const invalidEmail = x.email && validateEmail(x.email)
            if (invalidEmail) {
                return { message: [invalidEmail], email: true }
            }
            const invalidPhone = x.phone && validatePhone(x.phone)
            if (invalidPhone) {
                return { message: [invalidPhone], phone: true }
            }
            return null
        }

        if (mode == RecordEditorMode.AddOrder && addMode == RecordEditorMode.Multi && editData.csv) {
            const clientMap = allClients ? allClients.reduce((acc, x) => {
                if (x.email && x.email.length > 0) {
                    return ({ ...acc, [x.email.toLowerCase()]: x })
                } else if (x.name && x.name.length > 0) {
                    return ({ ...acc, [x.name.toLowerCase()]: x })
                } else {
                    return acc
                }
            }, {}) : {}
            if (!csvData) {
                try {
                    const lines = editData.csv.split(/\r?\n/)
                    let commas = (editData.csv.match(/,/g) || []).length
                    let tabs = (editData.csv.match(/\t/g) || []).length
                    const separator = commas > tabs ? ',' : '\t'
                    const format = csvFormat.split(',').map((x) => x.trim().toCamelCase())
                    if (!onlyUpdate && !format.includes('email') && !format.includes('name')) {
                        throw new Error('Missing email or name')
                    }
                    const newData = []
                    lines.forEach((x) => {
                        const tokens = x.split(separator).map((x) => x.trim())
                        if (tokens.length < format.length) {
                            return
                        }

                        // If tokens match format, ignore
                        if (tokens.every((x, i) => x.trim().toCamelCase() == format[i])) {
                            return
                        }

                        let newUser = { appId: app.meta.id, error: { message: [] } }
                        for (let i = 0; i < format.length; i += 1) {
                            if (format[i].length > 0 && i < tokens.length) {
                                newUser[format[i]] = tokens[i].length > 0 ? tokens[i] : null
                            }
                        }

                        const parseDate = (x) => {
                            const ret = x.split('/')
                            if (ret.length == 3) {
                                ret.reverse()
                            }
                            return ret.join('-')
                        }

                        if (newUser.agreementDate) {
                            newUser.agreementDate = parseDate(newUser.agreementDate)
                        }
                        if (newUser.appointmentDate) {
                            newUser.appointmentDate = parseDate(newUser.appointmentDate)
                        }

                        if (newUser.phone) {
                            newUser.phone = newUser.phone.toAlphanum()
                        }

                        if (newUser.unit) {
                            const unitName = transformUnitName(app, { name: newUser.unit })
                            const unit = app.maps.unitName[unitName]
                            if (unit) {
                                newUser.unitId = unit.id
                            } else {
                                newUser.error.unit = true
                                newUser.error.message.push(`Failed to find unit ${newUser.unit}`)
                            }
                        }

                        // Convert floorplan to floorplanVariationId
                        if (newUser.floorplan) {
                            const floorplanLink = transformFloorplanName(app, { name: newUser.floorplan }).toLowerCase().replace(' ', '')
                            const floorplan = app.floorplans.find((x) => x.link.toLowerCase() == floorplanLink || x.name.toLowerCase() == floorplanLink)//.maps.floorplanLink[floorplanLink]
                            let floorplanVariation = null
                            if (floorplan && floorplan.variations.length > 0) {
                                if (floorplan.variations.length > 1) {
                                    const floorplanVariationLink = transformFloorplanVariationName(app, { name: newUser.floorplan })
                                    floorplanVariation = floorplan.variations.find((x) => x.link == floorplanVariationLink)
                                }
                                if (!floorplanVariation) {
                                    let floor = null
                                    if (newUser.unit) {
                                        // Get floor from first character
                                        floor = parseInt(newUser.unit[0])
                                    }
                                    // Extract floor from variation

                                    if (floor != null) {
                                        floorplan.variations.forEach((x) => {
                                            if (floorplanVariation) {
                                                return
                                            }
                                            const regex = /(\d+)(?:\s*-\s*(\d+))?/
                                            const match = x.name.match(regex)

                                            if (match) {
                                                const min = parseInt(match[1], 10)
                                                const max = match[2] ? parseInt(match[2], 10) : min
                                                if (floor >= min && floor <= max) {
                                                    floorplanVariation = x
                                                }
                                            }
                                        })
                                    }

                                }
                                if (!floorplanVariation) {
                                    floorplanVariation = floorplan.variations[0]
                                }
                                newUser.floorplanVariationId = floorplanVariation.id
                            }
                            if (!newUser.floorplanVariationId) {
                                newUser.error.floorplan = true
                                newUser.error.message.push(`Failed to find floorplan ${newUser.floorplan}`)
                            }

                        }

                        // Find existing user
                        if (onlyUpdate) {
                            if (newUser.unitId) {
                                let matchingOrders = []
                                allClients.forEach((x) => {
                                    x.orders.forEach((y) => {
                                        if (y.unitId == newUser.unitId) {
                                            newUser.existing = {}
                                            const fields = ['price', 'bookingLink', 'agreementDate', 'appointmentDate', 'designCredit']
                                            fields.forEach((x) => {
                                                if (y[x] != newUser[x]) {
                                                    newUser.existing[x] = newUser[x]
                                                }
                                            })
                                            matchingOrders.push({ ...x, ...y, ...newUser })
                                        }
                                    })
                                })

                                matchingOrders.forEach((x) => {

                                    // Convert unit to unitId
                                    const validate = validateUser(x)
                                    if (validate) {
                                        const { message, ...rest } = validate
                                        x.error = { ...x.error, message: [...x.error.message, ...message], ...rest }
                                        // throw new Error(`${validate} - ${JSON.stringify(newUser)}`)
                                    }
                                    newData.push(x)
                                })
                            }
                        } else {
                            const existing = allClients.find((x) => {
                                let matchingUnit = true
                                let matchingFloorplan = true
                                if (newUser.unitId) {
                                    matchingUnit = x.orders.find((y) => y.unitId == newUser.unitId)
                                }
                                if (newUser.floorplanVariationID) {
                                    matchingFloorplan = !x.orders.find((y) => y.floorplanVariationId != newUser.floorplanVariationId)
                                }

                                const matchingEmail = newUser.email != null && newUser.email.toLowerCase() == x.email?.toLowerCase()
                                const matchingName = newUser.name != null && newUser.name.toLowerCase() == x.name?.toLowerCase()

                                if (newUser.name == null || (newUser.email?.length > 0)) {
                                    return matchingEmail && matchingUnit && matchingFloorplan
                                } else if (newUser.name && newUser.email) {
                                    return matchingName && matchingEmail && matchingUnit && matchingFloorplan
                                } else {
                                    return matchingName && matchingUnit && matchingFloorplan
                                }
                            })
                            if (existing) {
                                const order = existing.orders.find((x) => {
                                    if (newUser.unitId && x.unitId != newUser.unitId) {
                                        return false
                                    }
                                    if (newUser.floorplanVariationId && x.floorplanVariationId != newUser.floorplanVariationId) {
                                        return false
                                    }
                                    return x.appId == app.meta.id
                                })
                                if (order) {
                                    newUser.existing = {}
                                    if (existing.email != newUser.email) {
                                        newUser.existing.email = newUser.email
                                    }
                                    if (existing.name != newUser.name) {
                                        newUser.existing.name = newUser.name
                                    }
                                    if (existing.phone != newUser.phone) {
                                        newUser.existing.phone = newUser.phone
                                    }
                                    const fields = ['unitId', 'floorplanVariationId', 'price', 'bookingLink', 'agreementDate', 'appointmentDate', 'designCredit']
                                    fields.forEach((x) => {
                                        if (order[x] != newUser[x]) {
                                            newUser.existing[x] = newUser[x]
                                        }
                                        if (!format.includes(x)) {
                                            newUser[x] = order[x]
                                        }
                                    })
                                }
                            }
                            /*if (newUser.email || newUser.name) {
                                const existing = newUser.email.toLowerCase() in clientMap ? clientMap[newUser.email.toLowerCase()] : (newUser.name.toLowerCase() in clientMap ? clientMap[newUser.name.toLowerCase()] : null)
                                newUser.existing = null
                                if (existing) {
                                    newUser.existing = {}
                                    if (existing.email != newUser.email) {
                                        newUser.existing.email = newUser.email
                                    }
                                    if (existing.name != newUser.name) {
                                        newUser.existing.name = newUser.name
                                    }
                                    if (existing.phone != newUser.phone) {
                                        newUser.existing.phone = newUser.phone
                                    }
                                    if (existing.unitId != newUser.unitId) {
                                        newUser.existing.unitId = newUser.unitId
                                    }
                                    if (existing.floorplanVariationId != newUser.floorplanVariationId) {
                                        newUser.existing.floorplanVariationId = newUser.floorplanVariationId
                                    }
                                    if (existing.price != newUser.price) {
                                        newUser.existing.price = newUser.price
                                    }
                                }
                            }*/


                            // Convert unit to unitId
                            const validate = validateUser(newUser)
                            if (validate) {
                                const { message, ...rest } = validate
                                newUser.error = { ...newUser.error, message: [...newUser.error.message, ...message], ...rest }
                                // throw new Error(`${validate} - ${JSON.stringify(newUser)}`)
                            }
                            newData.push(newUser)
                        }
                    })
                    setCsvData(newData)
                } catch (e) {
                    dispatch(showPrompt({ type: PromptType.Confirm, title: 'Error parsing separated fields', message: e.toString() }))
                }
            } else {
                submitData(csvData)
            }
        } else {
            const validate = validateUser(editData)
            if (validate) {
                logger.info('Invalid user', editData, validate)
                dispatch(showPrompt({ type: PromptType.Confirm, title: 'Error creating user', message: validate.message.join(', ') }))
                return
            }
            submitData([editData])
        }
    }

    function getUserDropdown() {
        const userItems = allClients.map((x) => {
            return { text: `${x.name} (${x.email})`, value: x.id }
        })
        let value = editData.userId != 'new' ? editData.userId : null
        return <DropdownMenu value={value} search items={userItems} onChange={(x) => handleDataField('userId', x)} addButton={'New User'} />
    }

    if (!editData) {
        return null
    }
    let planItems = []
    let unitItems = null
    const plans = [...app.floorplans].sort((a, b) => {
        const numA = a.name.match(/\d+/)
        const numB = b.name.match(/\d+/)
        if (numA && numB) {
            return parseInt(numA[0]) - parseInt(numB[0])
        } else {
            return a.name.localeCompare(b.name)
        }
    })
    plans.forEach((x) => {
        x.variations.forEach((y) => {
            planItems.push({ text: x.variations.length > 1 ? `${x.name} - ${y.name}` : x.name, value: y.id })
        })
    })

    let floorplanVariation = null
    let floorplan = null
    if (editData.floorplanVariationId) {
        floorplanVariation = app.maps.floorplanVariation[editData.floorplanVariationId]
        if (floorplanVariation) {
            floorplan = app.maps.floorplan[floorplanVariation.floorplanId]
            // unitItems = app.units.filter((x) => x.floorplans.includes(floorplan.id)).map((x) => ({ text: x.name, value: x.id }))
            unitItems = app.units.filter((x) => x.floorplans.find((x) => x.floorplanId == floorplan.id)).map((x) => ({ text: x.name, value: x.id }))
        }
    }
    const lockUser = false
    const errors = csvData ? csvData.filter((x) => x.error.message.length > 0).length : 0

    const parseDate = (dateString) => {
        if (!dateString) {
            return ''
        }
        let ret = new Date(`${dateString.replaceAll('/', '-')}T12:00:00Z`)
        // If date is invalid, reset
        if (ret.toString() == 'Invalid Date') {
            ret = new Date()
        }
        return ret
    }

    let agreementDate = new Date()
    let appointmentDate = new Date()
    if (isEditing) {
        if (editData.agreementDate) {
            agreementDate = parseDate(editData.agreementDate)
        }
        if (editData.appointmentDate) {
            appointmentDate = parseDate(editData.appointmentDate)
        }
    }

    const format = csvFormat.split(',').map((x) => x.trim().toCamelCase())
    if (onlyUpdate) {
        format.unshift('email')
        format.unshift('name')
    }

    const emailConflict = editData.userId == null && allClients.find((x) => x.email == editData.email)
    let title = mode.toReadable()
    // switch(mode) {
    //     case RecordEditorMode.AddOrder:
    //         title = 'Add Order'
    //         break
    //     case RecordEditorMode.EditOrder:
    //         title = 'Edit Order'
    //         break
    //     case RecordEditorMode.AddUser:
    //         title = 'Add User'
    //         break
    //     case RecordEditorMode.EditUser:
    // }

    function getModeContent() {
        switch (mode) {
            case RecordEditorMode.ReassignUser:
                title = 'Reassign User'
                return <div className="row">
                    <table>
                        <tbody>
                            <tr>
                                <th>Pick User</th>
                                <td>{getUserDropdown()}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            case RecordEditorMode.AddOrder:
            case RecordEditorMode.EditOrder:
                title = 'Edit Order'
                return <div className="row">
                    {(isEditing || addMode == UserAddMode.Single) && <div className="column single">
                        <table>
                            <tbody>
                                {isEditing && <React.Fragment>
                                    <tr>
                                        <th>Name</th>
                                        <td><span>{editData.name}<IconButton icon="fas fa-pen" onClick={handleEditUser} noBg /></span></td>
                                    </tr>
                                    <tr>
                                        <th>Email</th>
                                        <td><span>{editData.email}</span></td>
                                    </tr>
                                    <tr>
                                        <th>Phone</th>
                                        <td><span>{fnc.phoneNumber(editData.phone)}<IconButton icon="fas fa-pen" onClick={handleEditUser} noBg /></span></td>
                                    </tr>
                                </React.Fragment>}
                                {!isEditing && <React.Fragment>
                                    {userMode == 0 && <React.Fragment>
                                        <tr>
                                            <th>Pick User</th>
                                            <td>{getUserDropdown()}</td>
                                        </tr>
                                        <tr>
                                            <th> </th>
                                            <td><Button small onClick={handleToggleUserMode}>Enter User Information Instead</Button></td>
                                        </tr>
                                    </React.Fragment>}
                                    {userMode == 1 && <React.Fragment>
                                        <tr>
                                            <th>Name</th>
                                            <td><Input value={editData.name} placeholder="Enter Name" onChange={(x) => handleDataField('name', x)} /></td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td><Input id="email" value={editData.email} placeholder="Enter Email" onChange={(x) => handleDataField('email', x)} errorLabel={emailConflict ? "Email already in use (will override)" : null} /></td>
                                        </tr>
                                        <tr>
                                            <th>Phone</th>
                                            <td><Input phone value={editData.phone} placeholder="Enter Phone" onChange={(x) => handleDataField('phone', x)} /></td>
                                        </tr>
                                        <tr>
                                            <th> </th>
                                            <td><Button small onClick={handleToggleUserMode}>Select User Instead</Button></td>
                                        </tr>
                                    </React.Fragment>}
                                </React.Fragment>}
                                <tr>
                                    <th>Booking Link</th>
                                    {/* {lockUser && <td><span>{editOrder.bookingLink}</span></td>} */}
                                    {/* {!lockUser && <td><Input value={editOrder.bookingLink} placeholder="Enter Booking Link" onChange={(x) => handleOrderField('bookingLink', x)} /></td>} */}
                                    <td><Input value={editData.bookingLink} placeholder="Enter Booking Link" onChange={(x) => handleDataField('bookingLink', x)} /></td>
                                </tr>
                                <tr>
                                    <th>Appointment Date</th>
                                    {/* <td><DatePicker value={appointmentDate} onChange={(x) => handleOrderField('appointmentDate', fnc.formatDateToYYYYMMDD(x))} /></td> */}
                                    <td><DateSelector collapse selected={appointmentDate} onChange={(x) => handleDataField('appointmentDate', fnc.formatDateToYYYYMMDD(x))} /></td>
                                </tr>
                                <tr>
                                    <th>Agreement Date</th>
                                    {/* <td><DatePicker value={agreementDate} onChange={(x) => handleOrderField('agreementDate', fnc.formatDateToYYYYMMDD(x))} /></td> */}
                                    <td><DateSelector collapse selected={agreementDate} onChange={(x) => handleDataField('agreementDate', fnc.formatDateToYYYYMMDD(x))} /></td>
                                </tr>
                                <tr>
                                    <th>Design Credit</th>
                                    <td><Input price value={editData.designCredit} placeholder="Enter Design Credit" onChange={(x) => handleDataField('designCredit', x)} /></td>
                                </tr>
                                <tr>
                                    <th>Floorplan</th>
                                    <td><DropdownMenu buttonClass="button-tertiary" items={planItems} text={!floorplan ? 'Select Floor Plan' : `Floorplan: ${floorplan.name}`} value={editData?.floorplanVariationId} onChange={(x) => handleDataField('floorplanVariationId', x)} /></td>
                                </tr>
                                {unitItems && <tr>
                                    <th>Units</th>
                                    <td><DropdownMenu buttonClass="button-tertiary" items={unitItems} text={(!editData?.unitId || !(editData.unitId in app.maps.unit)) ? 'Select Unit' : `Unit: ${app.maps.unit[editData.unitId].name}`} value={editData?.unitId} onChange={(x) => handleDataField('unitId', x)} /></td>
                                </tr>}
                                {editData.unitId && <tr>
                                    <th>Unit Price</th>
                                    <td><Input price value={editData.price} placeholder="Enter Unit Price" onChange={(x) => handleDataField('price', x)} /></td>
                                </tr>}
                                {/* <tr>
                                    <td colSpan={2}>
                                    </td>
                                </tr> */}
                                {isEditing && editData.upgradeOrderRecords?.map((x) => {
                                    if (!maps.views) {
                                        logger.error("No views found in maps", maps)
                                        return
                                    }
                                    const view = maps.views[x?.upgradeViewId]
                                    const unit = app.maps.unit[editData.unitId]

                                    const items = []
                                    Object.keys(UpgradeOrderStatus).filter((x) => isNaN(x)).forEach((x) => {
                                        items.push({ text: x.toReadable(), value: UpgradeOrderStatus[x], icon: upgradeOrderStatusIcons[UpgradeOrderStatus[x]] })
                                    })

                                    return <tr>
                                        <th>Unit {unit?.name} Status</th>
                                        <td>
                                            <DropdownMenu buttonClass={`alt view-option`} value={x.upgradeOrderStatusId} icon={upgradeOrderStatusIcons[x.upgradeOrderStatusId]} text={UpgradeOrderStatus[x.upgradeOrderStatusId]} items={items} onChange={(a) => {
                                                handleEditUpgradeOrder(x, a)
                                            }} />
                                        </td>
                                    </tr>

                                })}
                            </tbody>
                        </table>
                    </div>}
                    {!isEditing && addMode == UserAddMode.Multi && <div className="column multi">
                        <Input label="Format" value={csvFormat} onChange={setCsvFormat} />
                        {!csvData && <Input textarea placeholder={csvFormat} value={editData.csv} onChange={(x) => handleDataField('csv', x)} />}
                        {csvData && <div className="table-wrapper">
                            <table>
                                <thead>
                                    <tr>
                                        {format.map((x) => {
                                            return <th key={x}>{x.toReadable()}</th>
                                        })}
                                        <th>Error</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {csvData.map((x, ix) => {
                                        let floorplanVariation = null
                                        let floorplan = null
                                        let fpName = 'Missing floorplan ' + x.floorplan
                                        if (x.floorplanVariationId) {
                                            floorplanVariation = app.maps.floorplanVariation[x.floorplanVariationId]
                                            floorplan = app.maps.floorplan[floorplanVariation.floorplanId]
                                            fpName = floorplan.name
                                            if (floorplan.variations.length > 1) {
                                                fpName = `${fpName} - ${floorplanVariation.name}`
                                            }
                                        }
                                        const getColumnClass = (key) => {
                                            if (x.existing && key in x.existing) {
                                                return 'modified'
                                            }
                                            if (x.error && key in x.error) {
                                                return 'error'
                                            }
                                            return ''
                                        }

                                        return <tr key={`${x.name}-${ix}`} className={`${x.error.message.length > 0 ? 'with-error' : ''}${x.existing ? ' existing' : ''}`}>
                                            {format.map((key) => {
                                                let value = null
                                                switch (key) {
                                                    case 'phone':
                                                        value = fnc.phoneNumber(x[key])
                                                        break
                                                    case 'price':
                                                    case 'designCredit':
                                                        if (x[key] != null && x[key] != undefined && x[key] != '') {
                                                            value = fnc.toMoney(parseInt(x[key]))
                                                        } else {
                                                            value = ''
                                                        }
                                                        break
                                                    case 'unit':
                                                        value = x.unitId ? app.maps.unit[x.unitId].name : 'Missing unit ' + x.unit
                                                        break
                                                    case 'floorplan':
                                                        value = fpName
                                                        break
                                                    case 'agreementDate':
                                                        value = x.agreementDate.replaceAll('-', '/')
                                                        break
                                                    case 'appointmentDate':
                                                        value = x.appointmentDate.replaceAll('-', '/')
                                                        break
                                                    default:
                                                        value = x[key]
                                                        break

                                                }
                                                return <td className={getColumnClass(key)}>{value}</td>

                                            })}
                                            <td>{x.error?.message?.reduce((acc, currentValue, currentIndex, array) => acc.concat(currentValue, currentIndex < array.length - 1 ? <br /> : []), [])}</td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>}
                    </div>}
                </div>
                break
            case RecordEditorMode.AddUser:
            case RecordEditorMode.EditUser:
                return <div className="row" key={editData.userId}>
                    <table>
                        <tbody>
                            <tr>
                                <th>Name</th>
                                <td><Input value={editData.name} id="name" placeholder="Enter Name" onChange={(x) => handleDataField('name', x)} /></td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                {mode == RecordEditorMode.AddUser && <td><Input id="email" value={editData.email} placeholder="Enter Email" onChange={(x) => handleDataField('email', x)} errorLabel={emailConflict ? "Email already in use (will override)" : null} /></td>}
                                {mode == RecordEditorMode.EditUser && <td><span>{editData.email}</span></td>}
                            </tr>
                            <tr>
                                <th>Phone</th>
                                <td><Input phone value={editData.phone} id="phone" placeholder="Enter Phone" onChange={(x) => handleDataField('phone', x)} /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                break
        }
    }

    return <div className={`upgrade-record-editor${!editing && addMode == RecordEditorMode.Multi ? ' multi' : ''}`} data-mode={mode} key={refreshKey} onClick={(e) => e.stopPropagation()} onMouseDown={(e) => e.stopPropagation()}>
        <div className="column" style={{ opacity: editing ? 0 : 1 }}>
            <div className="row">
                <h4>{title}</h4>
            </div>
            {/*Options*/}
            {mode == RecordEditorMode.AddOrder && <div className="row">
                <Toggle items={Object.keys(UserAddMode).map((x) => ({ text: x, value: UserAddMode[x] }))} onChange={setAddMode} value={addMode} />
                {addMode == RecordEditorMode.Multi && <div className="top-right"><Checkbox value={onlyUpdate} onChange={setOnlyUpdate}>Only Update</Checkbox></div>}
            </div>}
            {getModeContent()}
            <div className="row">
                <Button className={errors > 0 ? 'disabled' : ''} tertiary onClick={errors > 0 ? null : handleSubmit}>{isEditing ? 'Save' : 'Submit'}</Button>
                {/* <Button tertiary onClick={handleSubmit}>Submit</Button> */}
                {/* {isEditing && !changingUser && <Button tertiary onClick={handleSubmit}>Save Order</Button>} */}
                {/* {isEditing && changingUser && <Button tertiary onClick={handleSubmitUser}>{editData.userId ? 'Set User' : 'Create User'}</Button>} */}
                {/* {editing && changingUser && <Button tertiary onClick={handleClearUser}>Clear User</Button>} */}
                {/* {isEditing && changingUser && <Button onClick={() => { */}
                {/* setChangingUser(false) */}
                {/* setEditUser(null) */}
                {/* }}>Cancel</Button>} */}
                {/* {!isEditing && addMode == RecordEditorMode.Single && <Button tertiary onClick={handleSubmit}>Add Order</Button>} */}
                {mode == RecordEditorMode.AddOrder && addMode == RecordEditorMode.Multi && <React.Fragment>
                    {/* <Button className={errors > 0 ? 'disabled' : ''} tertiary onClick={errors > 0 ? null : handleSubmit}>{csvData ? 'Add Users' : 'Add User'}</Button> */}
                    {csvData && <Button tertiary onClick={() => {
                        setCsvData(null)
                    }}>Reset</Button>}
                    {errors > 0 && csvData && <h4 className="error" style={{ margin: 'auto 0', width: 'auto' }}>Fix {errors} errors</h4>}
                </React.Fragment>}
            </div>
        </div>
        {props.children}
        {editing && <Spinner showAfter={0} />}
    </div>
}