import React, { useState, useEffect } from 'react'
import { AnalyticsEvent, AppData, DynamicContentType, CustomEventType, MediaType, PageType, PromptOptions, ThumbSize } from 'app/types'
import { Media, SlideShow, WaterMark, Button } from 'components'
import { Link } from 'react-router-dom'
import { showPrompt } from 'actions/appActions'
import { useAppDispatch } from 'app/hooks'
import { CustomContent } from './CustomContent'
import { Provinces } from 'app/constants'

export const LeonaCondominiumsContentData = {
    'about': [
        {
            type: DynamicContentType.Header,
            media: [{ galleryLink: 'gallery' }],
            alt: true,
        },
        {
            type: DynamicContentType.ProjectInfo,
            body: 'No update at this time',
            links: [{ label: 'Request Info', link: "#request", icon: "fas fa-paper-plane" }],
            media: [{ galleryLink: 'highlight', index: 0 }]
        }
    ],
    'neighborhood': [
        {
            type: DynamicContentType.Neighbourhood,
            // media: { link: 'LEONA CONDOS AMENITY MAP.kml' },
            title: 'Neighbourhood Points of Interest',
            media: { mediaLink: 'LEONA CONDOS AMENITY MAP.kml', default: '1' },
            // media: { link: 'https://www.google.com/maps/d/embed?mid=1GWy33Zq7PP-YV5sJs13o0KzSj8s4du8&ehbc=2E312F' }
        }
    ]
}