import React, { useEffect, useRef, useState } from 'react'
import { Spinner } from './Spinner'

export function LoadingSpinner(props) {
    const { loaded } = { ...props }
    const [showSpinner, setShowSpinner] = useState(true)
    const timeout = useRef()
    useEffect(() => {
        if(loaded > 0) {
            timeout.current = setTimeout(() => {
                setShowSpinner(false)
            }, 500)
            return () => {
                clearTimeout(timeout.current)
            }
        }

    }, [loaded])
    return <React.Fragment>
        {showSpinner && <Spinner overlay quotes={false} show={!loaded} />}
        {loaded && <div id="loaded"/>}
    </React.Fragment>
}
