import { logger } from "./logger"

/* globals process */
export function getApiUrl(): string {
    if (process.env.API_URL) {
        return process.env.API_URL
    }
    return `${getWindowLocation().protocol}//${getWindowLocation().host}${process.env.API_PATH}`
}

export function getAppPath(): string {
    return process.env.APP_PATH
}

export function getWindowLocation() {
    return window.location
}

export function getHost() {
    const tokens = getWindowLocation().host.split('.')
    if (tokens.length < 2) {
        return null
    }
    tokens.pop()
    let host = tokens[tokens.length - 1]

    // Debugging domain switching
    // switch (host) {
    //     case 'propertygyde':
    //         host = 'gatesofmeaford'
    //         // host = 'thepropertylinq'
    //         break
    //     case 'realestategyde':
    //         host = 'gatesofmeaford'
    //         break
    //     case 'livefruita':
    //         host = 'coppercreek'
    //         break
    //     default:
    //         break
    // }
    return host
}

export function getAppUrl(): string {
    if (process.env.APP_URL) {
        return process.env.APP_URL
    }
    return `${getWindowLocation().protocol}//${getWindowLocation().host}${process.env.APP_PATH}`
}

export function getMediaUrl(app): string {
    if (process.env.MEDIA_URL) {
        return `${process.env.MEDIA_URL}${app ? `/app/${app.meta.id}` : ''}`
    }
    return `${getWindowLocation().protocol}//${getWindowLocation().host}${process.env.MEDIA_PATH}${app ? `/app/${app.meta.id}` : ''}`
}

export function getTourUrl(): string {
    if (process.env.TOUR_URL) {
        return process.env.TOUR_URL
    }
    return `${getWindowLocation().protocol}//${getWindowLocation().host}${process.env.TOUR_PATH}`
}

export function getSpinUrl(spinLink: string, viewLink: string): string {
    return `${getMediaUrl()}/spins/${spinLink}/${viewLink}/`
}
export function getSpinIcon(icon: string): string {
    return `${getMediaUrl()}/spins/icons/${icon}`
}

export function getAppMode(): string {
    return process.env.APP_MODE
}

export function getPollInterval(): string {
    return process.env.POLL_INTERVAL
}

export function getPollIntervalCritical(): string {
    return process.env.POLL_INTERVAL_CRITICAL
}

export function getAssetPath(): string {
    return `${getAppPath()}/assets/`
}

export function getEnv(): string {
    return process.env.ENV
}

export function getAnalytics(): string {
    return process.env.ANALYTICS == 1
}

export function getMapsApiKey(): string {
    return process.env.MAPS_API_KEY
}

export function getOAuthClientId(): string {
    return process.env.OAUTH_CLIENT_ID
}

export function getSocialLogin(): string {
    return process.env.SOCIAL_LOGIN ? process.env.SOCIAL_LOGIN.split(',') : []
}

export function getReCaptchaSiteKey(): string {
    return process.env.RECAPTCHA_SITE_KEY
}

export function clearCache(hard = false) {
    logger.info('Clearing cache')
    // Clear any cached data (but save login tokens)
    const states = {
        app: null,
        user: null,
    }
    Object.keys(states).forEach((x) => {
        states[x] = localStorage.getItem(`state-${x}`)
    })
    localStorage.clear()
    if (!hard) {
        Object.keys(states).forEach((x) => {
            localStorage.setItem(`state-${x}`, states[x])
        })
    }
}

export function checkVersion(version) {
    const vId = `${version.major}.${version.minor}.${version.build}`
    const cachedVersion = localStorage.getItem('vId')
    if (cachedVersion != null && cachedVersion != vId) {
        clearCache()
        localStorage.setItem('vId', vId)
        logger.info(`Force reload from version ${cachedVersion} to ${vId}`)
        // Version changed, for cache
        if (cachedVersion != null) {
            const location = getWindowLocation()
            const queryParams = new URLSearchParams(location.search);
            queryParams.set('v', vId);
            // Construct the new URL with the updated query parameters
            const newUrl = `${location.protocol}//${location.host}${location.pathname}?${queryParams.toString()}`;
            return newUrl
            // getWindowLocation().href = newUrl
        }
    }
    return null
}

export function getSentryDSN() {
    return process.env.SENTRY_DSN
}

export function isLocal() {
    // return if on local host
    return getWindowLocation().hostname === 'localhost'
}

export function getPlaceholderImage() {
    return process.env.PLACEHOLDER_IMAGE
}