import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
    RootState,
    PromptData,
    PromptType,
    Dict,
} from 'app/types'
import {
    resolvePrompt,
} from 'actions/appActions'
import {
    ShareDialog,
    InputDialog,
    ConfirmDialog,
    PendingDialog,
    LightboxDialog,
    MediaDialog,
    FilterDialog,
    BookingDialog,
    ProjectDialog,
    GalleryDialog,
    TutorialDialog,
    FormDialog,
    MapDialog,
    ImportDialog,
    LocationDialog,
} from 'components'

let dismissTimeout = null
let showTimeout = null

interface PromptProps {
    prompt: PromptData,
    onDismiss: () => void,
}

export function Prompt(props: PromptProps) {
    const { onDismiss } = props
    const prompt = 'prompt' in props ? props.prompt : useAppSelector((state: RootState) => state.app.prompt)
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const dispatch = useAppDispatch()
    const [dismiss, setDismiss] = useState(false)
    const [show, setShow] = useState(false)
    const [disableClose, setDisableClose] = useState(false)
    const [canClose, setCanClose] = useState(false)

    const dialogComponents = {
        [PromptType.Input]: InputDialog,
        [PromptType.Confirm]: ConfirmDialog,
        [PromptType.Pending]: PendingDialog,
        [PromptType.Lightbox]: LightboxDialog,
        [PromptType.Filters]: FilterDialog,
        [PromptType.Booking]: BookingDialog,
        [PromptType.Project]: ProjectDialog,
        [PromptType.Media]: MediaDialog,
        [PromptType.Gallery]: GalleryDialog,
        [PromptType.Share]: ShareDialog,
        [PromptType.Tutorial]: TutorialDialog,
        [PromptType.Form]: FormDialog,
        [PromptType.Map]: MapDialog,
        [PromptType.Import]: ImportDialog,
        [PromptType.Location]: LocationDialog,
    }

    useEffect(() => {
        if (prompt) {
            setCanClose(false)
            setTimeout(() => {
                setCanClose(true)
            }, 100)
        }
    }, [prompt])

    useEffect(() => {
        if (prompt != null && prompt.result == null) {
            clearTimeout(showTimeout)
            showTimeout = null
            setShow(true)
        }
    }, [prompt])

    const promptStyle: Dict = {
        opacity: show ? 1 : 0,
        ...(prompt ? prompt.style : null),
    }
    promptStyle.opacity = 1

    if (!prompt) {
        return null
    }

    const {
        type,
        inAnimation,
        outAnimation,
        ...options
    } = prompt

    function dismissPrompt(result) {
        console.log("DISMISS PROMPT")
        console.trace()
        if (dismissTimeout) {
            clearTimeout(dismissTimeout)
            dismissTimeout = null
        }

        if (dismiss || !prompt) {
            return
        }

        setDismiss(true)
        setTimeout(() => {
            if (prompt) {
                if (onDismiss) {
                    onDismiss(result)
                } else {
                    dispatch(resolvePrompt(result))
                }
                setDismiss(false)
                // Prevent flash of background when going between prompts
                showTimeout = setTimeout(() => {
                    setShow(false)
                }, 100)
            }
        }, 100)
    }

    function handleClose(e: Event, delay: number = null) {
        if (e && e.button !== 0) {
            return
        }

        if (disableClose) {
            return
        }

        if (prompt.type === PromptType.Pending && prompt.pendingResult === null) {
            return
        }


        if (delay) {
            dismissTimeout = setTimeout(() => {
                dismissPrompt(false)
            }, delay)
        } else {
            dismissPrompt(false)
        }
    }

    function handleResult(result) {
        dismissPrompt(result)
    }

    function handleConfirm(x = null) {
        if (x != null) {
            dismissPrompt(x)
        } else {
            dismissPrompt(true)
        }
    }

    function handleInput(x) {
        dismissPrompt(x)
    }

    function handleDisableClose(x) {
        setDisableClose(x)
    }

    function getAnimation() {
        if (outAnimation && dismiss) {
            return outAnimation
        }
        if (inAnimation && !dismiss) {
            return inAnimation
        }
        switch (type) {
            default:
                return dismiss ? 'animate__fadeOutUp' : 'animate__fadeInUp'
            case PromptType.Filters:
                return dismiss ? 'animate__fadeOut' : 'animate__fadeIn'
            case PromptType.Lightbox:
            case PromptType.Map:
                return dismiss ? 'animate__fadeOut' : (screen.isMobile ? 'animate__fadeIn' : 'animate__zoomIn')
        }
    }

    function getDialog() {
        if (prompt.result != null) {
            return null
        }
        const animation = `animate__animated animate__fastest ${getAnimation()}`
        // const animation = `animate__animated animate__slow ${getAnimation()}`

        const DialogComponent = dialogComponents[type]

        switch (type) {
            case PromptType.Input:
                return <DialogComponent
                    animation={animation}
                    onClose={handleClose}
                    onSubmit={handleInput}
                    {...options} />
            case PromptType.Confirm:
                return <DialogComponent
                    animation={animation}
                    onClose={handleClose}
                    onConfirm={handleConfirm}
                    {...options} />
            case PromptType.Import:
                return <DialogComponent
                    animation={animation}
                    onClose={handleClose}
                    onResult={handleResult}
                    {...options} />
            default:
                return <DialogComponent
                    animation={animation}
                    onClose={handleClose}
                    onDisableClose={handleDisableClose}
                    onResult={handleResult}
                    {...options} />
        }
        return null
    }

    return <div
        key="prompt"
        id={`${prompt.type}-prompt`}
        className={`prompt${prompt.type === PromptType.Pending ? ' disabled' : ''}${prompt.underBar ? ' under-bar' : ''}`}
        onMouseDown={(e) => {
            if (!canClose) {
                return
            }

            if (!options.carefulClose && !options.noDismiss && !disableClose) {
                handleClose(e)
            }
        }}
        style={promptStyle}>
        {getDialog()}
    </div>
}
