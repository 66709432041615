import React, { useEffect, useState, useRef } from 'react'
import { Prompt } from 'react-router'
import { validateEmail, validatePhone } from 'helpers/authHeader'
import { Checkbox, Input, DropdownMenu, Message, Spinner } from 'components'
import { useAppDispatch, useAppSelector, useLocalStorage } from 'app/hooks'
import { logger } from 'helpers/logger'
import { CustomEventType, FormFieldType, ReCaptchaThreshold } from 'app/types'
import * as fnc from 'helpers/fnc'
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3"
import { getReCaptchaSiteKey } from 'helpers/config'
import { refreshHumanConfidence, retrieveReCaptchaScore, setHumanConfidence } from 'actions/userActions'
import { message } from 'app/constants'

interface ContentFormProps {
    fields: Dict,
    submitRef: React.RefObj,
    onSubmit: () => void,
    onLoad: () => void,
    onFill: () => void,
    onError: () => void,
    maxColumns: number,
    clearOnSubmit: boolean
    preventNav: boolean,
    options: Dict,
    alignment: number,
}

export function ContentForm(props: ContentFormProps) {
    const { fields, submitRef, onSubmit, onLoad, onError, maxColumns, clearOnSubmit, preventNav, onFill, options, alignment } = { maxColumns: 1, clearOnSubmit: true, preventNav: true, options: {}, ...props }
    const dispatch = useAppDispatch()
    const config = useAppSelector((state) => state.app.config)
    const humanConfidence = useAppSelector((state) => state.user.humanConfidence)
    const user = useAppSelector((state) => state.user.data)
    const fieldsKey = Object.keys(fields).join(',')
    const [validation, setValidation] = useState({})
    const [error, setError] = useState(null)
    const [formData, setFormData] = useLocalStorage(`form_${fieldsKey}_${config.version.code}`, null)
    const [formKey, setFormKey] = useState(0)
    const [formFilled, setFormFilled] = useState(0)
    const [animation, setAnimation] = useState(null)
    const [initialized, setInitialized] = useState(false)
    const [loaded, setLoaded] = useState(humanConfidence != null)
    const editInline = useAppSelector((state) => state.app.editInline)

    const formRef = useRef()
    const isAdmin = user?.admin

    const elements = useRef([])

    useEffect(() => {
        // if (formData && Object.keys(formData).length > 0) {
        // return
        // }
        let newData = { ...formData }
        // Clear any fields that are 'undefined' key
        newData = Object.keys(newData).reduce((acc, key) => {
            if (newData[key] !== undefined && key != 'undefined') {
                acc[key] = newData[key]
            }
            return acc
        }, {})

        if (fields) {
            Object.keys(fields).forEach((x) => {
                const fieldId = fields[x].link ? fields[x].link : fields[x].id
                const defaultChoice = fields[x].choices?.find((x) => x.isDefault)
                if (defaultChoice && (!formData || (!formData[fieldId]) || formData[fieldId].length == 0 || fields[x].cache === false)) {
                    newData[fieldId] = defaultChoice.label
                }
            })
        }
        setFormData(newData)
        setInitialized(true)
        if (editInline) {
            dispatch(refreshHumanConfidence())
        }
    }, [])

    useEffect(() => {
        if (humanConfidence == null) {
            return
        }
        setLoaded(true)
        if (onLoad) {
            onLoad(true)
        }
    }, [humanConfidence])

    /*useEffect(() => {
        if (preventNav) {
            window.addEventListener('beforeunload', handlePreventNav)
            return () => {
                window.removeEventListener('beforeunload', handlePreventNav)
            }
        }
    }, [formFilled])*/

    useEffect(() => {
        if (submitRef) {
            submitRef.addEventListener('click', handleSubmit)
            return () => {
                submitRef.removeEventListener('click', handleSubmit)
            }
        }
    }, [submitRef, formData, loaded, humanConfidence])

    function handlePreventNav() {
        if (formFilled) {
            return 'Are you sure you want to leave? If you leave, your information will not be saved.'
        }
        return null
    }
    function getElement(field = null) {
        return getElementById(field.link ? field.link : field.id)
    }

    function getElementById(fieldId = null) {
        let elem = null

        if (fieldId in elements.current) {
            elem = elements.current[fieldId]
        } else {
            if (formRef.current) {
                elem = formRef.current.querySelector(`#${fieldId}`)
            } else {
                elem = document.getElementById(fieldId)
            }
            elements.current[fieldId] = elem
        }
        return elem
    }

    function handleFill(x) {
        if (onFill) {
            onFill(x)
        }
        setFormFilled(x)
    }

    function handleFormValue(field, value) {
        window.dispatchEvent(new CustomEvent(CustomEventType.DebugMessage, { detail: `Form value changed: ${field} = ${value}` }))
        handleFill(1)
        setFormData({ ...formData, [field]: value })

        if (field in validation) {
            const newValidation = { ...validation }
            delete newValidation[field]
            setValidation(newValidation)
        }
    }

    async function handleSubmit(e) {
        e.preventDefault(0)
        if (!loaded) {
            return
        }
        setAnimation(null)
        setValidation({})
        await new Promise((resolve) => setTimeout(resolve, 0))
        const newValidation = {}

        if (humanConfidence < ReCaptchaThreshold.Suspicious) {
            if (onError) {
                onError(message.suspiciousActivityForm)
            } else {
                setError(message.suspiciousActivityForm)
            }
            return
        }
        // Validate data
        fields.forEach((x) => {
            const fieldId = x.link ? x.link : x.id

            switch (fieldId) {
                case 'phone': {
                    const val = validatePhone(formData[fieldId], x.required)
                    if (val != null) {
                        newValidation[fieldId] = val
                    }
                    break
                }
                case 'email': {
                    if (x.required) {
                        const val = validateEmail(formData[fieldId] ? formData[fieldId].trim() : null)
                        if (val != null) {
                            newValidation[fieldId] = val
                        }
                    }
                    break
                }
                default:
                    if (x.required && (!formData[fieldId] || formData[fieldId].length == 0)) {
                        newValidation[fieldId] = 'Required'
                    }
                    break
            }
        })
        if (Object.keys(newValidation).length > 0) {
            let maxElem = null
            let max = window.innerHeight
            Object.keys(newValidation).forEach((x) => {
                const elem = getElementById(x)
                // const elem = formRef.current?.getElementById(x)
                if (elem) {
                    const bounds = elem.getBoundingClientRect()
                    if (bounds.top < max) {
                        maxElem = elem
                        max = bounds.top
                    }
                }

            })
            if (maxElem) {
                maxElem.scrollIntoView({ behavior: 'smooth', block: 'center' })
                setTimeout(() => {
                    setValidation(newValidation)
                    setAnimation('animate__animated animate__headShake')
                }, 500)
            } else {
                setValidation(newValidation)
                setAnimation('animate__animated animate__headShake')
            }
            // Scroll to highest field
        } else if (onSubmit) {
            const finalData = { ...formData, ...options }
            // Final processing
            fields.forEach((x) => {
                const fieldId = x.link ? x.link : x.id
                if (fieldId in finalData) {
                    if (typeof finalData[fieldId] == 'string') {
                        finalData[fieldId] = finalData[fieldId].trim()
                    }
                }
            })
            logger.info("Submitting form with data: ", finalData)
            handleFill(0)
            onSubmit(finalData).then((x) => {
                if (x && clearOnSubmit) {
                    setValidation({})
                    setFormData({})
                    setFormKey(formKey + 1)
                    handleFill(0)
                } else {
                    handleFill(1)
                }
            }).catch((e) => {
                logger.info('Form submit failed', e)
                setError(e)
            })
        }
    }

    function getFormFields() {
        if (!fields) {
            return null
        }

        let rows = []
        let formFields = []
        const sortedFields = [...fields].sort((a, b) => a?.order - b?.order)
        for (let i = 0; i < fields.length; i += 1) {
            const field = sortedFields[i]
            let labels = [<span key="label">{field.label}{field.required ? '*' : ''}</span>]
            let className = ''
            let columnClass = 'col-12'
            const fieldId = field.link ? field.link : field.id

            if (field.adminOnly && !isAdmin) {
                continue
            }
            switch (field.size) {
                case 4:
                default:
                    columnClass = 'col-12'
                    break
                case 3:
                    columnClass = 'col-9'
                    break
                case 2:
                    columnClass = 'col-6'
                    break
                case 1:
                    columnClass = 'col-3'
                    break
            }
            if (fieldId in validation) {
                labels.push(<span key="error" className="error">{validation[fieldId]}</span>)
                className = `${animation} validation-error`
            }

            switch (field.formFieldTypeId) {
                case FormFieldType.Consent:
                    formFields.push(<div id={fieldId} className={`column col-12 form-consent ${className}`} key={fieldId} onClick={() => handleFormValue(fieldId, !formData[fieldId])
                    }>
                        {/* <Checkbox onChange={(x) => handleFormValue(fieldId, x)} checked={formData[fieldId]}>{labels}</Checkbox> */}
                        <input type="checkbox" id={fieldId} name="consent" checked={formData[fieldId]} style={{ minHeight: '15px', minWidth: '15px' }}
                            onChange={(e) => handleFormValue(fieldId, !formData[fieldId])}
                            onKeyPress={(e) => handleFormValue(fieldId, !formData[fieldId])}
                        />
                        <label htmlFor={fieldId} className="noselect" style={{ display: 'flex', flexDirection: 'column' }}>
                            {labels}
                            <span> {field.body}</span>
                        </label>
                    </div>)
                    break
                case FormFieldType.Text:
                case FormFieldType.Textarea:
                case FormFieldType.Email:
                case FormFieldType.Number:
                case FormFieldType.Phone:
                    let key = `${fieldId}`
                    let value = formData[fieldId]
                    if (!value && field.default) {
                        value = field.default
                        key = `${fieldId}-${field.default}}`
                    }
                    if (!value && field.defaultValue) {
                        value = field.defaultValue
                        key = `${fieldId}-${field.defaultValue}}`
                    }
                    formFields.push(<div id={fieldId} className={`column ${columnClass} ${className}`} key={fieldId}>
                        <label htmlFor={fieldId}>{labels}</label>
                        <Input value={value}
                            defaultValue={value}
                            onChange={(x) => handleFormValue(fieldId, x)}
                            textarea={field.formFieldTypeId == FormFieldType.Textarea}
                            phone={field.formFieldTypeId == FormFieldType.Phone}
                            number={field.formFieldTypeId == FormFieldType.Number}
                            placeholder={field.placeholder} />
                    </div>)
                    break
                case FormFieldType.Select:
                    if (!field.choices) {
                        return <h3>Missing choices</h3>
                    }
                    const items = ([...field.choices]).sort((a, b) => a.order - b.order).map((x) => ({ value: x.value, text: x.label }))
                    formFields.push(<div id={fieldId} className={`column ${columnClass} ${className}`} key={fieldId}>
                        <label>{labels}</label>
                        <DropdownMenu
                            asList
                            value={formData[fieldId]}
                            selectText={field.placeholder ? field.placeholder : "Select Option"}
                            onChange={(x) => handleFormValue(fieldId, x)}
                            items={items} />
                    </div>)
                    break
                case FormFieldType.Radio:
                    formFields.push(<div id={fieldId} className={`column ${columnClass} ${className}`} key={fieldId}>
                        <label>{labels}</label>
                        <div className="row form-choices">
                            {field.choices?.map(({ label: x }, ix) => {
                                return <React.Fragment key={ix}>
                                    <label htmlFor={`${fieldId}_${ix}`}>{x}</label>
                                    <input type="radio" name={fieldId} tabIndex="0" checked={formData[fieldId] == x} id={`${fieldId}_${ix}`} key={x} onKeyPress={() => handleFormValue(fieldId, x)} onChange={() => handleFormValue(fieldId, x)} />
                                </React.Fragment>
                            })}
                        </div>
                    </div>)
                    break
                case FormFieldType.Checkbox:
                case FormFieldType.Date:
                default:
                    break
            }

            if (field.breakAfter) {
                rows.push(<div className="row form-row" key={rows.length}>
                    {formFields}
                </div>)
                formFields = []
            }
        }

        /*if (formFields.length == maxColumns || field.fullWidth || i == fields.length - 1) {
            rows.push(<div className="row form-row" key={fieldId}>
                {formFields}
            </div>)
            formFields = []
        }*/
        if (formFields.length > 0) {
            rows.push(<div className="row form-row">
                {formFields}
            </div>)
        }
        return rows
    }

    if (!formData || !initialized) {
        return null
    }

    function supressSubmit(e) {
        e.preventDefault(0)

    }

    function supressKeySubmit(e) {
        if (e.code == "Enter") {
            // e.preventDefault(0)
        }
    }
    let className = "content-form"
    if (alignment == 1) {
        className += " align-center"
    } else if (alignment == 2) {
        className += " align-right"
    }
    return <form key={formKey} className={className} onSubmit={supressSubmit} onKeyDown={supressKeySubmit} ref={formRef}>
        {preventNav && <Prompt message="You have unsaved changes, are you sure you want to leave?" when={formFilled > 0} />}
        {getFormFields()}
        {!error && props.children && props.children(handleSubmit, loaded)}
        {error && <div className="error"><span>{error}</span></div>}
    </form>
}