import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, IconButton } from 'components'
import { RootState, PageType, CustomEventType, ScreenOrientation, ScreenSize, UserRole } from 'app/types'
import { useAppSelector, useAppDispatch, usePagePermissions, useUserPermissions, useAdminPermissions, useExclusivePermissions, useValidPages } from 'app/hooks'
import { navigateAsync, navigateFeature } from 'actions/appActions'
import { pageOrder, pageIcons, customIcons } from 'app/constants'

const commonSet = new Set([
    PageType.Info,
    PageType.Launch,
    PageType.Floorplan,
    PageType.Sitemap,
    PageType.Building,
    PageType.Gallery,
    PageType.Modelhome,
    PageType.Neighbourhood,
    // PageType.Sitemap,
])

interface ProjectFeaturesProps {
    app: AppData,
    apps: AppData,
    selected: string,
    splitIndex: number,
    expanded: boolean,
    collapsible: boolean,
    onCollapse: () => void,
    onResize: () => void,
    showInfo: boolean
    shake: boolean,
}

export function ProjectFeatures(props: ProjectFeaturesProps) {
    const { app, apps, selected, splitIndex, expanded, collapsible, onCollapse, onResize, showInfo, shake } = { showInfo: true, ...props }
    const dispatch = useAppDispatch()
    const pagePermissions = usePagePermissions()
    const userPermissions = useUserPermissions()
    const adminPermissions = useAdminPermissions()
    const exclusivePermissions = useExclusivePermissions()
    const validPages = useValidPages()
    const exclusive = useAppSelector((state: RootState) => state.app.exclusive)
    const projectPath = useAppSelector((state: RootState) => state.app.projectPath)
    const screen = useAppSelector((state: RootState) => state.app.screen)
    const standaloneApp = useAppSelector((state: RootState) => state.app.standaloneApp)
    const customDomain = useAppSelector((state: RootState) => state.app.customDomain)
    const user = useAppSelector((state: RootState) => state.user.data)
    const loggedIn = useAppSelector((state: RootState) => state.user.loggedIn)
    const salescenter = useAppSelector((state: RootState) => state.app.salescenter)

    const [pages, setPages] = useState([])
    const [collapsed, setCollapsed] = useState(screen.isMobile)
    const featureLimit = screen.isMobile && (screen.orientation == ScreenOrientation.Portrait || screen.size <= ScreenSize.Tablet) ? 1000 : 1 //screen.isMobile ? 1 : 3
    const selectedIndex = selected ? pages.findIndex((x) => x.link == selected) : -1

    useEffect(() => {
        const orderSet = pageOrder.reduce((acc, x, ix) => ({ ...acc, [x]: ix }), {})
        let newPages = []

        if (app && app.pages) {
            newPages = validPages(app).sort((a, b) => {
                const orderA = a.order != null ? a.order : orderSet[a.pageType]
                const orderB = b.order != null ? b.order : orderSet[b.pageType]

                return orderA - orderB
            }).filter((x) => {
                return (x.draft || x.showInMenu) && x.pageType != PageType.ScreenSaver
            })
            /*if (newPages.find((x) => x.pageType == PageType.Info) == null && !standaloneApp) {
                newPages.unshift({ name: 'Info', link: '', pageType: PageType.Info })
            }*/
            setPages(newPages)
        } else if (apps) {
            // Get common set
            const pages = {}
            for (let i = 0; i < apps.length; i++) {
                if (!apps[i]) {
                    continue
                }
                const appPages = validPages(apps[i])
                for (let j = 0; j < appPages.length; j += 1) {
                    const page = appPages[j]
                    if ((page.draft && !userPermissions(UserRole.AppsEdit))
                        || page.pageType == PageType.Info) {
                        continue
                    }
                    // Similar page fixes
                    if (!(page.pageType in pages)) {
                        pages[page.pageType] = 1
                    } else {
                        pages[page.pageType] += 1
                    }
                }
            }
            const appCount = apps.filter((x) => x != null).length
            const newPages = Object.keys(pages).filter((x) => {
                return pages[x] == appCount && commonSet.has(x)
            }).sort((a, b) => orderSet[a] - orderSet[b]).map((x) => ({ pageType: x, link: x, name: x.toTitleCase() }))

            if (newPages.length > 0) {
                newPages.unshift({ pageType: '', link: '', name: 'Info' })
            }
            setPages(newPages)
        }
    }, [app, apps, loggedIn, exclusive])

    useEffect(() => {
        // Force a re-render
        if (onResize) {
            onResize()
        }
    }, [expanded])

    function toggleCollapse() {
        if (onCollapse) {
            onCollapse(!collapsed)
        }
        setCollapsed(!collapsed)
        // Resize next frame
        setTimeout(() => {
            window.dispatchEvent(new CustomEvent(CustomEventType.ResizeSidebar))
        }, 0)
    }
    function getIcon(page) {
        if (page.icon) {
            return page.icon
        }

        const pageLink = page.link
        const typeName = page.pageType
        if (typeName == 'custom' && pageLink in customIcons) {
            return customIcons[pageLink]
        }
        if (typeName in pageIcons) {
            return pageIcons[typeName]
        }
        return null
    }

    function handleClick(x) {
        if (app) {
            if (x.pageType == PageType.External) {
                window.open(x.link, '_blank')
            } else {
                dispatch(navigateAsync({ app: app, pageType: x.link, options: { splitIndex } }))
            }
        } else if (apps) {
            dispatch(navigateFeature(x.link))
        }
    }


    function getFeature(x, ix) {
        const typeName = x.pageType
        const pageSelected = x.link == selected
        const pageClass = `project-feature${pageSelected ? ' selected' : ''}${collapsible && !collapsed ? ' uncollapsed' : ''} animate__animated animate__fadeIn`
        let buttonClass = ''
        if (shake == 'all' || shake == x.link) {
            buttonClass = "animate__animated animate__headShake"
        }
        const id = `nav-${x.link}`
        /*jif (animating) {
            pageClass += 'animate__animated animate__fadeIn animate__slow'
        }*/
        if (!showInfo && x.pageType == PageType.Info) {
            return null
        }

        const style = { opacity: 1 }
        // if ((expanded && (x.pageType == PageType.Custom || x.pageType == PageType.External))
        if ((expanded && x.pageType == PageType.Custom)
            || (collapsible && !expanded && collapsed && featureLimit == 1 && selectedIndex != ix && !(selectedIndex == -1 && x.pageType == PageType.Info))) {
            style.position = 'absolute'
            style.left = '500%'
            style.opacity = 0
            style.visibility = 'hidden'
            // return null
        }
        /*if(collapsible && !expanded && featureLimit == 1 && selectedIndex != ix) {
            style.backgroundColor:red;
        }*/
        let name = x.name
        if(x.draft) {
            name = `${name} (Draft)`
        } else if (x.exclusive && !salescenter) {
            name = `${name} (VIP)`
        }

        if (splitIndex == null && !apps) {
            let appPath = null
            if(customDomain.app) {
                appPath = `${projectPath}`
            } else {
                appPath = `${projectPath}${app.meta.link}/`
            }
            if (x.pageType == PageType.External) {
                return <a id={id} key={ix} className={pageClass} href={x.link} target="_blank" style={style}>
                    <Button icon={getIcon(x)} className={buttonClass} noBg>
                        {name}
                    </Button>
                </a>
            }
            return <Link id={id} key={ix} className={pageClass} to={`${appPath}${x.link}`} style={style}>
                <Button icon={getIcon(x)} className={buttonClass} noBg>
                    {name}
                </Button>
            </Link>
        }
        return <a id={id} key={ix} className={pageClass} onClick={() => handleClick(x)} style={style}>
            <Button icon={getIcon(x)} className={buttonClass} noBg>
                {name}
            </Button>
        </a>
    }

    if (!expanded && collapsible && (screen.orientation == ScreenOrientation.Landscape || screen.isMobile)) {
        return <React.Fragment>
            {pages.length > 0 && screen.isMobile && <a className={`project-feature hamburger${!collapsed ? ' selected--' : ''}`}><IconButton noBg icon={collapsed ? "fas fa-angle-left" : "fas fa-angle-right"} onClick={toggleCollapse} /></a>}
            {pages.map(getFeature)}
            {props.children}
        </React.Fragment>
    }

    return <React.Fragment>
        {props.children}
        {pages.map(getFeature)}
    </React.Fragment>
}