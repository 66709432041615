import React, {
    useRef,
    useState,
    useEffect,
    RefObject,
} from 'react'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
    EmailShareButton,
    EmailIcon,
    WhatsappShareButton,
    WhatsappIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    PinterestShareButton,
    PinterestIcon,
} from 'react-share'
import { PromptOptions, EmailType, EmailOptions } from 'app/types'
import * as fnc from 'helpers/fnc'
import {
    pushNotification,
    showPrompt,
    sendEmail,
} from 'actions/appActions'
import {
    CloseButton,
} from 'components'
import { icons } from 'app/constants'

interface CopyToClipboardButtonProps {
    input: RefObject,
    children: JSX.Element,
    size: number,
    round: boolean,
}
function CopyToClipboardButton(props: CopyToClipboardButtonProps) {
    const { input, size, round } = props
    const dispatch = useAppDispatch()

    function handleClick() {
        if (fnc.copyToClipboard(input.current.value)) {
            dispatch(pushNotification({ message: 'Link copied to clipboard' }))
        } else {
            dispatch(pushNotification({ message: 'There was a problem copying to the clipboard.' }))
        }
    }
    const style = {
        backgroundColor: 'transparent',
        border: 'none',
        padding: '0px',
        font: 'inherit',
        color: 'inherit',
        cursor: 'pointer',
        width: `${size}px`,
        height: `${size}px`,
    }

    const iconStyle = {
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: round ? '50%' : 0,
        fontSize: `${size / 2}px`,
        lineHeight: `${size}px`,
    }

    return <button aria-label="copy" className="react-share___ShareButton share-copy" style={style} onClick={handleClick}>
        <div style={iconStyle} className={icons.copy}></div>
    </button>
}

interface ShareDialogProps {
    title: string,
    description: string,
    media: string,
    onClose: () => void,
    animation: string,
    message: string,
    emailType: EmailType,
}

export function ShareDialog(props: ShareDialogProps) {
    const {
        title,
        description,
        media,
        onClose,
        animation,
        message,
        emailType,
    } = {
        emailType: EmailType.General,
        ...props,
    }

    const dispatch = useAppDispatch()
    const organization = useAppSelector((state: RootState) => state.app.organization)

    const [url, setUrl] = useState(props.url ? props.url : window.location.href)

    function handleEmail() {
        // dispatch(resolvePrompt(true))
        dispatch(showPrompt(PromptOptions.EmailShare))
            .then((x: { payload: EmailOptions }) => {
                if (x.payload && x.payload.value) {
                    return Promise.all([
                        dispatch(showPrompt(PromptOptions.EmailSending)),
                        dispatch(sendEmail({
                            emailType,
                            email: x.payload.value,
                            url,
                            options: { optIn: x.payload.optIn },
                        })),
                    ])
                }
                throw x
            })
            .catch((e) => {
                throw e
            })
    }

    const inputRef = useRef()
    const hashtags = []
    const size = 64
    const round = false
    if (organization && organization.link != 'homegyde') {
        if (organization.hashtag) {
            hashtags.push(organization.hashtag)
        } else {
            hashtags.push(organization.link)
        }
    }
    hashtags.push('homegyde')

    return <div className={`dialog ${animation}`} onClick={(e) => e.stopPropagation()} onMouseDown={(e) => e.stopPropagation()}>
        <div className="title">
            <h2> Share </h2>
            {title && <h4 className="noselect">{message}</h4>}
        </div>
        <div className="dialog-wrapper share-wrapper">
            <CloseButton onClick={onClose} />
            <div className="share-group" style={{ height: 0, visibility: 'hidden' }}>
                <input className="share-input" readOnly value={url} ref={inputRef} />
            </div>
            <div className="share-group">
                <CopyToClipboardButton input={inputRef} size={size} round={round} />
                <button onClick={handleEmail} className="react-share__ShareButton" style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    padding: '0px',
                    font: 'inherit',
                    color: 'inherit',
                    cursor: 'pointer',
                    width: '64px',
                    height: '64pxl',
                }}>
                    <EmailIcon size={size} round={round} />
                </button>
                {/* <EmailShareButton
                    url={url}
                    subject={title}
                    body={description}>
                    <EmailIcon size={size} round={round}/>
                </EmailShareButton> */}
                <WhatsappShareButton
                    url={url}
                    title={title}>
                    <WhatsappIcon size={size} round={round} />
                </WhatsappShareButton>
                <FacebookShareButton
                    url={url}
                    quote={title}
                    hashtag={hashtags.map((x) => `#${x}`).join(' ')}>
                    <FacebookIcon size={size} round={round} />
                </FacebookShareButton>
                <LinkedinShareButton
                    url={url}
                    title={title}
                    summary={description}
                    source={organization ? organization.name.toTitleCase() : 'Home Gyde'}>
                    <LinkedinIcon size={size} round={round} />
                </LinkedinShareButton>
                <TwitterShareButton
                    url={url}
                    title={title}
                    hashtags={hashtags}>
                    <TwitterIcon size={size} round={round} />
                </TwitterShareButton>
                {media && false && <PinterestShareButton
                    url={url}
                    media={media}
                    description={description}>
                    <PinterestIcon size={size} round={round} />
                </PinterestShareButton>}
            </div>
        </div>
    </div>
}
